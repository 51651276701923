import React, { useEffect, useRef, useState } from "react";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import { connect } from "react-redux";
import { Breadcrumb, Input, Layout, Pagination, Table } from "antd";
import noRecord from "../../../Utility/images/norecord.png";
import { Content } from "antd/es/layout/layout";
import Loader from "../../../Home/Loader/Loader";
import { getDemoQueryData } from "../../../redux/actions/API/demoQueryAction";

interface IRequestQueryCollectionProps {
  demoQueryData: any[];
  getDemoQueryData: Function;
  getDemoQueryDataState: IDataResponse;
}

const RequestQueryCollection: React.FC<IRequestQueryCollectionProps> = ({
  demoQueryData,
  getDemoQueryData,
  getDemoQueryDataState,
}) => {
  const prevPropsRef = useRef<IRequestQueryCollectionProps>();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getDemoQueryData();
  }, []);

  useEffect(() => {
    if (
      prevPropsRef?.current?.getDemoQueryDataState?.loading &&
      !getDemoQueryDataState?.loading
    ) {
      if (getDemoQueryDataState?.error) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    prevPropsRef.current = {
      demoQueryData,
      getDemoQueryData,
      getDemoQueryDataState,
    };
  }, [getDemoQueryDataState]);

  const columns = [
    {
        title: "S.No",
        dataIndex: "serialNo",
        key: "serialNo",
        render: (_: any, __: any, index: number) => index + 1,
      },
    { title: "Name", dataIndex: "name", key: "name", width: '300px' },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Phone", dataIndex: "phone", key: "phone" },
    { title: "Company Name", dataIndex: "companyName", key: "companyName", width: '300px' },
    { title: "Company Website", dataIndex: "companyWebsite", key: "companyWebsite" },
    { title: "Company Size", dataIndex: "companySize", key: "companySize" },
    { title: "Company ERP", dataIndex: "currentERP", key: "currentERP" },
    { title: "Industry", dataIndex: "industry", key: "industry" },
    { title: "Query", dataIndex: "query", key: "query" },
    { 
      title: "Modules", 
      dataIndex: "modules", 
      key: "modules",
      render: (modules: string) => {
        try {
          return JSON.parse(modules).join(", ");
        } catch (error) {
          return modules;
        }
      },
      width: '300px'
    },
    { title: "Source", dataIndex: "source", key: "source" },
  ];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredData = demoQueryData?.filter((query) =>
    Object.values(query)?.some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchTerm)
    )
  );  

  const paginatedData = filteredData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handlePaginationChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) setPageSize(pageSize);
  };

  return (
    <>
      <Loader loading={loading}></Loader>
      <Layout className="layout">
        <Breadcrumb items={[{ title: "Dashboard" }, { title: "Query" }]} />
        <Content className="content-section">
          <div className="flexBox">
            <h5 style={{ color: "#1890ff" }}>Demo Requests</h5>
            <div className="flexBox">
              <Input placeholder="Search query" onChange={handleSearch} />
            </div>
          </div>

          {paginatedData?.length > 0 ? (
            <>
              <Table
                className="mt-10"
                columns={columns}
                dataSource={paginatedData?.reverse()?.map((item) => ({
                  ...item,
                  key: item.id,
                }))}
                pagination={false}
                bordered
              />
              <Pagination
                className="mt-10"
                current={currentPage}
                pageSize={pageSize}
                total={filteredData?.length}
                onChange={handlePaginationChange}
                showSizeChanger
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
              />
            </>
          ) : (
            <div className="noData">
              <img
                src={noRecord}
                alt="No Records Found"
                style={{ width: "auto", height: "300px" }}
              />
            </div>
          )}
        </Content>
      </Layout>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  demoQueryData: state.api.getDemoQueryData?.data?.data,
  getDemoQueryDataState: state.api?.getDemoQueryData,
});

const mapDispatchToProps = (dispatch: any) => ({
  getDemoQueryData: (payload: any) => dispatch(getDemoQueryData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestQueryCollection);
