import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./AboutUs.css";

import { Row, Col, Typography, Card, Space, List } from "antd";

const { Title, Paragraph, Text } = Typography;

const keyFeatures = [
  "✔ Automated Inventory Management – Reduce wastage and optimize stock levels",
  "✔ Seamless Sales & Purchase Tracking – Stay on top of orders, invoices, and payments",
  "✔ Production Planning & Scheduling – Manage work orders and production timelines effortlessly",
  "✔ Real-time Analytics & Reporting – Gain actionable insights for data-driven decisions",
];

const AboutUs: React.FC = () => {
  return (
    <>
      <Header />
      <div className="container" id="aboutPage">
        <Title level={2} style={{ color: "#333" }}>
          Empowering Indian Manufacturing with Ease Margin
        </Title>
        <Paragraph style={{ fontSize: "16px", lineHeight: "1.8", color: "#555" }}>
          In today’s fast-paced industrial world, manufacturers need efficient, streamlined, and technologically advanced tools to remain competitive.
          <b> Ease Margin was created with one simple yet powerful goal:</b> to empower India's manufacturing sector with an advanced, yet easy-to-use manufacturing management software.
          By optimizing processes, improving efficiency, and enabling seamless workflow automation, Ease Margin helps businesses enhance their profit margins while keeping operations simple.
        </Paragraph>
        {/* About Organization */}
        <section>
          <Row justify="center">
            <Col xs={24} sm={24} md={24} lg={24}>
              <div style={{ background: '#f9f9f9', padding: '20px' }}>

                <Title level={3} style={{ color: "#333", marginTop: "20px" }}>
                  Bridging the Gap in Manufacturing Management
                </Title>
                <Paragraph style={{ fontSize: "16px", lineHeight: "1.8", color: "#555" }}>
                  India’s manufacturing sector is growing rapidly, contributing significantly to the country’s economy. However, many businesses still rely on manual processes, outdated systems, or fragmented software solutions that create inefficiencies.
                  <b> Ease Margin bridges this gap</b> by offering a modern, AI-powered, and cloud-based ERP system that simplifies production planning, inventory management, sales tracking, and overall operations.
                </Paragraph>

                {/* <Title level={3} style={{ color: "#333", marginTop: "20px" }}>
                  Simplifying Complex Manufacturing Workflows
                </Title>
                <Paragraph style={{ fontSize: "16px", lineHeight: "1.8", color: "#555" }}>
                  Many ERP systems are overly complex and difficult to implement, especially for small and mid-sized manufacturers.
                  <b> Ease Margin takes a user-first approach</b>, ensuring that businesses get all the advanced features they need without unnecessary complexity.
                </Paragraph>

                <List
                  dataSource={keyFeatures}
                  renderItem={(item) => (
                    <List.Item style={{ border: "none", fontSize: "16px", color: "#555" }}>
                      {item}
                    </List.Item>
                  )}
                />

                <Title level={3} style={{ color: "#333", marginTop: "20px" }}>
                  Boosting Profit Margins with Smart Automation
                </Title>
                <Paragraph style={{ fontSize: "16px", lineHeight: "1.8", color: "#555" }}>
                  One of the biggest challenges manufacturers face is reducing costs while maintaining quality.
                  <b> Ease Margin helps businesses improve their profit margins</b> by eliminating inefficiencies, reducing manual errors, and optimizing every aspect of production and resource management.
                </Paragraph>

                <Title level={3} style={{ color: "#333", marginTop: "20px" }}>
                  Empowering Indian Businesses for Global Success
                </Title>
                <Paragraph style={{ fontSize: "16px", lineHeight: "1.8", color: "#555" }}>
                  With India's "Make in India" initiative and the rapid push toward digital transformation, manufacturers are looking for ways to stay ahead of global competition.
                  <b> Ease Margin enables businesses to adopt world-class manufacturing practices</b>, ensuring they can compete on both local and international levels.
                </Paragraph>

                <Paragraph style={{ fontSize: "16px", lineHeight: "1.8", color: "#555" }}>
                  From small-scale manufacturers to large enterprises, Ease Margin’s ERP solution is scalable, affordable, and highly customizable, making it the perfect tool for businesses looking to grow without being held back by operational inefficiencies.
                </Paragraph> */}
              </div>
            </Col>
          </Row>
        </section>

        {/* Mission Statement */}
        <Row justify="center">
          <Col xs={24} md={24}>
            <div style={{ width: "100%", padding: "20px", background: "#f5f5f5", fontSize: '16px' }}>
              <Title level={3}>Our Mission</Title>
              <Paragraph style={{ fontSize: '16px' }}>
                At <b>Ease Margin</b>, our mission is to <b>empower India's manufacturing sector</b> by providing an
                <b> intelligent, efficient, and easy-to-use ERP solution</b> that helps businesses <b>streamline operations,
                  reduce inefficiencies, and maximize profitability.</b>
              </Paragraph>

              <Paragraph>
                We are committed to:
              </Paragraph>

              <ul>
                <li><b>Simplifying Manufacturing Processes</b> – Eliminating manual errors and automating workflows for seamless production management.</li>
                <li><b>Driving Innovation</b> – Leveraging AI-powered insights and modern technology to optimize supply chains, inventory, and sales tracking.</li>
                <li><b>Enhancing Business Growth</b> – Helping manufacturers scale effortlessly with a cost-effective, cloud-based ERP system tailored to their needs.</li>
                <li><b>Enabling Transparency & Control</b> – Providing real-time analytics, intuitive dashboards, and seamless integrations for better decision-making.</li>
              </ul>
              <br></br>
              <Paragraph style={{ fontSize: '16px' }}>
                At Ease Margin, we <b>bridge the gap between traditional manufacturing challenges and modern digital solutions</b>, making manufacturing
                smarter, faster, and more profitable.
              </Paragraph>
            </div>
          </Col>
        </Row>

        {/* Core Values */}
        <Row gutter={[16, 16]} justify="center" style={{ marginTop: "50px" }}>
          <Col span={24}>
            <Title level={3} >Our Core Values</Title>
          </Col>

          {[
            { title: "Innovation", desc: "We embrace new technologies to continuously improve our solutions." },
            { title: "Collaboration", desc: "We work as a team to deliver the best results for our clients." },
            { title: "Ownership", desc: "We take responsibility for our actions and strive for excellence." },
            { title: "Transparency", desc: "We believe in open communication and honest business practices." }
          ].map((value, index) => (
            <Col xs={24} sm={12} md={6} key={index}>
              <Card style={{ borderRadius: "10px", background: '#1780dd' }}>
                <Title level={4} style={{ color: '#fff' }}>{value.title}</Title>
                <Paragraph style={{ color: '#fff' }}>{value.desc}</Paragraph>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Target Market Summary */}
        <Row justify="center" gutter={[16, 16]} style={{ marginTop: "50px" }}>
          <Col xs={24} md={24}>
            <Card>
              <Title level={3}>Target Market</Title>
              <Paragraph style={{ fontSize: "16px", color: "#555" }}>
                Ease Margin is tailored to empower India’s Small and Medium-Sized Enterprises (SMEs) in the manufacturing sector, aiming to streamline and optimize their daily operations such as inventory management,production tracking and sales and purchase activity. Growing with technology is a necessity and our manufacturers are a strong pillar of India's growing economy.
                Ease Margin helps manufacturers across the sectors ranging from consumer goods,pharmaceutical,chemicals,foods & beverages,textile and engineering.Due to inadequate manual method of managing the daily operations there has always been a possibility of errors and mistakes.Which in returns have hindered the growth at which the business should actually grow.Ease Margin solution helps to define your own profit margin by streamlining all the daily operations through a simple yet a powerful software.
              </Paragraph>
            </Card>
          </Col>
        </Row>

        {/* Company History */}
        <div style={{ width: "100%", background: "#ffffff" }}>
          <Title level={2}>Brief Company History</Title>
          <Paragraph>
            <b>Saif, Harish, and Rahul</b> founded <b>Ease Margin</b> with a vision to solve the challenges manufacturers face in their daily operations.
            Many businesses struggle with <b>productivity loss</b> due to manual processes, leading to reduced profitability and limited scalability.
          </Paragraph>

          <Paragraph>
            Having spent years in the manufacturing sector, we recognized the urgent need for a <b>smart, automated, and efficient solution</b> to help manufacturers manage their operations seamlessly.
            Our goal is to empower businesses with <b>best-in-class software</b> that simplifies manufacturing management and contributes to India's self-reliance movement.
          </Paragraph>

          <Paragraph>
            Our journey began with a strong mission: to <b>reach every SME in India</b> and provide an <b>affordable, user-friendly, and manufacturer-centric ERP solution</b>.
            Ease Margin is designed to make businesses more productive, profitable, and future-ready.
          </Paragraph>
        </div>


      </div>
      <Footer></Footer>
    </>
  );
};

export default AboutUs;
