import { TableColumnsType, Checkbox, Input } from "antd";
import ColumnWidths from "./columnWidth";
import React from "react";

interface SalesDataType {
  documentNumber: number;
  documentType: string;
  documentDate: string;
  debitCreditSetOff: string;
  tdsAmount: number;
  advancePaid: number;
  lastModifiedAt: string;
  totalAmountReceivable: number;
  markReceived: boolean;
}

interface PurchaseDataType {
  company: string;
  documentType: string;
  documentNumber: number;
  documentDate: string;
  paymentDueDate: string;
  totalAmountPayable: number;
  debitCreditSetOff: string;
  amountPaid: number;
  balanceAmount: number;
  logPayment: string;
  markPaid: boolean;
  tdsAmount: number | null;
}

//const [dataSource, setDataSource] = React.useState<PurchaseDataType[]>(Purchase);

const handleMarkPaidChange = (checked: boolean, record: PurchaseDataType) => { 
  // const updatedData = dataSource.map((item) =>
  //   item.documentNumber === record.documentNumber
  //     ? { ...item, markPaid: checked }
  //     : item
  // );
  // setDataSource(updatedData);
};

export const salesColumns: TableColumnsType<SalesDataType> = [
  {
    title: "Document Number",
    dataIndex: "documentNumber",
    key: "documentNumber",
    sorter: (a, b) => a.documentNumber - b.documentNumber,
    width: ColumnWidths.DEFAULT,
  },
  {
    title: "Document Type",
    dataIndex: "documentType",
    key: "documentType",
    sorter: (a, b) => a.documentType.localeCompare(b.documentType),
    width: ColumnWidths.DEFAULT,
  },
  {
    title: "Document Date",
    dataIndex: "documentDate",
    key: "documentDate",
    sorter: (a, b) =>
      new Date(a.documentDate).getTime() - new Date(b.documentDate).getTime(),
    width: ColumnWidths.DEFAULT,
  },
  {
    title: "Debit/Credit Set-Off",
    dataIndex: "debitCreditSetOff",
    key: "debitCreditSetOff",
    sorter: (a, b) => a.debitCreditSetOff.localeCompare(b.debitCreditSetOff),
    width: ColumnWidths.DEFAULT,
  },
  {
    title: "TDS Amount",
    dataIndex: "tdsAmount",
    key: "tdsAmount",
    sorter: (a, b) => a.tdsAmount - b.tdsAmount,
    width: ColumnWidths.DEFAULT,
  },
  {
    title: "Advance Paid",
    dataIndex: "advancePaid",
    key: "advancePaid",
    sorter: (a, b) => a.advancePaid - b.advancePaid,
    width: ColumnWidths.DEFAULT,
  },
  {
    title: "Total Amount Receivable",
    dataIndex: "totalAmountReceivable",
    key: "totalAmountReceivable",
    sorter: (a, b) => a.totalAmountReceivable - b.totalAmountReceivable,
    width: ColumnWidths.DEFAULT,
  },
  {
    title: "Last Modified At",
    dataIndex: "lastModifiedAt",
    key: "lastModifiedAt",
    sorter: (a, b) =>
      new Date(a.lastModifiedAt).getTime() -
      new Date(b.lastModifiedAt).getTime(),
    width: ColumnWidths.DEFAULT,
  },
  {
    title: "Mark Received",
    dataIndex: "markReceived",
    key: "markPaid",
    width: ColumnWidths.DEFAULT,
    render: (markReceived: boolean, record: SalesDataType) =>
      React.createElement(Checkbox, {
        checked: markReceived,
        //onChange: (e) => handleMarkPaidChange(e.target.checked, record),
      }),
  },
];

export const purchaseColumns: TableColumnsType<PurchaseDataType> = [
  {
    title: "Company Name",
    dataIndex: "company",
    key: "company",
    sorter: (a, b) => a.company.localeCompare(b.company),
    width: ColumnWidths.DEFAULT,
  },
  {
    title: "Document Type",
    dataIndex: "documentType",
    key: "documentType",
    sorter: (a, b) => a.documentType.localeCompare(b.documentType),
    width: ColumnWidths.DEFAULT,
  },
  {
    title: "Document Number",
    dataIndex: "documentNumber",
    key: "documentNumber",
    sorter: (a, b) => a.documentNumber - b.documentNumber,
    width: ColumnWidths.DEFAULT,
  },
  {
    title: "Creation Date",
    dataIndex: "documentDate",
    key: "documentDate",
    sorter: (a, b) =>
      new Date(a.documentDate).getTime() - new Date(b.documentDate).getTime(),
    width: ColumnWidths.DEFAULT,
  },
  {
    title: "Due Date",
    dataIndex: "paymentDueDate",
    key: "paymentDueDate",
    sorter: (a, b) =>
      new Date(a.paymentDueDate).getTime() -
      new Date(b.paymentDueDate).getTime(),
    width: ColumnWidths.DEFAULT,
  },
  {
    title: "Debit/Credit Set-off",
    dataIndex: "debitCreditSetOff",
    key: "debitCreditSetOff",
    sorter: (a, b) => a.amountPaid - b.amountPaid,
    width: ColumnWidths.DEFAULT,
    render: (value) => value ?? "",
  },
  {
    title: "Total Amount",
    dataIndex: "totalAmountPayable",
    key: "totalAmountPayable",
    sorter: (a, b) => a.totalAmountPayable - b.totalAmountPayable,
    width: ColumnWidths.DEFAULT,
  },
  {
    title: "Amount Paid",
    dataIndex: "amountPaid",
    key: "amountPaid",
    sorter: (a, b) => a.amountPaid - b.amountPaid,
    width: ColumnWidths.DEFAULT,
  },
  {
    title: "TDS Amount",
    dataIndex: "tdsAmount",
    key: "tdsAmount",
    sorter: (a, b) => a.amountPaid - b.amountPaid,
    width: ColumnWidths.DEFAULT,
    render: (value) => value ?? "",
  },
  {
    title: "Balance Amount",
    dataIndex: "balanceAmount",
    key: "balanceAmount",
    sorter: (a, b) => a.balanceAmount - b.balanceAmount,
    width: ColumnWidths.DEFAULT,
    render: (value) => value ?? "",
  },
  // {
  //   title: "Log Payment",
  //   key: "logPayment",
  //   width: ColumnWidths.DEFAULT,
  //   //sorter: (a, b) => a.logPayment.localeCompare.(b.logPayment), // Handle undefined values safely
  //   render: (logPayment:string | null | undefined, record: PurchaseDataType) =>
  //     React.createElement(Input, {
  //       defaultValue: typeof logPayment === "string" ? logPayment : "", // If no value, show an empty input
  //       placeholder: "Enter amount", // Placeholder text
  //       //onChange: (e) => handleLogPaymentChange(e.target.value, record) // Pass value to handler
  //     }),
  // },
  // {
  //   title: "Mark Paid",
  //   dataIndex: "markPaid",
  //   key: "markPaid",
  //   width: ColumnWidths.DEFAULT,
  //   render: (markPaid: boolean, record: PurchaseDataType) =>
  //     React.createElement(Checkbox, {
  //       checked: markPaid,
  //       onChange: (e) => handleMarkPaidChange(e.target.checked, record),
  //     }),
  // },
];
