import React from "react";

import help from "../../../Utility/images/help.png";

interface IContactDetailsHeaderProps {}
const ContactDetailsHeader: React.FC<IContactDetailsHeaderProps> = ({}) => {
  return (
    <>
      <section id="banner-9" className="bg--02 py-70 x-border banner-section">
        <div className="container">
          <div className="banner-7-wrapper Mt-20">
            <div className="row d-flex align-items-center banner-margin">
              <div className="col-md-7 col-xl-5">
                <div className="banner-9-txt">
                  <h3 className="s-40 w-700">Still need help?</h3>
                  <p className="p-lg">
                    Don't hesitate to contact us about any question you might be
                    interested in
                  </p>
                </div>
              </div>
              <div className="col-md-5 col-xl-5">
                <div className="banner-9-img text-end">
                  <img className="img-fluid" src={help} alt="banner-image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactDetailsHeader;
