import * as type from '../../types/API/logisticDetails'

export function getLogisticDetails(payload: any) {
    return {
        type: type.GET_LOGISTIC_DETAILS,
        payload: payload
    }
}

export function addLogisticDetails(payload: any) {
    return {
        type: type.ADD_LOGISTIC_DETAILS,
        payload: payload
    }
}

export function editLogisticDetails(payload: any) {
    return {
        type: type.EDIT_LOGISTIC_DETAILS,
        payload: payload
    }
}

export function deleteLogisticDetails(payload: any) {
    return {
        type: type.DELETE_LOGISTIC_DETAILS,
        payload: payload
    }
}
