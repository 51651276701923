import * as type from '../../types/API/categoryType';
import { IAction } from '../userMetadataReducer';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const getCategoryReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.GET_CATEGORY:
      return {
        ...state,
        loading: true,
      };
    case type.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.responseData,
      };
    case type.GET_CATEGORY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const addCategoryReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.ADD_CATEGORY:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.ADD_CATEGORY_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.ADD_CATEGORY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const editCategoryReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.EDIT_CATEGORY:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.EDIT_CATEGORY_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.EDIT_CATEGORY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const deleteCategoryReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.DELETE_CATEGORY:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.DELETE_CATEGORY_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.DELETE_CATEGORY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
