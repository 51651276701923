import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Badge, Divider, Drawer, Space, Tag } from "antd";
import logo from "../../Utility/images/logo.png";
import ease from "../../Utility/images/logo.png";
import "../../Utility/css/bootstrap.min.css";
import "../../Utility/css/flaticon.css";
import "../../Utility/css/menu.css";
import "../../Utility/css/magnific-popup.css";
import "../../Utility/css/owl.carousel.min.css";
import "../../Utility/css/owl.theme.default.min.css";
import "../../Utility/css/lunar.css";
import "../../Utility/css/animate.css";
import "../../Utility/css/pink-theme.css";
import "../../Utility/css/blue-theme.css";
import "../../Utility/css/responsive.css";
import "../../Utility/css/fonts/rubik.css";
import "../../Utility/css/fonts/jakarta.css";
import "../../Utility/css/fonts/inter.css";

import {
  MenuOutlined,
} from '@ant-design/icons';

import "./Header.css";
import { Button } from "jodit/esm/modules";

const Header: React.FC = () => {
  const [drawerVisible, setDrawerVisible] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const linkStyle = (path: string) => ({
    color: currentPath === path ? "#1780FB" : "black",
  });

  const scrollToFeatures = () => {
    closeDrawer();
    if (currentPath !== "/") {
      navigate("/");
      setTimeout(() => {
        const featuresSection = document.getElementById("features-13");
        if (featuresSection) {
          featuresSection.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    } else {
      const featuresSection = document.getElementById("features-13");
      if (featuresSection) {
        featuresSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <>
      <header
        id="header"
        style={{ height: '70px' }}
        className="tra-menu navbar-dark white-scroll header-container"
      >
        <div className="header-wrapper">
          <div className="wsmobileheader clearfix">
            <MenuOutlined style={{ fontSize: "32px !important", color: "#1890ff" }} onClick={showDrawer} />
            <Link to="/">
              <img className="logo-ease" src={ease} alt="sign" />
            </Link>
          </div>
          <div className="wsmainfull menu clearfix">
            <div className="wsmainwp clearfix">
              <div className="desktoplogo">
                <Link to="/" className="logo-black">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <nav className="wsmenu clearfix">
                <ul className="wsmenu-list nav-theme">
                  <li aria-haspopup="true">
                    <Link to="/" style={linkStyle("/")} className="h-link">
                      Home
                    </Link>
                  </li>
                  <li aria-haspopup="true">
                    <Link
                      to="/about_us"
                      style={linkStyle("/about_us")}
                      className="h-link"
                    >
                      About Us
                    </Link>
                  </li>
                  <li aria-haspopup="true">
                    <Link
                      to="/payment-invoice"
                      style={linkStyle("/payment-invoice")}
                      className="h-link"
                    >
                      Free Invoicing <Tag color="#108ee9">Beta</Tag>
                    </Link>
                  </li>
                  <li aria-haspopup="true">
                    <Link
                      to="/our-blogs"
                      style={linkStyle("/our-blogs")}
                      className="h-link"
                    >
                      Blogs
                    </Link>
                  </li>
                  <li aria-haspopup="true">
                    <div className="loginLink" onClick={() => {navigate("/sign-in");}}>Login</div>
                    {/* <Link
                      to="/sign-in"
                      style={linkStyle("/login")}
                      className="h-link"
                    >
                      Login
                    </Link> */}
                  </li>
                  <li aria-haspopup="true">
                    {/* <Link
                      to="/requestdemo"
                      style={linkStyle("/requestdemo")}
                    > */}
                      <div className="demoLink" onClick={() => {navigate("/requestdemo");}}>Request a Demo</div>
                    {/* </Link> */}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <Drawer
        placement="left"
        closable={false}
        onClose={closeDrawer}
        open={drawerVisible}
        width="260px"
        className="mobile-menu-body"
      >
        {/* Main Navigation */}
        <div className="menu-section">
          <h4 className="menu-title">Main</h4>
          <div className="text-bar">
            <Link to="/" onClick={closeDrawer}>Home</Link>
          </div>
          <div className="text-bar">
            <a href="#" onClick={scrollToFeatures}>About</a>
          </div>
          <div className="text-bar">
            <Link to="/payment-invoice" onClick={closeDrawer}>
              Free Invoicing <Tag color="#108ee9">Beta</Tag>
            </Link>
          </div>
          <div className="text-bar">
            <Link to="/our-blogs" onClick={closeDrawer}>Blogs</Link>
          </div>
        </div>

        <Divider className="menuDevider" />

        {/* Product & Features */}
        <div className="menu-section">
          <h4 className="menu-title">Product</h4>
          <div className="text-bar">
            <Link to="/features" onClick={closeDrawer}>Features</Link>
          </div>
          <div className="text-bar">
            <Link to="/integrations" onClick={closeDrawer}>Integrations</Link>
          </div>
          <div className="text-bar">
            <Link to="/industries" onClick={closeDrawer}>Industries We Serve</Link>
          </div>
        </div>

        <Divider className="menuDevider" />

        {/* Business & Support */}
        <div className="menu-section">
          <h4 className="menu-title">Business</h4>
          <div className="text-bar">
            <Link to="/pricing" onClick={closeDrawer}>Pricing</Link>
          </div>
          <div className="text-bar">
            <Link to="/request-demo" onClick={closeDrawer}>Request a Demo</Link>
          </div>
        </div>

        <Divider className="menuDevider" />

        {/* Help & Account */}
        <div className="menu-section">
          <h4 className="menu-title">Help</h4>
          <div className="text-bar">
            <Link to="/support" onClick={closeDrawer}>Support</Link>
          </div>
          <div className="text-bar">
            <Link to="/contact" onClick={closeDrawer}>Contact Us</Link>
          </div>
        </div>

        <Divider className="menuDevider" />

        {/* User Authentication */}
        <div className="menu-section">
          <h4 className="menu-title">Account</h4>
          <div className="text-bar">
            <Link to="/sign-in" onClick={closeDrawer}>Login</Link>
          </div>
        </div>
      </Drawer>

    </>
  );
};

export default Header;
