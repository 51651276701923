import {
  CLEAR_INDIVIDUAL_DATA,
  RESET_CREATE_DOCUMENT_DATA,
  SET_ADDITIONAL_DETAIL_DATA,
  SET_ADVANCE_PAYMENT,
  SET_BANK_DETAILS,
  SET_COMMENT_INPUT_DATA,
  SET_COMPANY_DATA,
  SET_DOCUMENT_ADDITIONAL_CHARGES,
  SET_DOCUMENT_ATTACHMENTS_DATA,
  SET_DOCUMENT_FORM_DATA,
  SET_DOCUMENT_ITEM_GRID_DATA,
  SET_DOCUMENT_TO,
  SET_DOCUMENT_TYPE,
  SET_DOCUMENTS_SIGNATURE_DATA,
  SET_DOCUMENTS_TERMS_AND_CONDITION_DATA,
  SET_INDIVIDUAL_DATA,
  SET_LOGISTIC_DETAIL_DATA,
  SET_SUPPLIER_DATA,
} from "../../types/UI/createDocument";


export function setDocumentCompanyData(payload: any) {
  return {
    type: SET_COMPANY_DATA,
    payload: payload,
  };
}

export function setDocumentSupplierData(payload: any) {
  return {
    type: SET_SUPPLIER_DATA,
    payload: payload,
  };
}

export function setDocumentType(payload: any) {
  return {
    type: SET_DOCUMENT_TYPE,
    payload: payload,
  };
}

export function setDocumentTo(payload: any) {
  return {
    type: SET_DOCUMENT_TO,
    payload: payload,
  };
}


export function setBankDetails(payload: any) {
  return {
    type: SET_BANK_DETAILS,
    payload: payload,
  };
}

export function setAdvancePaymentData(payload: any) {
  return {
    type: SET_ADVANCE_PAYMENT,
    payload: payload,
  };
}

export function setAdditionalDetailsData(payload: any) {
  return {
    type: SET_ADDITIONAL_DETAIL_DATA,
    payload: payload,
  };
}

export function setCommentInputData(payload: any) {
  return {
    type: SET_COMMENT_INPUT_DATA,
    payload: payload,
  };
}

export function setLogisticDetailsData(payload: any) {
  return {
    type: SET_LOGISTIC_DETAIL_DATA,
    payload: payload,
  };
}

export function setDocumentItemGridData(payload: any) {
  return {
    type: SET_DOCUMENT_ITEM_GRID_DATA,
    payload: payload,
  };
}

export function setDocumentAdditionalChargesData(payload: any) {
  return {
    type: SET_DOCUMENT_ADDITIONAL_CHARGES,
    payload: payload,
  };
}

export function setDocumentItemFormData(payload: any) {
  return {
    type: SET_DOCUMENT_FORM_DATA,
    payload: payload,
  };
}

export function setDocumentTermsAndConditionsData(payload: any) {
  return {
    type: SET_DOCUMENTS_TERMS_AND_CONDITION_DATA,
    payload: payload,
  };
}

export function setDocumentAttachmentsData(payload: any) { 
  return {
    type: SET_DOCUMENT_ATTACHMENTS_DATA,
    payload: payload,
  };
}

export function setDocumentSignatureData(payload: any) {
  return {
    type: SET_DOCUMENTS_SIGNATURE_DATA,
    payload: payload,
  };
}

export function resetCreateDocumentData() {
  return {
    type: RESET_CREATE_DOCUMENT_DATA,
  };
}

export const setIndividualData = (payload: any) => {
  return {
    type: SET_INDIVIDUAL_DATA,
    payload: payload,
  };
};

export function clearIndividualData () {
  return {
    type: CLEAR_INDIVIDUAL_DATA,
  }
  
};