import { Button, Col, Form, Input, notification, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import Loader from "../../../Home/Loader/Loader";
import { updateUser } from "../../../redux/actions/API/users";
import { jwtDecode } from "jwt-decode";
import { getTeams } from "../../../redux/actions/API/team";

type NotificationType = "success" | "info" | "warning" | "error";

interface IAccountInformationProps {
  teamsData: any[];
  loginData: any;
  updateprofileInfo: Function;
  getTeams: Function;
  updateprofileInfoState: IDataResponse;
}

const AccountInformation: React.FC<IAccountInformationProps> = ({
  teamsData,
  loginData,
  getTeams,
  updateprofileInfo,
  updateprofileInfoState,
}) => {
  const prevPropsRef = useRef<IAccountInformationProps>();
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification[type]({
      message: message,
      duration: 3,
    });
  };

  useEffect(() => {
    getTeams({ companyId: Number(loginData?.companyId) });
  }, []);

  useEffect(() => {
    if (loginData?.token) {
      try {
        const decodedToken: any = jwtDecode(loginData?.token);
        if (Array.isArray(teamsData)) {
          let matchingRole: string = '';
          console.log(decodedToken.role);

          if (Number(decodedToken.role) === 1) {
            matchingRole = 'Company Admin';
          } else if (Number(decodedToken.role) === 2) {
            matchingRole = 'Super Admin';
          } else {
            matchingRole = matchingRole = teamsData.find((roleData) => roleData.id === Number(decodedToken.role))?.name;
          }

          form.setFieldsValue({
            companyName: decodedToken.companyName,
            email: decodedToken.email,
            businessType: decodedToken.businessType,
            companyWebsite: decodedToken.website,
            personName: decodedToken.name,
            personContactNo: decodedToken.contactPersonNumber,
            role: matchingRole,
          });
          setLoading(false);
        }
      } catch (error) {
        console.error("Invalid token:", error);
      }
    }
  }, [teamsData, loginData?.token]);

  useEffect(() => {
    if (prevPropsRef?.current?.updateprofileInfoState?.loading && !updateprofileInfoState?.loading) {
      if (updateprofileInfoState?.error) {
        openNotificationWithIcon("error", "Failed to update account information");
        setLoading(false);
      } else {
        openNotificationWithIcon("success", "Account update successfully.");
        setLoading(false);
      }
    }
    prevPropsRef.current = {
      loginData,
      teamsData,
      getTeams,
      updateprofileInfo,
      updateprofileInfoState,
    };
  }, [updateprofileInfoState]);

  const submitAccountInfo = () => {
    form
      .validateFields()
      .then((values) => {
        let matchingRole: number | null = null;
        if (values.role == 'Company Admin') {
          matchingRole = 1;
        } else if (values.role == 'Super Admin') {
          matchingRole = 2;
        } else {
          matchingRole = teamsData.find((roleData) => roleData.name === values.role).id;
        }

        const payload = {
          userId: Number(loginData?.id),
          companyId: Number(loginData?.companyId),
          companyName: values?.companyName,
          email: values?.email,
          companyBusinessType: values?.businessType,
          website: values.companyWebsite,
          name: values.personName,
          contactNo: values.personContactNo,
          role: matchingRole,
        };
        setLoading(true);
        updateprofileInfo(payload);
      })
      .catch(() => {
        openNotificationWithIcon("error", "Please filled all the required fileds");
      });
  };

  return (
    <>
      <Loader loading={loading} />
      <Form form={form} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6}>
            <Form.Item label="Company Name" name="companyName">
              <Input disabled placeholder="Please enter company name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item label="Company Email" name="email">
              <Input disabled placeholder="Please enter company email" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item label="Company Business Type" name="businessType">
              <Input disabled placeholder="Please enter business type" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item label="Company Website" name="companyWebsite">
              <Input placeholder="Please enter company website" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6}>
            <Form.Item
              label="Contact Person Name"
              name="personName"
              rules={[{ required: true, message: "Please enter contact person name" }]}
            >
              <Input placeholder="Please enter contact person name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item
              label="Person Contact No"
              name="personContactNo"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid contact number",
                  pattern: /^[6-9]\d{9}$/,
                },
              ]}
            >
              <Input prefix="+91 " placeholder="Please enter person contact no." />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item label="Role" name="role">
              <Input disabled placeholder="Enter role" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Button type="primary" onClick={submitAccountInfo}>
            Save Changes
          </Button>
        </Row>
      </Form>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  teamsData: state.api.getTeams?.data,
  loginData: state.api.login.data,
  usersData: state.api.getUsers?.data,
  updateprofileInfoState: state.api.updateUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateprofileInfo: (payload: any) => dispatch(updateUser(payload)),
  getTeams: (payload: any) => dispatch(getTeams(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountInformation);
