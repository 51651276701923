import React, { useEffect, useRef, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import "./ContactUs.css";
import { Button, Form, Input, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import { addContactUs, getContactUs } from "../../../redux/actions/API/contactUsAction";
import { connect } from "react-redux";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import { useLocation } from "react-router-dom";
import ContactDetailsHeader from "./ContactDetailsHeader";
import Loader from "../../Loader/Loader";

interface IContactUsProps {
  addContactUs: Function;
  addContactUsState: IDataResponse;
}

type NotificationType = "success" | "info" | "warning" | "error";

const ContactUs: React.FC<IContactUsProps> = ({
  addContactUs,
  addContactUsState,
}) => {
  const [form] = Form.useForm();
  const prevPropsRef = useRef<any>();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const scrollToTop = () => {
      const element = document.getElementById("contactus");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };

    scrollToTop();
  }, [pathname]);

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

  useEffect(() => {
    if (
      prevPropsRef?.current?.addContactUsState?.loading &&
      !addContactUsState?.loading
    ) {
      if (addContactUsState?.error) {
        openNotificationWithIcon("error", addContactUsState.error.message);
        setLoading(false)
      } else {
        form.resetFields()
        openNotificationWithIcon("success", addContactUsState?.data?.message);
        setLoading(false)
      }
    }
    prevPropsRef.current = {
      getContactUs,
      addContactUs,
      addContactUsState,
    };
  }, [addContactUsState]);

  const handleSubmit = () => {
    form.validateFields()
    .then((values:any) => {
      const payload = {
        name: values.name,
        email: values.email,
        query: values.query
      };
      console.log(values);
      setLoading(true);
      addContactUs(payload);
    }).catch(() => notification.error({ message: "Please fill mandatory fields!" }));
  };


  return (
    <>
      <Loader loading={loading} />
      <div id="contactus">
        <Header />
        <ContactDetailsHeader />
        <section className="contact-main-container">
          <div className="contact-sub-container-left">
            <h2 className="heading-contact-us">Contact Us</h2>
            <div className="p-lg text-margin">
              We would love to hear from you. If you have any <br />
              questions, feedback or suggestions. Please feel
              <br /> free to reach us through our contact details.
            </div>
            <div className="p-lg text-margin">
              <b>Email:</b> <br /> info@easemargin.com
            </div>
            <div className="p-lg text-margin">
              <b>Phone:</b> <br /> +91 82693 41122, +91 82693 71122
            </div>
            <div className="p-lg text-margin">
              <b>Office Hours:</b> <br /> Monday - Saturday: 10:00 AM - 7:30 PM
              <br />
              <span>Sunday: Closed</span>
            </div>
            <div className="p-lg text-margin">
              <b>Address:</b> <br /> Priyanka apartment, Hathital, Jabalpur (M.P),
              482001
            </div>
          </div>
          <div className="contact-sub-container-right">
            <Form form={form} layout="vertical">
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Please enter your name" }]}
              >
                <Input placeholder="Enter your Name" />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input placeholder="Enter your valid Email address" />
              </Form.Item>
              <Form.Item
                name="query"
                label="Query"
                rules={[{ required: true, message: "Please enter your query" }]}
              >
                <TextArea placeholder="Enter your query" rows={4} />
              </Form.Item>
              <Button type="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </Form>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  addContactUsState: state.api.addContactUs,
});

const mapDispatchToProps = (dispatch: any) => ({
  addContactUs: (payload: any) => dispatch(addContactUs(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
