import * as type from "../../types/API/categoryType";

export function getCategories(payload: any) {
  return {
    type: type.GET_CATEGORY,
    payload: payload,
  };
}

export function addCategory(payload: any) {
  return {
    type: type.ADD_CATEGORY,
    payload: payload,
  };
}

export function editCategory(payload: any) {
  return {
    type: type.EDIT_CATEGORY,
    payload: payload,
  };
}

export function deleteCategory(payload: any) {
  return {
    type: type.DELETE_CATEGORY,
    payload: payload,
  };
}
