import React, { useState } from "react";
import { Table, Switch } from "antd";
import { LockOutlined } from "@ant-design/icons";
import data from "../../utility/permissionData.json";
import "./Permissions.css";

const Permissions: React.FC = () => {
  const [permissions, setPermissions] = useState(data);

  const onChange = (checked: boolean, role: string, field: string) => {
    setPermissions((prevPermissions) =>
      prevPermissions.map((perm) =>
        perm.role === role ? { ...perm, [field]: checked } : perm
      )
    );
  };

  const columns = [
    {
      title: "Roles",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Buyer/Supplier",
      dataIndex: "buyerSupplier",
      key: "buyerSupplier",
      render: (value: boolean, record: any) => (
        <Switch
          checked={value}
          size="small"
          onChange={(checked) =>
            onChange(checked, record.role, "buyerSupplier")
          }
        />
      ),
    },
    {
      title: "Inventory",
      dataIndex: "inventory",
      key: "inventory",
      render: (value: boolean, record: any) => (
        <Switch
          checked={value}
          size="small"
          onChange={(checked) => onChange(checked, record.role, "inventory")}
        />
      ),
    },
    {
      title: "Production",
      dataIndex: "production",
      key: "production",
      render: (value: boolean, record: any) => (
        <Switch
          checked={value}
          size="small"
          onChange={(checked) => onChange(checked, record.role, "production")}
        />
      ),
    },
    {
      title: "Role Management",
      dataIndex: "roleManagement",
      key: "roleManagement",
      render: (value: boolean, record: any) => (
        <Switch
          checked={value}
          size="small"
          onChange={(checked) =>
            onChange(checked, record.role, "roleManagement")
          }
        />
      ),
    },
    {
      title: "User Management",
      dataIndex: "userManagement",
      key: "userManagement",
      render: (value: boolean, record: any) => (
        <Switch
          checked={value}
          size="small"
          onChange={(checked) =>
            onChange(checked, record.role, "userManagement")
          }
        />
      ),
    },
  ];

  return (
    <div className="table-container-permission">
      <div className="default-permission">
        <div className="heading-element">
          <span>
            <LockOutlined className="lock-icon"></LockOutlined>
          </span>
          <span className="text-default"> Default Permissions</span>
        </div>
        <p className="paragh-default">
          Default persmission are configured and are applied for all blueprints
          that does not have blueprint specific permissions
        </p>
      </div>
      <Table
        dataSource={permissions}
        columns={columns}
        rowKey="role"
        pagination={false}
      />
    </div>
  );
};

export default Permissions;
