import * as type from '../../types/API/transporterDetails'

export function getTransporterDetails(payload: any) {
    return {
        type: type.GET_TRANSPORTER_DETAILS,
        payload: payload
    }
}

export function addTransporterDetails(payload: any) {
    return {
        type: type.ADD_TRANSPORTER_DETAILS,
        payload: payload
    }
}

export function editTransporterDetails(payload: any) {
    return {
        type: type.EDIT_TRANSPORTER_DETAILS,
        payload: payload
    }
}

export function deleteTransporterDetails(payload: any) {
    return {
        type: type.DELETE_TRANSPORTER_DETAILS,
        payload: payload
    }
}
