import * as type from "../../types/API/termsConditions";
import { IAction } from "../userMetadataReducer";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const getTermsConditionReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.GET_TERMS_CONDITION:
      return {
        ...state,
        loading: true,
      };
    case type.GET_TERMS_CONDITION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.responseData,
      };
    case type.GET_TERMS_CONDITION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const addTermsConditionReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.ADD_TERMS_CONDITION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.ADD_TERMS_CONDITION_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.ADD_TERMS_CONDITION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const editTermsConditionReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.EDIT_TERMS_CONDITION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.EDIT_TERMS_CONDITION_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.EDIT_TERMS_CONDITION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const deleteTermsConditionReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.DELETE_TERMS_CONDITION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.DELETE_TERMS_CONDITION_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.DELETE_TERMS_CONDITION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
 