export const GET_TERMS_CONDITION = 'GET_TERMS_CONDITION';
export const GET_TERMS_CONDITION_SUCCESS = 'GET_TERMS_CONDITION_SUCCESS';
export const GET_TERMS_CONDITION_FAILED = 'GET_TERMS_CONDITION_FAILED';

export const ADD_TERMS_CONDITION = 'ADD_TERMS_CONDITION';
export const ADD_TERMS_CONDITION_SUCCESS = 'ADD_TERMS_CONDITION_SUCCESS';
export const ADD_TERMS_CONDITION_FAILED = 'ADD_TERMS_CONDITION_FAILED';

export const EDIT_TERMS_CONDITION = 'EDIT_TERMS_CONDITION';
export const EDIT_TERMS_CONDITION_SUCCESS = 'EDIT_TERMS_CONDITION_SUCCESS';
export const EDIT_TERMS_CONDITION_FAILED = 'EDIT_TERMS_CONDITION_FAILED';

export const DELETE_TERMS_CONDITION = 'DELETE_TERMS_CONDITION';
export const DELETE_TERMS_CONDITION_SUCCESS = 'DELETE_TERMS_CONDITION_SUCCESS';
export const DELETE_TERMS_CONDITION_FAILED = 'DELETE_TERMS_CONDITION_FAILED'; 