import React from "react";
import logo from "../../../Utility/images/ease/withBackground/hero-15.jpg";

const DemoContent: React.FC = () => {
  return (
    <div className="left-content">
      <h2 className="heading-request-demo mb-10 mt-10">We'd love to show you around.</h2>
      <p>
        Sign up for a personalized demo today to see how ActiveCampaign drives
        business growth and to <b>learn about limited-time offers.</b>
      </p>

      <img
        src={'https://y7b6t9n6.delivery.rocketcdn.me/media/uploads/2021/06/30/illustration-2-1-min.png'}
        alt="Placeholder Demo Image"
        className="demo-request-image"
      />

      <h5 className="fw-500 mt-20 mb-20">
        YOUR CUSTOM DEMO WILL SHOW YOU HOW TO:
      </h5>
 
      {[
        "Expand your customer base",
        "Automate your marketing",
        "Create a dashboard for your business including apps and integrations that are key to success.",
        "Drive better person-person relationships with our CRM",
        "Deliver incredible customer experiences through our templates, resources, and tools",
      ].map((text, index) => (
        <div className="cbox-1 ico-15" key={index}>
          <div className="ico-wrap color--theme" style={{marginBottom:'0px'}}>
            {/* <div className=" "> */}
              <span className="flaticon-check"></span>
            {/* </div> */}
          </div>
          <div className="cbox-1-txt">
            <p>{text}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DemoContent;
