import React, { useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Input, Tag, theme, Tooltip } from 'antd';


interface TagInputFieldProps {
  value: string[]; 
  onChange: (tags: string[]) => void; 
}

const TagInputField: React.FC<TagInputFieldProps> = ({ value, onChange }) => {
  const { token } = theme.useToken();
  const [tags, setTags] = useState<string[]>([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  }; 

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue) {
      const newTags = inputValue
        .split(',')
        .map(tag => tag.trim())
        .filter(tag => tag && !tags.includes(tag)); 
      setTags([...tags, ...newTags]);
      onChange([...tags, ...newTags]); 
    }
    setInputVisible(false);
    setInputValue('');
  };

  return (
    <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
      {tags.map(tag => {
        const tagElem = (
          <Tag key={tag} closable onClose={() => handleClose(tag)}>
            <span>{tag.length > 20 ? `${tag.slice(0, 20)}...` : tag}</span>
          </Tag>
        );
        return tag.length > 20 ? <Tooltip title={tag} key={tag}>{tagElem}</Tooltip> : tagElem;
      })}
      {inputVisible ? (
        <Input
          ref={inputRef}
          type="text" 
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      ) : (
        <Tag
          style={{ height: 22, background: token.colorBgContainer, borderStyle: 'dashed' }}
          icon={<PlusOutlined />}
          onClick={() => setInputVisible(true)}
        >
          Select Type
        </Tag>
      )}
    </div>
  );
};

export default TagInputField;