import React from "react";
import {
  Button,
  Drawer,
  Form,
  Input,
  List,
  Tooltip,
  Popconfirm,
  Space,
  notification,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import type { FormInstance } from "antd/es/form";
import {
  addBankDetail,
  deleteBankDetail,
  editBankDetail,
  getBankDetail,
} from "../../../redux/actions/API/bankDetail";
import { connect } from "react-redux";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import Loader from "../../../Home/Loader/Loader";

type NotificationType = "success" | "info" | "warning" | "error";

interface BankData {
  bankName: string;
  accountHolderName: string;
  accountNumber: string;
  branch: string;
  swiftCode: string;
  IFSCCode: string;
  MICRCode: string;
  address: string;
  id: number;
}

interface BankDetailsState {
  bankData: BankData[];
  drawerVisible: boolean;
  isEditing: boolean;
  currentEditBank: BankData | null;
  loading: boolean;
  bankName: string;
  accountHolderName: string;
  accountNumber: string;
  branch: string;
  swiftCode: string;
  IFSCCode: string;
  MICRCode: string;
  address: string;
  ip_address: string;
  addedBy: string;
}

interface IBankDetailProps {
  getBankDetail: Function;
  addBankDetail: Function;
  editBankDetail: Function;
  deleteBankDetail: Function;
  addBankDetailState: IDataResponse;
  getBankDetailState: IDataResponse;
  editBankDetailState: IDataResponse;
  deleteBankDetailState: IDataResponse;
  bankDetailData: any;
  companyId: any;
  userId: any;
}

class PaymentData extends React.Component<IBankDetailProps, BankDetailsState> {
  formRef: any;
  constructor(props: IBankDetailProps) {
    super(props);
    this.state = {
      bankData: this.props.bankDetailData,
      drawerVisible: false,
      isEditing: false,
      currentEditBank: null,
      loading: false,
      bankName: "",
      accountHolderName: "",
      accountNumber: "",
      branch: "",
      swiftCode: "",
      IFSCCode: "",
      MICRCode: "",
      addedBy: "",
      address: "",
      ip_address: "",
    };

    this.formRef = React.createRef();
  }

  componentDidMount(): void {
    this.props.getBankDetail({ companyId: Number(this.props.companyId) });
  }

  componentDidUpdate(
    prevProps: Readonly<IBankDetailProps>,
    prevState: Readonly<BankDetailsState>,
    snapshot?: any
  ): void {
    if (
      prevProps.getBankDetailState?.loading &&
      !this.props.getBankDetailState?.loading
    ) {
      if (this.props.getBankDetailState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to get account details");
      } else {
        this.setState({
          loading: false,
          bankData: this.props.bankDetailData,
        });
      }
    }

    if (
      prevProps.addBankDetailState?.loading &&
      !this.props.addBankDetailState?.loading
    ) {
      if (this.props.addBankDetailState?.error?.length > 0) {
        if (
          this.props.addBankDetailState.error.includes(
            "Account details already exist"
          )
        ) {
          this.openNotificationWithIcon(
            "warning",
            "Account details already exist."
          );
        } else {
          this.openNotificationWithIcon(
            "error",
            "Failed to add account details"
          );
        }
      } else {
        this.props.getBankDetail({
          companyId: Number(this.props.companyId),
        });
        this.openNotificationWithIcon(
          "success",
          "Account Details added successfully"
        );
        this.setState({
          loading: false,
          bankData: this.props.bankDetailData,
        });
      }
    }

    if (
      prevProps.editBankDetailState?.loading &&
      !this.props.editBankDetailState?.loading
    ) {
      if (this.props.editBankDetailState?.error?.length > 0) {
        this.openNotificationWithIcon(
          "error",
          "Failed to update account details"
        );
      } else {
        this.props.getBankDetail({
          companyId: Number(this.props.companyId),
        });
        // this.openNotificationWithIcon(
        //     "success",
        //     "Account Details updated successfully"
        // );
        this.setState({
          loading: false,
          bankData: this.props.bankDetailData,
        });
      }
    }

    if (
      prevProps.deleteBankDetailState?.loading &&
      !this.props.deleteBankDetailState?.loading
    ) {
      if (this.props.deleteBankDetailState?.error?.length > 0) {
        this.openNotificationWithIcon(
          "error",
          "Failed to delete account details"
        );
      } else {
        this.props.getBankDetail({
          companyId: Number(this.props.companyId),
        });
        this.openNotificationWithIcon(
          "success",
          "Account detail deleted successfully"
        );
        this.setState({
          loading: false,
          bankData: this.props.bankDetailData,
        });
      }
    }
    if (prevProps.bankDetailData !== this.props.bankDetailData) {
      this.setState({
        // filteredData: this.props.documentSeriesData,
      });
    }
  }

  openNotificationWithIcon = (type: NotificationType, message: string) => {
    notification[type]({
      message: message,
      duration: 2,
    });
  };

  showDrawer = () => {
    this.setState({
      drawerVisible: true,
      isEditing: false,
      currentEditBank: null,
    });
  };

  onClose = () => {
    this.formRef.current?.resetFields();
    this.setState({
      drawerVisible: false,
      currentEditBank: null,
      isEditing: false,
    });
  };

  handleSubmit = (values: BankData) => {
    const {
      bankName,
      accountHolderName,
      accountNumber,
      branch,
      swiftCode,
      IFSCCode,
      MICRCode,
      address,
    } = values;
    const { bankData, isEditing, currentEditBank } = this.state;
    const formattedAccountNumber = String(accountNumber).trim();

    const accountExists = bankData.some((bank) => {
      const bankAccountNumber = String(bank.accountNumber).trim();
      const isSameAccount = bankAccountNumber === formattedAccountNumber;
      const isDifferentId =
        !isEditing || (currentEditBank && bank.id !== currentEditBank.id);
      return isSameAccount && isDifferentId;
    });

    if (accountExists) {
      this.openNotificationWithIcon(
        "error",
        "Account details already exist for this account number."
      );
      return;
    }

    if (isEditing && currentEditBank) {
      this.props.editBankDetail({
        ...values,
        id: currentEditBank.id,
        companyId: Number(this.props.companyId),
        addedBy: Number(this.props.userId),
        ip_address: "127.0.0.1",
        status: 1,
      });
      this.setState({
        loading: true,
      });
    } else {
      this.props.addBankDetail({
        bankName,
        accountHolderName,
        accountNumber,
        branch,
        swiftCode,
        IFSCCode,
        MICRCode,
        address,
        companyId: Number(this.props.companyId),
        addedBy: Number(this.props.userId),
        ip_address: "127.0.0.1",
        status: 1,
      });
      this.setState({
        loading: true,
      });
    }
    this.onClose();
    this.formRef.current?.resetFields();
  };

  handleEdit = (item: BankData) => {
    this.setState(
      {
        drawerVisible: true,
        isEditing: true,
        currentEditBank: item,
      },
      () => {
        this.formRef.current?.setFieldsValue({
          bankName: item.bankName,
          accountHolderName: item.accountHolderName,
          accountNumber: item.accountNumber,
          branch: item.branch,
          swiftCode: item.swiftCode,
          IFSCCode: item.IFSCCode,
          MICRCode: item.MICRCode,
          address: item.address,
        });
      }
    );
  };

  handleDelete = (id: number) => {
    this.setState({
      loading: true,
    });
    this.props.deleteBankDetail({
      id: id,
    });
  };

  render() {
    const { bankData, drawerVisible, isEditing, currentEditBank } = this.state;

    return (
      <div className="bankDetails">
        <Loader loading={this.state.loading}></Loader>
        <div className="flexBox">
          <p>Manage payment details here</p>
          <Button
            type="link"
            onClick={this.showDrawer}
            style={{ marginBottom: 16, marginLeft: "auto" }}
          >
            Add Bank Details
          </Button>
        </div>

        <div className="table-container">
          <List
            itemLayout="horizontal"
            dataSource={bankData}
            renderItem={(item: BankData) => (
              <List.Item
                key={item.id}
                actions={[
                  <Tooltip title="Delete">
                    <Popconfirm
                      title="Are you sure to delete this?"
                      onConfirm={() => this.handleDelete(item.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <p className="actionIcons">
                        <DeleteOutlined />
                      </p>
                    </Popconfirm>
                  </Tooltip>,
                ]}
                style={{ borderBottom: "1px solid #dddddd", padding: "10px" }}
              >
                <List.Item.Meta
                  title={item.bankName}
                  description={
                    <div>
                      <div style={{ lineHeight: "12px", marginTop: "10px" }}>
                        <div className="mt-10">
                          <span className="title">
                            A/C Holder name: {item.accountHolderName}
                          </span>
                        </div>
                        <div className="mt-10">
                          <span className="title">
                            A/C Number: {item.accountHolderName}
                          </span>
                        </div>
                        <div className="mt-10">
                          <span className="title">IFSC: {item.IFSCCode}</span>
                        </div>
                        <div className="mt-10">
                          <span className="title">Address: {item.address}</span>
                        </div>
                        {/* <p><strong>A/C Holder name:</strong> {item.accountHolderName}</p><br />
                                <p><strong>A/C Number:</strong> {item.accountNumber}</p><br />
                                <p><strong>IFSC:</strong> {item.IFSCCode}</p><br />
                                <p><strong>Address: </strong> {item.address}</p> */}
                      </div>
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </div>

        <Drawer
          title={isEditing ? "Edit Bank Details" : "Add Bank Details"}
          width={720}
          onClose={this.onClose}
          open={drawerVisible}
          afterVisibleChange={(visible) => {
            if (!visible) {
              this.formRef.current?.resetFields();
            }
          }}
          extra={
            <Space>
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => this.formRef.current?.submit()}
              >
                {isEditing ? "Update" : "Submit"}
              </Button>
            </Space>
          }
        >
          <Form
            ref={this.formRef}
            layout="vertical"
            onFinish={this.handleSubmit}
            initialValues={currentEditBank || {}}
          >
            <Form.Item
              label="Bank Name"
              name="bankName"
              rules={[
                { required: true, message: "Please enter the bank name" },
                {
                  pattern: /^[A-Za-z\s]+$/,
                  message: "Only alphabets are allowed",
                },
              ]}
            >
              <Input placeholder="Enter bank name" />
            </Form.Item>
            <Form.Item
              label="Account Holder Name"
              name="accountHolderName"
              rules={[
                {
                  required: true,
                  message: "Please enter the account holder name",
                },
                {
                  pattern: /^[A-Za-z\s]+$/,
                  message: "Only alphabets are allowed",
                },
              ]}
            >
              <Input placeholder="Enter account holder name" />
            </Form.Item>
            <Form.Item
              label="Account Number"
              name="accountNumber"
              rules={[
                {
                  required: true,
                  message: "Please enter the account number",
                },
                { pattern: /^\d+$/, message: "Only numbers are allowed" },
                () => ({
                  validator(_, value) {
                    if (
                      isEditing ||
                      !value ||
                      !bankData.some((bank) => bank.accountNumber === value)
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Account number already exists")
                    );
                  },
                }),
              ]}
            >
              <Input placeholder="Enter account number" />
            </Form.Item>
            <Form.Item
              label="Branch"
              name="branch"
              rules={[
                { required: true, message: "Please enter the branch name" },
              ]}
            >
              <Input placeholder="Enter branch name" />
            </Form.Item>
            <Form.Item label="SWIFT Code" name="swiftCode">
              <Input placeholder="Enter SWIFT code" />
            </Form.Item>
            <Form.Item
              label="IFSC Code"
              name="IFSCCode"
              rules={[
                { required: true, message: "Please enter the IFSC code" },
              ]}
            >
              <Input placeholder="Enter IFSC code" />
            </Form.Item>
            <Form.Item label="MICR Code" name="MICRCode">
              <Input placeholder="Enter MICR code" />
            </Form.Item>
            <Form.Item label="Address" name="address">
              <Input placeholder="Enter address" />
            </Form.Item>
          </Form>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  bankDetailData: state.api.getBankDetail?.data?.reverse(),
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
  getBankDetailState: state.api.getBankDetail,
  addBankDetailState: state.api.addBankDetail,
  editBankDetailState: state.api.editBankDetail,
  deleteBankDetailState: state.api.deleteBankDetail,
});

const mapDispatchToProps = (dispatch: any) => ({
  addBankDetail: (payload: any) => dispatch(addBankDetail(payload)),
  getBankDetail: (payload: any) => dispatch(getBankDetail(payload)),
  editBankDetail: (payload: any) => dispatch(editBankDetail(payload)),
  deleteBankDetail: (payload: any) => dispatch(deleteBankDetail(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentData);
