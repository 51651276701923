import * as type from '../../types/API/paymentTerm'

export function getPaymentTerm(payload: any) {
    return {
        type: type.GET_PAYMENT_TERMS,
        payload: payload
    }
}

export function addPaymentTerm(payload: any) {
    return {
        type: type.ADD_PAYMENT_TERMS,
        payload: payload
    }
}

export function editPaymentTerm(payload: any) {
    return {
        type: type.EDIT_PAYMENT_TERMS,
        payload: payload
    }
}

export function deletePaymentTerm(payload: any) {
    return {
        type: type.DELETE_PAYMENT_TERMS,
        payload: payload
    }
}
