import { Card, Tooltip } from "antd";
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { AppConstants } from "../../../../Appconstants";
import { connect } from "react-redux";
import { setDocumentAttachmentsData } from "../../../../redux/actions/UI/createDocument";

interface IAttachmentsProps {
  setDocumentAttachmentsData: Function;
}

const Attachments: React.FC<IAttachmentsProps> = ({
  setDocumentAttachmentsData,
}) => {
  const [attachments, setAttachments] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setDocumentAttachmentsData([]);
  }, []);

  useEffect(() => {
    setDocumentAttachmentsData(attachments);
  }, [attachments]);

  const handleAttachmentsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedAttachments = event.target.files;
    const fileSizeLimit = 5 * 1024 * 1024;
    const maxFileCount = 5;
    if (selectedAttachments) {
      const newFiles = Array.from(selectedAttachments);
      if (attachments.length + newFiles.length > maxFileCount) {
        alert(`You can only upload a maximum of ${maxFileCount} files.`);
        return;
      }

      for (const file of newFiles) {
        if (file.size > fileSizeLimit) {
          alert(`File "${file.name}" exceeds the 5 MB size limit.`);
          return;
        }
      }
      setAttachments([...attachments, ...newFiles]);
    }
  };

  const handleDeleteAttachment = (index: number) => {
    setAttachments((prev) => prev.filter((_, idx) => idx !== index));
    if (attachments.length === 1 && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <Card className="custom-card no-header-border flex-grow-1 fileCard">
      <div className="card-content">
        <div className="title mb-10 fS-20">
          {AppConstants.ATTACHEMETS_DETAIL.ATTACHEMETS_LABEL} &nbsp;
          <Tooltip title={AppConstants.ATTACHEMETS_DETAIL.ATTACHEMETS_LABEL}>
            <InfoCircleOutlined className="info-icon" />
          </Tooltip>
        </div>
        <input
          type="file"
          multiple
          accept="*/*"
          onChange={handleAttachmentsChange}
          ref={fileInputRef}
        />
        {attachments.length > 0 ? (
          attachments.map((attachment, index) => (
            <div key={index} className="flexBox fileList">
              <div>{attachment?.name}</div>
              <div className="deleteIcon">
                <DeleteOutlined
                  className="delete-icon"
                  onClick={() => handleDeleteAttachment(index)}
                />
              </div>
            </div>
          ))
        ) : (
          <div className="file-size-info">
            {AppConstants.ATTACHEMETS_DETAIL.ATTACHEMETS_UPLOAD_DETAILS}
          </div>
        )}
      </div>
    </Card>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  setDocumentAttachmentsData: (payload: any) => dispatch(setDocumentAttachmentsData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Attachments);
