import React, { useEffect } from "react";
import Header from "./Header";
import logo from "../../Utility/images/logo.png";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import "./TermCondition.css";
import { Col, Row } from "antd";

const TermCondition: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Header />
      <div id="terms-and-condition" className="container">
        <Row>
          <div className="container">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <h1 className="text-3xl font-bold mb-6 mt-10 sub-container-term">
                Terms and Conditions
              </h1>
              <div className="space-y-6 text-gray-700   rounded-lg">
                <p>
                  Thank you for accessing easemargin.com .This site is owned by
                  Datronix Digital private Limited (hereinafter referred to as
                  easemargin.com).
                </p>
                <p>
                  By accessing this site, you indicate your unconditional acceptance
                  of these terms & conditions. We reserve this right, in our sole
                  discretion, to update or revise these terms & conditions.
                </p>
                <p>
                  Continued use of the site following the posting of any changes to
                  the ‘terms & conditions’, constitutes your acceptance of those
                  changes. At easemargin.com, we try our best to create a space
                  where you can create, save and send documents in a safe and secure
                  environment. Easemargin.com reserves the right to accept or reject
                  your offer. Your order for purchase, constitutes your "offer"
                  which shall be subject to the terms and conditions as listed
                  below.
                </p>
                <p>
                  <b>1. Eligibility to use our site</b>
                  <br />
                  Use of the Site is available only to persons who can legally enter
                  into contracts under applicable laws. Persons who are "incompetent
                  to contract", within the meaning of the Indian Contract Act, 1872
                  including un-discharged insolvents etc. are not eligible to use
                  the Site. Easemargin.com reserves the right to terminate your
                  access to the Site if it discovers that you are under the age of
                  18 years or suffers from any other disability, as recognized under
                  Indian Contract Act, 1872.
                </p>
                <p>
                  <b>2. Electronic Communications</b>
                  <br />
                  When you use the site or send emails or other data, information or
                  communicate to us, you agree and understand that you are
                  communicating with us electronically and give your consent to
                  receive communications electronically from us periodically, when
                  required.
                </p>
                <p>
                  <b>3. Reviews, Feedback, Submissions</b>
                  <br />
                  All reviews, comments, feedback, postcards, suggestions, ideas,
                  and other submissions disclosed, submitted or offered to
                  easemargin.com directly or otherwise disclosed, submitted or
                  offered in connection with your use of this Site (collectively
                  referred to "Comments") will remain easemargin.com property. Such
                  disclosure, submission or offer of any comments shall constitute
                  an assignment to easemargin.com of all worldwide rights, titles
                  and interests in all copyrights and other intellectual properties
                  in the comments,thus, it exclusively owns all such rights, titles
                  and interests and shall not be limited in any way in its use,
                  commercial or otherwise.easemargin.com will be entitled to use,
                  reproduce, disclose, modify, adapt, create derivative works from,
                  publish, display and distribute any comments you submit for any
                  purpose whatsoever, without restriction and without compensating
                  you in any way. easemargin.com is and shall be under no obligation
                  <br />
                  (1) to maintain any Comments in confidence; or <br />
                  (2) to pay you any compensation for any Comments; or <br />
                  (3) to respond to any Comments. You agree that any comments
                  submitted by you to the Site will not violate this policy or any
                  right of any third party, including copyright, trademark, privacy
                  or other personal or proprietary right(s), and will not cause
                  injury to any person or entity. You further agree that no comments
                  submitted by you to the site will be libelous or otherwise
                  unlawful, threatening, abusive or obscene material, or contain
                  software viruses, political campaigning, commercial solicitation,
                  chain letters, mass mailings or any form of "spam".
                  <br />
                  Easemargin.com does not regularly review posted comments, but does
                  reserve the right (but not the obligation) to monitor and edit or
                  remove any comment submitted to the Site. You grant easemargin.com
                  the right to use the name that you submit in connection with any
                  of the posted comments. You agree not to use a false email
                  address, impersonate any person or entity, or otherwise mislead as
                  to the origin of any Comments you submit. You are and shall remain
                  solely responsible for the content of any comments you make and
                  you agree to indemnify easemargin.com and its affiliates for all
                  claims resulting from any Comments you submit, we take no
                  responsibility and assume no liability for any comments submitted
                  by you or any third party.
                </p>
                <p>
                  <b>
                    4. Accuracy of Content/ Information of Products on the Web Site
                  </b>
                  <br />
                  While easemargin.com strives to provide accurate product and
                  pricing information, typographical errors may occur. In the event
                  that a product is listed at an incorrect price or with incorrect
                  information due to an error in pricing or product information,
                  easemargin.com shall have the right, on our sole discretion, to
                  modify the price of the products, or information of the products
                  or to refuse or cancel any orders placed for that product.
                </p>
                <h2 className="text-2xl font-bold mt-8">Cookies</h2>
                <p>
                  We employ the use of cookies. By accessing EaseMargin, you agreed
                  to use cookies in agreement with Company Name’s Privacy Policy.
                </p>
                <p>
                  Most interactive websites use cookies to let us retrieve the
                  user’s details for each visit. Cookies are used by our website to
                  enable the functionality of certain areas to make it easier for
                  people visiting our website. Some of our affiliate/advertising
                  partners may also use cookies.
                </p>
                <h2 className="text-2xl font-bold mt-8">License</h2>
                <p>
                  Unless otherwise stated, Company Name and/or its licensors own the
                  intellectual property rights for all material on EaseMargin. All
                  intellectual property rights are reserved. You may access this
                  from our website for your own personal use subjected to
                  restrictions set in these terms and conditions.
                </p>
                <p>You must not:</p>
                <ul className="list-disc list-inside">
                  <li>Republish material from EaseMargin</li>
                  <li>Sell, rent, or sub-license material from EaseMargin</li>
                  <li>Reproduce, duplicate or copy material from EaseMargin</li>
                  <li>Redistribute content from EaseMargin</li>
                </ul>
                <p>
                  This Agreement shall begin on the date hereof. Parts of this
                  website offer an opportunity for users to post and exchange
                  opinions and information in certain areas of the website. Company
                  Name does not filter, edit, publish or review Comments prior to
                  their presence on the website. Comments do not reflect the views
                  and opinions of Company Name, its agents, and/or affiliates.
                  Comments reflect the views and opinions of the person who post
                  their views and opinions. To the extent permitted by applicable
                  laws, Company Name shall not be liable for the Comments or for any
                  liability, damages or expenses caused and/or suffered as a result
                  of any use of and/or posting of and/or appearance of the Comments
                  on this website.
                </p>
                <p>
                  Company Name reserves the right to monitor all Comments and to
                  remove any Comments which can be considered inappropriate,
                  offensive, or causes breach of these Terms and Conditions.
                </p>
                <p>You warrant and represent that:</p>
                <ul className="list-disc list-inside">
                  <li>
                    You are entitled to post the Comments on our website and have
                    all necessary licenses and consents to do so;
                  </li>
                  <li>
                    The Comments do not invade any intellectual property right,
                    including without limitation copyright, patent or trademark of
                    any third party;
                  </li>
                  <li>
                    The Comments do not contain any defamatory, libelous, offensive,
                    indecent or otherwise unlawful material which is an invasion of
                    privacy;
                  </li>
                  <li>
                    The Comments will not be used to solicit or promote business or
                    custom or present commercial activities or unlawful activity.
                  </li>
                </ul>
                <p>
                  You hereby grant Company Name a non-exclusive license to use,
                  reproduce, edit and authorize others to use, reproduce and edit
                  any of your Comments in any and all forms, formats, or media.
                </p>
                <h2 className="text-2xl font-bold mt-8">
                  Hyperlinking to our Content
                </h2>
                <p>
                  The following organizations may link to our Website without prior
                  written approval:
                </p>
                <ul className="list-disc list-inside">
                  <li>Government agencies;</li>
                  <li>Search engines;</li>
                  <li>News organizations;</li>
                  <li>
                    Online directory distributors may link to our Website in the
                    same manner as they hyperlink to the Websites of other listed
                    businesses; and
                  </li>
                  <li>
                    System-wide Accredited Businesses except soliciting non-profit
                    organizations, charity shopping malls, and charity fundraising
                    groups which may not hyperlink to our Website.
                  </li>
                </ul>
                <br />
                <p>
                  These organizations may link to our home page, to publications or
                  to other Website information so long as the link: <br />
                  (a) is not in any way deceptive; <br />
                  (b) does not falsely imply sponsorship, endorsement, or approval
                  of the linking party and its products and/or services; and <br />
                  (c) fits within the context of the linking party’s site.
                </p>
                <p>
                  We may consider and approve other link requests from the following
                  types of organizations:
                </p>
                <ul className="list-disc list-inside">
                  <li>
                    Commonly-known consumer and/or business information sources;
                  </li>
                  <li>dot.com community sites;</li>
                  <li>associations or other groups representing charities;</li>
                  <li>online directory distributors;</li>
                  <li>internet portals;</li>
                  <li>accounting, law, and consulting firms; and</li>
                  <li>educational institutions and trade associations.</li>
                </ul>
                <p>
                  We will approve link requests from these organizations if we
                  decide that: (a) the link would not make us look unfavorably to
                  ourselves or to our accredited businesses; (b) the organization
                  does not have any negative records with us; (c) the benefit to us
                  from the visibility of the hyperlink compensates the absence of
                  Company Name; and (d) the link is in the context of general
                  resource information.
                </p>
                <p>
                  These organizations may link to our home page so long as the link:
                  (a) is not in any way deceptive; (b) does not falsely imply
                  sponsorship, endorsement, or approval of the linking party and its
                  products or services; and (c) fits within the context of the
                  linking party’s site.
                </p>
                <p>
                  If you are one of the organizations listed in paragraph 2 above
                  and are interested in linking to our website, you must inform us
                  by sending an e-mail to Company Name. Please include your name,
                  your organization name, contact information as well as the URL of
                  your site, a list of any URLs from which you intend to link to our
                  Website, and a list of the URLs on our site to which you would
                  like to link. Wait 2-3 weeks for a response.
                </p>
                <p>
                  Approved organizations may hyperlink to our Website as follows:
                </p>
                <ul className="list-disc list-inside">
                  <li>By use of our corporate name; or</li>
                  <li>
                    By use of the uniform resource locator being linked to; or
                  </li>
                  <li>
                    By use of any other description of our Website being linked to
                    that makes sense within the context and format of content on the
                    linking party’s site.
                  </li>
                </ul>
                <p>
                  No use of Company Name’s logo or other artwork will be allowed for
                  linking absent a trademark license agreement.
                </p>
                <h2 className="text-2xl font-bold mt-8">iFrames</h2>
                <p>
                  Without prior approval and written permission, you may not create
                  frames around our Webpages that alter in any way the visual
                  presentation or appearance of our Website.
                </p>
                <h2 className="text-2xl font-bold mt-8">Content Liability</h2>
                <p>
                  We shall not be hold responsible for any content that appears on
                  our Website. You agree to protect and defend us against all claims
                  that is rising on our Website. No link(s) should appear on any
                  Website that may be interpreted as libelous, obscene or criminal,
                  or which infringes, otherwise violates, or advocates the
                  infringement or other violation of, any third party rights.
                </p>
                <h2 className="text-2xl font-bold mt-8">Reservation of Rights</h2>
                <p>
                  We reserve the right to request that you remove all links or any
                  particular link to our Website. You approve to immediately remove
                  all links to our Website upon request. We also reserve the right
                  to amend these terms and conditions and it’s linking policy at any
                  time. By continuously linking to our Website, you agree to be
                  bound to and follow these linking terms and conditions.
                </p>
                <h2 className="text-2xl font-bold mt-8">
                  Removal of links from our website
                </h2>
                <p>
                  If you find any link on our Website that is offensive for any
                  reason, you are free to contact and inform us any moment. We will
                  consider requests to remove links but we are not obligated to or
                  so or to respond to you directly.
                </p>
                <p>
                  We do not ensure that the information on this website is correct,
                  we do not warrant its completeness or accuracy; nor do we promise
                  to ensure that the website remains available or that the material
                  on the website is kept up to date.
                </p>
                <h2 className="text-2xl font-bold mt-8">Disclaimer</h2>
                <p>
                  To the maximum extent permitted by applicable law, we exclude all
                  representations, warranties and conditions relating to our website
                  and the use of this website. Nothing in this disclaimer will:
                </p>
                <ul className="list-disc list-inside">
                  <li>
                    limit or exclude our or your liability for death or personal
                    injury;
                  </li>
                  <li>
                    limit or exclude our or your liability for fraud or fraudulent
                    misrepresentation;
                  </li>
                  <li>
                    limit any of our or your liabilities in any way that is not
                    permitted under applicable law; or
                  </li>
                  <li>
                    exclude any of our or your liabilities that may not be excluded
                    under applicable law.
                  </li>
                </ul>
                <p>
                  The limitations and prohibitions of liability set in this Section
                  and elsewhere in this disclaimer: <br />
                  (a) are subject to the preceding paragraph; and <br />
                  (b) govern all liabilities arising under the disclaimer, including
                  liabilities arising in contract, in tort and for breach of
                  statutory duty.
                </p>
                <p>
                  As long as the website and the information and services on the
                  website are provided free of charge, we will not be liable for any
                  loss or damage of any nature.
                </p>
              </div>
            </Col>
          </div>
        </Row>
      </div>
      <Footer />
    </>

  );
};
export default TermCondition;
