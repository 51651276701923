import { Breadcrumb, Button, Input, Layout, Space, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { FileAddOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const BOM: React.FC = () => {
  const navigate = useNavigate();
  const dataSource = [
    {
      key: "1",
      finishedGoodsId: "FG001",
      finishedGoodsName: "Product A",
      bomId: "BOM001",
      bomName: "BOM A",
      createdBy: "Sandeep Sharma",
      creationDate: "2024-01-01",
    },
    {
      key: "2",
      finishedGoodsId: "FG002",
      finishedGoodsName: "Product B",
      bomId: "BOM002",
      bomName: "BOM B",
      createdBy: "Harish Chandra",
      creationDate: "2024-01-02",
    },
  ];

  const columns = [
    {
      title: "Finished Goods (Item ID)",
      dataIndex: "finishedGoodsId",
      key: "finishedGoodsId",
    },
    {
      title: "Finished Goods (Item Name)",
      dataIndex: "finishedGoodsName",
      key: "finishedGoodsName",
    },
    {
      title: "BOM ID",
      dataIndex: "bomId",
      key: "bomId",
    },
    {
      title: "BOM Name",
      dataIndex: "bomName",
      key: "bomName",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Creation Date",
      dataIndex: "creationDate",
      key: "creationDate",
    },
  ];
  return (
    <Layout className="layout">
      <div>
        <Breadcrumb
          items={[{ title: "Dashboard" }, { title: "Bill of material" }]}
        />
      </div>

      <Content className="content-section">
        <div className="mb-10">
          <div className="flexBox">
            <div>
              <Space wrap>
                <Button
                  type="link"
                  onClick={() => navigate("/create_bill_of_material")}
                >
                  <FileAddOutlined /> Create Bill of Material
                </Button>
              </Space>
            </div>
            <div className="flexBox">
              <div></div>

              <Input placeholder="Search..." style={{ width: 200 }} />
            </div>
          </div>
        </div>
        <div className="textDocument">Created Bill of Material</div>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={{ pageSize: 5 }}
          rowKey="key"
        />
      </Content>
    </Layout>
  );
};

export default BOM;
