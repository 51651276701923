export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS';
export const GET_DOCUMENTS_FAILED = 'GET_DOCUMENTS_FAILED';

export const GET_DOCUMENTS_BY_ID = 'GET_DOCUMENTS_BY_ID';
export const GET_DOCUMENTS_BY_ID_SUCCESS = 'GET_DOCUMENTS_BY_ID_SUCCESS';
export const GET_DOCUMENTS_BY_ID_FAILED = 'GET_DOCUMENTS_BY_ID_FAILED';

export const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS';
export const CREATE_DOCUMENT_FAILED = 'CREATE_DOCUMENT_FAILED';

export const EDIT_DOCUMENT = 'EDIT_DOCUMENT';
export const EDIT_DOCUMENT_SUCCESS = 'EDIT_DOCUMENT_SUCCESS';
export const EDIT_DOCUMENT_FAILED = 'EDIT_DOCUMENT_FAILED';

export const DISCARD_DOCUMENT = 'DISCARD_DOCUMENT';
export const DISCARD_DOCUMENT_SUCCESS = 'DISCARD_DOCUMENT_SUCCESS';
export const DISCARD_DOCUMENT_FAILED = 'DISCARD_DOCUMENT_FAILED';

export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_FAILED = 'DELETE_DOCUMENT_FAILED';