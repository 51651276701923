import * as type from "../../types/API/authTypes";

export function loginUser(payload: any) {
  return {
    type: type.LOGIN_USER,
    payload: payload,
  };
}

export function signupUser(payload: any) {
  return {
    type: type.SIGNUP_USER,
    payload: payload,
  };
}

export function forgotPassword(payload: any) {
  return {
    type: type.FORGOT_PASSWORD,
    payload: payload,
  };
}

export function resetPassword(payload: any) {
  return {
    type: type.RESET_PASSWORD,
    payload: payload,
  };
}