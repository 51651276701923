import { combineReducers } from "redux";
import appLocationReducer from "./appLocationReducer";
import { forgotPasswordReducer, loginReducer, resetPasswordReducer, signupReducer } from "./API/auth";
import {
  teamsReducer,
  addTeamsReducer,
  editTeamsReducer,
  deleteTeamsReducer,
} from "./API/teams";
import {
  getStoreReducer,
  addStoreReducer,
  editStoreReducer,
  deleteStoreReducer,
  getStoreByIdReducer,
  getStoreByItemsReducer,
} from "./API/storeReducer";
import {
  addAddressReducer,
  deleteAddressReducer,
  editAddressReducer,
  getAddressReducer,
  setDefaultAddressReducer,
} from "./API/addressReducer";
import {
  addBlogReducer,
  deleteBlogReducer,
  editBlogReducer,
  getBlogByIdReducer,
  getBlogCategoriesReducer,
  getBlogsReducer,
} from "./API/blogs";
import { imageReducer } from "./API/imageUpload";
import {
  addUserReducer,
  deleteUserReducer,
  editUserReducer,
  updateUserReducer,
  updateProfileLogoReducer,
  usersReducer,
} from "./API/users";
import {
  addBlogCommentReducer,
  deleteBlogCommentReducer,
  approveCommentsReducer,
  getApprovedBlogCommentsReducer,
  getBlogCommentstoBeApproveReducer,
  getAllBlogsApprovedCommentsReducer,
} from "./API/commentReducer";
import {
  addItemsReducer,
  bulkDeleteItemsReducer,
  deleteItemsReducer,
  editItemsReducer,
  itemsReducer,
} from "./API/items";
import {
  addBuyerSupplierReducer,
  buyerSupplierReducer,
  deleteBuyerSupplierReducer,
  editBuyerSupplierReducer,
} from "./API/buyerSupplier";
import {
  addDocumentSeriesReducer,
  deleteDocumentSeriesReducer,
  documentSeriesReducer,
  editDocumentSeriesReducer,
  updateLastDocumentNumberReducer,
} from "./API/documentSeriesReducer";
import {
  addBankDetailReducer,
  bankDetailReducer,
  deleteBankDetailReducer,
  editBankDetailReducer,
} from "./API/bankDetailsReducer";
import {
  createDocumentReducer,
  deleteDocumentReducer,
  discardDocumentReducer,
  editDocumentReducer,
  getDocumentByIdReducer,
  getDocumentsReducer,
} from "./API/documentReducer";
import {
  getNotificationsReducer,
  notifyReducer,
} from "./API/notificationReducer";
import createDocumentUIReducer from "./UI/createDocumentReducer";
import {
  getAccountInfoReducer,
  updateAccountInfoReducer,
} from "./API/accountInfoReducer";
import { stockTransferReducer } from "./API/stockTransferReducer";
import {
  addUOMReducer,
  deleteUOMReducer,
  editUOMReducer,
  getUOMReducer,
} from "./API/uomReducer";
import {
  itemStockTransferHistoryReducer,
  stockTransferHistoryReducer,
} from "./API/transferHistoryReducer";
import {
  addCategoryReducer,
  deleteCategoryReducer,
  editCategoryReducer,
  getCategoryReducer,
} from "./API/categoryReducer";
import { stockUpdateReducer } from "./API/stockUpdateReducer";
import { stockReconcileReducer } from "./API/reconcileAndUploadReducer";
import {
  getPaymentTermReducer,
  addPaymentTermReducer,
  deletePaymentTermReducer,
  editPaymentTermReducer,
} from "./API/paymentTerm";
import {
  addLogisticDetailsReducer,
  deleteLogisticDetailsReducer,
  editLogisticDetailsReducer,
  getLogisticDetailsReducer,
} from "./API/logisticDetails";
import {
  addTransporterDetailsReducer,
  deleteTransporterDetailsReducer,
  editTransporterDetailsReducer,
  getTransporterDetailsReducer,
} from "./API/transporterDetails";
import {
  addTermsConditionReducer,
  deleteTermsConditionReducer,
  editTermsConditionReducer,
  getTermsConditionReducer,
} from "./API/termsCondition";
import {
  addCustomFieldsReducer,
  deleteCustomFieldsReducer,
  editCustomFieldsReducer,
} from "./API/customFields";
import { addContactUsReducer, getContactUsReducer } from "./API/contactUsReducer";
import { addDemoQueryDataReducer, getDemoQueryDataReducer, } from "./API/demoQueryReducer";

const UIReducers = combineReducers({
  appLocation: appLocationReducer,
  createDocumentUIData: createDocumentUIReducer,
});

const APIReducers = combineReducers({
  login: loginReducer,
  signup: signupReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,

  addBlog: addBlogReducer,
  editBlog: editBlogReducer,
  deleteBlog: deleteBlogReducer,
  getBlogs: getBlogsReducer,
  getBlogById: getBlogByIdReducer,
  getBlogCategories: getBlogCategoriesReducer,

  imageUpload: imageReducer,

  addTeam: addTeamsReducer,
  getTeams: teamsReducer,
  editTeam: editTeamsReducer,
  deleteTeam: deleteTeamsReducer,

  addUOM: addUOMReducer,
  getUOM: getUOMReducer,
  editUOM: editUOMReducer,
  deleteUOM: deleteUOMReducer,

  addCategory: addCategoryReducer,
  getCategory: getCategoryReducer,
  editCategory: editCategoryReducer,
  deleteCategory: deleteCategoryReducer,

  addUser: addUserReducer,
  getUsers: usersReducer,
  editUser: editUserReducer,
  deleteUser: deleteUserReducer,
  updateUser: updateUserReducer,
  updateProfileLogo: updateProfileLogoReducer,

  addItems: addItemsReducer,
  getItems: itemsReducer,
  editItems: editItemsReducer,
  deleteItems: deleteItemsReducer,
  bulkDeleteItems: bulkDeleteItemsReducer,

  addBuyerSupplier: addBuyerSupplierReducer,
  getBuyerSupplier: buyerSupplierReducer,
  deleteBuyerSupplier: deleteBuyerSupplierReducer,
  editBuyerSupplier: editBuyerSupplierReducer,

  addStore: addStoreReducer,
  getStore: getStoreReducer,
  getStoreById: getStoreByIdReducer,
  getStoreByItems: getStoreByItemsReducer,
  editStore: editStoreReducer,
  deleteStore: deleteStoreReducer,

  addAddress: addAddressReducer,
  getAddress: getAddressReducer,
  editAddress: editAddressReducer,
  deleteAddress: deleteAddressReducer,
  setDefaultAddress: setDefaultAddressReducer,

  addBlogComment: addBlogCommentReducer,
  deleteBlogComment: deleteBlogCommentReducer,
  approveComments: approveCommentsReducer,
  getApprovedBlogComments: getApprovedBlogCommentsReducer,
  getBlogCommentstoBeApprove: getBlogCommentstoBeApproveReducer,
  getAllBlogsApprovedComments: getAllBlogsApprovedCommentsReducer,

  addDocumentSeries: addDocumentSeriesReducer,
  getDocumentSeries: documentSeriesReducer,
  editDocumentSeries: editDocumentSeriesReducer,
  deleteDocumentSeries: deleteDocumentSeriesReducer,
  updateLastDocumentNumber: updateLastDocumentNumberReducer,

  getDocuments: getDocumentsReducer,
  getDocumentById: getDocumentByIdReducer,
  createDocument: createDocumentReducer,
  editDocument: editDocumentReducer,
  discardDocument: discardDocumentReducer,
  deleteDocument: deleteDocumentReducer,

  addBankDetail: addBankDetailReducer,
  deleteBankDetail: deleteBankDetailReducer,
  editBankDetail: editBankDetailReducer,
  getBankDetail: bankDetailReducer,

  notify: notifyReducer,
  getNotifications: getNotificationsReducer,

  getAccountInfo: getAccountInfoReducer,
  updateAccountInfo: updateAccountInfoReducer,

  stockTransfer: stockTransferReducer,
  stockUpdate: stockUpdateReducer,
  itemStockTransferHistory: itemStockTransferHistoryReducer,
  stockTransferHistory: stockTransferHistoryReducer,

  stockReconcile: stockReconcileReducer,

  addPaymentTerm: addPaymentTermReducer,
  getPaymentTerm: getPaymentTermReducer,
  editPaymentTerm: editPaymentTermReducer,
  deletePaymentTerm: deletePaymentTermReducer,

  addLogisticDetails: addLogisticDetailsReducer,
  getLogisticDetails: getLogisticDetailsReducer,
  editLogisticDetails: editLogisticDetailsReducer,
  deleteLogisticDetails: deleteLogisticDetailsReducer,

  addTransporterDetails: addTransporterDetailsReducer,
  getTransporterDetails: getTransporterDetailsReducer,
  editTransporterDetails: editTransporterDetailsReducer,
  deleteTransporterDetails: deleteTransporterDetailsReducer,

  addTermsCondition: addTermsConditionReducer,
  getTermsCondition: getTermsConditionReducer,
  editTermsCondition: editTermsConditionReducer,
  deleteTermsCondition: deleteTermsConditionReducer,

  addCustomFields: addCustomFieldsReducer,
  getCustomFields: addCustomFieldsReducer,
  editCustomFields: editCustomFieldsReducer,
  deleteCustomFields: deleteCustomFieldsReducer,

  addContactUs: addContactUsReducer,
  getContactUs: getContactUsReducer,
  
  addDemoQueryData: addDemoQueryDataReducer,
  getDemoQueryData: getDemoQueryDataReducer,
});

const RootReducer = combineReducers({
  api: APIReducers,
  ui: UIReducers,
});

export default RootReducer;
