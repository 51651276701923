import Table, { ColumnType } from "antd/es/table";
import React, { useState } from "react";
import { BOMAdditionalChargesDataType } from "./bomConstant";
import { Button, Card, Input, Popconfirm, Tooltip } from "antd";
import { DeleteOutlined, ReloadOutlined } from "@ant-design/icons";

interface IBOMAdditionalChargesProps {}

const BOMAdditionalCharges: React.FC<IBOMAdditionalChargesProps> = () => {
  const [keyCounter, setKeyCounter] = useState<number>(3);
  const [bomData, setBOMData] = useState<BOMAdditionalChargesDataType[]>([
    {
      key: 1,
      charges: "Machinery Charge",
      amount: 0,
    },
    {
      key: 2,
      charges: "Labour Charge",
      amount: 0,
    },
  ]);

  const addRow = () => {
    const newItem: BOMAdditionalChargesDataType = {
      key: keyCounter,
      charges: "",
      amount: null,
    };
    setBOMData([...bomData, newItem]);
    setKeyCounter(keyCounter + 1);
  };

  const handleDelete = (key: React.Key) => {
    const updatedData = bomData.filter((item) => item.key !== key);
    setBOMData(updatedData);
  };

  const handleResetField = (index: number) => {
    const newData = [...bomData];
    newData[index] = {
      ...newData[index],
      charges: "",
      amount: 0,
    };
    setBOMData(newData);
  };

  const columns: ColumnType<BOMAdditionalChargesDataType>[] = [
    {
      title: "Charges Name",
      dataIndex: "charges",
      key: "charges",
      fixed: "left",
      render: (text, record) => (
        <Input
          value={text}
          placeholder="Enter Charges"
          onChange={(e) =>
            setBOMData(
              bomData.map((item) =>
                item.key === record.key
                  ? { ...item, charges: e.target.value }
                  : item
              )
            )
          }
        />
      ),
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <Input
          value={text}
          type="number"
          placeholder="Enter Amount"
          onChange={(e) =>
            setBOMData(
              bomData.map((item) =>
                item.key === record.key
                  ? { ...item, amount: Number(e.target.value) }
                  : item
              )
            )
          }
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 100,
      render: (
        text: string,
        record: BOMAdditionalChargesDataType,
        index: number
      ) => {
        return (
          <div style={{ display: "flex", gap: 8 }}>
            <Tooltip title="Refresh">
              <span className="actionIcons">
                <ReloadOutlined onClick={() => handleResetField(index)} />
              </span>
            </Tooltip>
            {bomData?.length > 1 && (
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure you want to delete this row?"
                  onConfirm={() => handleDelete(record.key)}
                  okText="Yes"
                  cancelText="No"
                >
                  <span className="actionIcons">
                    <DeleteOutlined />
                  </span>
                </Popconfirm>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Card className="cardTable">
      <h5 className="mb-2">Additional Charges</h5>
      <Table
        dataSource={bomData}
        columns={columns}
        pagination={false}
        rowKey="key"
        className="table-component"
        scroll={{ x: "max-content" }}
      />
      <div className="mt-10">
        <Button type="link" onClick={addRow}>
          + Add Charges
        </Button>
      </div>
    </Card>
  );
};

export default BOMAdditionalCharges;
