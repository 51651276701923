import React, { useEffect, useState } from "react";
import { Modal, Radio, Button, Tabs } from "antd";
import { connect } from "react-redux";
import { AppConstants } from "../../../Appconstants";
import { setDocumentSupplierData } from "../../../redux/actions/UI/createDocument";

interface ISupplierAddressModalProps {
  modelTitle: string;
  isSupplierModalOpen: boolean;
  handleSaveSupplierAddress: (supplierData: any) => void;
  handleSupplierAddressCancel: () => void;
  supplierAddressData: any;
  setDocumentSupplierData: Function;
  selectedCompanyNameAsProps?: string;
  supplierDefaultBillId?: number;
  supplierDefaultDelId?: number;
  isSupplierDetailsEdit: boolean;
}

const SupplierAddressModal: React.FC<ISupplierAddressModalProps> = ({
  modelTitle,
  isSupplierModalOpen,
  handleSupplierAddressCancel,
  handleSaveSupplierAddress,
  supplierAddressData,
  setDocumentSupplierData,
  isSupplierDetailsEdit,
}) => {
  const [selectedSupplierData, setSelectedSupplierData] = useState<any>(null);
  const [defaultDeliveryAddressId, setDefaultDeliveryAddressId] = useState<number | null>(null);
  const [defaultBillingAddressId, setDefaultBillingAddressId] = useState<number | null>(null);
 
  useEffect(() => {
    if (supplierAddressData) {
      setSelectedSupplierData(supplierAddressData);

      const deliveryAddresses = supplierAddressData.filter(
        (address: any) =>
          address.addressType === AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_ID
      );
      const billingAddresses = supplierAddressData.filter(
        (address: any) =>
          address.addressType === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID
      );

      if (isSupplierDetailsEdit) {
        setDefaultDeliveryAddressId( deliveryAddresses[0]?.id);
        setDefaultBillingAddressId( billingAddresses[0]?.id);
      } else {
        if (deliveryAddresses.length > 0) {
          setDefaultDeliveryAddressId(deliveryAddresses[0].id);
        }
        if (billingAddresses.length > 0) {
          setDefaultBillingAddressId(billingAddresses[0].id);
        }
      }
    }
  }, [supplierAddressData, setDocumentSupplierData, isSupplierDetailsEdit]);

  const onSaveSupplierAddress = () => {
    if (selectedSupplierData) {
      setDocumentSupplierData(selectedSupplierData);

      const selectedSupplierDeliveryAddress = selectedSupplierData.find(
        (address: any) => address.id === defaultDeliveryAddressId
      );
      const selectedSupplierBillingAddress = selectedSupplierData.find(
        (address: any) => address.id === defaultBillingAddressId
      );

      const supplierDeliverAddress =
        selectedSupplierDeliveryAddress.addressLineOne +
        ", " +
        selectedSupplierDeliveryAddress.city +
        ", " +
        selectedSupplierDeliveryAddress.state +
        ", " +
        selectedSupplierDeliveryAddress.country +
        ". " +
        selectedSupplierDeliveryAddress.pincode;

      const supplierBillingAddress =
        selectedSupplierBillingAddress.addressLineOne +
        ", " +
        selectedSupplierBillingAddress.city +
        ", " +
        selectedSupplierBillingAddress.state +
        ", " +
        selectedSupplierBillingAddress.country +
        ". " +
        selectedSupplierBillingAddress.pincode;

      handleSaveSupplierAddress({
        supplierName: selectedSupplierData.companyName,
        supplierEmail: selectedSupplierData.companyEmail,
        supplierContactNumber: selectedSupplierData.phone,
        supplierSelectedDeliveryAddressId: selectedSupplierDeliveryAddress.id,
        supplierSelectedBillingAddressId: selectedSupplierBillingAddress.id,
        supplierDeliveryAddress: supplierDeliverAddress,
        supplierBillingAddress: supplierBillingAddress,
      });
    }
  };

  const handleCancelClick = () => {
    handleSupplierAddressCancel();
    setSelectedSupplierData(null);
  };

  const handleRadioChange = (addressId: number, addressType: number) => {
    if (addressType === AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_ID) {
      setDefaultDeliveryAddressId(addressId);
    } else if (addressType === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID) {
      setDefaultBillingAddressId(addressId);
    }
  };

  const renderSupplierAddress = (
    address: any,
    index: number,
    addressType: number
  ) => (
    <div
      key={address.id || index}
      className="addressList"
      style={{ position: "relative" }}
    >
      <div className="addressHeader flexBox">
        <div className="title">{getAddressType(address.addressType)}</div>
        <div className="radioContainer">
          <Radio
            checked={
              address.id ===
              (addressType === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID
                ? defaultBillingAddressId
                : defaultDeliveryAddressId)
            }
            onChange={() => handleRadioChange(address.id, addressType)}
          />
        </div>
      </div>
      <div className="addressDetails">
        <div>
          {`${address.addressLineOne}, ${address.city}, ${address.state}, ${address.country}, ${address.pincode}`}
        </div>
      </div>
    </div>
  );

  const getAddressType = (addressTypeId: number) => {
    return addressTypeId === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID
      ? "Billing Address"
      : "Delivery Address";
  };

  const items = [
    {
      key: "1",
      label: "Delivery Address",
      children: supplierAddressData?.some(
        (address: any) =>
          address.addressType === AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_ID
      ) ? (
        supplierAddressData
          .filter(
            (address: any) =>
              address.addressType ===
              AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_ID
          )
          .map((address: any, index: number) =>
            renderSupplierAddress(
              address,
              index,
              AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_ID
            )
          )
      ) : (
        <div className="addressList">No delivery addresses available.</div>
      ),
    },
    {
      key: "2",
      label: "Billing Address",
      children: supplierAddressData?.some(
        (address: any) =>
          address.addressType === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID
      ) ? (
        supplierAddressData
          .filter(
            (address: any) =>
              address.addressType ===
              AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID
          )
          .map((address: any, index: number) =>
            renderSupplierAddress(
              address,
              index,
              AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID
            )
          )
      ) : (
        <div className="addressList">No billing addresses available.</div>
      ),
    },
  ];

  return (
    <Modal
      title={modelTitle}
      open={isSupplierModalOpen}
      onCancel={handleCancelClick}
      footer={[
        <Button key="cancel" onClick={handleCancelClick}>
          Cancel
        </Button>,
        <Button
          type="primary"
          key="ok"
          disabled={!defaultDeliveryAddressId || !defaultBillingAddressId}
          onClick={onSaveSupplierAddress}
        >
          Submit
        </Button>,
      ]}
    >
      <Tabs defaultActiveKey="1" items={items} />
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  supplierAddressData: state.api.getAddress?.data,
  userId: state.api.login.data.id,
});

const mapDispatchToProps = (dispatch: any) => ({
  setDocumentSupplierData: (payload: any) => dispatch(setDocumentSupplierData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierAddressModal);
