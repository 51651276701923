import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Layout,
  message,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  TableColumnsType,
  notification,
} from "antd";
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import sales from "./sales.json";
import Purchase from "./payment.json";
import {
  purchaseColumns,
  salesColumns,
} from "../../utility/transactionUtility";
import { update } from "lodash";
import { AppConstants } from "../../../Appconstants";

type NotificationType = "success" | "info" | "warning" | "error";

interface IPaymentsProps {
  transactionType: string;
  documents: any;
  bankDetails: any;
}

const Payments: React.FC<IPaymentsProps> = ({
  transactionType,
  documents,
  bankDetails,
}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState<any[]>([]);
  const [columns, setColumns] = useState<TableColumnsType<any>>(salesColumns);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedStatus, setSelectedStatus] = useState<string>(() =>
    transactionType === AppConstants.TRANSACTION_TYPE.PURCHASE_TRANSACTION
      ? "payable"
      : "receivable"
  );
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerType, setDrawerType] = useState<string | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [originalData, setOriginalData] = useState<any[]>([]);
  const [updatedTotalAmount, setUpdatedTotalAmount] = useState<
    Record<string, number>
  >({});

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

  const handleTransactionTypeChange = (type: string) => {
    switch (type) {
      case AppConstants.TRANSACTION_TYPE.SALES_TRANSACTION:
        const filteredSalesData = documents.filter(
          (doc: any) => doc.documentType === "Invoice"
        );
        setData(filteredSalesData);
        setOriginalData(filteredSalesData);
        setColumns(salesColumns);
        break;
      case AppConstants.TRANSACTION_TYPE.PURCHASE_TRANSACTION:
        setData(Purchase);
        setOriginalData(Purchase);
        setColumns(purchaseColumns);
        break;
      default:
        setData([]);
        setOriginalData([]);
        setColumns([]);
    }
  };

  useEffect(() => {
    handleTransactionTypeChange(transactionType);
  }, [transactionType]);

  const paginatedData = data.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handlePaginationChange = (page: number, size?: number) => {
    setCurrentPage(page);
    if (size) setPageSize(size);
  };

  const getStatusOptions = () => {
    if (
      transactionType === AppConstants.TRANSACTION_TYPE.PURCHASE_TRANSACTION
    ) {
      return [
        { value: "payable", label: "Payable" },
        { value: "paid", label: "Paid" },
      ];
    }
    if (transactionType === AppConstants.TRANSACTION_TYPE.SALES_TRANSACTION) {
      return [
        { value: "receivable", label: "Receivable" },
        { value: "received", label: "Received" },
      ];
    }
    return [];
  };

  useEffect(() => {
    const newData =
      transactionType === AppConstants.TRANSACTION_TYPE.PURCHASE_TRANSACTION
        ? Purchase
        : sales;
    setOriginalData(newData);
    setData(newData);
  }, [transactionType]);

  const handleStatusChange = (value: string) => {
    setSelectedStatus(value);
    if (!value) {
      setData(originalData);
      return;
    }

    const filteredData = originalData.filter((item) => {
      if (
        transactionType === AppConstants.TRANSACTION_TYPE.PURCHASE_TRANSACTION
      ) {
        return value === "payable"
          ? item.balanceAmount > 0
          : item.balanceAmount === 0;
      } else if (
        transactionType === AppConstants.TRANSACTION_TYPE.SALES_TRANSACTION
      ) {
        return value === "receivable"
          ? item.balanceAmount > 0
          : item.balanceAmount === 0;
      }
      return true;
    });

    if (filteredData.length === 0) {
      setData(originalData);
    } else {
      setData(filteredData);
    }
  };

  useEffect(() => {
    if (
      transactionType === AppConstants.TRANSACTION_TYPE.PURCHASE_TRANSACTION
    ) {
      setSelectedStatus("payable");
    } else if (
      transactionType === AppConstants.TRANSACTION_TYPE.SALES_TRANSACTION
    ) {
      setSelectedStatus("receivable");
    }
  }, [transactionType]);

  const handleClosePaymentDrawer = () => {
    setDrawerVisible(false);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], newSelectedRows: any[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
      setSelectedRows(newSelectedRows);
    },
  };

  const handleLogPaymentChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    record: any
  ) => {
    const value = parseFloat(e.target.value) || 0;
    setSelectedRows((prevRows) =>
      prevRows.map((row) =>
        row.documentNumber === record.documentNumber
          ? { ...row, logPayment: value }
          : row
      )
    );
  };

  const handleOpenDrawer = (type: string) => {
    if (selectedRows.length === 0) {
      openNotificationWithIcon("warning", "select at least one row");
      return;
    }

    setDrawerType(type);
    setDrawerVisible(true);
    if (type === AppConstants.TRANSACTION_DRAWER_TYPE.LOG_PAYMENT) {
      const updatedRows = selectedRows.map((row) => ({
        ...row,
        totalAmount: row.amountPayableAfterTDS || row.balanceAmount,
      }));
      setSelectedRows(updatedRows);

      setColumns([
        ...purchaseColumns,
        {
          title: "Log Payment",
          dataIndex: "logPayment",
          key: "logPayment",
          render: (_, record) => (
            <Input
              type="number"
              value={record.logPayment || 0}
              onChange={(e) => handleLogPaymentChange(e, record)}
            />
          ),
        },
        {
          title: "Mark Paid",
          dataIndex: "markPaid",
          key: "markPaid",
          render: (_, record) => <Checkbox />,
        },
      ]); 
    }
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
    setDrawerType(null);
  };

  const handleLogTDS = (rowKey: string, values: any) => {
    const updatedData = data?.map((item) => {
      if (selectedRowKeys.includes(item.documentNumber)) {
        const tdsPercentage = values[`tdsPercentage_${item.documentNumber}`];
        const totalAmount = item.totalAmountPayable || 0;
        const tdsAmount = (tdsPercentage / 100) * totalAmount;
        const amountPayableAfterTDS = totalAmount - tdsAmount;

        setUpdatedTotalAmount((prev) => ({
          ...prev,
          [item.documentNumber]: amountPayableAfterTDS,
        }));

        return {
          ...item,
          tdsPercentage,
          tdsAmount: tdsAmount.toFixed(2),
          balanceAmount: amountPayableAfterTDS.toFixed(2),
        };
      }
      return item;
    });

    setData(updatedData);
    openNotificationWithIcon("success", "TDS added successfully");
    handleCloseDrawer();
  };

  const renderTDSDrawerContent = () => {
    if (!selectedRows || selectedRows.length === 0) return null;

    const handleTDSChange = (changedValues: any, allValues: any) => {
      selectedRows.forEach((row) => {
        const tdsPercentageField = `tdsPercentage_${row.documentNumber}`;
        const tdsAmountField = `tdsAmount_${row.documentNumber}`;
        const amountPayableField = `amountPayable_${row.documentNumber}`;

        if (changedValues[tdsPercentageField] !== undefined) {
          const tdsPercentage = changedValues[tdsPercentageField];
          const totalAmount = row.totalAmountPayable || 0;
          const tdsAmount = (tdsPercentage / 100) * totalAmount;
          const amountPayableAfterTDS = totalAmount - tdsAmount;

          form.setFieldsValue({
            [tdsAmountField]: tdsAmount.toFixed(2),
            [amountPayableField]: amountPayableAfterTDS.toFixed(2),
          });
        }
      });
    };

    return selectedRows?.map((row, index) => (
      <div key={row.documentNumber} style={{ marginBottom: "20px" }}>
        <Form
          form={form}
          layout="vertical"
          id="tds-form"
          className="mt-10"
          onFinish={(values) => handleLogTDS(row.documentNumber, values)}
          onValuesChange={handleTDSChange}
        >
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label={`Enter TDS in Percentage`}
                name={`tdsPercentage_${row.documentNumber}`}
                rules={[
                  { required: true, message: "TDS percentage is required" },
                ]}
              >
                <Input type="number" placeholder="Enter TDS %" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label={`TDS Amount`}
                name={`tdsAmount_${row.documentNumber}`}
              >
                <Input type="number" placeholder="Enter TDS Amount" readOnly />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label={`Total Amount`}
                name={`totalAmountPayable_${row.documentNumber}`}
              >
                <Input defaultValue={row.totalAmountPayable || 0} readOnly />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={`Total Amount Payable After Deduction`}
                name={`amountPayable_${row.documentNumber}`}
              >
                <Input
                  type="number"
                  placeholder="Enter Amount Payable"
                  readOnly
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label={`Company Name`}
                name={`company_${row.documentNumber}`}
              >
                <Input defaultValue={row.company || ""} readOnly />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    ));
  };

  const renderTransactionCard = () => {
    const cardDetails =
      transactionType === AppConstants.TRANSACTION_TYPE.SALES_TRANSACTION
        ? [
            { title: "Net Debit", value: "₹500,000" },
            { title: "Total Receivable", value: "₹300,000" },
            { title: "Overdue Receivable", value: "₹50,000" },
          ]
        : [
            { title: "Total Payable", value: "₹200,000" },
            { title: "Overdue Payable", value: "₹150,000" },
            { title: "My Wallet", value: "₹50,000" },
          ];
    const backgroundColors = ["#e6f7ff", "#fffbe6", "#fff0f6"];
    return (
      <Row gutter={[16, 16]}>
        {cardDetails?.map((card, index) => (
          <Col span={4} key={index}>
            <Card
              bordered={false}
              style={{
                backgroundColor:
                  backgroundColors[index % backgroundColors.length],
              }}
            >
              <h6 style={{ color: "#000", marginBottom: "8px" }}>
                {card.title}
              </h6>
              <p style={{ color: index % 2 === 0 ? "#1890ff" : "#ff4d4f" }}>
                {card.value}
              </p>
            </Card>
          </Col>
        ))}
      </Row>
    );
  };

  const handleLogPaymentSubmit = () => {
    const updatedData = data?.map((item) => {
      const matchingRow = selectedRows.find(
        (row) => row.documentNumber === item.documentNumber
      );
      if (matchingRow) {
        const newBalance = item.balanceAmount - (matchingRow.logPayment || 0);
        return {
          ...item,
          amountPaid: matchingRow.logPayment || 0,
          balanceAmount: Math.max(newBalance, 0),
          status: newBalance === 0 ? "paid" : "payable",
        };
      }
      return item;
    });
    setOriginalData(updatedData);
    setData(updatedData);
    openNotificationWithIcon("success", "Payment logged successfully");
    setDrawerVisible(false);
    setSelectedRows([]);
    setSelectedRowKeys([]);
    setSelectedStatus("payable");
  };

  const renderLogPayment = (): JSX.Element => {
    const filteredColumns =
      drawerType === AppConstants.TRANSACTION_DRAWER_TYPE.LOG_PAYMENT
        ? columns.filter(
            (col) =>
              col.key !== "tdsAmount" &&
              col.key !== "balanceAmount" &&
              col.key !== "debitCreditSetOff"
          )
        : columns; 

    const updatedDataSource = selectedRows.map((row) => ({
      ...row,
      totalAmountPayable:
        updatedTotalAmount[row.documentNumber] || row.totalAmountPayable,
    })); 

    const updatedColumns = filteredColumns.map((col) => {
      if ("dataIndex" in col && col.dataIndex === "totalAmount") {
        return {
          ...col,
          render: (_: any, row: any) => {
            const updatedTotal = updatedTotalAmount[row.documentNumber]; 
            return updatedTotal !== undefined
              ? updatedTotal.toFixed(2)  
              : row.totalAmountPayable;
          },
        };
      }
      return col;
    }); 

    return (
      <>
        <div className="textDocument">{transactionType}</div>
        {updatedDataSource.map((row) => (
          <div key={row.key}>
            <Table
              className="mt-10"
              columns={updatedColumns}
              dataSource={[row]}
              pagination={false}
              rowKey="key"
              bordered
              size="small"
            />
          </div>
        ))}
        <div className="textDocument mt-20">Bank/Cash Detail's</div>
        <Form
          layout="vertical"
          className="mt-10"
          //onFinish={(values) => handleLogPayment( values)}
        >
          <Row gutter={24}>
            <Col span={4}>
              <Form.Item
                label="Payment Date"
                name="paymentDate"
                rules={[{ required: true, message: "Enter payment date" }]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Select Bank/Cash"
                name="bankName"
                rules={[
                  { required: true, message: "Select a bank or cash option" },
                ]}
              >
                <Select placeholder="Select Bank/Cash">
                  {bankDetails?.map((bank: any) => (
                    <Select.Option key={bank.bankId} value={bank.bankName}>
                      {bank.bankName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Payment Mode"
                name="paymentMode"
                rules={[{ required: true, message: "Select payment mode" }]}
              >
                <Select placeholder="Select mode">
                  <Select.Option value="neft">Neft</Select.Option>
                  <Select.Option value="netBanking">Net Banking</Select.Option>
                  <Select.Option value="upi">UPI</Select.Option>
                  <Select.Option value="others">Others</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Transaction Number"
                name="transactionNumber"
                rules={[
                  { required: true, message: "Enter transaction number" },
                ]}
              >
                <Input placeholder="Enter transaction number" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Comments" name="comments">
                <Input.TextArea
                  rows={1}
                  placeholder="Enter comments"
                />
              </Form.Item>
            </Col>
            <Col span={2} style={{marginTop: "30px" }}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={handleLogPaymentSubmit}
              >
                {AppConstants.TRANSACTION_TEXT_TYPE.LOG_PAYMENT}
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  const filteredColumns = columns.filter(
    (col) => col.key !== "logPayment" && col.key !== "markPaid"
  );

  return (
    <Layout className="layout">
      <div>
        <Breadcrumb
          style={{
            margin: "16px 0",
            flex: 1,
          }}
        >
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item>{transactionType}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="flexBox"></div>
      <Content className="content-section">
        <div className="mb-10">
          <div className="flexBox">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                {transactionType === "Purchase Transaction" && (
                  <Button
                    type="link"
                    onClick={() =>
                      handleOpenDrawer(
                        AppConstants.TRANSACTION_DRAWER_TYPE.LOG_PAYMENT
                      )
                    }
                  >
                    <PlusOutlined />{" "}
                    {AppConstants.TRANSACTION_TEXT_TYPE.LOG_PAYMENT}
                  </Button>
                )}
              </div>
              <div>
                {transactionType === "Purchase Transaction" && (
                  <Button
                    type="link"
                    onClick={() => handleOpenDrawer("LogTDS")}
                  >
                    <PlusOutlined />{" "}
                    {AppConstants.TRANSACTION_TEXT_TYPE.LOG_TDS}
                  </Button>
                )}
              </div>
              <Button type="link" className="buttonAddItem">
                <DownloadOutlined /> Download
              </Button>
            </div>
            <div className="flexBox">
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select Status"
                optionFilterProp="label"
                options={getStatusOptions()}
                value={selectedStatus}
                onChange={handleStatusChange}
              />
            </div>
          </div>
        </div>
        {renderTransactionCard()}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              flex: 1,
              overflowY: "auto",
              minHeight: "calc(100vh - 380px)",
            }}
          >
            <Table
              className="mt-10"
              columns={filteredColumns}
              dataSource={paginatedData}
              rowKey="documentNumber"
              pagination={false}
              bordered
              scroll={{ y: "max-content", x: 1200 }}
              rowSelection={rowSelection}
            />
          </div>
          <div
            style={{
              marginTop: "16px",
              textAlign: "center",
              backgroundColor: "#fff",
            }}
          >
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={data?.length}
              onChange={handlePaginationChange}
              showSizeChanger
              pageSizeOptions={["10", "20", "50"]}
            />
          </div>
        </div>
        <Drawer
          width={
            drawerType === AppConstants.TRANSACTION_DRAWER_TYPE.LOG_PAYMENT
              ? 1350
              : 1250
          }
          title={
            drawerType === AppConstants.TRANSACTION_DRAWER_TYPE.LOG_PAYMENT
              ? AppConstants.TRANSACTION_TEXT_TYPE.LOG_PAYMENT
              : AppConstants.TRANSACTION_TEXT_TYPE.LOG_TDS
          }
          placement="right"
          onClose={handleCloseDrawer}
          open={drawerVisible}
          extra={
            <Space>
              <Button onClick={handleClosePaymentDrawer}>Cancel</Button>
              {/* <Button type="primary">Submit</Button> */}
              {drawerType ===
              AppConstants.TRANSACTION_DRAWER_TYPE.LOG_PAYMENT ? (
                ""
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() =>
                    document
                      .getElementById("tds-form")
                      ?.dispatchEvent(
                        new Event("submit", { cancelable: true, bubbles: true })
                      )
                  }
                >
                  {AppConstants.TRANSACTION_TEXT_TYPE.LOG_TDS}
                </Button>
              )}
            </Space>
          }
        >
          {drawerType === AppConstants.TRANSACTION_DRAWER_TYPE.LOG_PAYMENT
            ? renderLogPayment()
            : renderTDSDrawerContent()}
        </Drawer>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: any) => ({
  documents: state.api.getDocuments?.data?.reverse(),
  bankDetails: state.api.getBankDetail?.data?.reverse(),
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
