import React, { useEffect } from "react";
import Header from "./Header";
import logo from "../../Utility/images/logo.png";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import "./Privacy.css";
import { Col, Row } from "antd";

const Privacy: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Header />
      <div id="privacy-policy" className="container">
        <Row>
          <div className="container">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <h1 className="text-3xl font-bold mb-6 mt-10 heading-privacy">
                Privacy Policy
              </h1>
              <div className="paragh-privacy">
                <p className="mb-4">
                  We respect and are committed towards protecting your privacy.
                  Publishing, selling or renting any personal data or information to
                  any third party, without your consent, is against our ethics. The
                  privacy practices of this statement apply to our services
                  available under the domain and subdomains of the Site. By visiting
                  this Site you agree to be bound by the terms and conditions of
                  this privacy policy. If you do not agree, please do not use or
                  access our site.
                  <br /> This privacy policy does not apply to sites maintained by
                  other companies or organizations to which we link and we are not
                  responsible for any personal information you submit to third
                  parties via our website. Please ensure that you read the privacy
                  policy of such other companies or organizations before submitting
                  your details.
                </p>
                <p className="mb-4">
                  This privacy policy describes the information, as part of the
                  normal operation of our services, we collect from you and what may
                  happen to that information. This policy is inter alia formulated
                  and displayed, to inform you about our information
                  collection/retention policies and practices so that you can make
                  an informed decision, in relation to the sharing of your personal
                  information with us.
                  {/* <a
                href="mailto:Email@Website.com"
                className="text-blue-500 underline"
              >
                support@easemargin.com
              </a>
              . */}
                </p>
                <p className="mb-4">
                  By accepting the privacy policy and the user agreement or the use
                  of the site in general, you give your consent to our use and
                  disclosure of your personal information in accordance with this
                  privacy policy. This Privacy Policy is incorporated into and
                  subject to the terms of the User Agreement. This privacy policy is
                  effective upon acceptance of access by you to the site.
                </p>
                <h2 className="text-2xl font-semibold mt-6 mb-4">
                  Privacy Guarantee
                </h2>
                <p className="mb-4">
                  We agree that we will not sell or rent your personal information
                  to third parties for their marketing purposes without your
                  explicit consent. From time to time, we may reveal general
                  statistical information about our Site and visitors, such as
                  number of visitors, number and type of goods and services
                  purchased, etc. Only those of our employees who need access to
                  your information in order to perform their duties, are allowed
                  such access. Any employee who violates our privacy and/or security
                  policies is subjected to disciplinary action, including possible
                  termination and civil and/or criminal prosecution.
                </p>
                <h2 className="text-2xl font-semibold mt-6 mb-4">
                  Information We Collect
                </h2>
                <p className="mb-4">
                  The Personal Information is used for two general purposes: to
                  process your order, and to provide you with the best possible
                  services. Unless otherwise stated explicitly, this policy applies
                  to personal information as disclosed in any of the media.
                </p>
                <p className="mb-4">
                  In furtherance of the confidentiality with which we treat Personal
                  Information, we have put in place appropriate physical,
                  electronic, and managerial procedures to safeguard and secure the
                  information we collect online. We use data collection devices such
                  as "cookies" on certain pages of the Site to help and analyze our
                  web page flow, measure promotional effectiveness, and promote
                  trust and safety. "Cookies" are small files placed on your hard
                  drive that assist us in providing our services. We offer certain
                  features that are only available through the use of a "cookie".
                  Cookies can also help us provide information that is targeted to
                  your interests. Most cookies are "session cookies," which means
                  that they are automatically deleted from your hard drive at the
                  end of a session.
                </p>
                <p className="mb-4">
                  You are always free to decline our cookies if your browser
                  permits. Additionally, you may encounter "cookies" or other
                  similar devices on certain pages of the Site that are placed by
                  third parties. For example, if you view a web page created by a
                  user, there may be a "cookie" placed within that web page. We do
                  not control the use of cookies by third parties. You acknowledge
                  that you are disclosing Personal Information voluntarily. We
                  identify and use your IP address to help diagnose problems with
                  our server, and to administer our Website. Your IP address is also
                  used to help identify you and to gather broad demographic
                  information.
                </p>
                <h2 className="text-2xl font-semibold mt-6 mb-4">COOKIE POLICY</h2>
                <p className="mb-4">
                  Easemargin.com operates a strict privacy policy and we are
                  committed to being transparent about how we use cookies on our
                  website.
                </p>
                <ul className="list-disc list-inside mb-4 list-cookie">
                  <li>● Why are cookies important?</li>
                  <p className="mb-2">
                    Cookies help you make your online experience more efficient and
                    relevant to your interests. For instance, they are used to
                    remember your preferences on sites you visit often, to remember
                    your user ID and the contents of your shopping baskets, and to
                    help you navigate between pages efficiently.
                  </p>
                  <li>● What is a Cookie?</li>
                  <p className="mb-2">
                    A cookie is a small file, or files on your computer, phone, or
                    other device with a browser to save snippets of text for
                    reference by the website you are visiting. All cookies have
                    expiration dates in them that determine how long they stay in
                    your browser:
                  </p>
                  <li>
                    ● Session cookies - these are temporary cookies that expire (and
                    are automatically erased) whenever you close your browser.
                  </li>
                  <br />
                  <li>
                    ● Persistent cookies - these usually have an expiration date and
                    so stay in your browser until they expire, or until you manually
                    delete them. For example we use persistent cookies to better
                    understand usage patterns so that we can improve the site for
                    our customers.
                  </li>
                  <br />
                  <p className="mt-4">
                    Cookies are grouped into the following categories:
                  </p>
                  <li>
                    ● Essential - these are cookies that are required for the
                    regular operation of our websites.
                  </li>
                  <br />
                  <li>
                    ● Functional - these remember your preferences, and are intended
                    to make your experience on our websites better for you.
                  </li>
                  <br />
                  <li>
                    ● Analytics – these cookies are used for performance measurement
                    to understand things including how many people visit our
                    websites, how they navigate our sites, and what content is
                    popular. This allows us to improve your experience with us.
                    Additionally, you can see how Google Analytics (one of our
                    analytics tools) uses cookie information when you use our
                    partners' sites by visiting
                    www.google.com/policies/privacy/partners, or any other URL
                    Google may provide from time to time.
                  </li>
                  <br />
                  <li>
                    ● Advertising - these cookies enable us and our advertising
                    partners to serve you with relevant advertisements that we think
                    will interest you. You might see these advertisements on our
                    sites on other sites you visit. These cookies record your visit
                    to our website and the content you interact with. They may be
                    placed by us, or by advertising partners with our permission.
                  </li>
                </ul>
                <p className="mt-4">
                  To ensure compliance with our policies, we restrict the use of
                  third-party cookies to trusted partners. We also use third party
                  information from third party sources to enable us to deliver
                  advertising. These sources are validated by Third party, and not
                  by us.
                </p>
                <p className="mt-4">
                  To delete cookies from your browser, please note the following
                  simple steps. The following steps are indicative for Google chrome
                  and might vary depending on the different browsers that you may
                  use. Please refer to Settings on your browser for further details.
                </p>
                <p className="mt-4">
                  To ensure compliance with our policies, we restrict the use of third-party cookies to trusted partners.
                  You may also change other settings related to Privacy and Security under the same section.
                </p>
              </div>
            </Col>
          </div>
        </Row>
      </div>
      <Footer />
    </>
  );
};
export default Privacy;
