import React, { useState, useEffect, useRef } from "react";
import { Button, Table, Popconfirm, message, notification } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import CustomMetaDataModal from "../CustomMetaDataModal";
import moment from "moment";
import noRecord from "../../../Utility/images/norecord.png";
import { connect } from "react-redux";
import {
  addCustomFields,
  editCustomFields,
  getCustomFields,
  deleteCustomFields,
} from "../../../redux/actions/API/customFields";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";

interface CustomField {
  key: string;
  label: string;
  fieldType: "text" | "integer" | "select" | "datetime";
  options?: string[];
  defaultValue: any;
  level: "primary" | "item";
  createdBy: string;
  createdAt: string;
  category: string;
}

interface customFieldsProps {
  customFieldsData: any;
  addCustomFields: Function;
  editCustomFields: Function;
  getCustomFields: Function;
  deleteCustomFields: Function;
  addCustomFieldsState: IDataResponse;
  getCustomFieldsState: IDataResponse;
  companyId: number;
}

const CustomFieldComponent: React.FC<customFieldsProps> = ({
  addCustomFields,
  editCustomFields,
  getCustomFields,
  deleteCustomFields,
  companyId,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState<"form" | "table">("form");
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const [currentField, setCurrentField] = useState<CustomField | null>(null);
  const [createdBy, setCreatedBy] = useState("Admin");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getCustomFields({
      companyId: companyId,
    });
  }, []);

  const handleOpenModal = (
    mode: "form" | "table",
    fieldToEdit?: CustomField
  ) => {
    setModalMode(mode);
    setCurrentField(fieldToEdit || null);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setCurrentField(null);
  };

  const handleSaveFields = (fields: CustomField[], category: string) => {
    const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");

    if (currentField) {
      const updatedFields = customFields.map((field) =>
        field.key === currentField.key
          ? { ...field, ...fields[0], category, createdAt: currentTime }
          : field
      );
      editCustomFields(updatedFields);
      setCustomFields(updatedFields);
      message.success("Custom field updated successfully!");
    } else {
      const newFields: CustomField[] = fields.map((field) => ({
        ...field,
        key: `${Date.now()}`,
        level: modalMode === "form" ? "primary" : "item",
        createdBy,
        createdAt: currentTime,
        category,
      }));
      addCustomFields(newFields);
      setCustomFields((prevFields) => [...prevFields, ...newFields]);
      message.success("Custom field added successfully!");
    }

    handleCloseModal();
  };

  const handleEditField = (updatedField: CustomField) => {
    const updatedFields = customFields.map((field) =>
      field.key === updatedField.key ? updatedField : field
    );
    setCustomFields(updatedFields);
    message.success("Custom field updated successfully!");
    handleCloseModal();
  };

  const handleDeleteField = (key: string) => {
    deleteCustomFields(key);
    setCustomFields((prevFields) =>
      prevFields.filter((field) => field.key !== key)
    );
    message.success("Custom field deleted successfully!");
  };

  const columns = [
    {
      title: "Document Type",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Field Name",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Field Type",
      dataIndex: "fieldType",
      key: "fieldType",
    },
    {
      title: "Default Value",
      dataIndex: "defaultValue",
      key: "defaultValue",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: CustomField) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleOpenModal("form", record)}
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this field?"
            onConfirm={() => handleDeleteField(record.key)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" icon={<DeleteOutlined />} danger>
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="flexBox">
        <div></div>
        <Button type="link" onClick={() => handleOpenModal("form")}>
          <PlusOutlined />
          Add Custom Field
        </Button>
      </div>
      {isModalVisible && (
        <CustomMetaDataModal
          mode={modalMode}
          isVisible={isModalVisible}
          customField={customFields}
          currentField={currentField}
          onSave={handleSaveFields}
          onClose={handleCloseModal}
          onEdit={handleEditField}
          onDelete={handleDeleteField}
        />
      )}

      {customFields.length === 0 ? (
        <div className="noData">
          <img
            src={noRecord}
            alt="No Records Found"
            className="no-data-dimensions"
          />
        </div>
      ) : (
        <Table
          className="mt-10"
          rowKey="key"
          columns={columns}
          dataSource={customFields}
          locale={{
            emptyText: "No custom fields added yet",
          }}
          pagination={false}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  customFieldsData: state.api.getCustomFields?.data?.reverse(),
  addCustomFieldsState: state.api.addCustomFields,
  getCustomFieldsState: state.api.getCustomFields,
  companyId: state.api.login.data.companyId,
});

const mapDispatchToProps = (dispatch: any) => ({
  addCustomFields: (payload: any) => dispatch(addCustomFields(payload)),
  editCustomFields: (payload: any) => dispatch(editCustomFields(payload)),
  getCustomFields: (payload: any) => dispatch(getCustomFields(payload)),
  deleteCustomFields: (payload: any) => dispatch(deleteCustomFields(payload)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomFieldComponent);
