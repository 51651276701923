import {
  Breadcrumb,
  Button,
  Divider,
  Dropdown,
  FormInstance,
  Layout,
  MenuProps,
  notification,
  Pagination,
  Popconfirm,
  Popover,
  Radio,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { Col, Drawer, Form, Input, Row, Select, Space } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { connect } from "react-redux";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import {
  addItems,
  bulkDeleteItems,
  deleteItems,
  editItems,
  getItems,
} from "../../../redux/actions/API/items";
import { AppConstants } from "../../../Appconstants";
import Loader from "../../../Home/Loader/Loader";
import {
  EditOutlined,
  DeleteOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
  PlusOutlined,
  SettingOutlined,
  DownloadOutlined,
  UploadOutlined,
  SwapOutlined,
  ClockCircleOutlined,
  EllipsisOutlined,
  DatabaseOutlined,
  StockOutlined,
} from "@ant-design/icons";
import "./ItemManagement.css";
import { downloadExcel } from "../../../Utility/downloadExcel";
import { taxOptions, taxTypeOptions } from "../../utility/itemsUtility";
import StockTransfer from "../StockTransfer";
import History from "../History/History";
import { TableRowSelection } from "antd/es/table/interface";
import ManageUOM from "../manage-uom/manageUOM";
import type { ColumnType } from "antd/lib/table";
import { FilterOutlined } from "@ant-design/icons";
import FilterMenu from "../../../Home/Components/filter/FilterMenu";
import ManageCategories from "../manage-categories/ManageCategories";
import StockUpdate from "../stockUpdate/StockUpdate";
import {
  getNotifications,
  notify,
} from "../../../redux/actions/API/notifications";
import noRecord from "../../../Utility/images/norecord.png";
import ReconcileAndUpload from "../reconcileAndUpload/ReconcileAndUpload";
import {
  ICommandBarItems,
  IItemProps,
  IItemState,
} from "./ItemManagementConstants";
type NotificationType = "success" | "info" | "warning" | "error";

class ItemManagement extends React.Component<IItemProps, IItemState> {
  private formRef = React.createRef<FormInstance>();
  constructor(props: IItemProps) {
    super(props);
    this.state = {
      addEditItemOpen: false,
      loading: true,
      itemId: 0,
      itemName: "",
      itemType: 0,
      category: 0,
      taxType: 0,
      tax: 0,
      metricsUnit: 0,
      HSNCode: "",
      price: 0,
      currentStock: 0,
      minStock: 0,
      maxStock: 0,
      description: "",
      dataToEdit: [],
      isEdit: false,
      isManageCategoryOpen: false,
      isAddManageCategoryOpen: false,
      isDrawerVisible: false,
      filteredData: [],
      currentPage: 1,
      pageSize: 10,
      isStockTransferDrawerVisible: false,
      selectedItem: null,
      transferQuantity: 0,
      quantityError: "",
      isStockTransferVisible: false,
      itemData: [],
      isHistoryOpen: false,
      isManageUnitDrawerVisible: false,
      isStockUpdateFormVisible: false,
      allChecked: false,
      singleStoreCheck: false,
      commandBarItems: [
        {
          icon: <DatabaseOutlined />,
          title: "Physical Stock Reconciliation",
        },
        {
          icon: <UploadOutlined />,
          title: "Bulk Upload",
        },
        {
          icon: <EditOutlined />,
          title: "Bulk Edit Item",
        },
      ],
      selectedRowKeys: [],
      showBulkActions: false,
      forBulkEditOpen: false,
      isCommentRequired: false,
      showActionButton: true,
      // showCurrentStock: false,
      drawerTitle: "Items Bulk Upload",
      historyTitle: "Item History",
      showFilterMenu: false,
      showAlternateUnit: false,
      selectedMetricsUnit: "",
      alternateUnit: [{ altUnitId: null, conversionFactor: 0 }],
      categoryOptions: this.props.categoryData,
      subCategoriesOptions: [],
      microCategoryOptions: [],
      selectedCategory: null,
      selectedSubCategory: null,
      selectedMicroCategory: null,
      isMobileView: window.innerWidth <= 896,
    };
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize); // Remove resize listener
  }

  handleResize = () => {
    this.setState({ isMobileView: window.innerWidth <= 896 });
  };

  openNotificationWithIcon = (type: NotificationType, message: string) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

  componentDidMount(): void {
    this.props.getItems({ companyId: Number(this.props.companyId) });
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(
    prevProps: Readonly<IItemProps>,
    prevState: Readonly<IItemState>,
    snapshot?: any
  ): void {
    if (prevState.selectedRowKeys !== this.state.selectedRowKeys) {
      this.setState({ showBulkActions: this.state.selectedRowKeys.length > 0 });
    }

    if (prevProps.getItemState?.loading && !this.props.getItemState?.loading) {
      if (this.props.getItemState?.error?.length > 0) {
      } else {
        this.setState({
          loading: false,
          filteredData: this.generateData(this.props?.itemsData),
        });
      }
    }

    if (
      prevProps.getNotificationsState?.loading &&
      !this.props.getNotificationsState?.loading
    ) {
      if (this.props.getItemState?.error?.length > 0) {
        this.openNotificationWithIcon(
          "error",
          "Failed to add item. Please try again."
        );
      } else {
        this.props.notify({
          notification: `New Item added by ${this.props.username}`,
          companyId: this.props.companyId,
          createdBy: this.props.userId,
          createdByName: this.props.username,
          status: 1,
          ip_address: "127.0.0.1",
        });

        this.setState({
          loading: false,
        });

        this.openNotificationWithIcon("success", "Item added successfully");
      }
    }

    if (prevProps.addItemState?.loading && !this.props.addItemState?.loading) {
      if (this.props.addItemState?.error) {
        this.openNotificationWithIcon(
          "error",
          this.props.addItemState?.error?.message
        );
        this.setState({
          loading: false,
        });
      } else {
        this.props.getItems({
          companyId: this.props.companyId,
        });
        this.formRef.current?.resetFields();
        this.setState({
          addEditItemOpen: false,
          loading: false,
        });
        this.openNotificationWithIcon("success", "Item added successfully");
      }
    }

    if (
      prevProps.editItemState?.loading &&
      !this.props.editItemState?.loading
    ) {
      if (this.props.editItemState?.error) {
        this.openNotificationWithIcon(
          "error",
          this.props.editItemState?.error?.message
        );
        this.setState({ loading: false });
      } else {
        this.props.getItems({ companyId: this.props.companyId });
        this.formRef.current?.resetFields();
        this.setState({
          addEditItemOpen: false,
          loading: false,
          isEdit: false,
        });
        this.openNotificationWithIcon("success", "Item updated successfully");
      }
    }

    if (
      prevProps.bulkDeleteItemState?.loading &&
      !this.props.bulkDeleteItemState?.loading
    ) {
      this.props.getItems({ companyId: this.props.companyId });
      this.setState({ loading: false });
      this.openNotificationWithIcon("success", "Item deleted successfully");
    }

    if (
      prevProps.deleteItemState?.loading &&
      !this.props.deleteItemState?.loading
    ) {
      this.props.getItems({ companyId: this.props.companyId });
      this.setState({ loading: false });
      this.openNotificationWithIcon(
        "success",
        this.props.deleteItemState?.data?.message
      );
    }
  }

  onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    this.setState({
      selectedRowKeys: newSelectedRowKeys,
    });
  };

  rowSelection: TableRowSelection<any> = {
    onChange: this.onSelectChange,
  };

  columns = (): ColumnType<any>[] => {
    const { isMobileView } = this.state;
    return [
      {
        title: "Item ID",
        dataIndex: "itemId",
        key: "itemId",
        width: "95px",
        fixed: isMobileView ? undefined : "left",
        className: "mobItemIdCol",
        sorter: (a: any, b: any) => parseFloat(a.itemId) - parseFloat(b.itemId),
        render: (_: any, record: any) => (
          <div className="linkText">{record.itemId}</div>
        ),
      },
      {
        title: "Item Details",
        dataIndex: "itemName",
        key: "itemName",
        width: "125px",
        sorter: (a: any, b: any) =>
          a.itemName.trim().localeCompare(b.itemName.trim()),
        render: (_: any, record: any) => (
          <>
            <div className="fw-500">{record.itemName}</div>
            <div>UOM: {record.metricsUnit}</div>
            <div>{record.HSNCode ? "HSN: " + record.HSNCode : <></>}</div>
          </>
        ),
      },
      {
        title: "Type",
        dataIndex: "itemType",
        key: "itemType",
        width: "65px",
        sorter: (a: any, b: any) => a.itemType.localeCompare(b.itemType),
        render: (_: any, record: any) => (
          <Tag
            color={
              record.itemType == "Buy"
                ? "green"
                : record.itemType == "Sell"
                ? "blue"
                : "purple"
            }
            key={1}
          >
            {record.itemType}
          </Tag>
        ),
      },
      {
        title: "Store",
        key: "store",
        dataIndex: "store",
        width: "200",
        sorter: (a: any, b: any) => {
          const storeA = a.store?.[0]?.storeName || "";
          const storeB = b.store?.[0]?.storeName || "";
          return storeA.localeCompare(storeB);
        },
        render: (stores: any) => (
          <>
            {stores.length > 0 ? (
              <>
                {stores.length == 1 ? (
                  <Popover
                    key={`popover-${stores[0]?.storeId}`}
                    placement="right"
                    title={"Stock details"}
                    content={
                      <>
                        {stores?.map((elem: any, index: number) => {
                          return (
                            <div
                              key={elem.storeId || `${index}`}
                              className="mt-10"
                              style={{
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                padding: "5px 10px",
                              }}
                            >
                              <div>Store name: {elem.storeName}</div>
                              <div>Quantity: {elem.quantity}</div>
                              <div>
                                Stock value:
                                <span style={{ color: "green" }}>
                                  {new Intl.NumberFormat("en-IN").format(
                                    elem.stockValue
                                  ) + " INR"}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    }
                  >
                    <span className="link">{stores[0]?.storeName}</span>
                  </Popover>
                ) : (
                  stores[0]?.storeName
                )}
                {stores.length - 1 > 0 && (
                  <Popover
                    key={`popover-${stores[0]?.storeId}`}
                    placement="right"
                    title={"Stock details"}
                    content={
                      <>
                        {stores?.map((elem: any) => {
                          return (
                            <div
                              key={elem.storeId}
                              className="mt-10"
                              style={{
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                padding: "5px 10px",
                              }}
                            >
                              <div>Store name: {elem.storeName}</div>
                              <div>Quantity: {elem.quantity}</div>
                              <div>
                                Stock value:{" "}
                                <span style={{ color: "green" }}>
                                  {new Intl.NumberFormat("en-IN").format(
                                    elem.stockValue
                                  ) + " INR"}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    }
                  >
                    <span className="link"> +{stores.length - 1} Stores</span>
                  </Popover>
                )}
              </>
            ) : (
              <>N/A</>
            )}
          </>
        ),
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        width: "200px",
        sorter: (a: any, b: any) => a.category.localeCompare(b.category),
        render: (_: any, stockData: any) => {
          const category = this.props.categoryData?.find(
            (category) => category.id === stockData.category
          );
          const subCategory = category?.child?.find(
            (sub: { id: any }) => sub.id === stockData.subCategory
          );
          const microCategory = subCategory?.child?.find(
            (micro: { id: any }) => micro.id === stockData.microCategory
          );
          const categoryData = [
            { label: "Category", name: category?.name || "" },
            subCategory && {
              label: "Sub Category",
              name: subCategory?.name || "",
            },
            microCategory && {
              label: "Micro Category",
              name: microCategory?.name || "",
            },
          ]?.filter(Boolean);
          return (
            <>
              {categoryData.map(({ label, name }, index) => (
                <div key={index}>
                  <span className="link">{label}: </span>
                  <span>{name}</span>
                </div>
              ))}
            </>
          );
        },
      },
      {
        title: "Price",
        key: "price",
        dataIndex: "price",
        width: "120px",
        sorter: (a: any, b: any) => a.price - b.price,
      },
      {
        title: "Tax Type",
        key: "taxTypeToShow",
        dataIndex: "taxTypeToShow",
        width: "150px",
        sorter: (a: any, b: any) =>
          a.taxTypeToShow.localeCompare(b.taxTypeToShow),
      },
      {
        title: "Stock Data",
        key: "currentStock",
        dataIndex: "currentStock",
        width: "200px",
        sorter: (a: any, b: any) => a.currentStock - b.currentStock,
        render: (_: any, stockData: any) => {
          const icon =
            stockData.minStock > stockData.currentStock ? (
              <ArrowDownOutlined style={{ color: "red", marginLeft: 5 }} />
            ) : stockData.maxStock < stockData.currentStock ? (
              <ArrowUpOutlined style={{ color: "green", marginLeft: 5 }} />
            ) : null;

          return (
            <div>
              {/* <div>Current Stock : {stockData.currentStock} {icon}</div> */}
              <div>
                {stockData.currentStock ? (
                  "Current Stock: " + stockData.currentStock
                ) : (
                  <>--</>
                )}
              </div>
              <div>
                {stockData.minStock ? (
                  "Min Stock: " + stockData.minStock
                ) : (
                  <></>
                )}
              </div>
              <div>
                {stockData.maxStock ? (
                  "Max Stock: " + stockData.maxStock
                ) : (
                  <></>
                )}
              </div>
            </div>
          );
        },
      },
      {
        title: "Actions",
        key: "action",
        width: "80px",
        fixed: "right",
        render: (_: any, record: any) =>
          isMobileView ? (
            <Dropdown
              menu={{
                items: [
                  {
                    key: "edit",
                    label: "Edit Details",
                    icon: <EditOutlined />,
                    onClick: () => this.handleEdit(record),
                  },
                  {
                    key: "stockTransfer",
                    label: "Stock Transfer",
                    icon: <SwapOutlined />,
                    onClick: () => this.openStockTransferDrawer(record),
                  },
                  {
                    key: "history",
                    label: "History",
                    icon: <ClockCircleOutlined />,
                    onClick: () =>
                      this.openHistoryDrawer(record, "Item History"),
                  },
                  {
                    key: "delete",
                    label: (
                      <Popconfirm
                        title="Are you sure to delete this?"
                        onConfirm={() => this.handleDelete(record)}
                        okText="Yes"
                        cancelText="No"
                      >
                        Delete
                      </Popconfirm>
                    ),
                    icon: <DeleteOutlined />,
                    danger: true,
                  },
                ],
              }}
              trigger={["click"]}
            >
              <div style={{ padding: "20px" }}>
                <EllipsisOutlined style={{ cursor: "pointer", fontSize: 18 }} />
              </div>
            </Dropdown>
          ) : (
            <Space size="middle">
              <Tooltip title="Edit Details">
                <span
                  className="actionIcons"
                  onClick={() => {
                    this.handleEdit(record);
                  }}
                >
                  <EditOutlined />
                </span>
              </Tooltip>
              <Tooltip title="Stock Transfer">
                <span
                  className="actionIcons"
                  onClick={() => this.openStockTransferDrawer(record)}
                >
                  <SwapOutlined />
                </span>
              </Tooltip>
              <Tooltip title="History">
                <span
                  className="actionIcons"
                  onClick={() => this.openHistoryDrawer(record, "Item History")}
                >
                  <ClockCircleOutlined />
                </span>
              </Tooltip>
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure to delete this?"
                  onConfirm={() => this.handleDelete(record)}
                  okText="Yes"
                  cancelText="No"
                >
                  <span className="actionIcons">
                    <DeleteOutlined />
                  </span>
                </Popconfirm>
              </Tooltip>
            </Space>
          ),
      },
    ];
  };

  handleDelete = (record: any) => {
    this.setState({ loading: true });
    this.props.deleteItems({ itemId: record.id });
  };

  renderItemType = (itemType: string) => {
    return (
      <Tag
        color={
          itemType == "Buy"
            ? "geekblue"
            : itemType == "Sell"
            ? "green"
            : "loser"
        }
        key={1}
      >
        {itemType}
      </Tag>
    );
  };

  handleCategoryChange = (value: number) => {
    let filteredSubCategory = this.state.categoryOptions.filter(
      (elem: any) => elem.id == Number(value)
    );
    let filterMicrocategory: any[] = [];
    if (filteredSubCategory.length > 0) {
      filterMicrocategory = filteredSubCategory[0]?.child.filter(
        (microcat: any) => microcat.id == filteredSubCategory[0].child[0].id
      );
    }
    this.setState(
      {
        selectedCategory: value,
        subCategoriesOptions:
          filteredSubCategory[0]?.child?.length > 0
            ? filteredSubCategory[0]?.child
            : [],
        microCategoryOptions:
          filterMicrocategory[0]?.child?.length > 0
            ? filterMicrocategory[0]?.child
            : [],
      },
      () => {
        this.formRef.current?.setFieldValue(
          "subCategory",
          filteredSubCategory.length > 0 &&
            filteredSubCategory[0]?.child.length > 0
            ? filteredSubCategory[0]?.child[0]?.id
            : null
        );
        this.formRef.current?.setFieldValue(
          "microCategory",
          filterMicrocategory.length > 0 &&
            filterMicrocategory[0]?.child.length > 0
            ? filterMicrocategory[0]?.child[0].id
            : null
        );
      }
    );
  };

  handleSubCategoryChange = (value: any) => {
    let filteredMicroCategory = this.state?.subCategoriesOptions.filter(
      (elem: any) => elem.id == Number(value)
    );
    this.setState(
      {
        selectedSubCategory: value,
        selectedMicroCategory: null,
        microCategoryOptions:
          filteredMicroCategory[0]?.child.length > 0
            ? filteredMicroCategory[0]?.child
            : [],
      },
      () => {
        this.formRef.current?.setFieldValue(
          "microCategory",
          filteredMicroCategory.length > 0 &&
            filteredMicroCategory[0]?.child.length > 0
            ? filteredMicroCategory[0].child[0].id
            : null
        );
      }
    );
  };

  handleMicroCategoryChange = (value: any) => {
    this.setState({ selectedMicroCategory: value });
  };

  getStockData = (
    stores: any,
    price: number,
    storeNames: Record<number | string, string>
  ) => {
    let storeData: {
      storeName: string;
      quantity: number;
      stockValue: number;
    }[] = [];

    stores?.forEach((store: any) => {
      const storeId = store?.storeId;
      const storeName = storeNames[storeId?.toString()];

      storeData.push({
        storeName: storeName,
        quantity: store?.quantity || 0,
        stockValue: (store?.quantity || 0) * price,
      });
    });

    return storeData;
  };

  generateData = (itemsData: any) => {
    const storeNames = Array.isArray(this.props.storeData)
      ? this.props.storeData.reduce((acc: any, store: any) => {
          acc[store.id] = store.name;
          return acc;
        }, {})
      : {};

    const uomCode = Array.isArray(this.props.UOMData)
      ? this.props.UOMData.reduce((acc: any, uom: any) => {
          acc[uom.id] = uom.code;
          return acc;
        }, {})
      : {};

    let data: any[] = [];
    itemsData?.forEach((item: any) => {
      const storeData = this.getStockData(item.stores, item.price, storeNames);
      const metricsUnit = item?.metricsUnit;

      const alternateUnits =
        item.alternateUnits?.map((altUnit: any) => ({
          unit: uomCode[altUnit.alternateUnits] || "N/A",
          conversionFactor: altUnit.conversionfactor,
        })) || [];

      data.push({
        key: item.id,
        id: item.id,
        itemId: item.itemId,
        itemName: item.itemName,
        itemType:
          item.itemType === AppConstants.ITEM_TYPES.BUY.ITEM_TYPE_ID
            ? AppConstants.ITEM_TYPES.BUY.ITEM_TYPE
            : item.itemType === AppConstants.ITEM_TYPES.SELL.ITEM_TYPE_ID
            ? AppConstants.ITEM_TYPES.SELL.ITEM_TYPE
            : AppConstants.ITEM_TYPES.BOTH.ITEM_TYPE,
        category: item.category,
        subCategory: item.subCategory,
        microCategory: item.microCategory,
        metricsUnit:
          metricsUnit && uomCode[metricsUnit] !== undefined
            ? uomCode[metricsUnit]
            : "N/A",
        HSNCode: item?.HSNCode,
        taxTypeToShow:
          item.taxType === AppConstants.ITEM_TAX_TYPES.INCLUSIVE.ITEM_TAX_ID
            ? `${AppConstants.ITEM_TAX_TYPES.INCLUSIVE.ITEM_TAX} (${item.tax}%)`
            : `${AppConstants.ITEM_TAX_TYPES.EXCLUSIVE.ITEM_TAX} (${item.tax}%)`,
        taxType: item.taxType,
        tax: item.tax,
        store: storeData,
        price: new Intl.NumberFormat("en-IN").format(item.price) + " INR",
        minStock: item.minStock,
        maxStock: item.maxStock,
        currentStock: item.currentStock,
        description: item.description,
        createdAt: item.createdAt,
        alternateUnits,
      });
    });
    return data;
  };

  handleItemSubmit = () => {
    this.setState({ loading: true });
    this.formRef.current
      ?.validateFields()
      .then((values) => {
        let alternateUnitData = this.state.alternateUnit.map((row) => ({
          alternateUnits: row.altUnitId,
          conversionfactor: row.conversionFactor,
        }));
        let dataToSend: any = {
          itemId: values.itemId,
          itemName: values.itemName,
          itemType: Number(values.itemType),
          category: Number(values.category),
          subCategory: Number(values.subCategory),
          microCategory: Number(values.microCategory),
          taxType: Number(values.taxType),
          tax: Number(values.tax),
          metricsUnit: Number(values.metricsUnit),
          HSNCode: values.HSNCode,
          storeId: values.store,
          userId: this.props.userId,
          price: Number(values.price),
          currentStock: Number(values.currentStock),
          minStock: Number(values.minStock),
          maxStock: Number(values.maxStock),
          description: values.description,
          companyId: Number(this.props.companyId),
          alternateUnits: alternateUnitData,
        };
        if (this.state.isEdit) {
          dataToSend.id = this.state.dataToEdit.id;
        }
        this.setState(dataToSend, () => {
          this.state.isEdit
            ? this.props.editItems(dataToSend)
            : this.props.addItems(dataToSend);
        });
      })
      .catch((errorInfo) => {
        this.setState({
          loading: false,
        });
      });
  };

  handleEdit = (record: any) => {
    const alternateUnit =
      record.alternateUnits && Array.isArray(record.alternateUnits)
        ? record.alternateUnits.map((unit: any) => ({
            altUnitId:
              this.props.UOMData?.find(
                (uom: { code: string }) => uom.code === unit.unit
              )?.id || null,
            conversionFactor: unit.conversionFactor,
          }))
        : [];

    const metricsUnitId = this.props.UOMData?.find(
      (uom: { code: any }) => uom.code === record.metricsUnit
    )?.id;

    const priceNumeric =
      parseFloat(record.price.replace(/[^0-9.-]+/g, "")) || 0;

    this.handleCategoryChange(record.category);
    const dataToEdit = {
      id: record.id,
      itemId: record.itemId,
      itemName: record.itemName,
      itemType:
        record.itemType === "Buy" ? 1 : record.itemType === "Sell" ? 2 : 3,
      category: record.category,
      subCategory: record.subCategory,
      microCategory: record.microCategory,
      taxType: record.taxType,
      tax: record.tax,
      metricsUnit: metricsUnitId,
      HSNCode: record.HSNCode,
      price: priceNumeric,
      currentStock: record.currentStock,
      minStock: record.minStock,
      maxStock: record.maxStock,
      description: record.description,
      companyId: this.props.companyId,
      alternateUnit,
    };
    this.setState(
      {
        isEdit: true,
        addEditItemOpen: true,
        dataToEdit,
        showAlternateUnit: alternateUnit.length > 0,
        alternateUnit,
      },
      () => {
        this.formRef.current?.setFieldsValue(dataToEdit);
        this.forceUpdate();
      }
    );
  };

  categoryDrawerStatus = (data: boolean) => {
    this.setState({
      isManageCategoryOpen: data,
    });
  };

  handleSearch = (value: string) => {
    const filteredData =
      value.trim() === ""
        ? this.generateData(this.props.itemsData)
        : this.generateData(this.props.itemsData).filter((item: any) =>
            Object.keys(item).some((key) =>
              String(item[key]).toLowerCase().includes(value.toLowerCase())
            )
          );
    this.setState({ filteredData, currentPage: 1 });
  };

  onPageChange = (page: number, pageSize: number) => {
    this.setState({ currentPage: page, pageSize: pageSize });
  };

  openDrawer = (title: string) => {
    this.setState({ isDrawerVisible: true, drawerTitle: title });
  };

  closeDrawer = () => {
    this.setState({ isDrawerVisible: false });
  };

  // openMultipleStockTransferDrawer = () => {
  //   this.setState({ isStockTransferVisible: true });
  // };

  closeStockTransferForm = () => {
    this.setState({ isStockTransferVisible: false });
  };

  openHistoryDrawer = (record: any, title: string) => {
    this.setState({
      isHistoryOpen: true,
      historyTitle: title,
      selectedItem: record.id,
    });
  };

  closeHistoryDrawer = () => {
    this.setState({ isHistoryOpen: false, selectedItem: null });
  };

  openManageUnitDrawer = () => {
    this.setState({ isManageUnitDrawerVisible: true });
  };

  openStockUpdateForm = () => {
    this.setState({ isStockUpdateFormVisible: true });
  };

  closeStockUpdateForm = () => {
    this.setState({ isStockUpdateFormVisible: false });
  };

  closeManageUnitDrawer = () => {
    this.setState({ isManageUnitDrawerVisible: false });
  };

  openStockTransferDrawer = (record: any) => {
    const dataToSet = {
      id: record.id,
      itemId: record.itemId,
      itemName: record.itemName,
      transferBtnDisable: true,
      // companyId: this.props.companyId,
      // stores: record.store,
    };
    this.setState({
      isStockTransferVisible: true,
      selectedItem: record,
      // transferQuantity: 0,
      // isCommentRequired: true,
      showActionButton: false,
      // showCurrentStock: true,
      dataToSet,
    });
  };

  handleCloseStockTransferDrawer = () => {
    this.setState({
      isStockTransferVisible: false,
      // selectedItem: null,
    });
  };

  openDrawerWithComments = () => {
    this.setState({
      isStockTransferVisible: true,
      isCommentRequired: true,
      showActionButton: true,
    });
  };

  handleTransferQuantityChange = (value: number) => {
    const { selectedItem } = this.state;
    if (selectedItem) {
      if (value > selectedItem.currentStock) {
        this.setState({
          transferQuantity: value,
          quantityError: "Transfer quantity cannot exceed current stock.",
        });
      } else {
        this.setState({
          transferQuantity: value,
          quantityError: "",
        });
      }
    }
  };

  handleSubmitStockTransfer = () => {
    this.handleCloseStockTransferDrawer();
    notification.success({
      message: "Stock Transfer Successful",
      description: `The stock has been successfully transferred.`,
    });
  };

  downloadSelectedRow = () => {
    const selectedData = this.state.filteredData
      .filter((item: any) => this.state.selectedRowKeys.includes(item.id))
      .map((item: any) => ({
        "Item ID": item.itemId,
        "Item Name": item.itemName,
        "Item Type": item.itemType,
        UOM: item.metricsUnit,
        "HSN Code": item.HSNCode,
        "Item Category": item.category,
        Price: item.price,
        "Current Stock": item.currentStock,
        "Min Stock": item.minStock,
        "Max Stock": item.maxStock,
        Tax: item.tax,
        store: item.store,
      }));
    downloadExcel(selectedData, "Selected_Items");
  };

  handleAddRow = () => {
    this.setState((prevState) => ({
      alternateUnit: [
        ...prevState.alternateUnit,
        { altUnitId: null, conversionFactor: 0 },
      ],
    }));
  };

  handleDeleteRow = (index: number) => {
    this.setState((prevState) => ({
      alternateUnit: prevState.alternateUnit.filter(
        (_: any, i: number) => i !== index
      ),
    }));
  };

  handleBulkDelete = () => {
    this.setState({ loading: true, selectedRowKeys: [] });
    const updatedData = this.state.selectedRowKeys.filter(
      (row: any) => !this.state.selectedRowKeys.includes(row.id)
    );
    this.props.bulkDeleteItems({ items: updatedData });
  };

  handleFilteredData = (filteredData: any[]) => {
    this.setState({ filteredData });
  };

  render() {
    const {
      isDrawerVisible,
      currentPage,
      pageSize,
      filteredData,
      isStockTransferVisible,
      isHistoryOpen,
      isManageUnitDrawerVisible,
      drawerTitle,
      historyTitle,
      isStockUpdateFormVisible,
      isMobileView,
    } = this.state;
    const startIndex = (currentPage - 1) * pageSize;
    const currentData = filteredData.slice(startIndex, startIndex + pageSize);

    const commandBarItems = this.state.commandBarItems.map(
      (item: ICommandBarItems) => ({
        key: item.title,
        label: (
          <span onClick={() => this.openDrawer(item.title)}>
            {item.icon} {item.title}
          </span>
        ),
      })
    );

    const additionalItems = [
      {
        key: "StockTransfer",
        label: (
          <span
            onClick={() =>
              this.setState({
                isStockTransferVisible: true,
                isCommentRequired: true,
                showActionButton: true,
              })
            }
          >
            <SwapOutlined /> Stock Transfer
          </span>
        ),
      },
      {
        key: "Download",
        label: (
          <span onClick={this.downloadSelectedRow}>
            <DownloadOutlined /> Download
          </span>
        ),
      },
      {
        key: "viewHistory",
        label: (
          <span
            onClick={() =>
              this.setState({
                isHistoryOpen: true,
                historyTitle: "View Item History",
              })
            }
          >
            <ClockCircleOutlined /> View Item History
          </span>
        ),
      },
      ...(isMobileView
        ? [
            {
              key: "ManageUOM",
              label: (
                <span onClick={this.openManageUnitDrawer}>
                  <SettingOutlined /> Manage UOM
                </span>
              ),
            },
            {
              key: "StockUpdate",
              label: (
                <span onClick={this.openStockUpdateForm}>
                  <StockOutlined /> Stock Update
                </span>
              ),
            },
          ]
        : []),
    ];
    const menuItems: MenuProps["items"] = [
      ...commandBarItems,
      ...additionalItems,
    ];

    const breadcrumbItems = [
      { key: "dashboard", title: "Dashboard" },
      { key: "itemManagement", title: "Item Management" },
    ];

    const handleCloseDrawer = () => {
      this.setState({
        addEditItemOpen: false,
        isEdit: false,
        showAlternateUnit: false,
      });
      this.formRef.current?.resetFields();
    };

    return (
      <>
        <Loader loading={this.state.loading}></Loader>
        <Layout className="layout">
          <div className="flexBox mb-flexFlow">
            <Breadcrumb className="breadcrumb" items={breadcrumbItems} />
            <div className="mt-10"></div>
          </div>
          <Content className="content-section">
            <div className="mb-10">
              <div className="flexBox">
                <div>
                  <Button
                    type="link"
                    onClick={() => this.setState({ addEditItemOpen: true })}
                    className="buttonAddItem"
                  >
                    <PlusOutlined /> Add Item
                  </Button>
                  <Button
                    type="link"
                    onClick={() =>
                      this.setState({ isManageCategoryOpen: true })
                    }
                    className="buttonCategories"
                  >
                    <SettingOutlined /> Categories
                  </Button>
                  {!isMobileView && (
                    <>
                      <Button
                        type="link"
                        onClick={this.openManageUnitDrawer}
                        className="buttonAddItem"
                      >
                        <SettingOutlined /> Manage UOM
                      </Button>
                      <Button
                        type="link"
                        onClick={this.openStockUpdateForm}
                        className="buttonStockUpdate"
                      >
                        <StockOutlined /> Stock Update
                      </Button>
                    </>
                  )}

                  <Dropdown menu={{ items: menuItems }} trigger={["click"]}>
                    <Button
                      type="link"
                      className="buttonAddItem buttonEllipsisMobile"
                    >
                      <EllipsisOutlined />
                    </Button>
                  </Dropdown>
                </div>
                <div className="flexBox">
                  {this.state.showBulkActions &&
                  this.state.selectedRowKeys.length > 1 ? (
                    <div className="flexBox">
                      <Button
                        type="link"
                        danger
                        onClick={this.handleBulkDelete}
                      >
                        <DeleteOutlined /> Bulk Delete
                      </Button>
                    </div>
                  ) : (
                    []
                  )}

                  <div className="mobileViewFilterSearchRow">
                    {isMobileView ? (
                      <>
                        <Input
                          placeholder="Search..."
                          style={{ width: "80%" }}
                          onChange={(e) => this.handleSearch(e.target.value)}
                          className="mobile-search-input"
                        />
                        <Popover
                          placement="bottom"
                          content={
                            <FilterMenu
                              filterType="itemFilter"
                              filterData={this.generateData(
                                this.props?.itemsData
                              )}
                              onApplyFilter={(filteredData) => {
                                this.handleFilteredData(filteredData);
                                this.setState({ showFilterMenu: false });
                              }}
                            />
                          }
                          trigger="click"
                          open={this.state.showFilterMenu}
                          onOpenChange={() => {
                            this.setState({
                              showFilterMenu: !this.state.showFilterMenu,
                            });
                          }}
                          overlayStyle={{ width: 280 }}
                        >
                          <Button
                            type="link"
                            icon={<FilterOutlined />}
                            className="buttonItemFilter"
                          ></Button>
                        </Popover>
                      </>
                    ) : (
                      <>
                        <Popover
                          placement="bottom"
                          content={
                            <FilterMenu
                              filterType="itemFilter"
                              filterData={this.generateData(
                                this.props?.itemsData
                              )}
                              onApplyFilter={(filteredData) => {
                                this.handleFilteredData(filteredData);
                                this.setState({ showFilterMenu: false });
                              }}
                            />
                          }
                          trigger="click"
                          open={this.state.showFilterMenu}
                          onOpenChange={() => {
                            this.setState({
                              showFilterMenu: !this.state.showFilterMenu,
                            });
                          }}
                          overlayStyle={{ width: 280 }}
                        >
                          <Button
                            type="link"
                            icon={<FilterOutlined />}
                            className="buttonItemFilter"
                          >
                            Filter
                          </Button>
                        </Popover>
                        <Input
                          placeholder="Search..."
                          style={{ width: 200 }}
                          onChange={(e) => this.handleSearch(e.target.value)}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {currentData.length > 0 ? (
              <>
                <Table
                  columns={this.columns()}
                  bordered
                  rowKey="id"
                  dataSource={currentData}
                  scroll={{ x: "max-content" }}
                  pagination={false}
                  sticky
                  rowSelection={this.rowSelection}
                />
                <Pagination
                  total={filteredData?.length}
                  pageSize={pageSize}
                  current={currentPage}
                  onChange={this.onPageChange}
                  showSizeChanger
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                  style={{ textAlign: "center", marginTop: 20 }}
                />
              </>
            ) : (
              <div style={{ textAlign: "center", marginTop: "10%" }}>
                <img
                  src={noRecord}
                  alt="historyData"
                  style={{ width: "auto", height: "300px" }}
                />
                <p>No items found !</p>
              </div>
            )}
          </Content>
        </Layout>

        <Drawer
          title={this.state.isEdit ? "Edit an Item" : "Add an Item"}
          width={720}
          onClose={handleCloseDrawer}
          open={this.state.addEditItemOpen}
          styles={{ body: { paddingBottom: 80 } }}
          extra={
            <Space>
              <Button
                className="buttomCancelMobView"
                onClick={() => {
                  this.setState({
                    addEditItemOpen: false,
                    isEdit: false,
                    showAlternateUnit: false,
                  });
                  this.formRef.current?.resetFields();
                }}
              >
                Cancel
              </Button>
              <Button onClick={this.handleItemSubmit} type="primary">
                {this.state.isEdit ? "Update" : "Submit"}
              </Button>
            </Space>
          }
        >
          <Form
            layout="vertical"
            onFinish={this.handleItemSubmit}
            ref={this.formRef}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="itemId"
                  label="Item ID"
                  rules={[{ required: true, message: "Please enter item ID" }]}
                >
                  <Input
                    placeholder="Please enter item ID"
                    disabled={this.state.isEdit}
                    onChange={(event: any) => {
                      this.setState({ itemId: event.target.value });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="itemName"
                  label="Item Name"
                  rules={[
                    { required: true, message: "Please enter item name" },
                  ]}
                >
                  <Input placeholder="Please enter item name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12} xs={24} sm={24} lg={12}>
                <Form.Item
                  name="itemType"
                  label="Item type"
                  rules={[{ required: true, message: "Please select type" }]}
                >
                  <Radio.Group>
                    <Radio value={1} defaultChecked={this.state.itemType == 1}>
                      Buy
                    </Radio>
                    <Radio value={2} defaultChecked={this.state.itemType == 2}>
                      Sell
                    </Radio>
                    <Radio value={3} defaultChecked={this.state.itemType == 3}>
                      Both
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8} xs={12} sm={12} lg={8}>
                <Form.Item name="category" label="Category">
                  <Select
                    showSearch
                    placeholder="Please choose the category"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.children
                        ? option?.children
                            ?.toString()
                            ?.toLowerCase()
                            .includes(input?.toLowerCase())
                        : false
                    }
                    value={this.state.selectedCategory}
                    onChange={this.handleCategoryChange}
                  >
                    {this.state.categoryOptions?.map((category: any) => (
                      <Select.Option key={category.id} value={category.id}>
                        {category.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8} xs={12} sm={12} lg={8}>
                <Form.Item name="subCategory" label="Sub Category">
                  <Select
                    placeholder="Please choose the sub category"
                    value={this.state.selectedSubCategory}
                    onChange={this.handleSubCategoryChange}
                    disabled={this.state.subCategoriesOptions.length == 0}
                  >
                    {this.state.subCategoriesOptions.map((subCategory: any) => (
                      <Select.Option
                        key={subCategory.id}
                        value={subCategory.id}
                      >
                        {subCategory.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8} xs={12} sm={12} lg={8}>
                <Form.Item name="microCategory" label="Micro Category">
                  <Select
                    placeholder="Please choose the micro category"
                    value={this.state.microCategoryOptions}
                    onChange={this.handleMicroCategoryChange}
                    disabled={this.state.microCategoryOptions.length == 0}
                  >
                    {this.state.microCategoryOptions.map(
                      (microCategory: any) => (
                        <Select.Option
                          key={microCategory.id}
                          value={microCategory.id}
                        >
                          {microCategory.name}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={this.state.isEdit ? 12 : 8} xs={12} sm={12} lg={8}>
                <Form.Item
                  name="metricsUnit"
                  label="Unit of Metrics"
                  rules={[{ required: true, message: "Please choose the UOM" }]}
                >
                  <Select
                    showSearch
                    placeholder="Please choose the UOM"
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                      typeof option?.label === "string" &&
                      option?.label
                        ?.toLowerCase()
                        .includes(input?.toLowerCase())
                    }
                    options={this.props?.UOMData?.map(
                      (item: { name: string; code: string; id: number }) => ({
                        label: `${item.name} (${item.code}) `,
                        value: item.id,
                      })
                    )}
                    onChange={(value) => {
                      this.setState({ selectedMetricsUnit: value });
                      this.formRef.current?.setFieldsValue({
                        metricsUnit: value,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              {!this.state.isEdit && (
                <Col span={8} xs={12} sm={12} lg={8}>
                  <Form.Item name="store" label="Store">
                    <Select
                      showSearch
                      placeholder="Please choose store"
                      optionFilterProp="label"
                      filterOption={(input, option) =>
                        typeof option?.label === "string" &&
                        option?.label
                          ?.toLowerCase()
                          .includes(input?.toLowerCase())
                      }
                      options={this.props.storeData?.map((store) => ({
                        key: store.id,
                        label: store.name,
                        value: store.id,
                      }))}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col span={this.state.isEdit ? 12 : 8} xs={12} sm={12} lg={8}>
                <Form.Item name="HSNCode" label="HSN (Harmonised System)">
                  <Input placeholder="Please enter HSN code" />
                </Form.Item>
              </Col>
            </Row>
            {this.state.isEdit && (
              <Row justify="end">
                <Divider
                  orientation="right"
                  variant="dashed"
                  style={{ borderColor: "#1777ff" }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      this.setState((prevState) => ({
                        alternateUnit: [
                          ...prevState.alternateUnit,
                          { altUnitId: null, conversionFactor: 0 },
                        ],
                        showAlternateUnit: true,
                      }));
                    }}
                  >
                    <PlusOutlined /> Alternate Unit
                  </Button>
                </Divider>
              </Row>
            )}

            {this.state.showAlternateUnit && (
              <>
                {this.state.alternateUnit.map((row, index: number) => {
                  return (
                    <Row gutter={18} key={index}>
                      <Col span={11}>
                        <Form.Item
                          name={["alternateUnit", index, "altUnitId"]}
                          label="Alternate Unit"
                          initialValue={row.altUnitId}
                          rules={[
                            {
                              required: true,
                              message: "Please select an alternate unit",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Alternate Unit"
                            options={this.props.UOMData?.map(
                              (item: {
                                name: string;
                                code: string;
                                id: number;
                              }) => ({
                                label: `${item.name} (${item.code})`,
                                value: item.id,
                              })
                            )}
                            onChange={(value) => {
                              const updatedRows = [...this.state.alternateUnit];
                              updatedRows[index].altUnitId = value;
                              this.setState({ alternateUnit: updatedRows });
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          name={["alternateUnit", index, "conversionFactor"]}
                          initialValue={row.conversionFactor}
                          label={`Conversion Factor`}
                          rules={[
                            {
                              required: true,
                              message: "Enter a conversion factor",
                            },
                            {
                              pattern: /^[0-9]*\.?[0-9]+$/,
                              message: "Enter a valid number",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter Conversion Factor"
                            type="number"
                            value={
                              this.state.alternateUnit[index]?.conversionFactor
                            }
                            onChange={(e) => {
                              const updatedRows = [...this.state.alternateUnit];
                              const value = parseFloat(e.target.value);
                              updatedRows[index].conversionFactor = value;
                              this.setState({ alternateUnit: updatedRows });
                              this.formRef.current?.setFieldsValue({
                                alternateUnit: updatedRows,
                              });
                            }}
                            addonAfter={
                              <div style={{ whiteSpace: "nowrap" }}>
                                {this.props.UOMData?.length
                                  ? this.props.UOMData.find(
                                      (uom: { id: number }) =>
                                        uom.id ===
                                        this.formRef.current?.getFieldValue(
                                          "metricsUnit"
                                        )
                                    )?.name
                                  : "--"}
                              </div>
                            }
                          />
                          <br />
                          <div>
                            {row.altUnitId ? (
                              <div style={{ whiteSpace: "nowrap" }}>
                                {`Base Unit 1 ${
                                  this.props.UOMData?.find(
                                    (uom: { id: number }) =>
                                      uom.id === row.altUnitId
                                  )?.code
                                } = ${row.conversionFactor} ${
                                  this.props.UOMData?.find(
                                    (uom: { id: number }) =>
                                      uom.id ===
                                      this.formRef.current?.getFieldValue(
                                        "metricsUnit"
                                      )
                                  )?.name
                                }`}
                              </div>
                            ) : null}
                          </div>
                        </Form.Item>
                      </Col>
                      <Col
                        span={2}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Space>
                          {index === 0 && (
                            <Button
                              type="link"
                              icon={<PlusOutlined style={{ color: "green" }} />}
                              onClick={this.handleAddRow}
                            />
                          )}
                          {index > 0 && (
                            <Button
                              danger
                              icon={<DeleteOutlined />}
                              onClick={() => this.handleDeleteRow(index)}
                            />
                          )}
                        </Space>
                      </Col>
                    </Row>
                  );
                })}
              </>
            )}

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="price"
                  label="Price"
                  rules={[
                    {
                      pattern: /^[0-9]*\.?[0-9]*$/,
                      message: "Please enter a valid number",
                    },
                  ]}
                >
                  <Input type="number" placeholder="Please enter price" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="taxType" label="Tax type">
                  <Select
                    placeholder="Please choose the tax"
                    options={taxTypeOptions}
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="tax" label="Tax">
                  <Select placeholder="Please select tax">
                    {taxOptions.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="currentStock" label="Current Stock">
                  <Input
                    type="number"
                    placeholder="Please enter current stock"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="minStock" label="Min Stock">
                  <Input type="number" placeholder="Please enter min stock" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="maxStock" label="Max Stock">
                  <Input type="number" placeholder="Please enter max stock" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="description" label="Description">
                  <Input.TextArea
                    rows={4}
                    placeholder="Please enter description"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>

        <ManageCategories
          isManageCategoryOpen={this.state.isManageCategoryOpen}
          isAddManageCategoryOpen={this.state.isAddManageCategoryOpen}
          categoryDrawerStatus={this.categoryDrawerStatus}
        />
        <ReconcileAndUpload
          title={drawerTitle}
          onClose={this.closeDrawer}
          open={isDrawerVisible}
          uploadTemplateText={`Template for ${drawerTitle}`}
          templatePath="Item-excel-template"
          resultTitle={`Successfully completed ${drawerTitle}`}
          resultSubTitle={`Your file for ${drawerTitle.toLowerCase()} has been processed successfully`}
        />
        <StockTransfer
          title="Stock Transfer"
          open={isStockTransferVisible}
          onClose={this.closeStockTransferForm}
          // showCommentSection={this.state.isCommentRequired}
          // showActionButton={this.state.showActionButton}
          // showCurrentStock={this.state.showCurrentStock}
          dataToSet={this.state.dataToSet}
        />
        <History
          title={historyTitle}
          open={isHistoryOpen}
          onClose={this.closeHistoryDrawer}
          selectedItem={this.state.selectedItem || this.props.companyId}
        />
        <ManageUOM
          title="Manage Unit of Measurement"
          open={isManageUnitDrawerVisible}
          onClose={this.closeManageUnitDrawer}
        />
        <StockUpdate
          title="Stock Update"
          open={isStockUpdateFormVisible}
          onClose={this.closeStockUpdateForm}
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  itemsData: state.api.getItems?.data?.reverse(),
  UOMData: state.api.getUOM?.data?.reverse(),
  categoryData: state.api.getCategory?.data?.data,
  getItemState: state.api.getItems,
  addItemState: state.api.addItems,
  editItemState: state.api.editItems,
  getNotificationsState: state.api.getNotifications?.data,
  deleteItemState: state.api.deleteItems,
  bulkDeleteItemState: state.api.bulkDeleteItems,
  userId: state.api.login.data.id,
  username: state.api.login.data.username,
  companyId: state.api.login.data.companyId,
  storeData: state.api.getStore?.data?.reverse(),
});

const mapDispatchToProps = (dispatch: any) => ({
  getItems: (payload: any) => dispatch(getItems(payload)),
  addItems: (payload: any) => dispatch(addItems(payload)),
  editItems: (payload: any) => dispatch(editItems(payload)),
  deleteItems: (payload: any) => dispatch(deleteItems(payload)),
  bulkDeleteItems: (payload: any) => dispatch(bulkDeleteItems(payload)),
  notify: (payload: any) => dispatch(notify(payload)),
  getNotifications: (payload: any) => dispatch(getNotifications(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemManagement);
