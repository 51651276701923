import React, { useState } from "react";
import { Button, Popconfirm, Space, Table } from "antd";
import { TableRowSelection } from "antd/es/table/interface";
import noRecord from "../../../../Utility/images/norecord.png";
import "./logs.css";
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

const Logs: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const data: any[] = [
    {
      key: "1",
      documentNo: "12345",
      documentType: "Invoice",
      approvalStatus: "Approved",
      approvalBy: "Harish Chandra",
      approvalDate: "2024-12-20",
      documentCreatedBy: "Sandeep Sharma",
      documentCreationDate: "2024-12-10",
    },
    {
      key: "2",
      documentNo: "12346",
      documentType: "Purchase Order",
      approvalStatus: "Pending",
      approvalBy: "N/A",
      approvalDate: "N/A",
      documentCreatedBy: "Shamsher",
      documentCreationDate: "2024-12-15",
    },
  ];

  const columns = [
    {
      title: "Document No",
      dataIndex: "documentNo",
      key: "documentNo",
    },
    {
      title: "Document Type",
      dataIndex: "documentType",
      key: "documentType",
    },
    {
      title: "Approval Status",
      dataIndex: "approvalStatus",
      key: "approvalStatus",
    },
    {
      title: "Approval By",
      dataIndex: "approvalBy",
      key: "approvalBy",
    },
    {
      title: "Approval Date",
      dataIndex: "approvalDate",
      key: "approvalDate",
    },
    {
      title: "Document Created By",
      dataIndex: "documentCreatedBy",
      key: "documentCreatedBy",
    },
    {
      title: "Document Creation Date",
      dataIndex: "documentCreationDate",
      key: "documentCreationDate",
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (_: any, record: { actions: string }) => (
    //     <div>
    //       <>
    //         <Button
    //           type="link"
    //           color="primary"
    //           onClick={() => console.log("Approve")}
    //         >
    //           <CheckCircleOutlined />
    //           Approve
    //         </Button>
    //         <Popconfirm
    //           title="Are you sure to reject this?"
    //           onConfirm={() => console.log("Delete")}
    //           okText="Yes"
    //           cancelText="No"
    //         >
    //           <Button color="danger" variant="text" onClick={() => console.log("danger")}>
    //             <CloseCircleOutlined style={{ marginRight: 8 }} />
    //             Reject
    //           </Button>
    //         </Popconfirm>
    //       </>
    //     </div>
    //   ),
    // },
  ];

  const rowSelection: TableRowSelection<any> = {
    type: "checkbox",
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  return (
    <>
      <div className="flexBox">
        <div>
          <h3>Logs</h3>
        </div>
        {selectedRowKeys.length > 0 && (
          <Space wrap>
            <Button
              type="link"
              onClick={() => console.log("Approve clicked", selectedRowKeys)}
            >
              Approve
            </Button>
            <Button
              type="link"
              danger
              onClick={() => console.log("Reject clicked", selectedRowKeys)}
            >
              Reject
            </Button>
          </Space>
        )}
      </div> 
      <p>Here you can view the logs related to document approvals.</p>
      <div className="alignedFlex col-gap-20 mb-20">
        <div className="logCards approvedCard">Approved</div>
        <div className="logCards pendingCard">Pending</div>
        <div className="logCards cancelledCard">Rejected</div>
        <div className="logCards cancelledCard">Sent</div>
      </div>

      {data.length === 0 ? ( 
        <img
          src={noRecord}
          alt="No Records Found"
          style={{
            width: "auto",
            height: "300px",
            display: "block",
            margin: "0 auto",
          }}
        />
      ) : (
        <Table
          style={{ height: "400px", overflowY: "auto" }}
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered
          rowSelection={rowSelection}
        />
      )}
    </>
  );
};

export default Logs;
