import * as type from "../../types/API/demoQueryType";

export function getDemoQueryData(payload: any) {
  return {
    type: type.GET_REQUEST_DEMO_QUERY_DATA,
    payload: payload,
  };
}

export function addDemoQueryData(payload: any) {
  return {
    type: type.ADD_REQUEST_DEMO_QUERY_DATA,
    payload: payload,
  };
}

