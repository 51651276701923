import React from "react";
import { Row, Col, Card, Typography, Button, List, Avatar } from "antd";
import {
  FundOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
  GlobalOutlined,
  BarChartOutlined,
  CheckCircleOutlined,
  CloudServerOutlined,
  DeploymentUnitOutlined,
  SecurityScanOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import Header from "./Header";
import Footer from "./Footer";
import logo from "../../Utility/images/ease/withBackground/hero-15.jpg";

const { Title, Text, Paragraph } = Typography;

const multipleStock = [
  "Create multiple stores and manage stocks as per store.",
  "Get alerts and notifications for low stocks as per store.",
  "Get stock valuation as per store.",
  "Negative stocks restriction as per store.",
  "Realtime update of stocks as per document creation.",
];

const physicalStock = [
  "Update product stocks by checking physical stock as per stores.",
  "Manually update stocks for adjustments as per store."
];

const customField = [
  "Create custom fields on item level and fetch it on documents.",
  "Manage multiple units for single items and get realtime stocks as per different units."
];

const stockHistory = [
  "Get realtime item level history as per stores and units.",
  "Store level history for better tracking."
];

const services = [
  {
    title: 'Manage Multiple Stores',
    icon: <CloudServerOutlined style={{ fontSize: '40px', color: '#000' }} />,
    color: '#FFD3B6'
  },
  {
    title: 'Physical Stock Reconciliation',
    icon: <DeploymentUnitOutlined style={{ fontSize: '40px', color: '#000' }} />,
    color: '#A8E6CF'
  },
  {
    title: 'Custom Fields and Alternate Units',
    icon: <GlobalOutlined style={{ fontSize: '40px', color: '#000' }} />,
    color: '#D4A5A5'
  },
  {
    title: 'Stock History',
    icon: <SecurityScanOutlined style={{ fontSize: '40px', color: '#000' }} />,
    color: '#91C4F2'
  }
];


const InventoryLanding = () => {
  return (
    <>
      <Header />
      <div className="inventory-container container">
        <div>
          {/* Hero Section */}
          <div className="inventoryHero">
            <Title level={2}>Smart Inventory Management for Growing Businesses</Title>
            <Text style={{ fontSize: "15px", color: "#555" }}>
              Automate stock tracking, manage orders, and optimize supply chain operations with AI-driven inventory control.
            </Text>
            <div style={{ marginTop: "20px" }}>
              <Button type="primary" size="large">Get Started for Free</Button>
            </div>
          </div>

          <Row gutter={[32, 32]}>
            <Col xs={24} md={12}>
              <Title level={2}>Monitor stock levels with live updates. Get automated alerts for low inventory and prevent overstocking.</Title>
              <Paragraph style={{ fontSize: '15px' }}>
                Efficiently manage your inventory with real-time stock level tracking. Our system continuously updates stock availability across all locations, ensuring you have the latest data at your fingertips. With live updates, you can quickly identify fast-moving products, prevent stock shortages, and optimize your inventory strategy.
              </Paragraph>
              <Paragraph style={{ fontSize: '15px' }}>Get automated alerts when stock levels drop below predefined thresholds, helping you take timely action before running out of essential items. Additionally, our system prevents overstocking by providing insights into demand trends, allowing you to make informed purchasing decisions. By reducing excess inventory and preventing stockouts, you can improve cash flow and operational efficiency.</Paragraph>
            </Col>
            <Col xs={24} md={12}>
              <Row gutter={[16, 16]}>
                {services.map((feature, index) => (
                  <Col xs={24} sm={12} key={index}>
                    <Card hoverable className="effectiveCard" style={{ textAlign: 'center', padding: '20px', minHeight: '180px', background: '#FFF', boxShadow: '0 0 30px 0 rgba(186, 184, 191, 0.35)' }}>
                      <div className="inventoryIcon"><img src="https://themazine.com/html/sap/preview/assets/media/feature-section/features/button-2.png" /></div>
                      <Title level={4} style={{ marginTop: '10px', color: '#000', fontWeight: 'normal', lineHeight: '1.5', fontSize: '16px' }}>{feature.title}</Title>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          
          {/* Multi stock management */}
          <Row gutter={[32, 32]} className="mt-5">
            <Col xs={24} md={12}>
              <Row gutter={[16, 16]}>
                <img
                  src={'https://blogimages.softwaresuggest.com/blog/wp-content/uploads/2023/08/16123513/ERP-evaluation-Process-Feature-image.jpg'}
                  alt="Placeholder Demo Image"
                  className="demo-request-image"
                />
              </Row>
            </Col>
            <Col xs={24} md={12}>
              <Title level={2}>Manage Multiple Stores</Title>
              <Paragraph style={{ fontSize: "15px", color: "#555", marginBottom: '10px' }}>Multiple store management allows businesses to track inventory, sales, and stock valuation across different locations. It ensures real-time updates, low-stock alerts, and negative stock restrictions per store. This system improves efficiency, prevents stockouts, and provides better control over stock distribution, enhancing overall business operations and decision-making.</Paragraph>
              {multipleStock.map((feature, index) => (
                <div key={index} style={{ fontSize: '16px'}}><CheckCircleFilled style={{ color: '#689F38' }} /> {feature}</div>
              ))}
            </Col>
          </Row>

          {/* Physical stock */}
          <Row gutter={[32, 32]} className="mt-5">
            <Col xs={24} md={12}>
              <Title level={2}>Physical Stock Reconciliation</Title>
              <Paragraph style={{ fontSize: "15px", color: "#555", marginBottom: '10px' }}>Physical stock reconciliation at the item level ensures accurate inventory management by comparing system records with actual stock counts. This process helps identify discrepancies due to errors, theft, or mismanagement. By reconciling stock regularly, businesses can maintain precise inventory levels, prevent stock shortages, and improve financial reporting. It also enhances decision-making by providing real-time insights into stock movements. Efficient reconciliation minimizes losses, optimizes stock allocation across multiple stores, and ensures compliance with accounting standards. Implementing a structured reconciliation process boosts operational efficiency, reduces discrepancies, and enhances overall store management, leading to better resource utilization and profitability.</Paragraph>
              {physicalStock.map((feature, index) => (
                <div key={index} style={{ fontSize: '16px'}}><CheckCircleFilled style={{ color: '#689F38' }} /> {feature}</div>
              ))}
            </Col>
            <Col xs={24} md={12}>
              <Row gutter={[16, 16]}>
                <img
                  src={'https://bizom.com/wp-content/uploads/2023/04/Unlocking-the-Benefits-of-Inventory-Management-Software-blog-banner-image.jpg'}
                  alt="Placeholder Demo Image"
                  className="demo-request-image"
                />
              </Row>
            </Col>
          </Row>

          {/* custom fields */}
          <Row gutter={[32, 32]} className="mt-5">
            <Col xs={24} md={12}>
              <Row gutter={[16, 16]}>
                <img
                  src={'https://www.jotform.com/blog/wp-content/uploads/2019/09/HTML-forms-featured-01.png'}
                  alt="Placeholder Demo Image"
                  className="demo-request-image"
                />
              </Row>
            </Col>
            <Col xs={24} md={12}>
              <Title level={2}>Custom Fields and Alternate Units
              </Title>
              <Paragraph style={{ fontSize: "15px", color: "#555", marginBottom: '10px' }}>Custom fields and alternate units at the item level enhance store management by allowing businesses to tailor inventory tracking to their needs. Custom fields help store additional item-specific details, improving categorization and reporting. Alternate units enable seamless conversions between different measurement units (e.g., pieces to boxes), ensuring accurate stock control. These features improve flexibility, streamline operations, and support better decision-making. By customizing item attributes and managing units efficiently, businesses can optimize inventory processes, reduce errors, and enhance overall store performance. This system ensures adaptability for various industries, making inventory management more precise and efficient.</Paragraph>
              {customField.map((feature, index) => (
                <div key={index} style={{ fontSize: '16px'}}><CheckCircleFilled style={{ color: '#689F38' }} /> {feature}</div>
              ))}
            </Col>
          </Row>

          {/* Stock history */}
          <Row gutter={[32, 32]} className="mt-5">
            <Col xs={24} md={12}>
              <Title level={2}>Stock History</Title>
              <Paragraph style={{ fontSize: "15px", color: "#555", marginBottom: '10px' }}>Stock history tracking at both the item and store levels provides a detailed record of inventory movements, ensuring transparency and accuracy. It logs item transfers between stores, helping businesses monitor stock allocation, prevent shortages, and optimize distribution. By maintaining a history of stock movements, businesses can analyze trends, detect discrepancies, and improve decision-making. This feature enhances accountability, reduces losses, and ensures real-time visibility into stock flow. Efficient stock history management improves inventory control, supports auditing, and helps businesses respond proactively to demand fluctuations, ultimately leading to better operational efficiency and resource utilization across multiple store locations.</Paragraph>
              {stockHistory.map((feature, index) => (
                <div key={index} style={{ fontSize: '16px'}}><CheckCircleFilled style={{ color: '#689F38' }} /> {feature}</div>
              ))}
            </Col>
            <Col xs={24} md={12}>
              <Row gutter={[16, 16]}>
                <img
                  src={'https://static.vecteezy.com/system/resources/previews/017/600/482/non_2x/inventory-control-system-concept-professional-manager-checking-goods-and-stock-supply-inventory-management-with-goods-demand-vector.jpg'}
                  alt="Placeholder Demo Image"
                  className="demo-request-image"
                />
              </Row>
            </Col>
          </Row>

          {/* Call to Action */}
          <div style={{ display: "flex", justifyContent: "space-between" }} className="flexBox mt-5 mb-5">
            <Title level={3}>Start Managing Your Inventory Efficiently Today!</Title>
            <Button type="primary" size="large">Sign Up Now</Button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default InventoryLanding;
