import { Breadcrumb, Button, Card, Col, Layout, Radio, Row, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import "./ReportType.css";
import { AppConstants } from "../../../Appconstants";
import { reportsType } from "../../utility/reportsUtility";
import { DatePicker, Input } from "antd";
import { BarChartOutlined, DollarOutlined, ShoppingOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";

const { RangePicker } = DatePicker;

interface IReportTypeProps {
  setReportTypeValue: any
}

const ReportType: React.FC<IReportTypeProps> = ({ setReportTypeValue }) => {
  const [selectedReportType, setSelectedReportType] = useState<string>('');

  const onReportSelection = () => {
    console.log(selectedReportType);
    setReportTypeValue(selectedReportType);
  }


  const renderReportsType = (): any => {
    return reportsType.map((elem: any) => {
      return <Col span={6}>
        <Card className="reportCard">
          <Radio.Group
            className="radio-group-top-right"
            onChange={(elem) => {
              setSelectedReportType(elem.target.value);
            }}
            value={selectedReportType}
          >
            <Radio value={elem.value} />
          </Radio.Group>
          <div className="text-content-row">
            <div className="text-content-column">
              <h5>
                {elem.icon} {elem.title}
              </h5>
              <p>{elem.description}</p>
            </div>
          </div>
        </Card>
      </Col>
    })
  }
  return (
    <>
      <Layout className="layout">
        <div>
          <Breadcrumb
            style={{
              margin: "16px 0",
              flex: 1,
            }}
          >
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item>Report Type</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Content className="content-section">
          {/* <Row gutter={24}>
            <div className="report-cards">
              {renderReportsType()}
            </div>
          </Row>
          <div className="flexBox">
            <div></div>
            <Button
              type="primary"
              onClick={onReportSelection}
              disabled={!setSelectedReportType}
              style={{ marginTop: 16 }}
            >
              {AppConstants.BUTTON_REPORT.VIEW_REPORT}
            </Button>
          </div> */}
          <div className="reports-dashboard">
            <h2>Reports Dashboard</h2>
            <p>The Reports Dashboard offers key insights into Inventory, Payments, Sales, and Purchases, empowering businesses to streamline operations and make data-driven decisions. The Inventory Report tracks stock levels and movement to prevent shortages or overstocking. The Payment Report monitors transactions, ensuring smooth cash flow and financial stability. The Sales Report analyzes revenue trends and customer behavior, helping optimize sales strategies and improve performance. The Purchase Report tracks procurement activities, ensuring cost control and timely order management. Together, these reports provide a comprehensive view of business operations, enabling proactive planning and sustainable growth.</p>
            <div className="reports-row">
              <div className="report-card inventory-report">
                <Title level={4} color="#1777ff">Inventory Report</Title>
                <div className="card-icon">
                  <i className="icon bar-chart"></i>
                </div>
                <p>Tracks stock levels, shortages, and movement to ensure smooth operations.</p>
                <div className="card-actions">
                  <button className="btn-link" onClick={() => {setReportTypeValue('inventoryReport')}}>View Details</button>
                </div>
              </div>
              <div className="report-card payment-report">
              <Title level={4} color="#1777ff">Payment Report</Title>
                <div className="card-icon">
                  <i className="icon dollar"></i>
                </div>
                <p>Monitors transaction statuses for better cash flow management.</p>
                <div className="card-actions">
                  <button className="btn-link" onClick={() => {setReportTypeValue('paymentReport')}}>View Details</button>
                  
                </div>
              </div>
              <div className="report-card sales-report">
                <Title level={4} color="#1777ff">Sales Report</Title>
                <div className="card-icon">
                  <i className="icon sales"></i>
                </div>
                <p>Analyzes revenue trends and customer behavior to optimize sales.</p>
                <div className="card-actions">
                  <button className="btn-link" onClick={() => {
                    setReportTypeValue('salesReport');
                  }}>View Details</button>
                </div>
              </div>
              <div className="report-card purchase-report">
                <Title level={4} color="#1777ff">Purchase Report</Title>
                <div className="card-icon">
                  <i className="icon purchase"></i>
                </div>
                <p>Tracks procurement and supplier details for cost control.</p>
                <div className="card-actions">
                  <button className="btn-link" onClick={() => {setReportTypeValue('purchaseReport')}}>View Details</button>
                  
                </div>
              </div>
            </div>
          </div>

        </Content>
      </Layout >
    </>
  );
};

export default ReportType;
