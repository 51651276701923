import React, { useState, useEffect, useRef } from "react";
import { Input, Button, Drawer, Select, Table, Space, Tooltip, notification, Form, Row, Col, Popconfirm } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import noRecord from "../../../Utility/images/norecord.png";
import { connect } from "react-redux";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import { addTermsCondition, deleteTermsCondition, editTermsCondition, getTermsCondition } from "../../../redux/actions/API/termsCondition";
import Loader from "../../../Home/Loader/Loader";

const { TextArea } = Input;

interface TermCondition {
  key: number;
  docType: string;
  terms: { id: number | null; input: string; description: string }[];
}

interface termsConditionProps {
  userId: number;
  companyId: number;
  termsConditionData: any;
  getTermsCondition: Function;
  addTermsCondition: Function;
  editTermsCondition: Function;
  deleteTermsCondition: Function;
  getTermsConditionState: IDataResponse;
  addTermsConditionState: IDataResponse;
  editTermsConditionState: IDataResponse;
  deleteTermsConditionState: IDataResponse;
}

type NotificationType = "success" | "info" | "warning" | "error";

const TermsCondition: React.FC<termsConditionProps> = ({
  userId,
  companyId,
  termsConditionData,
  getTermsCondition,
  addTermsCondition,
  editTermsCondition,
  deleteTermsCondition,
  addTermsConditionState,
  getTermsConditionState,
  editTermsConditionState,
  deleteTermsConditionState
}) => {

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [rows, setRows] = useState<TermCondition>({
    key: Date.now(),
    docType: "",
    terms: [{ id: null, input: "", description: "" }],
  });
  const [editMode, setEditMode] = useState(false);
  const [editingKey, setEditingKey] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const prevPropsRef = useRef<any>();
  const [ipAddress, setIpAddress] = useState("127.0.0.1");
 
  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

  useEffect(() => {
    getTermsCondition({ companyId: companyId });

    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => {
        openNotificationWithIcon("error", "Something went wrong!");
      });
  }, [companyId]);

  useEffect(() => {
    if ( prevPropsRef?.current?.getTermsConditionState?.loading && !getTermsConditionState?.loading ) {
      if (getTermsConditionState?.error) {
        openNotificationWithIcon("error", "Something went wrong!");
        setLoading(false);
      } else {
        setLoading(false);
      }
    }

    if ( prevPropsRef?.current?.addTermsConditionState?.loading && !addTermsConditionState?.loading ) {
      if (addTermsConditionState?.error) {
        openNotificationWithIcon("error", addTermsConditionState.error.message);
        setLoading(false);
      } else {
        getTermsCondition({ companyId: Number(companyId) });
        handleCloseDrawer()
        openNotificationWithIcon("success", addTermsConditionState?.data?.message);
      }
    }

    if ( prevPropsRef?.current?.editTermsConditionState?.loading && !editTermsConditionState?.loading ) {
      if (editTermsConditionState?.error) {
        openNotificationWithIcon("error", editTermsConditionState?.error?.message);
        setLoading(false);
      } else {
        getTermsCondition({ companyId: Number(companyId) });
        openNotificationWithIcon("success", editTermsConditionState?.data?.message);
        handleCloseDrawer()
      }
    }

    if ( prevPropsRef?.current?.deleteTermsConditionState?.loading && !deleteTermsConditionState?.loading ) {
      if (deleteTermsConditionState?.error) {
        openNotificationWithIcon("error", "Something went wrong!");
        setLoading(false);
      } else {
        getTermsCondition({ companyId: Number(companyId) });
        openNotificationWithIcon("success", deleteTermsConditionState?.data?.message);
        handleCloseDrawer()
      }
    }
    prevPropsRef.current = {
      userId,
      companyId,
      termsConditionData,
      getTermsCondition,
      addTermsCondition,
      editTermsCondition,
      deleteTermsCondition,
      addTermsConditionState,
      getTermsConditionState,
      editTermsConditionState,
      deleteTermsConditionState,
    };
  }, [addTermsConditionState, getTermsConditionState, editTermsConditionState, deleteTermsConditionState]);

  const handleAddTerm = () => {
    setRows((prev) => ({
      ...prev,
      terms: [...prev.terms, { id: null, input: "", description: "" }],
    }));
  };

  const handleOpenDrawer = () => {
    setEditMode(false);
    setEditingKey(null);
    setDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    form.resetFields();
    setDrawerVisible(false);
    setEditMode(false);
    setEditingKey(null);
    setRows({
      key: Date.now(), 
      docType: "",
      terms: [{ id: null, input: "", description: "" }],
    });
  };

  const handleRemoveRow = (index: number) => { 
    const updatedTerms = rows.terms.filter((_, idx) => idx !== index);
    setRows({
      ...rows,
      terms: updatedTerms,
    });
  };

  const generateData = termsConditionData?.flatMap((item:any) => 
    item?.terms?.map((term: { docType: any; termsData: any[]; }) => ({
      docType: term?.docType,  
      terms: term?.termsData?.map(t => ({
        id: t.id,
        term: t.term,
        description: t.desc
      }))
    }))
  );

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => { 
        const termsPayload = rows.terms.map((term) => ({
          id: term?.id,
          term: term.input,    
          description: term.description,  
        }));
  
        const payload = {
          documentType: values.docType,
          userId,
          companyId: Number(companyId),
          ip_address: ipAddress,
          status: 1,
          terms: termsPayload, 
        };
        setLoading(true);
        if (editMode) {
          editTermsCondition(payload);
        } else {
          addTermsCondition(payload);
        }
      })
      .catch((errorInfo) => {
        openNotificationWithIcon("error", "Please fill in all the required fields.");
      });
  };

  const handleDeleteTerm = (id: number) => {
    setLoading(true);
    const payload = {
      termsConditionId: Number(id),
      companyId: Number(companyId),
    };

    deleteTermsCondition(payload);
  };

  const handleEdit = (record: any) => {
    setDrawerVisible(true);
    setEditMode(true);
  
    form.setFieldsValue({
      docType: record.docType,
      terms: record.terms.map((term: any) => ({
        input: term.term, 
        description: term.description,
      })),
    });
 
    setRows((prevRows: any) => ({
      ...prevRows,
      terms: record.terms.map((term: any) => ({
        id: term.id,
        input: term.term,
        description: term.description,
      })),
    }));
  };  
  
  const columns = [
    {
      title: "Document Type",
      dataIndex: "docType",
      key: "docType",
    },
    {
      title: "Terms and Conditions",
      dataIndex: "terms",
      key: "terms",
      render: (terms: any[]) =>
        terms.map((term, index) => (
          <div key={term.id} style={{ marginBottom: "10px" }}>
            <strong>
              Term {index + 1}. {term.term}
            </strong>
            <div className="flexBox noData">Description: {term.description}
            <Popconfirm
              title="Are you sure to delete this term?"
              onConfirm={() => handleDeleteTerm(term.id)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <Button icon={<DeleteOutlined />} danger />
              </Tooltip>
            </Popconfirm>
            </div>
          </div>
        )),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <Button icon={<EditOutlined />} onClick={() => handleEdit(record)} />
          </Tooltip>
        </Space>
      ),
    },
  ];
  
  return (
    <div>
      <Loader loading={loading} />
      <div className="flexBox">
        <div></div>
        <Button type="link" icon={<PlusOutlined />} onClick={handleOpenDrawer}>
          Add Terms and Conditions
        </Button>
      </div>

      {generateData && generateData.length === 0 ? (
        <div className="noData">
          <img
            src={noRecord}
            alt="No Records Found"
            className="no-data-dimensions"
          />
        </div>
      ) : (
        <Table
          style={{ marginTop: 20 }}
          bordered
          columns={columns}
          dataSource={generateData}
          rowKey="id"
          pagination={false}
        />
      )}

      <Drawer
        title={ editMode ? "Edit Terms and Conditions" : "Add Terms and Conditions" }
        open={drawerVisible}
        onClose={handleCloseDrawer}
        width={800}
        extra={
          <Space>
            <Button onClick={handleCloseDrawer}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSave}>
              Submit
            </Button>
          </Space>
        }
      >
        <Form form={form} layout="vertical" onFinish={handleSave}>
          <Form.Item
            label="Document Type"
            name="docType"
            rules={[{ required: true, message: "Document Type is required" }]}
          >
            <Select placeholder="Select Document Type">
              <Select.Option value="Sales Enquiry">Sales Enquiry</Select.Option>
              <Select.Option value="Sales Quotation">Sales Quotation</Select.Option>
              <Select.Option value="Invoice">Invoice</Select.Option>
              <Select.Option value="Order Confirmation">Order Confirmation</Select.Option>
              <Select.Option value="Delivery Challan">Delivery Challan</Select.Option>
              <Select.Option value="Sales Return">Sales Return</Select.Option>
          </Select>
          </Form.Item>
          {rows?.terms?.map((term, index) => (
            <div key={index} className="form-label mt-20">
              <Form.Item
                label={`Term ${index + 1}`}
                name={['terms', index, 'input']} 
                rules={[
                  {
                    required: true,
                    message: "Please add terms and conditions",
                  },
                ]}
              >
                <Row gutter={16} align="middle" >
                  <Col flex="auto">
                    <Input
                      placeholder="Enter term"
                      value={term.input}
                      onChange={(e) => {
                        const newTerms = [...rows.terms];
                        newTerms[index].input = e.target.value;
                        setRows({ ...rows, terms: newTerms });
                      }}
                    />
                  </Col>
                  <Col>
                    {index === 0 ? (
                      <Col>
                      {index === 0 ? (
                        <Tooltip title="Add more terms">
                          <Button
                            icon={<PlusOutlined />}
                            type="dashed"
                            onClick={handleAddTerm}
                            disabled={editMode || rows.terms.some((term) => !term.description)} 
                          />
                        </Tooltip>
                      ) : (
                        <Button
                          icon={<DeleteOutlined />}
                          onClick={() => handleRemoveRow(index)}
                          disabled={editMode}
                          danger
                        />
                      )}
                    </Col>
                    ) : (
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={() => handleRemoveRow(index)}
                        disabled={editMode}
                        danger
                      />
                    )}
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item
                label="Description"
                name={['terms', index, 'description']}
                rules={[{ required: true, message: "Please enter a description" },]}
              >
                <TextArea
                  placeholder="Enter description"
                  value={term.description}
                  onChange={(e) => {
                    const newTerms = [...rows.terms];
                    newTerms[index].description = e.target.value;
                    setRows({ ...rows, terms: newTerms });
                  }}
                  rows={3}
                />
              </Form.Item>
            </div>
          ))}
        </Form>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userId: state.api.login.data?.id,
  companyId: state.api.login.data.companyId,
  termsConditionData: state.api.getTermsCondition?.data?.data,
  getTermsConditionState: state.api.getTermsCondition,
  addTermsConditionState: state.api.addTermsCondition,
  editTermsConditionState: state.api.editTermsCondition,
  deleteTermsConditionState: state.api.deleteTermsCondition,
});

const mapDispatchToProps = (dispatch: any) => ({
  addTermsCondition: (payload: any) => dispatch(addTermsCondition(payload)),
  editTermsCondition: (payload: any) => dispatch(editTermsCondition(payload)),
  getTermsCondition: (payload: any) => dispatch(getTermsCondition(payload)),
  deleteTermsCondition: (payload: any) => dispatch(deleteTermsCondition(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsCondition);
