export const ADD_BLOG = 'ADD_BLOG';
export const ADD_BLOG_SUCCESS = 'ADD_BLOG_SUCCESS';
export const ADD_BLOG_FAILED = 'ADD_BLOG_FAILED';

export const EDIT_BLOG = 'EDIT_BLOG';
export const EDIT_BLOG_SUCCESS = 'EDIT_BLOG_SUCCESS';
export const EDIT_BLOG_FAILED = 'EDIT_BLOG_FAILED';

export const DELETE_BLOG = 'DELETE_BLOG';
export const DELETE_BLOG_SUCCESS = 'DELETE_BLOG_SUCCESS';
export const DELETE_BLOG_FAILED = 'DELETE_BLOG_FAILED';

export const GET_BLOG_BY_ID = 'GET_BLOG_BY_ID';
export const GET_BLOG_BY_ID_SUCCESS = 'GET_BLOG_BY_ID_SUCCESS';
export const GET_BLOG_BY_ID_FAILED = 'GET_BLOG_BY_ID_FAILED';

export const GET_BLOGS = 'GET_BLOGS';
export const GET_BLOGS_SUCCESS = 'GET_BLOGS_SUCCESS';
export const GET_BLOGS_FAILED = 'GET_BLOGS_FAILED';

export const GET_BLOGS_CATEGORIES = 'GET_BLOGS_CATEGORIES';
export const GET_BLOGS_CATEGORIES_SUCCESS = 'GET_BLOGS_CATEGORIES_SUCCESS';
export const GET_BLOGS_CATEGORIES_FAILED = 'GET_BLOGS_CATEGORIES_FAILED';