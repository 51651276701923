import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import parse from "html-react-parser";
import { connect } from "react-redux";
import "./BlogDetails.css";
import AddBlogComments from "./AddBlogComments";
import { getBlogById, getBlogCategories } from "../../redux/actions/API/blogs";
import { getApprovedBlogComments } from "../../redux/actions/API/commentActions";
import ApprovedBlogCommentList from "./ApprovedBlogCommentList";
import Loader from "../Loader/Loader";

interface IBlogById {
  getBlogById: Function;
  blogDataById: any;
  getBlogCategories: Function;
}

const BlogDetails: React.FC<IBlogById> = ({ getBlogById, blogDataById, getBlogCategories }) => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      getBlogById({ id: Number(id) });
      setLoading(false);
      getApprovedBlogComments({ blogId: Number(id) });
    }
  }, [id, getBlogById, getApprovedBlogComments]);

  const renderBlog = (blog: any) => {
    if (!blog) {
      return <div>No blog found</div>;
    }

    const updatedAtDate = new Date(blog.updatedAt).toLocaleDateString();

    return (
      <div>
        <div>
          <div className="m-0">
            <h2 className="s-32 w-700 margin-mobile">{blog.title}</h2>
            <div className="blog-post-meta mt-35 ml-10">
              <ul className="post-meta-list ico-10">
                <li>
                  <p className="p-sm w-500 margin-mobile">By {blog.author}</p>
                </li>
                <li className="meta-list-divider">
                  <p>
                    <span className="flaticon-minus"></span>
                  </p>
                </li>
                <li>
                  <p className="p-sm margin-mobile">{updatedAtDate}</p>
                </li>
              </ul>
            </div>
            <p className="s-18 w-400 ml-10 mt-10 margin-mobile">
              {blog.shortDesc}
            </p>
            <div className="blog-post-img">
              <img
                className="img-fluid r-16 w-100 margin-mobile"
                src={blog.imageURL}
                alt="blog-post-image"
              />
            </div>
          </div>
          <div>
            <div className="mt-20 ml-10 margin-mobile">
              {parse(String(blog.content))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
    <Loader loading={loading}></Loader>
      <div>
        <Header />
        <div className="blog-section-container">
          <section
            id="single-post"
            className="inner-page-hero blog-page-section"
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="post-content">
                    <div className="single-post-title">
                      {blogDataById ? (
                        renderBlog(blogDataById)
                      ) : (
                        <div>No blog found</div>
                      )}
                      <div className="comment-section">
                        <AddBlogComments />
                      </div>
                      <div className="comment-section">
                        <ApprovedBlogCommentList />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="blog-detail-footer">
          <Footer />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  blogDataById: state.api.getBlogById.data,
});

const mapDispatchToProps = (dispatch: any) => ({
  getBlogById: (id: number) => dispatch(getBlogById(id)),
  getBlogCategories: (category: string) => dispatch(getBlogCategories(category))
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogDetails);
