import * as type from "../../types/API/customFields";
import { IAction } from "../userMetadataReducer";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const getCustomFieldsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.GET_CUSTOM_FIELDS:
      return {
        ...state,
        loading: true,
      };
    case type.GET_CUSTOM_FIELDS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.GET_CUSTOM_FIELDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const addCustomFieldsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.ADD_CUSTOM_FIELDS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.ADD_CUSTOM_FIELDS_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.ADD_CUSTOM_FIELDS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const editCustomFieldsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.EDIT_CUSTOM_FIELDS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.EDIT_CUSTOM_FIELDS_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.EDIT_CUSTOM_FIELDS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const deleteCustomFieldsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.DELETE_CUSTOM_FIELDS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.DELETE_CUSTOM_FIELDS_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.DELETE_CUSTOM_FIELDS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
 