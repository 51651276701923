import { Breadcrumb, Button, Card, Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import BomForm from "./BomForm";
import BOMRawMaterial from "./BOMRawMaterial";
import BOMScrapMaterial from "./BOMScrapMaterial";
import BOMAdditionalCharges from "./BOMAdditionalCharges";
import { AppConstants } from "../../../Appconstants";
import BOMFinishedGood from "./BOMFinishedGood";
import { useNavigate } from "react-router-dom";

interface ICreateBillMaterialProps {}

const CreateBillMaterial: React.FC<ICreateBillMaterialProps> = () => {
  const navigate = useNavigate()
  return (
    <>
      <Layout className="layout">
        <Breadcrumb
          items={[
            { title: "Dashboard" },
            { title: "Bill of Material" },
            { title: "Create bill of materials" },
          ]}
        />

        <Content className="content-section" style={{ overflow: "auto" }}>
          <div className="divDocuments">
            <p className="textDocument">Bill of Material</p>
          </div>
          <Card>
            <BomForm />
          </Card>
          <BOMFinishedGood />
          <div className="mt-10">
            <BOMRawMaterial />
          </div>
          <div className="mt-10">
            <BOMScrapMaterial />
          </div>
          <div className="mt-10">
            <BOMAdditionalCharges />
          </div>

          <div className="button-group mt-20">
            <div className="flexBox">
              <Button type="primary">
                {AppConstants.SAVE_BUTTON.SAVE_DRAFT_BUTTON}
              </Button>
              <Button type="primary" onClick={() => {navigate('/previewBOM')}}>
                {AppConstants.SAVE_BUTTON.SAVE_BUTTON}
              </Button>
            </div>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default CreateBillMaterial;
