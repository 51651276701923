import React, { useState } from "react";
import { Button, Popconfirm, Space, Table } from "antd";
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { TableRowSelection } from "antd/es/table/interface";

const ApproveDocumentList: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const documents = [
    {
      key: "1",
      documentNo: "DOC123",
      documentType: "Invoice",
      totalAmount: "$500",
      companyName: "ABC Corp",
      createdBy: "Sandeep Sharma",
      creationDate: "2024-12-10",
      actions: "Approve",
    },
    {
      key: "2",
      documentNo: "DOC124",
      documentType: "Purchase Order",
      totalAmount: "$300",
      companyName: "XYZ Ltd.",
      createdBy: "Jane Smith",
      creationDate: "2024-12-12",
      actions: "Reject",
    },
    {
      key: "3",
      documentNo: "DOC125",
      documentType: "Invoice",
      totalAmount: "$700",
      companyName: "Tech Solutions",
      createdBy: "Alice Brown",
      creationDate: "2024-12-14",
      actions: "Approve",
    },
  ];

  const columns = [
    {
      title: "Document No",
      dataIndex: "documentNo",
      key: "documentNo",
    },
    {
      title: "Document Type",
      dataIndex: "documentType",
      key: "documentType",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Creation Date",
      dataIndex: "creationDate",
      key: "creationDate",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: { actions: string }) => (
        <div>
          <>
            <Button
              type="link"
              color="primary"
              onClick={() => console.log("Approve")}
            >
              <CheckCircleOutlined style={{ marginRight: 8 }} />
              Approve
            </Button>
            <Popconfirm
              title="Are you sure to reject this?"
              onConfirm={() => console.log("Delete")}
              okText="Yes"
              cancelText="No"
            >
              <Button color="danger" variant="text" onClick={() => console.log("danger")}>
                <CloseCircleOutlined />
                Reject
              </Button>
            </Popconfirm>
          </>
        </div>
      ),
    },
  ];

  const rowSelection: TableRowSelection<any> = {
    type: "checkbox",
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  return (
    <div>
      <div className="flexBox">
        <div>
          <h3>Approve Documents</h3>
        </div>
        {selectedRowKeys.length > 0 && (
          <Space wrap>
            <Button
              type="link"
              onClick={() => console.log("Approve clicked", selectedRowKeys)}
            >
              <CheckOutlined /> Approve
            </Button>
            <Popconfirm
              title="Are you sure to reject this?"
              onConfirm={() => console.log("Delete")}
              okText="Yes"
              cancelText="No"
            >
              <Button
                color="danger"
                variant="text"
                onClick={() => console.log("danger")}
              >
                <CloseCircleOutlined />
                Reject
              </Button>
            </Popconfirm>
          </Space>
        )}
      </div>
      <p>Here you can view pending documents to be approve.</p>
      <div className="alignedFlex col-gap-20 mb-20">
        {/* <div className="logCards approvedCard">Approved</div> */}
        <div className="logCards pendingCard">Pending</div>
        {/* <div className="logCards cancelledCard">Rejected</div> */}
      </div>
      <Table
        bordered
        columns={columns}
        dataSource={documents}
        pagination={false}
        rowSelection={rowSelection}
        className="mt-10"
      />
    </div>
  );
};

export default ApproveDocumentList;
