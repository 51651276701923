import React from "react";
import { Button, Card, Col, Layout, Row, Typography, Image, Collapse } from "antd";
import {
  SendOutlined,
  FilePdfOutlined,
  WhatsAppOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import CustomHeader from "./Header";
import CustomFooter from "./Footer";

const { Header, Content } = Layout;
const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

const PaymentInvoice = () => {
  return (
    <>
      <CustomHeader />
      <Layout style={{ minHeight: "100vh", backgroundColor: "#FFF" }}>
        {/* Hero Section */}
        <Header
          style={{
            background: "linear-gradient(135deg, #0073e6, #004a99)",
            padding: "50px 20px",
            color: "white",
            height: "fit-content",
          }}
        >
          <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
            <Title level={1} style={{ color: "white", fontSize: "40px", fontWeight: "bold" }}>
              The Ultimate Free Invoicing Solution
            </Title>
            <Paragraph style={{ fontSize: "20px", margin: "0 auto", color: "#e0e0e0" }}>
              Say goodbye to complex and costly invoicing software! Our free invoicing tool lets you effortlessly create,
              customize, and send invoices in just a few clicks. Whether you're a freelancer, small business, or enterprise,
              our platform is designed to make invoicing smooth and hassle-free.
            </Paragraph>
            <Button type="primary" size="large" style={{ marginTop: "25px", backgroundColor: "#ff9800", borderRadius: "25px", border: "none", padding: "12px 24px", fontSize: "18px" }}>
              Get Started for Free
            </Button>
          </div>
        </Header>

        <Content style={{ padding: "80px 0px", maxWidth: '1200px', margin: '0 auto' }}>
          {/* Features Section */}
          <Title level={2} style={{ marginBottom: "50px", fontSize: "32px", fontWeight: "bold" }}>
            Why Choose Our Free Invoicing Platform?
          </Title>
          <Row gutter={[32, 32]}>
            {[
              {
                icon: <CheckCircleOutlined style={{ fontSize: "50px", color: "#0073e6" }} />,
                title: "Customizable Invoices",
                description: "Easily add your business logo, adjust invoice fields, and include details such as taxes, discounts, and payment terms to suit your needs."
              },
              {
                icon: <SendOutlined style={{ fontSize: "50px", color: "#0073e6" }} />,
                title: "Instant Sharing",
                description: "Send invoices directly to clients via Email or WhatsApp with just a single click, ensuring prompt and hassle-free payments."
              },
              {
                icon: <FilePdfOutlined style={{ fontSize: "50px", color: "#0073e6" }} />,
                title: "PDF Download",
                description: "Generate sleek, professional invoices in high-quality PDF format. Save, print, or share them whenever needed."
              },
              {
                icon: <WhatsAppOutlined style={{ fontSize: "50px", color: "#0073e6" }} />,
                title: "User-Friendly Interface",
                description: "A simple, intuitive design ensures anyone can create invoices in minutes, without any prior technical knowledge."
              },
            ].map((feature, index) => (
              <Col xs={24} sm={12} md={12} key={index}>
                <Card bordered={false} hoverable style={{ padding: "40px", borderRadius: "15px", background: "white", boxShadow: "rgba(186, 184, 191, 0.35) 0px 0px 30px 0px" }}>
                  {feature.icon}
                  <Title level={4} style={{ marginTop: "15px", fontSize: "20px", fontWeight: "bold" }}>{feature.title}</Title>
                  <Paragraph style={{ fontSize: "16px", color: "#555" }}>{feature.description}</Paragraph>
                </Card>
              </Col>
            ))}
          </Row>

          {/* Platform Benefits */}
          <div style={{ marginTop: "60px" }}>
            <Title level={3} style={{ fontSize: "28px", fontWeight: "bold",}}>
              Discover the Beauty of Our Invoicing Platform
            </Title>
            <Row gutter={[32, 32]} align="middle" justify="center">
              {/* <Col xs={24} md={12}>
                <Image
                  src="/images/beautiful-dashboard.png"
                  alt="Dashboard Preview"
                  style={{ width: "100%", borderRadius: "15px", boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.2)" }}
                />
              </Col> */}
              <Col xs={24} md={24}>
                <Paragraph style={{ fontSize: "18px", color: "#555", textAlign: "justify" }}>
                  Our intuitive and beautifully designed dashboard allows you to manage your invoices effortlessly.
                  With easy navigation, instant previews, and automation, you'll experience stress-free invoicing like never before.
                </Paragraph>
              </Col>
            </Row>
          </div>

          {/* FAQs Section */}
          <div style={{ marginTop: "80px" }}>
            <Title level={3} style={{ textAlign: "center" }}>Frequently Asked Questions</Title>
            <Collapse accordion style={{ maxWidth: "1200px", margin: "0 auto" }}>
              <Panel header="Will I be able to create Invoices for free?" key="1">
                <Paragraph>Yes! Our platform is completely free to use with no hidden fees.</Paragraph>
              </Panel>
              <Panel header="Will my data be safe and secured?" key="2">
                <Paragraph>Yes, we prioritize your security and ensure your data remains encrypted and safe.</Paragraph>
              </Panel>
              <Panel header="Can I convert my invoice in PDF and export?" key="3">
                <Paragraph>Yes, you can generate a PDF of your invoice and download it easily.</Paragraph>
              </Panel>
              <Panel header="How do I accept payments through Ease Margin?" key="4">
                <Paragraph>You can integrate your preferred payment method with Ease Margin to receive payments efficiently.</Paragraph>
              </Panel>
              <Panel header="How can I contact Ease Margin?" key="5">
                <Paragraph>You can contact us at info@easemargin.com.</Paragraph>
              </Panel>
            </Collapse>
          </div>
        </Content>
      </Layout>
      <CustomFooter />
    </>
  );
};

export default PaymentInvoice;
