import { Alert, Button, Drawer, Space, Progress, message } from "antd";
import React, { useState } from "react";
import {
  UploadOutlined, 
  CloseCircleOutlined,
} from "@ant-design/icons";
import { RcFile } from "antd/es/upload";

interface IBulkEditProps {
  visible: boolean;
  onClose: () => void;
  selectedItems: React.Key[];
  title: string;
}

const BulkEdit: React.FC<IBulkEditProps> = ({
  visible,
  onClose,
  selectedItems,
  title
}) => {
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [fileError, setFileError] = useState<string | null>(null);

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles.length) {
      const file = droppedFiles[0] as RcFile;
      validateFile(file);
    }
  };

  const validateFile = (file: RcFile) => {
    const validTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];
    const isValidType = validTypes.includes(file.type);
    const isValidSize = file.size / 1024 / 1024 < 25;

    if (!isValidType) {
      setFileError(
        "Invalid file type. Only XLS, XLSX, and CSV formats are allowed."
      );
      return false;
    }
    if (!isValidSize) {
      setFileError("File size exceeds the limit of 25MB.");
      return false;
    }

    setFileError(null);
    setFileList((prev) => [...prev, file]);
    return true;
  };

  const handleUpload = () => {
    if (fileList.length === 0) {
      message.error("Please select a file before uploading.");
      return;
    }

    setUploading(true);
    setUploadProgress(0);

    const simulateUpload = () => {
      let progress = 0;
      const interval = setInterval(() => {
        progress += 10;
        setUploadProgress(progress);
        if (progress >= 100) {
          clearInterval(interval);
          setUploading(false);
          message.success("File uploaded successfully!");
        }
      }, 500);
    };

    simulateUpload();
  };

  return (
    <Drawer
      title={title} 
      placement="right"
      onClose={onClose}
      open={visible}
      width={720}
      extra={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleUpload} type="primary" loading={uploading}>
            Upload
          </Button>
        </Space>
      }
    >
      <Alert message="You can perform a bulk edit here." />
      <div
        className="bulk-drawer-container"
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleDrop}
      >
        <div className="upload-input-container">
          <label htmlFor="file-upload" className="custom-file-upload">
            <UploadOutlined className="file-add-icon" /> Drag and Drop file here
            or Choose file
          </label>
          <input
            id="file-upload"
            type="file"
            accept=".xls,.xlsx,.csv"
            onChange={(e) => {
              const file = e.target.files?.[0] as RcFile;
              if (file) {
                validateFile(file);
              }
            }}
          />
        </div>
        <div className="text-container">
          <p className="ant-upload-hint">Supported formats: XLS, XLSX, CSV</p>
          <p>Maximum size: 25MB</p>
        </div>
        {fileList.length > 0 && (
          <div>
            <p className="file-name">{fileList[0].name}</p>
            <Button onClick={() => setFileList([])}>Remove</Button>
          </div>
        )}
        {uploadProgress > 0 && (
          <Progress
            percent={uploadProgress}
            status={uploadProgress === 100 ? "success" : "active"}
          />
        )}
        {fileError && (
          <p style={{ color: "red" }}>
            <CloseCircleOutlined style={{ marginRight: "5px" }} />
            {fileError}
          </p>
        )}
      </div>
    </Drawer>
  );
};

export default BulkEdit;
