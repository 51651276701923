import React, { useEffect, useRef, useState } from "react";
import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Input,
  notification,
  Popover,
  Row,
  Select,
  Switch,
  Tooltip,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { getStores } from "../../../redux/actions/API/storesActions";
import SeriesMetaDataModal from "../SeriesMetaData/SeriesMetaDataModal";
import { getDocumentSeries } from "../../../redux/actions/API/documentSeriesAction";
import { AppConstants } from "../../../Appconstants";
import "./documentMetaDataForm.css";
import { getDocuments } from "../../../redux/actions/API/documents";
import {
  setAdditionalDetailsData,
  setDocumentAdditionalChargesData,
  setDocumentItemFormData,
  setDocumentItemGridData,
  setDocumentSignatureData,
  setDocumentTermsAndConditionsData,
  setLogisticDetailsData,
} from "../../../redux/actions/UI/createDocument";
import { CustomField } from "./documentItemGrid/TableMetaDataConstant";
import moment from "moment";
import CustomMetaDataModal from "../CustomMetaDataModal";
import { getPaymentTerm } from "../../../redux/actions/API/paymentTerm";

interface IDocumentItemFormProps {
  companyId: number;
  userId: number;
  dataSource: [];
  getStores: Function;
  storesData: any;
  seriesData: any;
  paymentTermData: any;
  transporterData: any;
  documentsData: any;
  setDocumentItemForm: Function;
  getDocumentSeries: Function;
  getPaymentTerm: Function;
  getDocuments: Function;
  documentType: string;
  documentValidationData: any;
  setDocumentTermsAndConditionsData: Function;
  setLogisticDetailsData: Function;
  setAdditionalDetailsData: Function;
  setDocumentAdditionalChargesData: Function;
  setDocumentSignatureData: Function;
  setDocumentItemGridData: Function;
}

interface DocumentData {
  value: string;
  label: string;
  enquiryDate?: string;
  purchaseOrderDate?: string;
}

const DocumentMetaDataForm: React.FC<IDocumentItemFormProps> = ({
  storesData,
  seriesData,
  documentsData,
  paymentTermData,
  transporterData,
  companyId,
  getPaymentTerm,
  setDocumentItemForm,
  documentType,
  documentValidationData,
  setDocumentTermsAndConditionsData,
  setLogisticDetailsData,
  setAdditionalDetailsData,
  setDocumentAdditionalChargesData,
  setDocumentSignatureData,
  setDocumentItemGridData,
}) => {
  const [documentMetaData, setDocumentMetaData] = useState<any>({
    documentNumber: "",
    currentSeriesNumber: 0,
    seriesId: 0,
    documentDate: moment(), // initial date selected
    enquiryNumber: "",
    quotationNumber: "",
    quotationDate: "",
    enquiryDate: moment(),
    deliveryDate: "",
    store: "",
    paymentTerm: "",
    ammendment: 0,
    paymentDate: "",
    POCName: "",
    POCNumber: "",
    expectedReplyDate: "",
    kindAttention: "",
    purchaseOrderNumber: "",
    purchaseOrderDate: moment(),
    orderConfirmationNumber: "",
    orderConfirmationDate: "",
    transporterName: "",
    transporterGSTNumber: "",
    transportationDocumentNumber: "",
    vehicleNumber: "",
    transportationDocumentDate: "",
    invoiceNumber: "",
    recievedDate: "",
    invoiceDate: "",
    creditNoteNumber: "",
    supplierInvoiceNumber: "",
    challanNumber: "",
    indent_number: "",
    gRNNumber: "",
    dNNDate: "",
    dNNumber: "",
    payToTransporter: "",
    indent_date: "",
    gRNDate: "",
    inspectionDate: "",
    challanDate: "",
    supplierInvoiceDate: "",
    creditNoteDate: "",
    billDate: moment(),
  });
  const prevPropsRef = useRef<any>();
  const [documentNumber, setDocumentNumber] = useState<string>("");
  const [selectedDocument, setSelectedDocument] = useState<
    string | undefined
  >();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [storeDropdownData, setStoreDropdownData] = useState([]);
  const [docValidData, setDocValidData] = useState(documentValidationData);
  const [inputSeriesNumber, setInputSeriesNumber] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState<"form" | "table">("form");
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const [category, setCategory] = useState("");
  const [createdBy, setCreatedBy] = useState("Admin");
  const [currentField, setCurrentField] = useState<CustomField | null>(null);
  const [hideNonMandatory, setHideNonMandatory] = useState(false);

  const toggleLabel = hideNonMandatory ? "Hide" : "Show";
  const popoverContent = !hideNonMandatory 
  ? "If you toggle this, the non-mandatory fields will be shown." 
  : "If you toggle this, the non-mandatory fields will be hidden.";

  const handleOpenModal = (mode: "form" | "table") => {
    setModalMode(mode);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleSaveFields = (
    fields: Omit<CustomField, "level" | "createdBy" | "createdAt">[],
    selectedCategory: string
  ) => {
    const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");

    const updatedFields: CustomField[] = fields.map((field) => ({
      ...field,
      level: modalMode === "form" ? "primary" : "item",
      createdBy,
      createdAt: currentTime,
      category: selectedCategory,
    }));

    setCustomFields((prevFields) => [...prevFields, ...updatedFields]);
    setCategory(selectedCategory);
    handleCloseModal();
  };

  useEffect(() => {
    if (
      prevPropsRef?.current?.documentValidationData !== documentValidationData
    ) {
      setDocValidData(documentValidationData);
    }
    prevPropsRef.current = {
      documentValidationData,
    };
  }, [documentValidationData]);

  useEffect(() => {
    setDocumentItemForm({ documentMetaData });
  }, [documentMetaData]);

  useEffect(() => {
    generateSeriesData();
  }, [seriesData]);

  useEffect(() => {
    generateStoreData();
  }, [storesData]);

  const handleFieldChange = (field: string, value: string) => {
    setDocumentMetaData((prevFormData: any) => {
      const updatedFormData = prevFormData?.map((form: any) =>
        form.documentNumber === documentMetaData.documentNumber
          ? { ...form, [field]: value }
          : form
      );
      setDocumentItemForm(updatedFormData);
      return updatedFormData;
    });
  };

  const handleSeriesModalClose = () => {
    setIsVisible(false);
  };

  const handleSeriesSelection = (selectedSeries: string) => {
    setSelectedDocument(selectedSeries);
    handleFieldChange("documentNumber", selectedSeries);
    handleSeriesModalClose();
  };

  const generateSeriesData = () => {
    let data: { value: string; label: string }[] = [];
    seriesData?.forEach((series: { seriesName: string }) => {
      data.push({ value: series.seriesName, label: series.seriesName });
    });

    const filteredSeries = seriesData?.filter(
      (series: { default: number; DocType: string }) =>
        series.default === 1 && series.DocType == documentType
    );

    if (filteredSeries && filteredSeries.length > 0) {
      const { prefix, nextNumber } = filteredSeries[0];
      setDocumentNumber(`${prefix}-${nextNumber}`);
      setDocumentMetaData({
        ...documentMetaData,
        documentNumber: `${prefix}-${nextNumber}`,
        currentSeriesNumber: nextNumber,
        seriesId: filteredSeries[0].id,
      });
      setDocumentItemForm({
        ...documentMetaData,
        documentNumber: `${prefix}-${nextNumber}`,
        currentSeriesNumber: nextNumber,
        seriesId: filteredSeries[0].id,
      });
    } else {
    }
  };

  const generateStoreData = () => {
    let data: any = [];
    storesData?.map((store: any) => {
      data.push({ value: store.name, label: store.name });
    });
    setStoreDropdownData(data);
  };

  const calculateExpectedPaymentDate = (paymentTerm: string) => {
    let daysToAdd = 0;
    switch (paymentTerm) {
      case "7 Days Valididty":
        daysToAdd = 7;
        break;
      case "15 Days Valididty":
        daysToAdd = 15;
        break;
      case "30 Days Valididty":
        daysToAdd = 30;
        break;
      case "45 Days Valididty":
        daysToAdd = 45;
        break;
      default:
        daysToAdd = 0;
    }
    return moment().add(daysToAdd, "days");
  };

  const returnDocumentTypeTitle = () => {
    switch (documentType) {
      case AppConstants.DOCUMENT_TYPE.SALES_ENQUIRY:
        return "Sales Enquiry";
      case AppConstants.DOCUMENT_TYPE.SALES_QUOTATION:
        return "Sales Quotation";
      case AppConstants.DOCUMENT_TYPE.ORDER_CONFIRMATION:
        return "Order Confirmation";
      case AppConstants.DOCUMENT_TYPE.DELIVERY_CHALLAN:
        return "Delivery Challan";
      case AppConstants.DOCUMENT_TYPE.INVOICE:
        return "Invoice";
      case AppConstants.DOCUMENT_TYPE.SALES_RETURN:
        return "Sales Return";
      case AppConstants.DOCUMENT_TYPE.GOODS_RECEIVED_NOTE:
        return "Goods Receive Notes";
      case AppConstants.DOCUMENT_TYPE.QUALITY_REPORT:
        return "Quality Report";
      case AppConstants.DOCUMENT_TYPE.DEBIT_NOTE:
        return "Debit Note";
      case AppConstants.DOCUMENT_TYPE.CREDIT_NOTE:
        return "Credit Note";
      case AppConstants.DOCUMENT_TYPE.PURCHASE_RETURN:
        return "Purchase Return";
      case AppConstants.DOCUMENT_TYPE.PURCHASE_ORDER:
        return "Purchase Order";
    }
  };

  const returnDeliveryLabel = () => {
    switch (documentType) {
      case AppConstants.DOCUMENT_TYPE.SALES_ENQUIRY:
        return "Expected Delivery Date";
      case AppConstants.DOCUMENT_TYPE.SALES_QUOTATION:
        return "Expected Delivery Date";
      case AppConstants.DOCUMENT_TYPE.ORDER_CONFIRMATION:
        return "Expected Delivery Date";
      case AppConstants.DOCUMENT_TYPE.DELIVERY_CHALLAN:
        return "Expected Delivery Date";
      case AppConstants.DOCUMENT_TYPE.INVOICE:
        return "Expected Delivery Date";
      case AppConstants.DOCUMENT_TYPE.SALES_RETURN:
        return "Expected Delivery Date";
      case AppConstants.DOCUMENT_TYPE.GOODS_RECEIVED_NOTE:
        return "Delivery Date";
      case AppConstants.DOCUMENT_TYPE.QUALITY_REPORT:
        return "Expected Delivery Date";
      case AppConstants.DOCUMENT_TYPE.DEBIT_NOTE:
        return "Expected Delivery Date";
      case AppConstants.DOCUMENT_TYPE.CREDIT_NOTE:
        return "Expected Delivery Date";
      case AppConstants.DOCUMENT_TYPE.PURCHASE_RETURN:
        return "Expected Delivery Date";
      case AppConstants.DOCUMENT_TYPE.PURCHASE_ORDER:
        return "Delivery Date";
    }
  };

  const renderQuotationNumber = () => {
    const filteredDocuments =
      documentsData?.filter(
        (document: any) => document.documentType === "Sales Quotation"
      ) || [];
    const data =
      filteredDocuments.map((document: any) => ({
        value: document.documentNumber,
        label: document.documentNumber,
      })) || [];
    const label = "Quotation Number";
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24}>
        <label>{label}</label>
        <AutoComplete
          className="documentMetaDataFormFields"
          placeholder={`Please select or enter ${label.toLowerCase()}`}
          value={inputSeriesNumber}
          options={data?.map((doc: { value: any; label: any }) => ({
            value: doc.value,
            label: doc.label,
          }))}
          onChange={(value: string) => {
            const selectedDocument = filteredDocuments.find(
              (doc: { documentNumber: string }) => doc.documentNumber === value
            );
            console.log(selectedDocument);
            const quotationDate = selectedDocument?.documentDate || null;
            const deliveryDate = selectedDocument?.deliveryDate || null;
            const store = selectedDocument?.store || null;
            const paymentTerm = selectedDocument?.paymentTerm || null;
            const paymentDate = selectedDocument?.paymentDate || null;
            setInputSeriesNumber(value);
            setDocumentMetaData({
              ...documentMetaData,
              quotationNumber: value,
              // quotationDate:
              //   documentType === AppConstants.DOCUMENT_TYPE.ORDER_CONFIRMATION
              //     ? quotationDate
              //     : documentMetaData.documentDate,
              // deliveryDate: deliveryDate || documentMetaData.deliveryDate,
              store: store || documentMetaData.store,
              // paymentDate: paymentDate || documentMetaData.paymentDate,
              paymentTerm: paymentTerm || documentMetaData.paymentTerm,
            });

            setDocumentItemForm({
              ...documentMetaData,
              quotationNumber: value,
              // quotationDate: documentType === AppConstants.DOCUMENT_TYPE.ORDER_CONFIRMATION
              // ? quotationDate
              // : documentMetaData.quotationDate,
              // deliveryDate: deliveryDate || documentMetaData.deliveryDate,
              store: store || documentMetaData.store,
              // paymentDate: paymentDate || documentMetaData.paymentDate,
              paymentTerm: paymentTerm || documentMetaData.paymentTerm,
            });
            let filterByDocumentNumber = filteredDocuments.filter(
              (doc: { documentNumber: any }) => doc.documentNumber === value
            );
            console.log(filteredDocuments);
            setDocumentItemGridData(filterByDocumentNumber[0]?.items);
            setDocumentTermsAndConditionsData([
              filterByDocumentNumber[0]?.termsCondition,
            ]),
              setLogisticDetailsData([
                filterByDocumentNumber[0]?.logisticDetails,
              ]);
            setAdditionalDetailsData([
              filterByDocumentNumber[0]?.additionalDetails,
            ]);
            setDocumentAdditionalChargesData(
              filterByDocumentNumber[0]?.additionalCharges
            );
          }}
        />
      </Col>
    );
  };

  const renderQuotationDate = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24}>
        <div>
          <label>Quotation Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                quotationDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                quotationDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderEnquiryNumber = () => {
    // const filteredDocuments = documentsData?.filter((document: any) => document.documentType === "Sales Enquiry") || [];
    // const data =
    //   filteredDocuments.map((document: any) => ({
    //     value: document.documentNumber,
    //     label: document.documentNumber,
    //   })) || [];
    const label = "Enquiry Number";
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24}>
        <label>{label}</label>
        {/* <AutoComplete
          className="documentMetaDataFormFields"
          placeholder={`Please select or enter ${label.toLowerCase()}`}
          value={inputSeriesNumber}
          // options={data?.map((doc: { value: any; label: any }) => ({
          //   value: doc.value,
          //   label: doc.label,
          // }))}
          onChange={(value: string) => {
            // const selectedDocument = filteredDocuments.find((doc: { documentNumber: string }) => doc.documentNumber === value);
            // const enquiryDate = selectedDocument?.documentDate || null;
            // const deliveryDate = selectedDocument?.deliveryDate || null;
            // const store = selectedDocument?.store || null;
            // const paymentTerm = selectedDocument?.paymentTerm || null;
            setInputSeriesNumber(value);
            // setDocumentMetaData({
            //   ...documentMetaData,
            //   enquiryNumber: value,
            //   enquiryDate:
            //     documentType === AppConstants.DOCUMENT_TYPE.SALES_QUOTATION
            //       ? enquiryDate
            //       : documentMetaData.documentDate,
            //   deliveryDate: deliveryDate || documentMetaData.deliveryDate,
            //   store: store || documentMetaData.store,
            //   paymentTerm: paymentTerm || documentMetaData.paymentTerm,
            // });

            setDocumentItemForm({
              ...documentMetaData,
              // enquiryNumber: value,
              // enquiryDate:
              //   documentType === AppConstants.DOCUMENT_TYPE.SALES_QUOTATION
              //     ? enquiryDate
              //     : documentMetaData.enquiryDate,
              // deliveryDate: deliveryDate || documentMetaData.deliveryDate,
              // store: store || documentMetaData.store,
              // paymentTerm: paymentTerm || documentMetaData.paymentTerm,
            });
            // let filterByDocumentNumber = filteredDocuments.filter((doc: { documentNumber: any }) => doc.documentNumber === value);
            // setDocumentItemGridData(filterByDocumentNumber[0]?.items);
            // setDocumentTermsAndConditionsData([filterByDocumentNumber[0]?.termsCondition]),
            // setLogisticDetailsData([filterByDocumentNumber[0]?.logisticDetails]);
            // setAdditionalDetailsData([filterByDocumentNumber[0]?.additionalDetails]);
            // setDocumentAdditionalChargesData(filterByDocumentNumber[0]?.additionalCharges);
          }}
        /> */}

        <Input
          className="documentMetaDataFormFields"
          placeholder="Enter Enquiry Number"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              enquiryNumber: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              enquiryNumber: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderEnquiryDate = () => {
    const label = "Enquiry Date";
    const validEnquiryDate = documentMetaData.enquiryDate
      ? moment(documentMetaData.enquiryDate)
      : moment();
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24}>
        <label>{label}</label>
        <DatePicker
          disabled
          className="documentMetaDataFormFields"
          value={validEnquiryDate}
          onChange={(date) => {
            setDocumentMetaData({
              ...documentMetaData,
              enquiryDate: date,
            });
            setDocumentItemForm({
              ...documentMetaData,
              enquiryDate: date,
            });
          }}
        />
      </Col>
    );
  };

  const renderDocumentNumber = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <div className="flexBox rowMobileDocumentLabel">
          <label>
            {returnDocumentTypeTitle()} Number{" "}
            <span style={{ color: "red" }}>*</span>
          </label>
          <span className="expoText" onClick={() => setIsVisible(true)}>
            Customize
          </span>
        </div>
        <Input
          placeholder={`Please enter ${returnDocumentTypeTitle()?.toLocaleLowerCase()} number`}
          value={documentNumber}
          disabled
          style={{ marginTop: "5px" }}
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              documentNumber: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              documentNumber: ev.target.value,
            });
          }}
        />
        {docValidData && !docValidData[1] && (
          <span className="errorText">
            * {returnDocumentTypeTitle()} Number is required
          </span>
        )}
      </Col>
    );
  };

  const renderDocumentDate = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        {/* <label>{returnDocumentTypeTitle()} Date *</label> */}
        <label>
          Document Date <span style={{ color: "red" }}>*</span>
        </label>
        <DatePicker
          className="documentMetaDataFormFields"
          disabled
          defaultValue={moment()}
          onChange={() => {
            setDocumentMetaData({
              ...documentMetaData,
              documentDate: moment(),
            });
            setDocumentItemForm({
              ...documentMetaData,
              documentDate: moment(),
            });
          }}
          // onChange={(option) => {
          //   setDocumentMetaData({
          //     ...documentMetaData,
          //     documentDate: option,
          //   });
          //   setDocumentItemForm({
          //     ...documentMetaData,
          //     documentDate: option,
          //   });
          // }}
        />
        {docValidData && !docValidData[2] && (
          <span className="errorText">* Document Date is required</span>
        )}
      </Col>
    );
  };

  const renderExpectedDeliveryDate = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <div>
          <label>{returnDeliveryLabel()} *</label>
          <DatePicker
            className="documentMetaDataFormFields"
            value={
              documentMetaData.deliveryDate
                ? moment(documentMetaData.deliveryDate)
                : null
            }
            onChange={(date) => {
              setDocumentMetaData({
                ...documentMetaData,
                deliveryDate: date,
              });
              setDocumentItemForm({
                ...documentMetaData,
                deliveryDate: date,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderStore = () => {
    const handleStoreChange = (option: any) => {
      setDocumentMetaData({ ...documentMetaData, store: option });
      setDocumentItemForm({ ...documentMetaData, store: option });
    };
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>
          Store <span style={{ color: "red" }}>*</span>
        </label>
        <Select
          className="documentMetaDataFormFields"
          showSearch
          placeholder="Select store"
          value={documentMetaData.store || undefined}
          options={storeDropdownData}
          onChange={handleStoreChange}
        />
        {docValidData && !docValidData[3] && (
          <span className="errorText">* Store is required</span>
        )}
      </Col>
    );
  };

  const renderAmmendment = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>Ammendment</label>
        <Input
          value={0}
          className="documentMetaDataFormFields"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              ammendment: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              ammendment: ev.target.value,
            });
          }}
          disabled
        />
      </Col>
    );
  };

  // const renderPaymentTerm = () => {
  //   return (
  //     <Col className="gutter-row" span={6}>
  //       <label>Payment Term</label>
  //       <Select
  //         className="documentMetaDataFormFields"
  //         showSearch
  //         placeholder="Select Payment Term"
  //         filterOption={(input, option) =>
  //           (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
  //         }
  //         value={documentMetaData.paymentTerm || undefined}
  //         options={[
  //           { value: "7 Days Valididty", label: "7 Days Valididty" },
  //           { value: "15 Days Valididty", label: "15 Days Valididty" },
  //           { value: "30 Days Valididty", label: "30 Days Valididty" },
  //           { value: "45 Days Valididty", label: "45 Days Valididty" },
  //         ]}
  //         onChange={(option: any) => {
  //           const calculatedDate = calculateExpectedPaymentDate(option);
  //           setDocumentMetaData({
  //             ...documentMetaData,
  //             paymentTerm: option,
  //             paymentDate: calculatedDate,
  //           });
  //           setDocumentItemForm({
  //             ...documentMetaData,
  //             paymentTerm: option,
  //             paymentDate: calculatedDate,
  //           });
  //         }}
  //       />
  //     </Col>
  //   );
  // };

  // const renderExpectedPaymentDate = () => {
  //   return (
  //     <Col className="gutter-row" span={6}>
  //       <div>
  //         <label>Payment Date <span style={{ color: 'red' }}>*</span></label>
  //         <DatePicker
  //           className="documentMetaDataFormFields"
  //           value={documentMetaData.paymentDate}
  //           onChange={(option) => {
  //             setDocumentMetaData({
  //               ...documentMetaData,
  //               paymentDate: option,
  //             });
  //             setDocumentItemForm({
  //               ...documentMetaData,
  //               paymentDate: option,
  //             });
  //           }}
  //         />
  //       </div>
  //     </Col>
  //   );
  // };

  const renderPaymentTerm = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>Payment Term</label>
        <Select
          className="documentMetaDataFormFields"
          showSearch
          placeholder="Select Payment Term"
          value={documentMetaData.paymentTerm || undefined}
          options={paymentTermData.map((paymentTerm: { name: any; days: any; }) => ({
            value: paymentTerm.name, 
            label: `${paymentTerm.name} (${paymentTerm.days} Days)`,
          }))}
          onChange={(option: any) => { 
            if (!documentMetaData.paymentDateManuallySet) {
              const calculatedDate = calculateExpectedPaymentDate(option);
              setDocumentMetaData({
                ...documentMetaData,
                paymentTerm: option,
                paymentDate: calculatedDate,
              });
              setDocumentItemForm({
                ...documentMetaData,
                paymentTerm: option,
                paymentDate: calculatedDate,
              });
            } else {
              setDocumentMetaData({
                ...documentMetaData,
                paymentTerm: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                paymentTerm: option,
              });
            }
          }}
        />
      </Col>
    );
  };

  const renderExpectedPaymentDate = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <div>
          <label>
            Payment Date <span style={{ color: "red" }}>*</span>
          </label>
          <DatePicker
            className="documentMetaDataFormFields"
            value={documentMetaData.paymentDate}
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                paymentDate: option,
                paymentDateManuallySet: true, // Flag that the date was manually set
              });
              setDocumentItemForm({
                ...documentMetaData,
                paymentDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderBillDate = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <div>
          <label>
            Bill Date <span style={{ color: "red" }}>*</span>
          </label>
          <DatePicker
            // defaultValue={moment()}
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                billDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                billDate: option,
              });
            }}
          />
        </div>
        {docValidData && !docValidData[4] && (
          <span className="errorText">* Bill Date is required</span>
        )}
      </Col>
    );
  };

  const renderPOC = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>POC Name</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Please enter POC Name"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              POCName: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              POCName: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderPOCNumber = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>POC Number</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Please enter POC Number"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              POCNumber: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              POCNumber: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderExpectedReplyDate = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <div>
          <label>Expected Reply Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                expectedReplyDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                expectedReplyDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderKindAttention = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>Kind Attention</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Kind Attention..."
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              kindAttention: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              kindAttention: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderPurchaseOrderNumber = () => {
    // const filteredDocuments = documentsData?.filter((document: any) => document.documentType === "Purchase Order") || [];
    // const data =
    //   filteredDocuments.map((document: any) => ({
    //     value: document.documentNumber,
    //     label: document.documentNumber,
    //   })) || [];
    const label = "Purchase Order Number";
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>{label}</label>
        {/* <AutoComplete
          className="documentMetaDataFormFields"
          placeholder={`Please select or enter ${label.toLowerCase()}`}
          // value={inputSeriesNumber}
          // options={data?.map((doc: { value: any; label: any }) => ({
          //   value: doc.value,
          //   label: doc.label,
          // }))}

          onChange={(value: string) => {
            // const selectedDocument = filteredDocuments.find((doc: { documentNumber: string }) => doc.documentNumber === value);
            // const documentDate = selectedDocument?.documentDate || null;
            // const store = selectedDocument?.store || null;
            // const deliveryDate = selectedDocument?.deliveryDate || null;

            // setInputSeriesNumber(value);
            setDocumentMetaData({
              ...documentMetaData,
              // purchaseOrderNumber: value,
              // documentDate:
              //   documentType === AppConstants.DOCUMENT_TYPE.GOODS_RECEIVED_NOTE
              //     ? documentDate
              //     : documentMetaData.documentDate,
              // deliveryDate:
              //   documentType === AppConstants.DOCUMENT_TYPE.GOODS_RECEIVED_NOTE
              //     ? deliveryDate
              //     : documentMetaData.deliveryDate,
              // store: store || documentMetaData.store,
            });

            if (documentType === AppConstants.DOCUMENT_TYPE.QUALITY_REPORT) return;
            
            // let filterByDocumentNumber = filteredDocuments.filter((doc: { documentNumber: any }) => doc.documentNumber === value);
            setDocumentItemForm({
              ...documentMetaData,
              // purchaseOrderNumber: value,
              // documentDate:
              //   documentType === AppConstants.DOCUMENT_TYPE.GOODS_RECEIVED_NOTE
              //     ? documentDate
              //     : documentMetaData.documentDate,
              // store: store || documentMetaData.store,
            });
            // setDocumentItemGridData(filterByDocumentNumber[0]?.items);
            // setDocumentTermsAndConditionsData([filterByDocumentNumber[0]?.termsCondition]),
            // setLogisticDetailsData([filterByDocumentNumber[0]?.logisticDetails]);
            // setAdditionalDetailsData([filterByDocumentNumber[0]?.additionalDetails]);
            // setDocumentAdditionalChargesData(filterByDocumentNumber[0]?.additionalCharges);
          }}
        /> */}
        <Input
          className="documentMetaDataFormFields"
          placeholder="Enter Purchase Order Number"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              purchaseOrderNumber: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              purchaseOrderNumber: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderPurchaseOrderDate = () => {
    const label = "Purchase Order Date";
    const validPurchaseOrderDate = documentMetaData.documentDate
      ? moment(documentMetaData.documentDate)
      : moment();
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <div>
          <label>{label}</label>
          <DatePicker
            // disabled
            className="documentMetaDataFormFields"
            // value={validPurchaseOrderDate}
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                documentDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                documentDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderOrderConfirmationNumber = () => {
    const filteredDocuments =
      documentsData?.filter(
        (document: any) => document.documentType === "Order Confirmation"
      ) || [];
    const data =
      filteredDocuments?.map((document: any) => ({
        value: document.documentNumber,
        label: document.documentNumber,
      })) || [];

    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>Order Confirmation Number</label>
        <AutoComplete
          className="documentMetaDataFormFields"
          placeholder="Please enter order confirmation number"
          value={inputSeriesNumber}
          options={data?.map((doc: { value: any; label: any }) => ({
            value: doc.value,
            label: doc.label,
          }))}
          onChange={(value: string) => {
            const selectedDocument = data.find(
              (doc: DocumentData) => doc.value === value
            );

            setInputSeriesNumber(value);
            setDocumentMetaData({
              ...documentMetaData,
              enquiryNumber: value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              enquiryNumber: value,
            });
          }}
        />
      </Col>
    );
  };

  const renderOrderConfirmationDate = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <div>
          <label>Order Confirmation Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                orderConfirmationDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                orderConfirmationDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderTransporterName = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>Transporter Name</label>
        <Select
          className="documentMetaDataFormFields"
          showSearch
          placeholder="Select Transporter Name"
          value={documentMetaData.transporterName || undefined}
          options={transporterData?.map((transporter: { name: any }) => ({
            value: transporter.name,
            label: `${transporter.name}`,
          }))}
          onChange={(option) => {
            const selectedTransporter = transporterData.find(
              (transporter: any) => transporter.name === option
            );

            setDocumentMetaData({
              ...documentMetaData,
              transporterName: selectedTransporter.name,
              transporterGSTNumber: selectedTransporter.GSTNumber,
            });
            setDocumentItemForm({
              ...documentMetaData,
              transporterName: selectedTransporter.name,
              transporterGSTNumber: selectedTransporter.GSTNumber,
            });
          }}
        />
      </Col>
    );
  };

  const renderTransporterGSTNumber = () => {
    return (
      <Col className="gutter-row" span={6}>
        <label>Transporter GST Number</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Please enter transporter GST number"
          value={documentMetaData.transporterGSTNumber || ''}
          disabled 
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              transporterGSTNumber: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              transporterGSTNumber: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderTransportationDocumentNumber = () => {
    return (
      <Col className="gutter-row" span={6}>
        <label>Transportation Document Number</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Please enter transportation document number"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              transportationDocumentNumber: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              transportationDocumentNumber: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderVehicleNumber = () => {
    return (
      <Col className="gutter-row" span={6}>
        <label>Vehicle Number</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Please enter vehicle number"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              vehicleNumber: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              vehicleNumber: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderTransportationDocumentDate = () => {
    return (
      <Col className="gutter-row" span={6}>
        <div>
          <label>Transportation Document Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                transportationDocumentDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                transportationDocumentDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderInvoiceNumber = () => {
    return (
      <Col className="gutter-row" span={6}>
        <label>
          Invoice Number <span style={{ color: "red" }}>*</span>
        </label>

        <Input
          className="documentMetaDataFormFields"
          placeholder="Please enter invoice number"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              invoiceNumber: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              invoiceNumber: ev.target.value,
            });
          }}
        />
        {docValidData && !docValidData[5] && (
          <span className="errorText">* Invoice Number is required</span>
        )}
      </Col>
    );
  };

  const renderInvoiceDate = () => {
    return (
      <Col className="gutter-row" span={6}>
        <div>
          <label>Invoice Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                invoiceDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                invoiceDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderReturnRecievedDate = () => {
    return (
      <Col className="gutter-row" span={6} xs={16} sm={16}>
        <div>
          <label>Return Recieved Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                recievedDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                recievedDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderCreditNoteNumber = () => {
    return (
      <Col className="gutter-row" span={6}>
        <label>Credit Note Number</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Please enter credit note number"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              creditNoteNumber: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              creditNoteNumber: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderChallanNumber = () => {
    return (
      <Col className="gutter-row" span={6}>
        <label>Challan Number</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Please enter supplier challan number"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              challanNumber: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              challanNumber: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderSupplierInvoiceNumber = () => {
    return (
      <Col className="gutter-row" span={6}>
        <label>Supplier Invoice Number</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Please enter supplier invoice number"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              supplierInvoiceNumber: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              supplierInvoiceNumber: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderSupplierInvoiceDate = () => {
    return (
      <Col className="gutter-row" span={6}>
        <div>
          <label>Supplier Invoice Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                supplierInvoiceDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                supplierInvoiceDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderChallanDate = () => {
    return (
      <Col className="gutter-row" span={6}>
        <div>
          <label>Challan Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                challanDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                challanDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderIndentNumber = () => {
    return (
      <Col className="gutter-row" span={6}>
        <label>Indent Number</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Please enter indent number"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              indent_number: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              indent_number: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderGRNNumber = () => {
    const filteredDocuments =
      documentsData?.filter(
        (document: any) => document.documentType === "Goods Receive Notes"
      ) || [];
    const data =
      filteredDocuments?.map((document: any) => ({
        value: document.documentNumber,
        label: document.documentNumber,
      })) || [];
    const label = "GRN Number *";
    return (
      <Col className="gutter-row" span={6}>
        <label>{label}</label>
        {/* <Input
            className="documentMetaDataFormFields"
            placeholder="Please enter GRN number"
            onBlur={(ev: any) => {
              setDocumentMetaData({
                ...documentMetaData,
                gRNNumber: ev.target.value,
              });
              setDocumentItemForm({
                ...documentMetaData,
                gRNNumber: ev.target.value,
              });
            }}
          /> */}
        <AutoComplete
          className="documentMetaDataFormFields"
          placeholder={`Please select or enter ${label.toLowerCase()}`}
          // value={inputSeriesNumber}
          options={data?.map((doc: { value: any; label: any }) => ({
            value: doc.value,
            label: doc.label,
          }))}
          onChange={(value: string) => {
            const selectedDocument = filteredDocuments.find(
              (doc: { documentNumber: string }) => doc.documentNumber === value
            );
            const documentDate = selectedDocument?.documentDate || null;
            const store = selectedDocument?.store || null;
            const deliveryDate = selectedDocument?.deliveryDate || null;

            // setInputSeriesNumber(value);
            // setDocumentMetaData({
            //   ...documentMetaData,
            //   gRNNumber: value,
            //   documentDate:
            //     documentType === AppConstants.DOCUMENT_TYPE.QUALITY_REPORT
            //       ? documentDate
            //       : documentMetaData.documentDate,
            //   deliveryDate:
            //     documentType === AppConstants.DOCUMENT_TYPE.QUALITY_REPORT
            //       ? deliveryDate
            //       : documentMetaData.deliveryDate,
            //   store: store || documentMetaData.store,
            // });

            // setDocumentItemForm({
            //   ...documentMetaData,
            //   gRNNumber: value,
            //   documentDate:
            //     documentType === AppConstants.DOCUMENT_TYPE.QUALITY_REPORT
            //       ? documentDate
            //       : documentMetaData.documentDate,
            //   // store: store || documentMetaData.store,
            // });
            // let filterByDocumentNumber = filteredDocuments.filter((doc: { documentNumber: any }) => doc.documentNumber === value);
            // setDocumentItemGridData(filterByDocumentNumber[0]?.items);
            // setDocumentTermsAndConditionsData([filterByDocumentNumber[0]?.termsCondition]),
            // setLogisticDetailsData([filterByDocumentNumber[0]?.logisticDetails,]);
            // setAdditionalDetailsData([filterByDocumentNumber[0]?.additionalDetails,]);
            // setDocumentAdditionalChargesData(filterByDocumentNumber[0]?.additionalCharges);
          }}
          // disabled={!inputSeriesNumber}
        />
      </Col>
    );
  };

  const renderDNNumber = () => {
    return (
      <Col className="gutter-row" span={6}>
        <label>DN Number</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Please enter GRN number"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              dNNumber: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              dNNumber: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderPayToTransporter = () => {
    return (
      <Col className="gutter-row" span={6}>
        <label>Pay to transporter</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Pay to transporter"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              payToTransporter: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              payToTransporter: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderIndentDate = () => {
    return (
      <Col className="gutter-row" span={6}>
        <div>
          <label>Indent Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                indent_date: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                indent_date: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderGRNDate = () => {
    return (
      <Col className="gutter-row" span={6}>
        <div>
          <label>GRN Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                gRNDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                gRNDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderInspectionDate = () => {
    return (
      <Col className="gutter-row" span={6}>
        <div>
          <label>Inspection Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                inspectionDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                inspectionDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderDNNDate = () => {
    return (
      <Col className="gutter-row" span={6}>
        <div>
          <label>DN Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                dNNDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                dNNDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const handleDeleteField = (key: string) => {
    setCustomFields((prevFields) =>
      prevFields.filter((field) => field.key !== key)
    );
    // notification("Custom field deleted successfully!");
  };

  const handleEditField = (updatedField: CustomField) => {
    const updatedFields = customFields?.map((field) =>
      field.key === updatedField.key ? updatedField : field
    );
    setCustomFields(updatedFields);
    handleCloseModal();
  };

  const renderCustomFields = () => {
    return (
      <>
        <div>
          <Button type="link" onClick={() => handleOpenModal("form")}>
            Add Custom Field
          </Button>
        </div>
        {isModalVisible && (
          <CustomMetaDataModal
            mode={modalMode}
            isVisible={isModalVisible}
            customField={customFields}
            currentField={currentField}
            onSave={handleSaveFields}
            onClose={handleCloseModal}
            onEdit={handleEditField}
            onDelete={handleDeleteField}
          />
        )}
      </>
    );
  };

  const renderCreditNoteDate = () => {
    return (
      <Col className="gutter-row" span={6}>
        <div>
          <label>Credit Note Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                creditNoteDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                creditNoteDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  return (
    <>
      <div className="documentMetaDataForm">
        <div className="flexBox mobAddCategory">
          <div></div>
          <div>
            <Switch
              checked={hideNonMandatory}
              onChange={(checked) => {
                setHideNonMandatory(checked);
                if (checked) {
                  getPaymentTerm({ companyId: Number(companyId) });
                }
              }}
              style={{ marginRight: "8px" }}
            />
            <span style={{ marginRight: "16px" }}>{toggleLabel}</span>
            <Popover content={popoverContent}>
              <Tooltip>
                <InfoCircleOutlined
                  style={{ fontSize: "16px", color: "#888" }}
                />
              </Tooltip>
            </Popover>
          </div>
        </div>
        {documentType === AppConstants.DOCUMENT_TYPE.SALES_ENQUIRY && (
          <>
            <div className="containerDocumentMobileView">
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
                {renderDocumentNumber()}
                {renderEnquiryDate()}
                {renderAmmendment()}
                {renderExpectedDeliveryDate()}
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
                {renderPaymentTerm()}
                {renderStore()}
                {renderPOC()}
                {renderPOCNumber()}
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
                {renderExpectedReplyDate()}
                {renderKindAttention()}
              </Row>
            </div>
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.SALES_QUOTATION && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderEnquiryNumber()}
              {renderEnquiryDate()}
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderExpectedDeliveryDate()}
              {renderStore()}
              {renderPaymentTerm()}
              {renderExpectedPaymentDate()}
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderAmmendment()}
            </Row>
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.ORDER_CONFIRMATION && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderQuotationNumber()}
              {renderQuotationDate()}
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderExpectedDeliveryDate()}
              {renderStore()}
              {renderPaymentTerm()}
              {renderExpectedPaymentDate()}
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderAmmendment()}
              {renderPurchaseOrderNumber()}
              {renderPurchaseOrderDate()}
            </Row>
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.DELIVERY_CHALLAN && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderPurchaseOrderNumber()}
              {renderPurchaseOrderDate()}
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderOrderConfirmationNumber()}
              {renderOrderConfirmationDate()}
              {renderStore()}
              {renderTransporterName()}
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderTransporterGSTNumber()}
              {renderTransportationDocumentNumber()}
              {renderVehicleNumber()}
              {renderTransportationDocumentDate()}
            </Row>
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.INVOICE && (
          <>
            <div className="containerDocumentMobileView">
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
                {renderDocumentNumber()}
                {renderDocumentDate()}
                {renderBillDate()}
                {hideNonMandatory ? (
                  <>{renderPurchaseOrderNumber()}</>
                ) : (
                  <>{renderExpectedPaymentDate()}</>
                )}
              </Row>

              {hideNonMandatory && (
                <>
                  <Row
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    className="mt-20"
                  >
                    {renderPurchaseOrderDate()}
                    {renderOrderConfirmationNumber()}
                    {renderOrderConfirmationDate()}
                    {renderPaymentTerm()}
                  </Row>
                </>
              )}

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
                {hideNonMandatory ? (
                  <>
                    {renderExpectedPaymentDate()}
                    {renderTransporterName()}
                    {renderTransporterGSTNumber()}
                  </>
                ) : (
                  <></>
                )}
                {renderStore()}
              </Row>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                className="mt-20"
              ></Row>
            </div>
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.SALES_RETURN && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderTransportationDocumentDate()}
              {renderReturnRecievedDate()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderPurchaseOrderNumber()}
              {renderPurchaseOrderDate()}
              {renderOrderConfirmationNumber()}
              {renderOrderConfirmationDate()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderInvoiceNumber()}
              {renderInvoiceDate()}
              {renderCreditNoteNumber()}
              {renderCreditNoteDate()}
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderStore()}
              {renderTransporterName()}
              {renderTransportationDocumentNumber()}
              {renderVehicleNumber()}
            </Row>
          </>
        )}
        {documentType === AppConstants.DOCUMENT_TYPE.PURCHASE_ORDER && (
          <>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {renderCustomFields()}
            </div>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderDocumentNumber()}
              {renderPurchaseOrderDate()}
              {renderExpectedDeliveryDate()}
              {renderIndentNumber()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderIndentDate()}
              {renderPaymentTerm()}
              {renderStore()}
            </Row>
          </>
        )}
        {documentType === AppConstants.DOCUMENT_TYPE.GOODS_RECEIVED_NOTE && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderExpectedDeliveryDate()}
              {renderPurchaseOrderNumber()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderPurchaseOrderDate()}
              {renderSupplierInvoiceNumber()}
              {renderSupplierInvoiceDate()}
              {renderChallanNumber()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderChallanDate()}
              {renderStore()}
              {renderTransporterName()}
              {renderTransportationDocumentNumber()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderTransportationDocumentDate()}
              {renderVehicleNumber()}
            </Row>
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.QUALITY_REPORT && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderPurchaseOrderNumber()}
              {renderGRNNumber()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderGRNDate()}
              {renderPurchaseOrderDate()}
              {renderStore()}
              {renderInspectionDate()}
            </Row>
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.PURCHASE_INVOICE && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderSupplierInvoiceNumber()}
              {renderSupplierInvoiceDate()}
              {renderPurchaseOrderNumber()}
              {renderPurchaseOrderDate()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderPaymentTerm()}
              {renderExpectedPaymentDate()}
              {renderGRNNumber()}
              {renderTransporterName()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderTransporterGSTNumber()}
              {renderTransportationDocumentNumber()}
              {renderTransportationDocumentDate()}
              {renderPayToTransporter()}
            </Row>
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.DEBIT_NOTE && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderPurchaseOrderNumber()}
              {renderPurchaseOrderDate()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderInvoiceNumber()}
              {renderInvoiceDate()}
              {renderCreditNoteNumber()}
              {renderCreditNoteDate()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderPaymentTerm()}
              {renderExpectedPaymentDate()}
            </Row>
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.CREDIT_NOTE && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderPurchaseOrderNumber()}
              {renderPurchaseOrderDate()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderInvoiceNumber()}
              {renderInvoiceDate()}
              {renderDNNumber()}
              {renderDNNDate()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderPaymentTerm()}
              {renderExpectedPaymentDate()}
            </Row>
          </>
        )}
        {documentType === AppConstants.DOCUMENT_TYPE.PURCHASE_RETURN && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderPurchaseOrderNumber()}
              {renderPurchaseOrderDate()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderStore()}
              {renderTransporterName()}
              {renderTransportationDocumentNumber()}
              {renderTransportationDocumentDate()}
            </Row>
          </>
        )}
      </div>
      <SeriesMetaDataModal
        isVisible={isVisible}
        onClose={handleSeriesModalClose}
        onSelectSeries={handleSeriesSelection}
        setIsVisible={setIsVisible}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  storesData: state.api.getStore?.data?.reverse(),
  seriesData: state.api.getDocumentSeries?.data?.reverse(),
  documentsData: state.api.getDocuments?.data?.reverse(),
  paymentTermData: state.api.getPaymentTerm?.data,
  transporterData: state.api.getTransporterDetails?.data,
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
  dataSource: state.ui.createDocumentUIData.formData,
  documentType: state.ui.createDocumentUIData.documentType,
});

const mapDispatchToProps = (dispatch: any) => ({
  setDocumentItemForm: (payload: any) =>
    dispatch(setDocumentItemFormData(payload)),
  getStores: (payload: any) => dispatch(getStores(payload)),
  getDocumentSeries: (payload: any) => dispatch(getDocumentSeries(payload)),
  getDocuments: (payload: any) => dispatch(getDocuments(payload)),
  setDocumentItemGridData: (payload: any) =>
    dispatch(setDocumentItemGridData(payload)),
  setDocumentTermsAndConditionsData: (payload: any) =>
    dispatch(setDocumentTermsAndConditionsData(payload)),
  setLogisticDetailsData: (payload: any) =>
    dispatch(setLogisticDetailsData(payload)),
  setAdditionalDetailsData: (payload: any) =>
    dispatch(setAdditionalDetailsData(payload)),
  setDocumentAdditionalChargesData: (payload: any) =>
    dispatch(setDocumentAdditionalChargesData(payload)),
  setDocumentSignatureData: (payload: any) =>
    dispatch(setDocumentSignatureData(payload)),
  getPaymentTerm: (payload: any) => dispatch(getPaymentTerm(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentMetaDataForm);
