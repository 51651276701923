import { Breadcrumb, Layout } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";

interface IIntegrationsProps {}

const Integrations: React.FC<IIntegrationsProps> = () => {
  useEffect(() => {}, []);

  return (
    <Layout className="layout">
      <div>
        <Breadcrumb
          items={[{ title: "Dashboard" }, { title: "Integrations" }]}
        />
      </div>
      <div className="flexBox mt-20"></div>
    </Layout>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
