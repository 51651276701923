import * as type from '../../types/API/userTypes'

export function getUsers(payload: any) {
    return {
        type: type.GET_USERS,
        payload: payload
    }
}

export function addUser(payload: any) {
    return {
        type: type.ADD_USER,
        payload: payload
    }
}

export function editUser(payload: any) {
    return {
        type: type.EDIT_USER,
        payload: payload
    }
}

export function deleteUser(payload: any) {
    return {
        type: type.DELETE_USER,
        payload: payload
    }
}

export function updateUser(payload: any) {
    return {
        type: type.UPDATE_USER,
        payload: payload
    }
}

export function updateProfileLogo(payload: any) {
    return {
        type: type.UPDATE_PROFILE_LOGO,
        payload: payload
    }
}