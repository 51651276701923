import * as type from '../../types/API/customFields'

export function getCustomFields(payload: any) {
    return {
        type: type.GET_CUSTOM_FIELDS,
        payload: payload
    }
}

export function addCustomFields(payload: any) {
    return {
        type: type.ADD_CUSTOM_FIELDS,
        payload: payload
    }
}

export function editCustomFields(payload: any) {
    return {
        type: type.EDIT_CUSTOM_FIELDS,
        payload: payload
    }
}

export function deleteCustomFields(payload: any) {
    return {
        type: type.DELETE_CUSTOM_FIELDS,
        payload: payload
    }
}
