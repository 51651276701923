import { Breadcrumb, Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import Loader from "../../Home/Loader/Loader";
import DashboardCounters from "./DashboardCounters";
import { Chart } from "react-charts";
import { Row, Col, Card, Statistic, Table, Typography, Button } from 'antd';
import { UserOutlined, DollarOutlined, ShoppingCartOutlined, StockOutlined, FileOutlined, BankOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { calc } from "antd/es/theme/internal";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import './Dashboard.css'

const getLast7Days = () => {
    const days = [];
    for (let i = 6; i >= 0; i--) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      days.push(date.toLocaleDateString('en-US', { weekday: 'long' })); // Get weekday names (e.g., "Monday")
    }
    return days;
  };
  
  const generateRevenueData = () => {
    const days = getLast7Days();
    return days.map((day) => ({
      day,
      revenue: Math.floor(Math.random() * 5000) + 8000, 
    }));
  };
  
  const revenueData = generateRevenueData();


// const { Titles } = Typography;
// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
// Define the type for the sales report data
interface SalesReport {
    invoiceNumber: string;
    company: string;
    totalValue: number;
    paidStatus: string;
    payment: string;
    createdBy: string[];
    createdDate: number;
}

const salesData: SalesReport[] = [
    {
        invoiceNumber: 'INV-001',
        company: 'Ease Margin',
        totalValue: 150,
        paidStatus: "Partially Paid",
        payment: 'Net-40',
        createdBy: ['Shamsher', 'Sandeep'],
        createdDate: 20,
    },
    {
        invoiceNumber: 'INV-002',
        company: 'Implement Easy',
        totalValue: 120,
        paidStatus: "Partially Paid",
        payment: 'Net-40',
        createdBy: ['Rahul'],
        createdDate: 25,
   },
    {
        invoiceNumber: 'INV-003',
        company: 'National Kutir Udhyog',
        totalValue: 90,
        paidStatus: "Partially Paid",
        payment: 'Net-40',
        createdBy: ['Rahul', 'Sandeep'],
        createdDate: 20,
    },
    {
        invoiceNumber: 'INV-004',
        company: 'Genesis',
        totalValue: 200,
        paidStatus: "Paid",
        payment: 'Net-40',
        createdBy: ['Harish'],
        createdDate: 25,
    },
    {
        invoiceNumber: 'INV-005',
        company: 'Datronix Digital',
        totalValue: 50,
        paidStatus: "Paid",
        payment: 'Net-40',
        createdBy: ['Saif'],
        createdDate: 20,
    },
];

// Sample Data
const topSellingProducts = [
    {
        itemName: 'Product A',
        quantitySold: 150,
        unitPrice: 20,
        totalRevenue: 3000,
        category: 'Category 1',
    },
    {
        itemName: 'Product B',
        quantitySold: 120,
        unitPrice: 25,
        totalRevenue: 3000,
        category: 'Category 2',
    },
    {
        itemName: 'Product C',
        quantitySold: 200,
        unitPrice: 15,
        totalRevenue: 3000,
        category: 'Category 1',
    },
    {
        itemName: 'Product D',
        quantitySold: 90,
        unitPrice: 50,
        totalRevenue: 4500,
        category: 'Category 3',
    },
    {
        itemName: 'Product E',
        quantitySold: 110,
        unitPrice: 30,
        totalRevenue: 3300,
        category: 'Category 2',
    },
];

// Table columns definition
const columns = [
    {
        title: 'Item Name',
        dataIndex: 'itemName',
        key: 'itemName',
    },
    {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
    },
    {
        title: 'Quantity Sold',
        dataIndex: 'quantitySold',
        key: 'quantitySold',
    },
    {
        title: 'Unit Price (USD)',
        dataIndex: 'unitPrice',
        key: 'unitPrice',
        render: (text: number) => `$${text.toFixed(2)}`,
    },
    {
        title: 'Total Revenue (USD)',
        dataIndex: 'totalRevenue',
        key: 'totalRevenue',
        render: (text: number) => `$${text.toFixed(2)}`,
    },
];

export default function Dashboard() {
    const [loading, setLoading] = useState(true);
    // Sample data for counters and tables
    const [dashboardData, setDashboardData] = useState({
        totalSales: 12,
        totalInventory: 5,
        totalCustomers: 30,
        totalItems: 15,
    });

    const [recentSales, setRecentSales] = useState([
        { key: 1, orderId: 'ORD001', customer: 'John Doe', amount: '$5000', date: '2024-11-21' },
        { key: 2, orderId: 'ORD002', customer: 'Jane Smith', amount: '$3000', date: '2024-11-20' },
        { key: 3, orderId: 'ORD003', customer: 'Alice Johnson', amount: '$4500', date: '2024-11-18' },
    ]);

    // Columns for the Recent Sales Table
    const salesColumns = [
        {
            title: 'Order ID',
            dataIndex: 'orderId',
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
        },
        {
            title: 'Date',
            dataIndex: 'date',
        },
    ];

    // Define columns for the table
    const columns = [
        {
            title: 'Invoice Number',
            dataIndex: 'invoiceNumber',
            key: 'invoiceNumber',
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
        },
        {
            title: 'Total Value',
            dataIndex: 'totalValue',
            key: 'totalValue',
        },
        {
            title: 'Paid Status',
            dataIndex: 'paidStatus',
            key: 'paidStatus',
            //render: (text: number) => `$${text.toFixed(2)}`,
        },
        {
            title: 'Payment',
            dataIndex: 'payment',
            key: 'payment',
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            key: 'createdBy',
            //render: (stores: string[]) => stores.join(', '),
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            //render: (text: number) => `$${text.toFixed(2)}`,
        },
    ];

    // Sample Data
    const topSellingProducts = [
        {
            itemName: 'Table',
            quantitySold: 150,
            unitPrice: 20,
            totalRevenue: 3000,
            category: 'Category 1',
        },
        {
            itemName: 'Steel',
            quantitySold: 120,
            unitPrice: 25,
            totalRevenue: 3000,
            category: 'Category 2',
        },
        {
            itemName: 'Rods',
            quantitySold: 200,
            unitPrice: 15,
            totalRevenue: 3000,
            category: 'Category 1',
        },
        {
            itemName: 'Raw Material',
            quantitySold: 90,
            unitPrice: 50,
            totalRevenue: 4500,
            category: 'Category 3',
        },
        {
            itemName: 'Plastic',
            quantitySold: 110,
            unitPrice: 30,
            totalRevenue: 3300,
            category: 'Category 2',
        },
    ];

    // Table columns definition
    const itemcolumns = [
        {
            title: 'Item Name',
            dataIndex: 'itemName',
            key: 'itemName',
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Quantity Sold',
            dataIndex: 'quantitySold',
            key: 'quantitySold',
        },
        {
            title: 'Unit Price (INR)',
            dataIndex: 'unitPrice',
            key: 'unitPrice',
            render: (text: number) => `₹${text.toFixed(2)}`,
        },
        {
            title: 'Total Revenue (INR)',
            dataIndex: 'totalRevenue',
            key: 'totalRevenue',
            render: (text: number) => `₹${text.toFixed(2)}`,
        },
    ];

    // Sample Revenue Data
    // const revenueData = [
    //     { month: 'January', revenue: 5000 },
    //     { month: 'February', revenue: 6000 },
    //     { month: 'March', revenue: 7000 },
    //     { month: 'April', revenue: 8500 },
    //     { month: 'May', revenue: 9000 },
    //     { month: 'June', revenue: 9500 },
    //     { month: 'July', revenue: 10000 },
    //     { month: 'August', revenue: 11000 },
    //     { month: 'September', revenue: 10500 },
    //     { month: 'October', revenue: 12000 },
    //     { month: 'November', revenue: 13000 },
    //     { month: 'December', revenue: 14000 },
    // ];

    // Chart Data
    const chartData = {
        labels: revenueData.map((data) => data.day),
        datasets: [
            {
                label: 'Revenue (INR)',
                data: revenueData.map((data) => data.revenue),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    // Chart Options
    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Revenue Overview',
            },
        },
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
            },
        },
    };


    useEffect(() => {
        setLoading(false);
    }, []);

    return <>
        <Loader loading={loading}></Loader>
        <Layout
            style={{
                padding:'15px',
                overflow: 'auto',
                marginBottom: '15px'
            }}
        >

            <Content
                style={{
                    margin: 0,
                }}
            >
                <div>
                    {/* Welcome Message */}
                    <h5 style={{ color: '#1890ff' }}>Welcome Back, Admin!</h5>
                    <p style={{ color: '#666', fontSize: '16px' }}>Your dashboard gives you a quick overview of the key metrics of your business.</p>

                    {/* Counters Row */}
                    <Row gutter={16} style={{ marginBottom: '20px' }}>
                        <Col span={5} xs={12} sm={12} lg={5}>
                            <Card
                                bordered={false}
                                style={{
                                    backgroundColor: '#e6f7ff',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '10px',
                                }}
                            >
                                <Statistic
                                    title="Companies"
                                    value={dashboardData.totalSales}
                                    prefix={<BankOutlined style={{ color: '#52c41a' }} />}
                                    //suffix="USD"
                                    valueStyle={{ color: '#52c41a', fontSize: '24px' }}
                                />
                            </Card>
                        </Col>
                        <Col span={5} xs={12} sm={12} lg={5}>
                            <Card
                                bordered={false}
                                style={{
                                    backgroundColor: '#fffbe6',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '10px',
                                }}
                            >
                                <Statistic
                                    title="Stores"
                                    value={dashboardData.totalInventory}
                                    prefix={<EnvironmentOutlined style={{ color: '#faad14' }} />}
                                    valueStyle={{ color: '#faad14', fontSize: '24px' }}
                                />
                            </Card>
                        </Col>
                        <Col span={5} xs={12} sm={12} lg={5} className="cardDashboardInfo">
                            <Card
                                bordered={false}
                                style={{
                                    backgroundColor: '#fff0f6',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '10px',
                                }}
                            >
                                <Statistic
                                    title="Items"
                                    value={dashboardData.totalCustomers}
                                    prefix={<ShoppingCartOutlined style={{ color: '#eb2f96' }} />}
                                    valueStyle={{ color: '#eb2f96', fontSize: '24px' }}
                                />
                            </Card>
                        </Col>
                        <Col span={4} xs={12} sm={12} lg={4} className="cardDashboardInfo">
                            <Card
                                bordered={false}
                                style={{
                                    backgroundColor: '#f0f5ff',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '10px',
                                }}
                            >
                                <Statistic
                                    title="Invoice"
                                    value={dashboardData.totalItems}
                                    prefix={<FileOutlined style={{ color: '#1890ff' }} />}
                                    valueStyle={{ color: '#1890ff', fontSize: '24px' }}
                                />
                            </Card>
                        </Col>
                        <Col span={5} xs={12} sm={12} lg={5} className="cardDashboardInfo"> 
                            <Card
                                bordered={false}
                                style={{
                                    backgroundColor: '#e6f7ff',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '10px',
                                }}
                            >
                                <Statistic
                                    title="Users"
                                    value={dashboardData.totalSales}
                                    prefix={<UserOutlined style={{ color: '#52c41a' }} />}
                                    // suffix="USD"
                                    valueStyle={{ color: '#52c41a', fontSize: '24px' }}
                                />
                            </Card>
                        </Col>
                    </Row>
                    <div>
                        {/* <Titles level={2} style={{ color: '#1890ff' }}>Revenue Overview</Titles> */}
                        <Row gutter={16}>
                            <Col span={12} xs={24} sm={24} lg={12}>
                                <Card
                                    bordered={false}
                                    style={{
                                        borderRadius: '10px',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    <Bar data={chartData} options={chartOptions}/>
                                </Card>
                            </Col>
                            <Col span={12} xs={24} sm={24} lg={12} className="mobItemTable">
                                    <Table
                                        dataSource={topSellingProducts}
                                        columns={itemcolumns}
                                        rowKey="productName"
                                        pagination={false}
                                        style={{
                                            backgroundColor: '#fff',
                                            borderRadius: '10px',
                                            minHeight: '365px'
                                        }}
                                    />
                            </Col>
                        </Row>
                    </div>
                    <div className="mt-20">
                        <Row gutter={16}>
                            <Col span={24}>
                                <Table
                                    dataSource={salesData}
                                    columns={columns}
                                    rowKey="itemName"
                                    pagination={false}
                                    scroll={{ y: 47 * 5 , x: 'calc(700px + 50%)', }}
                                    style={{
                                        backgroundColor: '#fff',
                                        borderRadius: '5px',
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                        minHeight: '300px'
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </Content>
        </Layout>
    </>;
}
