import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Form, Row, Col, Popconfirm, Space, Table, Tag, Checkbox, Select, notification, Alert, Drawer, TableProps } from "antd";
import { EditOutlined, DeleteOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { SeriesMetaDataModalProps } from "./ISeriesMetaData";
import "./SeriesMetaDataModal.css";
import { addDocumentSeries, deleteDocumentSeries, editDocumentSeries, getDocumentSeries } from "../../../redux/actions/API/documentSeriesAction";
import { connect } from "react-redux";
import Loader from "../../../Home/Loader/Loader";
type NotificationType = "success" | "info" | "warning" | "error";

const { Option } = Select;

const SeriesMetaDataModal: React.FC<SeriesMetaDataModalProps> = ({
  isVisible,
  onClose,
  onSelectSeries,
  documentSeriesData,
  addDocumentSeries,
  addDocumentSeriesState,
  editDocumentSeries,
  editDocumentSeriesState,
  getDocumentSeries,
  getDocumentSeriesState,
  deleteDocumentSeries,
  deleteDocumentSeriesState,
  companyId,
  userId,
  documentType,
}) => {
  const prevPropsRef = useRef<any>();
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [activeKey, setActiveKey] = useState<string | null>(null);
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editRecord, setEditRecord] = useState<any>(null);
  const [loading, setLoading] = useState(true); 

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification[type]({
      message: message,
      type: type,
      duration: 3,
    });
  };

  useEffect(() => {
    getDocumentSeries({ companyId: Number(companyId) });
  }, [companyId]);

  useEffect(() => {
    if (documentSeriesData) {
      const filteredSeries = documentSeriesData?.filter(
        (series: { DocType: string }) => series.DocType == documentType
      );
      setDataSource(filteredSeries);
    }
  }, [documentSeriesData]);

  useEffect(() => {
    if (
      prevPropsRef?.current?.getDocumentSeriesState?.loading &&
      !getDocumentSeriesState?.loading
    ) {
      if (addDocumentSeriesState?.error) {
        openNotificationWithIcon("error", getDocumentSeriesState.error.message);
        setLoading(false)
      } else {
        setLoading(false);
      }
    }

    if (
      prevPropsRef?.current?.addDocumentSeriesState?.loading &&
      !addDocumentSeriesState?.loading
    ) {
      if (addDocumentSeriesState?.error) {
        openNotificationWithIcon("error", addDocumentSeriesState.error.message);
        setLoading(false);
      } else {
        getDocumentSeries({ companyId: Number(companyId) });
        setIsEditing(false);
        setIsFormVisible(false);
        form.resetFields();
        openNotificationWithIcon("success", addDocumentSeriesState?.data?.message);
      }
    }

    if (
      prevPropsRef?.current?.editDocumentSeriesState?.loading &&
      !editDocumentSeriesState?.loading
    ) {
      if (editDocumentSeriesState?.error) {
        openNotificationWithIcon("error", editDocumentSeriesState?.error?.message);
      } else {
        openNotificationWithIcon("success", editDocumentSeriesState?.data?.message);
        getDocumentSeries({ companyId: Number(companyId) });
        setIsEditing(false);
        setIsFormVisible(false);
        form.resetFields();
      }
    }

    if ( 
      prevPropsRef?.current?.deleteDocumentSeriesState?.loading &&
      !deleteDocumentSeriesState?.loading
    ) {
      if (deleteDocumentSeriesState.error) {
        openNotificationWithIcon("error", "Failed to delete series");
      } else {
        getDocumentSeries({ companyId: Number(companyId) });
        openNotificationWithIcon("success", deleteDocumentSeriesState?.data?.message);
      }
      getDocumentSeries({ companyId: Number(companyId) });
    }
    prevPropsRef.current = {
      getDocumentSeriesState,
      addDocumentSeriesState,
      editDocumentSeriesState,
      deleteDocumentSeriesState,
    };
  }, [
    getDocumentSeriesState,
    addDocumentSeriesState,
    editDocumentSeriesState,
    deleteDocumentSeriesState,
  ]); 

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values: any) => {
        const { seriesName, series, number, docType } = values;
        const documentNumber = `${series}${number}`;
        const payload = {
          seriesName,
          series,
          docType,
          companyId: Number(companyId),
          userId: Number(userId),
          ip_address: "127.0.0.1",
        };
        setLoading(true);
        if (isEditing && editRecord) {
          editDocumentSeries({
            id: editRecord.id,
            ...payload,
          });
          const updatedDataSource = dataSource.map((item) =>
            item.id === editRecord.id
              ? { ...item, seriesName, series, number, docType }
              : item
          );
        } else {
          addDocumentSeries({
            ...payload,
            number,
            nextNumber: number,
            default: dataSource.length > 0 ? 0 : 1,
          });
        }
      })
      .catch((errorInfo: any) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const handleAddNewSeries = () => {
    setIsFormVisible(true);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setIsFormVisible(false);
    form.resetFields();
  };

  const handleEditSeries = (record: any) => {
    setIsEditing(true);
    setEditRecord(record);
    setIsFormVisible(true);
    form.setFieldsValue({
      seriesName: record.seriesName,
      series: record.prefix,
      number: record.number,
      docType: record.DocType,
    });
  };

  const handleBackToAdd = () => {
    setIsEditing(false);
    setEditRecord(null);
    form.resetFields();
    setIsFormVisible(false);
  };

  const handleCheckboxChange = (key: string, documentNumber: string) => {
    setActiveKey(key);
    const updatedDataSource = dataSource.map((series) => ({
      ...series,
      isActive: series.key === key,
    }));
    setDataSource(updatedDataSource);
    onSelectSeries(documentNumber);
  };

  const handleDelete = (id: any) => {
    setLoading(true);
    deleteDocumentSeries({ id });
  };

  const formatData = (dataSource: any) => {
    const filteredSeries = documentSeriesData?.filter(
      (series: { DocType: string }) => series.DocType == documentType
    );
    let data = filteredSeries?.reverse()?.map((elem: any) => {
      return {
        id: elem.id,
        DocType: elem.DocType,
        seriesName: elem.seriesName,
        prefix: elem.prefix,
        number: elem.number,
        numberData: elem.prefix + elem.number,
        nextNumber: elem.nextNumber,
        nextNumberData: elem.prefix + elem.nextNumber,
        default: elem.default,
        defaultData:
          elem.default == 1 ? (
            <Tag color="green">Default</Tag>
          ) : (
            <Tag color="blue" style={{ cursor: "pointer" }}>
              Set as default
            </Tag>
          ),
      };
    });
    // setDataSource(filteredSeries);
    return data;
  };

  const columns: TableProps["columns"] = [
    {
      title: "",
      dataIndex: "",
      key: "checked",
      render: (_: any, record: any) => (
        <Checkbox
          checked={record.default == 1 ? true : false}
          value={record.seriesName}
          onChange={() => {}}
        ></Checkbox>
      ),
    },
    {
      title: "Document Type",
      dataIndex: "DocType",
      key: "docType",
    },
    {
      title: "Name",
      dataIndex: "seriesName",
      key: "seriesName",
    },
    {
      title: "Prefix",
      dataIndex: "prefix",
      key: "series",
    },
    {
      title: "Doc. Starting No.",
      dataIndex: "numberData",
      key: "number",
    },
    {
      title: "Doc. Next No.",
      dataIndex: "nextNumberData",
      key: "nextNumber",
    },
    {
      title: "Action",
      key: "action",
      width: "70px",
      render: (_: any, record: any) => (
        <Space size="middle">
          <a onClick={() => handleEditSeries(record)}>
            <EditOutlined />
          </a>
          <Popconfirm
            title="Are you sure to delete this?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleDelete(record.id)}
          >
            <a key="list-loadmore-more">
              <DeleteOutlined />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Drawer
      title={"Customize document number"}
      placement="right"
      size={"large"}
      onClose={onClose}
      open={isVisible}
      width={"1000px"}
      extra={
        <Space>
          <Button onClick={onClose}>Close</Button>
        </Space>
      }
    >
      {isFormVisible && (
        <p
          className="backLinkSM"
          onClick={() => {
            setIsFormVisible(false);
            setIsEditing(false);
            form.resetFields();
          }}
        >
          <ArrowLeftOutlined /> Back to Manage document series
        </p>
      )}
      <Loader loading={loading} />
      <Alert
        description="A custom document number series generates unique identifiers for records using prefixes, sequential numbers, and date information, ensuring traceability and efficient management."
        type="info"
      />

      {!isEditing && !isFormVisible && (
        <div className="flexBox mt-10 mobile-row">
          <Input
            placeholder="Search Series"
            onChange={(e: any) => {}}
            className=""
          />
          <Button type="primary" onClick={handleAddNewSeries}>
            Add New Series
          </Button>
        </div>
      )}
      {isFormVisible && (
        <Form
          form={form}
          layout="vertical"
          className="series-form"
          onFinish={handleSubmit}
        >
          <Row gutter={16}>
            <Col span={6} xs={12} sm={12} lg={6}>
              <Form.Item
                label="Document Type"
                name="docType"
                rules={[
                  {
                    required: true,
                    message: "Please select a document type",
                  },
                ]}
              >
                <Select placeholder="Select document type">
                  <Option value="Sales Enquiry">Sales Enquiry</Option>
                  <Option value="Sales Quotation">Sales Quotation</Option>
                  <Option value="Order Confirmation">Order Confirmation</Option>
                  <Option value="Delivery Challan">Delivery Challan</Option>
                  <Option value="Invoice">Invoice</Option>
                  <Option value="Sales Return">Sales Return</Option>
                  <Option value="Purchase Order">Purchase Order</Option>
                  <Option value="Goods Receive Notes">
                    Goods Receive Notes
                  </Option>
                  <Option value="Quality Report">Quality Report</Option>
                  <Option value="Purchase Invoice">Purchase Invoice</Option>
                  <Option value="Debit Note">Debit Note</Option>
                  <Option value="Credit Note">Credit Note</Option>
                  <Option value="Purchase Return">Purchase Return</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6} xs={12} sm={12} lg={6}>
              <Form.Item label="Series Name" name="seriesName">
                <Input placeholder="Enter series name" />
              </Form.Item>
            </Col>
            <Col span={6} xs={12} sm={12} lg={6}>
              <Form.Item
                label="Series"
                name="series"
                rules={[
                  {
                    required: true,
                    message: "Please fill the prefix",
                  },
                ]}
              >
                <Input placeholder="Enter prefix" />
              </Form.Item>
            </Col>
            <Col span={6} xs={12} sm={12} lg={6}>
              <Form.Item
                label="Number"
                name="number"
                rules={[
                  {
                    required: true,
                    message: "Please fill the number",
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Enter number"
                  disabled={isEditing}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} justify="end">
            <Col>
              <Button onClick={handleSubmit} type="primary">
                {isEditing ? "Update" : "Submit"}
              </Button>
            </Col>
            <Col>
              <Button onClick={handleCancel}>Cancel</Button>
            </Col>
          </Row>
        </Form>
      )}

      {!isEditing && !isFormVisible && (
        <Table
          bordered
          dataSource={formatData(dataSource)}
          columns={columns}
          pagination={false}
          rowKey="id"
          className="mt-20"
        />
      )}
    </Drawer>
  );
};

const mapStateToProps = (state: any) => ({
  documentSeriesData: state.api.getDocumentSeries?.data,
  userId: state.api.login?.data.id,
  companyId: state.api.login?.data.companyId,
  getDocumentSeriesState: state.api?.getDocumentSeries,
  addDocumentSeriesState: state.api?.addDocumentSeries,
  editDocumentSeriesState: state.api?.editDocumentSeries,
  deleteDocumentSeriesState: state.api?.deleteDocumentSeries,
  documentType: state.ui.createDocumentUIData.documentType,
});

const mapDispatchToProps = (dispatch: any) => ({
  addDocumentSeries: (payload: any) => dispatch(addDocumentSeries(payload)),
  getDocumentSeries: (payload: any) => dispatch(getDocumentSeries(payload)),
  editDocumentSeries: (payload: any) => dispatch(editDocumentSeries(payload)),
  deleteDocumentSeries: (payload: any) => dispatch(deleteDocumentSeries(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeriesMetaDataModal);