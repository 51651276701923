import React, { useState, useEffect, useRef } from "react";
import { Button, Checkbox, CheckboxProps, Col, Drawer, Form, Input, InputNumber, notification, Row, Select, Space } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { stockUpdate } from "../../../redux/actions/API/stockUpdateAction";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import Loader from "../../../Home/Loader/Loader";
import { getStoreByItems } from "../../../redux/actions/API/storesActions";
import { stockTransfer } from "../../../redux/actions/API/stockTransferAction";
import { getItems } from "../../../redux/actions/API/items";

const { Option } = Select;

interface IRowData {
  storeDisabled: boolean | null;
  key: string;
  item: string | null;
  store: number | null;
  quantity: number | null;
  price: number | null;
  uom: string | null;
  comment: string | "";
}

interface IStockUpdateProps {
  title: string;
  onClose: () => void;
  open: boolean;
  itemsData: any;
  storeData: any;
  UOMData: any;
  storeByItemsData: any;
  // stockUpdate: Function;
  stockTransfer: Function;
  getItems: Function;
  getStoreByItems: Function;
  stockUpdateState: IDataResponse;
  userId: number;
  companyId: number;
}

const StockUpdate: React.FC<IStockUpdateProps> = ({
  title,
  onClose,
  open,
  itemsData,
  storeData,
  storeByItemsData,
  getStoreByItems,
  // stockUpdate,
  stockTransfer,
  getItems,
  stockUpdateState,
  UOMData,
  userId,
  companyId,
}) => {
  type NotificationType = "success" | "info" | "warning" | "error";

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };
  const [form] = Form.useForm();
  const [items, setItems] = useState<any[]>([]);
  const prevPropsRef = useRef<IStockUpdateProps | null>(null);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<IRowData[]>([
    {
      key: "1",
      item: null,
      store: null,
      quantity: null,
      price: null,
      storeDisabled: true,
      uom: null,
      comment: "",
    },
  ]);
  const [showStockProperty, setShowStockProperty] = useState<boolean>(false);
  const [availableQuantities, setAvailableQuantities] = useState<Record<string, number>>({});
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [isFIFO, setIsFIFO] = useState(true);

  useEffect(() => {
    if (itemsData && itemsData.length > 0) {
      setItems(itemsData);
    }
  }, [itemsData]);

  useEffect(() => {
    if (
      prevPropsRef.current?.stockUpdateState?.loading &&
      !stockUpdateState.loading
    ) {
      if (stockUpdateState?.error) {
        openNotificationWithIcon("error", "Something went wrong!");
        setLoading(false);
      } else {
        getItems({companyId: Number(companyId)})
        onCloseHandler();
      }
      setLoading(false);
    }
    prevPropsRef.current = {
      title,
      onClose,
      open,
      itemsData,
      storeData,
      storeByItemsData,
      getStoreByItems,
      // stockUpdate,
      stockTransfer,
      getItems,
      stockUpdateState,
      UOMData,
      userId,
      companyId
    };
  }, [stockUpdateState]);

  const addRow = () => {
    const newRow: IRowData = {
      key: Date.now().toString(),
      item: null,
      store: null,
      quantity: null,
      price: null,
      storeDisabled: true,
      uom: null,
      comment: "",
    };
    setRows([...rows, newRow]);
  };

  const deleteRow = (key: string) => {
    setRows(rows.filter((row) => row.key !== key));
  };

  const resetRows = () => {
    setRows([
      {
        key: "1",
        item: null,
        store: null,
        quantity: null,
        price: 0,
        storeDisabled: true,
        uom: null,
        comment: "",
      },
    ]);
    form.resetFields();
  };

  const onCloseHandler = () => {
    resetRows();
    onClose();
  };

  const generateTransferNumber = (() => {
    let lastTimestamp = Date.now() % 1000;
    let counter = 100;
  
    return () => {
      const currentTimestamp = Date.now() % 1000;
  
      if (currentTimestamp !== lastTimestamp) {
        lastTimestamp = currentTimestamp;
        counter = 100;
      } else if (counter >= 999) {
        counter = 100 + Math.floor(Math.random() * 900);
      }
  
      const transferNumber = (currentTimestamp * 1000 + counter).toString().padStart(6, '0');
      counter += 1;
  
      return Number(transferNumber);
    };
  })();

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => { 
        setLoading(true);
        const stockData = rows.map((row) => ({
          fromStoreId: values[`fromStore-${row.key}`],
          itemId: values[`item-${row.key}`] || row.item,
          toStoreId: values[`toStore-${row.key}`],
          comment: values[`comment-${row.key}`],
          quantity: Number(values[`quantity-${row.key}`]) || row.quantity,
          transferDate: new Date().toISOString(),
        }));
   
        const payload = {
          transferNumber: generateTransferNumber(),  
          transferredBy: userId,                    
          companyId: Number(companyId),                     
          stockData,                               
        }; 
        stockTransfer(payload); 
      })
      .catch((error) => {
        console.error("Form Validation Error:", error);
      });
  };

  const handleItemChange = (key: string, field: string, value: string) => {
    const selectedItem = itemsData.find(
      (item: any) => Number(item.id) === Number(value)
    );
    if (selectedItem) {
      const uom = UOMData.find(
        (uom: any) => String(uom.id) === String(selectedItem.metricsUnit)
      );
      const uomName = uom ? uom.name : "";
      const updatedRow: IRowData = {
        key,
        item: value,
        storeDisabled: false,
        price: selectedItem.price,
        uom: uomName,
        comment: selectedItem.description,
        quantity: null,
        store: null,
      };

      setRows((prevRows) =>
        prevRows.map((row) => (row.key === key ? updatedRow : row))
      );
      form.setFieldsValue({
        [`price-${key}`]: selectedItem.price,
        [`uom-${key}`]: uomName, 
      });

      getStoreByItems({ itemId: value });
    }
  };

  const getAvailableItems = (currentKey: string, searchText: string) => {
    const selectedItems = rows?.map((row) => row.item);
    return itemsData?.filter((item: any) => {
      const isItemSelected =
        !selectedItems.includes(item.id) ||
        rows.find((row) => row.key === currentKey)?.item === item.id;
      const isSearchMatch =
        item.itemName.toLowerCase().includes(searchText.toLowerCase()) ||
        item.itemId.toLowerCase().includes(searchText.toLowerCase());
      return isItemSelected && isSearchMatch;
    });
  };

  const handleStoreChange = (key: string, storeId: number) => {
    const updatedRows = rows.map((row) =>
      row.key === key ? { ...row, store: storeId } : row
    );
    setRows(updatedRows);
    const selectedStore = storeData?.find(
      (store: { id: number }) => store.id === storeId
    );
    if (selectedStore) {
      setAvailableQuantities((prevQuantities) => ({
        ...prevQuantities,
        [key]: selectedStore.itemCount || 0,
      }));
    }
  };

  const onChange: CheckboxProps["onChange"] = (e) => {
    setIsFIFO(e.target.checked);
  };

  return (
    <>
      <Loader loading={loading} />
      <Drawer
        title={title}
        width={1000}
        onClose={onCloseHandler}
        open={open}
        extra={
          <Space>
            <Button className="buttomCancelMobView" onClick={onCloseHandler}>Cancel</Button>
            <Button type="primary" onClick={handleSubmit}> Submit </Button>
          </Space>
        }
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Checkbox checked={isFIFO} onChange={onChange}> FIFO (First in first out) Price</Checkbox>
        </div>
        <Form layout="vertical" form={form} style={{paddingRight: '20px'}} className="mt-20">
          {rows.map((row, index) => (
            <Row gutter={24} align="top" key={row.key} style={{ marginTop: index > 0 ? '40px' : '0px' }}>
              <Col span={4} xs={12} sm={12} lg={4}>
                <Form.Item
                  label="Item"
                  name={`item-${row.key}`}
                  rules={[{ required: true, message: "Please select an item" }]}
                >
                  <Select
                    placeholder="Select/Search item"
                    value={row.item || undefined}
                    onChange={(value) => {
                      handleItemChange(row.key, "item", value);
                      setFilteredItems([]);
                    }}
                    optionLabelProp="label"
                    showSearch
                    filterOption={false}
                    onSearch={(searchText) => {
                      const filtered = getAvailableItems(row.key, searchText);
                      setFilteredItems(filtered);
                    }}
                  >
                    {(filteredItems.length > 0
                      ? filteredItems
                      : getAvailableItems(row.key, "")
                    )?.map((item: any) => {
                      return (
                        <Option
                          key={item.id}
                          value={item.id}
                          label={item.itemName}
                        >
                          <div className="stockDropdown">
                            <span className="stockDropdownTitle"> {item.itemName} </span>
                            <div style={{ display: !showStockProperty ? "block" : "none"}}>
                              <div style={{ fontSize: "12px", color: "#888" }}>
                                Item-Id: {item.itemId}
                              </div>
                            </div>
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4} xs={12} sm={12} lg={4}>
                <Form.Item
                  label="Store"
                  name={`store-${row.key}`}
                  rules={[{ required: true, message: "Please select a from store" }]}
                >
                  <Select
                    placeholder="Select store"
                    value={row.store}
                    onChange={(value) => handleStoreChange(row.key, value)}
                    onDropdownVisibleChange={(data) => setShowStockProperty(data)}
                    optionLabelProp="label"
                  >
                    {storeData?.map((store: any) => (
                      <Option
                        key={store.id}
                        value={store.id}
                        label={store.name}
                      >
                        <div className="stockDropdown">
                          <span className="stockDropdownTitle">
                            {store.name}
                          </span>
                          <div style={{ display: showStockProperty ? "block" : "none"}}>
                            <div style={{ fontSize: "12px", color: "#888" }}>
                              Avl. Qty: {store.itemCount}
                            </div>
                          </div>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {row.store && (
                  <div className="stock-quantity">
                    Avl Qty: {availableQuantities[row.key] || 0}
                  </div>
                )}
              </Col>
              <Col span={3} xs={12} sm={12} lg={3}>
                <Form.Item
                  label="Quantity"
                  name={`quantity-${row.key}`}
                  rules={[
                    { required: true, message: "Please enter valid quantity" },
                    {
                      pattern: /^[1-9][0-9]*$/, 
                      message: "Please enter valid quantity",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Quantity"
                  />
                </Form.Item>
              </Col>
              <Col span={4} xs={12} sm={12} lg={4}>
                <Form.Item
                  label="Price"
                  name={`price-${row.key}`}
                  rules={[
                    { required: true, message: "Please enter price!" },
                    {
                      pattern: /^(?:[1-9]\d*|0)(?:\.\d+)?$/, 
                      message: "Price should be 0 or greater!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter price"
                    type="number"
                  />
                </Form.Item>
              </Col>
              <Col span={4} xs={12} sm={12} lg={4}>
                <Form.Item
                  label="UOM"
                  name={`uom-${row.key}`}
                  rules={[
                    {
                      required: true,
                      message: "Please select a unit of measurement",
                    },
                  ]}
                >
                  <Input disabled placeholder="UOM value" />
                </Form.Item>
              </Col>
              <Col span={4} xs={12} sm={12} lg={4}>
                <Form.Item label="Comment" name={`comment-${row.key}`}>
                  <Input
                    // readOnly
                    placeholder="Write your comment"
                    value={row.comment}
                    onChange={(e) => {
                      const updatedRows = rows.map((r) =>
                        r.key === row.key
                          ? { ...r, comment: e.target.value }
                          : r
                      );
                      setRows(updatedRows);
                    }}
                  />
                </Form.Item>
              </Col>
                <Col span={1} className="mt-20">
                  {index === 0 ? (
                    <Button
                      className="button-action"
                      icon={<PlusOutlined />}
                      onClick={addRow}
                    />
                  ) : (
                    <Button
                      className="button-action"
                      icon={<DeleteOutlined />}
                      danger
                      onClick={() => deleteRow(row.key)}
                    />
                  )}
                </Col>
            </Row>
          ))}
        </Form>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userId: state.api.login.data?.id,
  companyId: state.api.login.data.companyId,
  itemsData: state.api.getItems?.data?.reverse(),
  storeData: state.api.getStore?.data?.reverse(),
  UOMData: state.api.getUOM?.data?.reverse(),
  stockUpdateState: state.api.stockTransfer,
  storeByItemsData: state.api.getStoreByItems?.data,
});

const mapDispatchToProps = (dispatch: any) => ({
  // stockUpdate: (payload: any) => dispatch(stockUpdate(payload)),
  getItems: (payload: any) => dispatch(getItems(payload)),
  stockTransfer: (payload: any) => dispatch(stockTransfer(payload)),
  getStoreByItems: (payload: any) => dispatch(getStoreByItems(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StockUpdate);
