import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Typography, Row, Col, Card, Space, Button } from "antd";
import "./RefundAndCancellation.css";
import { Link } from "react-router-dom";
const { Title, Text, Paragraph } = Typography;


const RefundAndCancellation: React.FC = () => {
  return (
    <>
      <Header />
      <div className="refund-container">
        <Row>
          <div className="container">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="refund-card">
                <Title level={2} className="headingRefund">
                  Cancellation and Refund Policy
                </Title>

                <Paragraph>
                  At <b>Ease Margin</b>, we provide a seamless and efficient <b>ERP software solution</b> to optimize your business operations.
                  Please review our cancellation and refund policy below.
                </Paragraph>

                <Space direction="vertical" size="large" style={{ display: "flex" }}>
                  {/* Subscription & Payment */}
                  <div>
                    <Title level={4}>1. Subscription & Payment</Title>
                    <Paragraph>
                      • Ease Margin operates on a subscription model with monthly, quarterly, and annual billing cycles.<br />
                      • All payments are processed securely through third-party payment gateways (Razorpay, Cashfree).<br />
                      • Subscription charges are non-refundable except as explicitly mentioned in this policy.
                    </Paragraph>
                  </div>

                  {/* Free Trial & No Refund Policy */}
                  <div>
                    <Title level={4}>2. Free Trial & No Refund Policy</Title>
                    <Paragraph>
                      • We offer a <b>7-day free trial</b> for users to explore the platform before subscribing.<br />
                      • Once the trial ends, all payments made are <b>non-refundable</b>.<br />
                      • No refunds are issued for dissatisfaction or failure to use the service.
                    </Paragraph>
                  </div>

                  {/* Cancellation Policy */}
                  <div>
                    <Title level={4}>3. Cancellation Policy</Title>
                    <Paragraph>
                      • Users can cancel their subscription anytime from their Ease Margin account dashboard.<br />
                      • The subscription remains active until the current billing cycle ends.<br />
                      • No prorated refunds are provided for mid-cycle cancellations.
                    </Paragraph>
                  </div>

                  {/* Refund Eligibility */}
                  <div>
                    <Title level={4}>4. Refund Eligibility</Title>
                    <Paragraph>
                      Refunds will only be considered in the following cases:
                      <ul>
                        <li><b>Duplicate Payments</b> – If you were charged twice for the same subscription.</li>
                        <li><b>Technical Issues</b> – If Ease Margin is completely non-functional due to system errors.</li>
                        <li><b>Unauthorized Transactions</b> – If a fraudulent transaction is reported within 7 days.</li>
                      </ul>
                      All refund requests must be submitted within <b>7 days of payment</b>.
                    </Paragraph>
                  </div>

                  {/* Payment Disputes */}
                  <div>
                    <Title level={4}>5. Payment Disputes & Chargebacks</Title>
                    <Paragraph>
                      • If a payment dispute is raised without contacting us, your account may be temporarily suspended.<br />
                      • Please reach out to our support team at <b>info@easemargin.com</b> before initiating chargebacks.
                    </Paragraph>
                  </div>

                  {/* Changes to Policy */}
                  <div>
                    <Title level={4}>6. Changes to This Policy</Title>
                    <Paragraph>
                      • Ease Margin reserves the right to update this policy at any time.<br />
                      • Updates will be communicated via email and our website.<br />
                      • Continued use of our services implies acceptance of the updated policy.
                    </Paragraph>
                  </div>

                  {/* Contact Information */}
                  <div style={{ padding: "20px", background: "#f5f5f5", borderRadius: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div>
                      <Title level={4}>📩 Need Help?</Title>
                      <Paragraph>
                        If you have any questions regarding refunds or cancellations, contact us at:<br />
                        <b>Email:</b> <a href="mailto:info@easemargin.com">info@easemargin.com</a>
                      </Paragraph>
                    </div>


                    <Link to="/contactus"><Button type="primary" size="large">Write Us</Button></Link>

                  </div>
                </Space>
              </div>
            </Col>
          </div>
        </Row>
      </div>
      <div className="refundFooter">
        <Footer />
      </div>
    </>
  );
};

export default RefundAndCancellation;
