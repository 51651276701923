import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import SalesQuotation from "../../Utility/images/quotation-management.png";
import OrderConfirmation from "../../Utility/images/orderconfirmation.png";
import Invoice from "../../Utility/images/invoicemanagement.png";
import Goods from "../../Utility/images/goods.png";
import StocKeeping from "../../Utility/images/stockskeeping.png";
import ManageProducts from "../../Utility/images/productsmanagement.png";
import Variations from "../../Utility/images/variations.png";
import WOW from "../../Utility/js/wow";
import { Button } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import "./InventoryLanding.css";

const faqData = [
  {
    question:
      "Can I use the discounts while creating invoices in Ease Margin ?",
    answer:
      "Yes,in fact you will have an option to apply successive discounts in your invoices created on Ease Margin.",
  },
  {
    question: "Can I create a GST invoice ?",
    answer:
      "Yes our format is made as per GST compliant invoices which gives you an easy and correct method to bill your customer. ",
  },
  {
    question:
      "Do I have an option to create the sales quotation in Ease Margin ?",
    answer:
      "Yes, you can create the sales quotation and send it to the customer by email or through whatsapp. ",
  },
  {
    question: "Can I attach a payment link in the invoices ?",
    answer:
      "Yes, you can collect the amount from customers by attaching the payment link in the invoices.Ease margin ensures a safe and secure gateway for the smooth transaction.  ",
  },
  {
    question: "Is there an option to customize the fields in the invoice ? ",
    answer: "Yes, you can add the fields which you require to be present in the invoice through our custom field addition feature.  ",
  },
  {
    question: "Can I attach any file along with my invoice ?",
    answer: "We give a flexibility where users can attach any pdf or documents through our attachment plugins within invoice format. ",
  },
  {
    question: "Do I need to manually reduce the stock of an item after dispatching to the customer location ? ",
    answer: "No, you can set your inventory stock to automatically get reduced once the delivery challan or sales invoice is created in Ease Margin.",
  },
];

const SalesFeature: React.FC = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<number | null>(null);
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const { pathname } = useLocation();
  
  useEffect(() => {
    const scrollToTop = () => {
      const element = document.getElementById("sales");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
  
    scrollToTop();
  }, [pathname]);

  useEffect(() => {
    try {
      const wow = new WOW.WOW();
      wow.init();
    } catch (error) {
      console.error("Error initializing WOW.js:", error);
    }
  }, []);

  useEffect(() => {
    const observers: IntersectionObserver[] = [];

    cardRefs.current.forEach((card, index) => {
      if (card) {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              card.classList.add("inventory-card-transition");
            } else {
              card.classList.remove("inventory-card-transition");
            }
          });
        });
        observer.observe(card);
        observers.push(observer);
      }
    });

    return () => {
      observers.forEach((observer) => observer.disconnect());
    };
  }, []);

  const navigateToSignUp = () => {
    navigate("/sign-in", { state: { isSignUp: true } });
  };

  const toggleCard = (index: number) => {
    setExpanded(expanded === index ? null : index);
  };
  return (
    <div id="sales">
      <Header />
      <div className="inventory-landing-container">
        <section className="section-inventory-feature">
          <div className="text-inventory-feature">
            Inventory Tracking Software to keep track of your sales activity.
          </div>
        </section>

        <h2 className="text-heading-feature">Features</h2>

        <section className="section-multiple-warehouse">
          <div className="inventory-container-row left-column wow fadeInRight txt-block">
            <img
              src={SalesQuotation}
              alt="sales-quotation"
              className="image-multiple-warehouse"
            />
            <div className="inventory-container-column container-multiple-warehouse">
              <h4>Quotation Management</h4>
              <div className="content-inventory-text">
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>
                      Create sales quotation and update quotation as per
                      negotiation.
                    </p>
                  </div>
                </div>
                <br />
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>
                      Get older version of quotation and communicate with
                      teammates.
                    </p>
                  </div>
                </div>
                <br />
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>Send quotation via Email/WhatsApp.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-physical-stock">
          <div className="inventory-container-row right-column wow fadeInLeft txt-block">
            <div className="inventory-container-column container-phy-stock container-order-confirmation">
              <h4>Order Acceptance and Advance Receipt</h4>
              <div className="content-inventory-text">
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>Create Order Acceptance once, you received the PO.</p>
                  </div>
                </div>
                <br />
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>
                      Punch advance payments received from the Buyer against the
                      order.
                    </p>
                  </div>
                </div>
                <br />
              </div>
            </div>
            <img
              src={OrderConfirmation}
              alt="order-confirmation"
              className="image-order-confirmation"
            />
          </div>
        </section>

        <section className="section-bulk-upload">
          <div className="inventory-container-row left-column wow fadeInRight txt-block">
            <img
              src={Invoice}
              alt="bulk-upload"
              className="image-invoice-challan"
            />
            <div className="inventory-container-column container-bulk-upload">
              <h4>Invoicing and Challan</h4>
              <div className="content-inventory-text">
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>Create GST Invoicing.</p>
                  </div>
                </div>
                <br />
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>Create Debit/Credit Note against the invoice.</p>
                  </div>
                </div>
                <br />
                <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme icon-check">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>
                  <div className="cbox-1-txt">
                    <p>
                      Create Delivery Challan (auto reduction of stocks from
                      inventory).
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section ref={wrapperRef} className="section-inventory-cards">
          <div className="heading-inventory-card">
            Try it for free. No payments required.
          </div>
          <Button
            className="button-inventory-signup"
            type="default"
            onClick={() => navigateToSignUp()}
          >
            Sign up for free
          </Button>
          <div className="inventory-card-row">
            {[
              {
                image: Goods,
                title: "Accurate stock keeping",
                description:
                  "Order confidently and save money by using EaseMargin’s inventory management software. EaseMargin gives your business control over your product stocks.",
              },
              {
                image: StocKeeping,
                title: "Improve your stock keeping",
                description:
                  "Improve speed and efficiency, remove error or fraud, and reduce time-consuming manual tasks. Simple and easy to use across your entire organization.",
              },
              {
                image: ManageProducts,
                title: "Manage all your products",
                description:
                  "Full control of all your products anywhere, anytime, from any device. See where every Rupee goes and monitor the bottom-line impact with real-time budgeting.",
              },
              {
                image: Variations,
                title: "Comprehensive product variation",
                description:
                  "Add and manage products with distinct variations like color, size, price & more. Curate and manage your evolving inventory from a single dashboard.",
              },
            ].map((card, index) => (
              <div
                key={index}
                ref={(el) => (cardRefs.current[index] = el)}
                className="inventory-cards inventory-card-transition"
              >
                <img
                  src={card.image}
                  alt={card.title}
                  className="icon-inventory-cards"
                />
                <h3 className="headingh3">{card.title}</h3>
                <div className="paraghtextCards">{card.description}</div>
              </div>
            ))}
          </div>
        </section>

        <section className="section-faqs">
          <h2 className="heading-intro">
            <span className="span-intro">Frequently Asked Questions</span>
          </h2>
          <p className="paragh-faq">
            If you can't find an answer that you're looking for, feel free to
            drop us a line.
          </p>
          <div className="faqs-button">
            <Button type="default" className="button-sub-faq">
              About the company
            </Button>
            <Button type="default" className="button-sub-faq ml-10">
              Contact Support
            </Button>
          </div>
          <div className="faq-cards">
            {faqData.map((faq, index) => (
              <div key={index} className="faq-card">
                <div className="faq-question" onClick={() => toggleCard(index)}>
                  <span>{faq.question}</span>
                  {expanded === index ? <UpOutlined /> : <DownOutlined />}
                </div>
                {expanded === index && (
                  <div className="faq-answer">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </section>
      </div>
      <div className="inventory-footer-container">
        <Footer />
      </div>
    </div>
  );
};

export default SalesFeature;
