import Table, { ColumnType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { BOMRawMaterialDataType } from "./bomConstant";
import { Button, Card, Input, Popconfirm, Select, Tooltip } from "antd";
import { DeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import { getItems } from "../../../redux/actions/API/items";
import { connect } from "react-redux";
const { Option } = Select;

interface IBOMRawMaterialProps {
  itemsData: any;
  companyId: number;
  getItems: Function;
}

const BOMRawMaterial: React.FC<IBOMRawMaterialProps> = ({
  itemsData,
  companyId,
}) => {
  const [keyCounter, setKeyCounter] = useState<number>(2);
  const [bomData, setBOMData] = useState<BOMRawMaterialDataType[]>([
    {
      key: 1,
      itemId: "",
      itemName: "",
      UOM: "",
      quantity: null,
      store: "",
    },
  ]);

  useEffect(() => {
    getItems({ companyId: Number(companyId) });
  }, []);

  const addRow = () => {
    const newItem: BOMRawMaterialDataType = {
      key: keyCounter,
      itemId: "",
      itemName: "",
      UOM: "",
      quantity: null,
      store: "",
    };
    setBOMData([...bomData, newItem]);
    setKeyCounter(keyCounter + 1);
  };

  const handleDelete = (key: React.Key) => {
    const updatedData = bomData?.filter((item) => item.key !== key);
    setBOMData(updatedData);
  };

  const handleResetField = (index: number) => {
    const newData = [...bomData];
    newData[index] = {
      ...newData[index],
      itemId: "",
      itemName: "",
      UOM: "",
      quantity: null,
      store: "",
    };
    setBOMData(newData);
  };

  const selectedItemIds = bomData?.map((item) => item.itemId)?.filter(Boolean);
  const selectedItemNames = bomData
    ?.map((item) => item.itemName)
    ?.filter(Boolean);

  const columns: ColumnType<BOMRawMaterialDataType>[] = [
    {
      title: "Item ID",
      dataIndex: "itemId",
      key: "itemId",
      fixed: "left",
      render: (text, record) => (
        <Select
          value={text || null}
          placeholder="Select/Search Item ID"
          showSearch
          // style={{
          //   width: "100%",
          //   minWidth: "6rem", 
          //   maxWidth: "8rem",
          // }}
          onChange={(value) => {
            const selectedItem = itemsData.find(
              (item: any) => item.itemId === value
            );
            setBOMData(
              bomData?.map((item) =>
                item.key === record.key
                  ? {
                      ...item,
                      itemId: value,
                      itemName: selectedItem?.itemName || "",
                      UOM: selectedItem?.metricsUnit || "",
                    }
                  : item
              )
            );
          }}
          filterOption={(input, option) =>
            (option?.value ?? "")
              .toString()
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {itemsData
            ?.filter((item: any) => !selectedItemIds.includes(item.itemId))
            ?.map((item: any) => (
              <Option key={item.id} value={item.itemId}>
                {item.itemId}
              </Option>
            ))}
        </Select>
      ),
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
      fixed: "left",

      render: (text, record) => (
        <Select
          value={text || null}
          placeholder="Select/Search Item Name"
          showSearch
          // style={{
          //   width: "100%",
          //   minWidth: "10rem",  
          //   maxWidth: "15rem",
          // }}
          onChange={(value) => {
            const selectedItem = itemsData.find(
              (item: any) => item.itemName === value
            );
            setBOMData(
              bomData?.map((item) =>
                item.key === record.key
                  ? {
                      ...item,
                      itemId: selectedItem?.itemId,
                      itemName: selectedItem?.itemName || "",
                      UOM: selectedItem?.metricsUnit || "",
                    }
                  : item
              )
            );
          }}
          filterOption={(input, option) =>
            (option?.value ?? "")
              .toString()
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {itemsData
            ?.filter((item: any) => !selectedItemNames.includes(item.itemName))
            ?.map((item: any) => (
              <Option key={item.id} value={item.itemName}>
                {item.itemName}
              </Option>
            ))}
        </Select>
      ),
    },
    {
      title: "UOM",
      dataIndex: "UOM",
      key: "UOM",
      render: (text, record) => (
        <Input
          value={text}
          placeholder="Enter UOM"
          // style={{
          //   width: "100%",
          //   minWidth: "4rem",
          //   maxWidth: "5rem",
          // }}
          onChange={(e) =>
            setBOMData(
              bomData?.map((item) =>
                item.key === record.key
                  ? { ...item, UOM: e.target.value }
                  : item
              )
            )
          }
        />
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <Input
          value={text || ""}
          type="number"
          placeholder="Enter Quantity"
          // style={{
          //   width: "100%",
          //   minWidth: "4rem",
          //   maxWidth: "6rem",
          // }}
          onChange={(e) =>
            setBOMData(
              bomData?.map((item) =>
                item.key === record.key
                  ? { ...item, quantity: Number(e.target.value) }
                  : item
              )
            )
          }
        />
      ),
    },
    {
      title: "Store",
      dataIndex: "store",
      key: "store",
      render: (text, record) => (
        <Input
          value={text}
          placeholder="Enter Store"
          // style={{
          //   width: "100%",
          //   minWidth: "10rem", 
          //   maxWidth: "15rem",
          // }}
          onChange={(e) =>
            setBOMData(
              bomData?.map((item) =>
                item.key === record.key
                  ? { ...item, store: e.target.value }
                  : item
              )
            )
          }
        />
      ),
    },

    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 100,
      render: (text: string, record: BOMRawMaterialDataType, index: number) => {
        return (
          <div style={{ display: "flex", gap: 8 }}>
            <Tooltip title="Refresh">
              <span className="actionIcons">
                <ReloadOutlined onClick={() => handleResetField(index)} />
              </span>
            </Tooltip>
            {bomData?.length > 1 && (
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure you want to delete this row?"
                  onConfirm={() => handleDelete(record.key)}
                  okText="Yes"
                  cancelText="No"
                >
                  <span className="actionIcons">
                    <DeleteOutlined />
                  </span>
                </Popconfirm>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Card className="cardTable">
      <h5 className="mb-2">Raw Material Table </h5>
      <Table
        dataSource={bomData}
        columns={columns}
        pagination={false}
        rowKey="key"
        className="table-component"
        scroll={{ x: "max-content" }}
      />
      <div className="mt-10">
        <Button type="link" onClick={addRow}>
          + Add Raw Material
        </Button>
      </div>
    </Card>
  );
};

const mapStateToProps = (state: any) => ({
  itemsData: state.api.getItems?.data,
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
});

const mapDispatchToProps = (dispatch: any) => ({
  getItems: (payload: any) => dispatch(getItems(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BOMRawMaterial);
