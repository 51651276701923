import { Col, DatePicker, Input, Row, Select, Form, Button, Drawer, Alert, Space } from "antd";

import React, { useState, useEffect } from "react";
import moment from "moment";
import "./CustomMetaDataModal.css";
import TagInputField from "./TagInputField";
import { connect } from "react-redux";

interface CustomMetaDataModalProps {
  mode: "form" | "table";
  customField: CustomField[];
  currentField: CustomField | null;
  isVisible: boolean;
  onSave: (fields: CustomField[], category: string) => void;
  onEdit: (updatedField: CustomField) => void;
  onDelete: (key: string) => void;
  onClose: () => void;
  documentType: string;
}

interface CustomField {
  key: string;
  label: string;
  fieldType: "text" | "integer" | "select" | "datetime";
  options?: string[];
  defaultValue: any;
  level: "primary" | "item";
  createdBy: string;
  createdAt: string;
  category: string;
}

const CustomMetaDataModal: React.FC<CustomMetaDataModalProps> = ({
  mode,
  onSave,
  onClose,
  isVisible,
  customField,
  documentType
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [fields, setFields] = useState<CustomField[]>(customField);
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const [newFieldType, setNewFieldType] = useState<"text" | "integer" | "select" | "datetime">("text");
  const [newFieldLabel, setNewFieldLabel] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newFieldOptions, setNewFieldOptions] = useState<string[]>([]);
  const [defaultValue, setDefaultValue] = useState("");
  const [isEditingIndex, setIsEditingIndex] = useState<number>();
  const [category, setCategory] = useState<string>("Sales Enquiry");
  const [isPageRefreshed, setIsPageRefreshed] = useState<boolean>(false);
  const [level, setLevel] = useState<"primary" | "item">("primary");
  const [form] = Form.useForm();

  useEffect(() => {
    setFields(customFields);
  }, [customFields]);

  const getStorageKey = () => mode === "form" ? `customFields_form_${category}` : `customFields_table_${category}`;

  useEffect(() => {
    if (!isEditing) {
      const savedFields = localStorage.getItem(getStorageKey());
      if (savedFields) {
        const parsedFields = JSON.parse(savedFields);
        setCustomFields(parsedFields);
        setFields(parsedFields);
      }
    }
  }, [category, mode, isEditing]);

  const handleCustomModal = () => {
    form
      .validateFields()
      .then(() => {
        if (newFieldLabel.trim() && newFieldType) {
          if (newFieldType === "select" && newFieldOptions.length === 0) {
            alert("Please add options for the select field.");
            return;
          }
  
          const newFieldKey = `customField_${customFields.length + 1}`;
          const formattedDefaultValue =
            newFieldType === "datetime" && defaultValue
              ? moment(defaultValue).format("YYYY-MM-DD HH:mm:ss")
              : defaultValue;
  
          const newField: CustomField = {
            key: newFieldKey,
            label: newFieldLabel,
            fieldType: newFieldType,
            options: newFieldType === "select" ? newFieldOptions : undefined,
            defaultValue: formattedDefaultValue,
            level,
            createdBy: "System",
            createdAt: new Date().toISOString(),
            category,
          };
  
          const updatedFields = isEditing
            ? customFields.map((field, index) =>
                index === isEditingIndex ? newField : field
              )
            : [...customFields, newField];
  
          setFields(updatedFields);
          setCustomFields(updatedFields);
          localStorage.setItem(getStorageKey(), JSON.stringify(updatedFields));
          onSave(updatedFields, category);
          resetForm();
          setCustomFields(updatedFields);
          setIsEditing(false);
          setIsModalVisible(false); 
        }
      })
      .catch((error) => {
        console.error("Form validation failed:", error); 
      });
  };

  const resetForm = () => {
    setNewFieldLabel("");
    setNewFieldType("text");
    setNewFieldOptions([]);
    setDefaultValue("");
    setIsEditing(false);
    setIsEditingIndex(undefined);
    setIsModalVisible(false);
  };

  const handleLoadFields = () => {
    const savedFields = localStorage.getItem(`customFields_${category}`);
    if (savedFields) {
      setCustomFields(JSON.parse(savedFields));
      setIsPageRefreshed(true);
    }
  };

  const handleFieldOptionsChange = (tags: string[]) => {
    setNewFieldOptions(tags);  
  };

  return (
    <div>
      <Drawer
        width={600}
        title="Custom Field"
        onClose={onClose}
        open={isVisible}
        extra={
          <Space>
            <Button type="default" onClick={onClose}> Cancel </Button>
            <Button type="primary" onClick={handleCustomModal}> {isEditing ? "Update" : "Submit"} </Button>
          </Space>
        }
      >
        <Form onFinish={handleCustomModal} form={form} layout="vertical">
          <>
            <Alert
              type="info"
              message="Add the customize field for primary level and column for item level, if you want to add any specific data."
            />
            <div className="category-container mt-10 ml-10">
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="Select Document Type"
                    name="category"
                    rules={[{ required: true, message: "Please select a document!" }]}
                  >
                    <Select
                      value={category}
                      placeholder="Select document type"
                      onChange={(value) => setCategory(value)}
                      options={[
                        { value: "Sales Enquiry", label: "Sales Enquiry" },
                        {
                          value: "Sales Quotation",
                          label: "Sales Quotation",
                        },
                        {
                          value: "Order Confirmation",
                          label: "Order Confirmation",
                        },
                        {
                          value: "Delivery Challan",
                          label: "Delivery Challan",
                        },
                        { value: "Invoice", label: "Invoice" },
                        { value: "Sales Return", label: "Sales Return" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Level"
                    name="level"
                    rules={[{ required: true, message: "Please select a level!" }]}
                  >
                    <Select
                      placeholder="Select level"
                      value={level}
                      onChange={(value) => setLevel(value as "primary" | "item")}
                      options={[
                        { value: "primary", label: "Primary" },
                        { value: "item", label: "Item" },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="Field Name"
                    name="fieldName"
                    rules={[{ required: true, message: "Please enter a field name!" }]}
                  >
                    <Input
                      placeholder="Enter field name"
                      value={newFieldLabel}
                      onChange={(e) => setNewFieldLabel(e.target.value)}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Data Type"
                    name="dataType"
                    rules={[{ required: true, message: "Please select a data type!" }]}
                  >
                    <Select
                      placeholder="Select field type"
                      onChange={(value) => setNewFieldType(value)}
                      value={newFieldType}
                      options={[
                        { value: "text", label: "Text" },
                        { value: "number", label: "Number" },
                        { value: "select", label: "Select" },
                        { value: "datetime", label: "Date" },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="Default Value"
                    name="defaultValue" 
                  >
                    {newFieldType === "datetime" ? (
                      <DatePicker
                      value={defaultValue ? moment(defaultValue, "YYYY-MM-DD") : null}
                        onChange={(date, dateString) => {
                          if (date && moment(date).isValid()) {
                            setDefaultValue(dateString as string);
                          } else {
                            setDefaultValue("");
                          }
                        }}
                        format="YYYY-MM-DD"
                      />
                    ) : (
                      <Input
                        placeholder="Default value"
                        value={defaultValue}
                        onChange={(e) => setDefaultValue(e.target.value)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  {newFieldType === "select" && (
                   <Form.Item
                   label="Options"
                   name="options"
                   rules={[{ required: newFieldType === "select", message: "Please enter options for the select field!" }]}
                 >
                   {newFieldType === "select" ? (
                     <TagInputField 
                     value={newFieldOptions}
                     onChange={handleFieldOptionsChange}
                   />
                   ) : null}
                 </Form.Item> 
                  )}
                </Col>
              </Row>
            </div>
          </>
        </Form>

        {isPageRefreshed && (
          <Button
            type="primary"
            onClick={handleLoadFields}
            style={{ marginTop: 16 }}
          >
            Load Saved Fields
          </Button>
        )}
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
  documentType: state.ui.createDocumentUIData.documentType, 
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CustomMetaDataModal);

