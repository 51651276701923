import { connect } from "react-redux";
import React from "react";
import { Navigate } from "react-router-dom";
import { loginUser } from "../../../redux/actions/API/auth";
import { setAppLocation } from "../../../redux/actions/UI/appLocation";
import Signin from "../Signin";

interface PrivateRouteProps {
  children: React.ReactNode;
  loginData: any;
}

// const isAuthenticated = () => {
//     return !!localStorage.getItem('authToken')
// }

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, loginData }) => {
  return loginData?.data?.token ? <>{children}</> : <Navigate to="/login" />;
};

// export default PrivateRoute
const mapStateToProps = (state: any) => ({
  loginData: state.api.login,
});

const mapDispatchToProps = (dispatch: any) => ({
  setAppLocation: (payload: any) => dispatch(setAppLocation(payload)),
  loginUser: (payload: any) => dispatch(loginUser(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
