import React, { useEffect, useRef, useState } from "react";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import { getContactUs } from "../../../redux/actions/API/contactUsAction";
import { connect } from "react-redux";
import { Breadcrumb, Input, Layout, Pagination, Table } from "antd";
import noRecord from "../../../Utility/images/norecord.png";
import { Content } from "antd/es/layout/layout";
import Loader from "../../../Home/Loader/Loader";

interface IQueryCollectionProps {
  queryData: any[];
  getContactUs: Function;
  getContactUsState: IDataResponse;
}

const QueryCollection: React.FC<IQueryCollectionProps> = ({
  queryData,
  getContactUs,
  getContactUsState,
}) => {
  const prevPropsRef = useRef<IQueryCollectionProps>();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getContactUs();
  }, []);

  useEffect(() => {
    if (
      prevPropsRef?.current?.getContactUsState?.loading &&
      !getContactUsState?.loading
    ) {
      if (getContactUsState?.error) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    prevPropsRef.current = {
      queryData,
      getContactUs,
      getContactUsState,
    };
  }, [getContactUsState]);

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Query", dataIndex: "query", key: "query" },
  ];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredData = queryData?.filter(
    (query) =>
      query?.name?.toLowerCase().includes(searchTerm) ||
      query?.email?.toLowerCase().includes(searchTerm) ||
      query?.query?.toLowerCase().includes(searchTerm)
  );

  const paginatedData = filteredData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handlePaginationChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) setPageSize(pageSize);
  };

  return (
    <>
      <Loader loading={loading}></Loader>
      <Layout className="layout">
        <Breadcrumb items={[{ title: "Dashboard" }, { title: "Query" }]} />
        <Content className="content-section">
          <div className="flexBox">
            <h5 style={{ color: "#1890ff" }}>Customer Query</h5>
            <div className="flexBox">
              <Input placeholder="Search query" onChange={handleSearch} />
            </div>
          </div>

          {paginatedData?.length > 0 ? (
            <>
              <Table
                className="mt-10"
                columns={columns}
                dataSource={paginatedData?.map((item) => ({
                  ...item,
                  key: item.id,
                }))}
                pagination={false}
                bordered
              />
              <Pagination
                className="mt-10"
                current={currentPage}
                pageSize={pageSize}
                total={filteredData?.length}
                onChange={handlePaginationChange}
                showSizeChanger
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
              />
            </>
          ) : (
            <div className="noData">
              <img
                src={noRecord}
                alt="No Records Found"
                style={{ width: "auto", height: "300px" }}
              />
            </div>
          )}
        </Content>
      </Layout>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  queryData: state.api.getContactUs?.data?.data,
  getContactUsState: state.api?.getContactUs,
});

const mapDispatchToProps = (dispatch: any) => ({
  getContactUs: (payload: any) => dispatch(getContactUs(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QueryCollection);
