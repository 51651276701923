import React, { useEffect, useState } from "react";
import { Modal, Select, Form, Space, Button, Table, Col, Row } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { getUsers } from "../../../redux/actions/API/users";
import { getDocuments } from "../../../redux/actions/API/documents";
import noRecord from "../../../Utility/images/norecord.png";
import { TableRowSelection } from "antd/es/table/interface";
const { Option } = Select;

interface ISetupPermissionProps {
  usersData: any;
  getUsers: Function;
  getDocuments: Function;
  companyId: number;
  userId: number;
}

const SetupPermission: React.FC<ISetupPermissionProps> = ({
  usersData,
  getUsers,
  companyId,
  userId,
}) => {
  const [visible, setVisible] = useState(false);
  const [document, setDocument] = useState<string[]>([]);
  const [approval, setApproval] = useState<string[]>([]);
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState<any[]>([]);
  const [rows, setRows] = useState([{}]);
  const [selectedDocuments, setSelectedDocuments] = useState<{
    [key: number]: string;
  }>({});

  useEffect(() => {
    getUsers({ companyId: Number(companyId) });
    getDocuments({ companyId: Number(companyId) });
  }, [companyId]);

  const data = [
    { key: "1", title: "Document Type", dataIndex: "documentType" },
    { key: "2", title: "Permission By", dataIndex: "approvedBy" },
    { key: "3", title: "User", dataIndex: "user" },
    {
      key: "4",
      title: "Creation Date",
      dataIndex: "creationDate",
      render: (text: string) => new Date(text).toLocaleDateString(),
    },
    {
      key: "5",
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: any) => (
        <Space>
          <Button type="link" onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button type="link" danger onClick={() => handleDelete(record.key)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleAddRow = () => {
    setRows([...rows, {}]);
  };

  const handleDocumentChange = (index: number, value: string) => {
    setSelectedDocuments((prev) => ({ ...prev, [index]: value }));
  };

  const getFilteredDocuments = (currentIndex: number) => {
    const selected = Object.values(selectedDocuments).filter(
      (_, index) => index !== currentIndex
    );
    const allDocuments = [
      { value: "sales_quotation", label: "Sales Quotation" },
      { value: "order_confirmation", label: "Order Confirmation" },
      { value: "sales_invoice", label: "Sales Invoice" },
      { value: "purchase_order", label: "Purchase Order" },
    ];
    return allDocuments.filter((doc) => !selected.includes(doc.value));
  };

  const handleEdit = (record: any) => {
    console.log("Edit record:", record);
  };

  const handleDelete = (key: string) => {
    console.log("Delete record with key:", key);
    setTableData((prevData) => prevData.filter((item) => item.key !== key));
  };

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        console.log("Form Values:", values);
        const documents = Object.keys(values)
          .filter((key) => key.startsWith("document_"))
          .map((key) => values[key]);
        const approvals = Object.keys(values)
          .filter((key) => key.startsWith("approval_"))
          .map((key) => values[key]);

        if (!documents.length || !approvals.length) {
          console.error("Validation Failed: Missing required fields.");
          return;
        }

        const documentTypeMap: { [key: string]: string } = {
          sales_quotation: "Sales Quotation",
          order_confirmation: "Order Confirmation",
          sales_invoice: "Sales Invoice",
          purchase_order: "Purchase Order",
        };

        const newRows = documents.flatMap((doc, index) =>
          approvals[index].map((approvalId: string) => {
            const documentName = documentTypeMap[doc];
            const approvedByName = usersData?.find(
              (user: any) => user.id.toString() === approvalId
            )?.name;

            if (documentName && approvedByName) {
              return {
                key: `${Date.now()}-${doc}-${approvalId}`,
                documentType: documentName,
                approvedBy: approvedByName,
                user: userId,
                creationDate: new Date().toISOString(),
              };
            }
            return null;
          })
        );

        console.log("New Rows Data:", newRows);

        if (newRows.length > 0) {
          setTableData((prevData) => [...prevData, ...newRows.filter(Boolean)]);
          setVisible(false);
          form.resetFields();
        }
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
      });
  };

  const rowSelection: TableRowSelection<any> = {
    type: "checkbox",
  };

  const renderData = () => {
    if (tableData.length > 0) {
      return (
        <Table
          style={{ height: "400px", overflowY: "auto" }}
          sticky
          pagination={false}
          bordered
          rowSelection={rowSelection}
          dataSource={tableData}
          columns={data}
        />
      );
    } else {
      return (
        <img
          src={noRecord}
          alt="No Records Found"
          className="no-data-dimensions"
        />
      );
    }
  };

  return (
    <>
      <div className="mb-10">
        <Button type="link" onClick={showModal}>
          <PlusOutlined /> Set Permission
        </Button>
      </div>
      {renderData()}
      <Modal
        title="Set-up Permission"
        open={visible}
        onCancel={handleCancel}
        okText="Save"
        cancelText="Cancel"
        onOk={handleSave}
        maskClosable={false}
      >
        <Form form={form} layout="vertical" style={{ width: "100%" }}>
          {rows.map((row, index) => (
            <Row gutter={18} align="top" key={index}>
              <Col xs={24} sm={12} md={11}>
                <Form.Item
                  label="Select Documents"
                  name={`document_${index}`}
                  rules={[
                    { required: true, message: "Please select a document!" },
                  ]}
                >
                  <Select
                    placeholder="Choose documents"
                    onChange={(value) => handleDocumentChange(index, value)}
                  >
                    {getFilteredDocuments(index).map((doc) => (
                      <Option key={doc.value} value={doc.value}>
                        {doc.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={11}>
                <Form.Item
                  label="Approval By"
                  name={`approval_${index}`}
                  rules={[
                    {
                      required: true,
                      message: "Please assign approval to a user!",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Assign approval by"
                    value={approval}
                    onChange={setApproval}
                  >
                    {usersData &&
                      usersData.map((user: any) => (
                        <Option key={user.id} value={user.id.toString()}>
                          {user.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={2} className="mt-20">
                {index === 0 ? (
                  <Button
                    className="button-action"
                    icon={<PlusOutlined />}
                    onClick={handleAddRow}
                  />
                ) : (
                  <Button
                    className="button-action"
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => setRows(rows.filter((_, i) => i !== index))}
                  />
                )}
              </Col>
            </Row>
          ))}
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userId: state.api.login?.data?.id,
  companyId: state.api.login?.data?.companyId,
  usersData: state.api.getUsers?.data,
});

const mapDispatchToProps = (dispatch: any) => ({
  getUsers: (payload: any) => dispatch(getUsers(payload)),
  getDocuments: (payload: any) => dispatch(getDocuments(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetupPermission);
