import React, { useEffect, ChangeEvent, useRef, useState } from "react";
import { connect } from "react-redux";
import logo from "../../../Utility/images/ease/withBackground/company-logo-placeholder.avif";
import { notification, Tooltip } from "antd";
import { uploadImage } from "../../../redux/actions/API/imageUpload";
import { updateProfileLogo } from "../../../redux/actions/API/users";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import { jwtDecode } from "jwt-decode";

type NotificationType = "success" | "info" | "warning" | "error";
interface IProfileHeaderProps {
  profileData: any;
  uploadImage: Function;
  updateProfileLogo: Function;
  uploadImgState: any;
  updateProfileLogoState: IDataResponse;
}

const ProfileHeader: React.FC<IProfileHeaderProps> = ({
  profileData,
  uploadImage,
  updateProfileLogo,
  uploadImgState,
  updateProfileLogoState,
}) => {
  const prevPropsRef = useRef<any>();
  const [isUploading, setIsUploading] = useState(false);
  const [userData, setUserData] = useState<any>(null);

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

  useEffect(() => {
    setUserData(jwtDecode(profileData.token));
  }, []);
  
  useEffect(() => {
    if (
      prevPropsRef?.current?.uploadImgState?.loading &&
      !uploadImgState?.loading
    ) {
      if (uploadImgState?.error) {
        setIsUploading(false);
      } else {
        updateProfileLogo({
          userId: Number(profileData.id),
          profileURL: uploadImgState.imageUrl,
        });
      }
    }
    if (
      prevPropsRef?.current?.updateProfileLogoState?.loading &&
      !updateProfileLogoState?.loading
    ) {
      if (updateProfileLogoState?.error) {
        setIsUploading(false);
        openNotificationWithIcon("error", "Something went wrong! Please try again.");
      } else {
        setIsUploading(false);
        openNotificationWithIcon("success", "Profile logo updated successfully!");
      }
    }
    prevPropsRef.current = {
      profileData,
      uploadImage,
      updateProfileLogoState,
      updateProfileLogo,
      uploadImgState,
    };
  }, [uploadImgState, updateProfileLogoState,]);

  const handleLogoChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsUploading(true);
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append("file", file);
      uploadImage(formData);
    }
  };

  return (
    <div className="mb-50">
      <div className="profileCover"></div>
      <div className="profileLogoBody">
        <div className="logoAndDetails">
        <Tooltip title={isUploading ? "Changing logo..." : "Change Logo"}>
          <label htmlFor="logoInput" style={{ cursor: "pointer" }}>
            {isUploading ? (
              <div className="profileLogo shimmer"></div>
            ) : (
              <img className="profileLogo" src={userData?.profileURL || logo} alt="Logo" />
            )}
          </label>
        </Tooltip>
          <div className="mt-10">
            <div className="linkText">
              <div className="flexBox"></div>
              {profileData.companyName}
              <div></div>
            </div>
            <div>
              <div className="flexBox"></div>
              {profileData.email}
              <div></div>
            </div>
          </div>
        </div>
        <input
          type="file"
          accept="image/*"
          onChange={handleLogoChange}
          style={{ display: "none" }}
          id="logoInput"
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  profileData: state.api.login.data,
  uploadImgState: state.api.imageUpload,
  updateProfileLogoState: state.api.updateProfileLogo,
  uploadResponseURL: state.api.imageUpload.imageUrl,
});

const mapDispatchToProps = (dispatch: any) => ({
  uploadImage: (payload: any) => dispatch(uploadImage(payload)),
  updateProfileLogo: (payload: any) => dispatch(updateProfileLogo(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileHeader);
