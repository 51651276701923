import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Layout,
  Breadcrumb,
  Input,
  Button,
  Tabs,
  TabsProps,
  Avatar,
  List,
  Dropdown,
  Menu,
  Tag,
} from "antd";
import { Content } from "antd/es/layout/layout";
import "./Timeline.css";
import {
  CheckCircleOutlined,
  FileOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import cardData from "../utility/timelineData.json";
import purchaseData from "../utility/purchaseTimeline.json";
import JoditEditor from "jodit-react";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { formatDate } from "../utility/reportsUtility";
import { getUsers } from "../../redux/actions/API/users";
import EntitySelectionForm from "./Documents/EntitySelectionForm";
import { setDocumentCompanyData } from "../../redux/actions/UI/createDocument";
import { useNavigate } from "react-router-dom";
import {
  purchaseDocumentLabels,
  purchaseTimeline,
  salesDocumentLabels,
  salesTimeline,
} from "../utility/timelineUtility";
import { AppConstants } from "../../Appconstants";

interface ITimelineProps {
  timelineType: string;
  loginData: any;
  usersData: any;
  companyId: number;
  getUsers: Function;
  setDocumentCompanyData: Function;
}

const Timeline: React.FC<ITimelineProps> = ({
  timelineType,
  loginData,
  usersData,
  companyId,
  getUsers,
  setDocumentCompanyData,
}) => {
  const editorRef = useRef(null);
  const [commentsByDocument, setCommentsByDocument] = useState<{
    [key: string]: {
      id: string;
      text: string;
      mentions: string[];
      author: string;
      dateTime: string;
      replies: any[];
    }[];
  }>({});
  const [editorContent, setEditorContent] = useState("");
  const [isEditorFocused, setIsEditorFocused] = useState(false);
  const [replyTo, setReplyTo] = useState<{
    id: string;
    author: string;
    text: string;
    dateTime: string;
  } | null>(null);
  const [mentionDropdownVisible, setMentionDropdownVisible] = useState(false);
  const [filteredMentions, setFilteredMentions] = useState<any[]>([]);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [docType, setDocType] = useState<string>("");
  const [data, setData] = useState<any[]>([]);
  const navigate = useNavigate();
  const url = window.location.href;

  useEffect(() => {
    getUsers({ companyId: Number(companyId) });
  }, [companyId, getUsers]);

  useEffect(() => {
    if (url.includes("purchase")) {
      setData(purchaseData);
      setDocumentType("Purchase");
    } else {
      setData(cardData);
      setDocumentType(AppConstants.TIMELINE.SALES);
    }
  }, [url]);

  const handleDocumentClick = (docType: string) => {
    setModalTitle(docType);
    setDocumentType(docType);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalTitle("");
    setDocumentType("");
  };

  const onSubmit = (companyData: any) => {
    setDocumentCompanyData(companyData);
    navigate("/create_document?documentType=" + docType);
  };

  const shouldDisableHandler = (step: string): boolean => {
    const enabledSteps = ["Sales Enquiry", "Sales Quotation"];
    return !enabledSteps.includes(step);
  };

  const getInitials = (email: string) => {
    if (!email) return "";
    return email.charAt(0).toUpperCase();
  };

  const mentionItems = useMemo(() => {
    if (!usersData) return [];
    return (usersData || [])
      .filter((user: any) => user?.id && user?.email && user?.name)
      .map((user: any) => ({
        id: user.id,
        value: user.email,
        name: user.name,
      }));
  }, [usersData]);

  const handleKeyDown = (event: any) => {
    const selection = window.getSelection();
    if (!selection || !editorRef.current) return;

    const editorElement = (editorRef.current as any)?.editor;
    const range = selection.getRangeAt(0);

    if (event.key === "@") {
      const rect = range.getBoundingClientRect();
      setCursorPosition({ x: rect.left, y: rect.bottom });
      setFilteredMentions(mentionItems); // Reset to all mentions when "@" is typed
      setMentionDropdownVisible(true);
    } else if (mentionDropdownVisible) {
      // Get the text after "@" and filter mentions
      const textAfterAt = editorContent.split("@").pop()?.trim();
      if (textAfterAt) {
        const filtered = mentionItems.filter(
          (item: any) =>
            item.value.toLowerCase().includes(textAfterAt.toLowerCase()) // Filtering by user input after "@"
        );
        setFilteredMentions(filtered);
      }
    }
  };

  const handleMentionSelect = (mention: any) => {
    const contentWithMention = `@${mention.value} `;
    setEditorContent(contentWithMention);
    setMentionDropdownVisible(false);
  };

  const renderDropdown = () => (
    <Dropdown
      open={mentionDropdownVisible}
      overlay={
        <Menu style={{ overflowY: "scroll", height: "250px" }}>
          {filteredMentions.map((mention) => {
            const getAvatarColor = (value: string) => {
              const colors = [
                "#F56A00",
                "#7265E6",
                "#FFBF00",
                "#00A2AE",
                "#FF4D4F",
                "#36CFC9",
                "#40A9FF",
              ];
              const index =
                Math.abs(
                  value
                    .split("")
                    .reduce((acc, char) => acc + char.charCodeAt(0), 0)
                ) % colors.length;
              return colors[index];
            };

            return (
              <Menu.Item
                key={mention.id}
                onClick={() => handleMentionSelect(mention)}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Avatar
                    style={{ backgroundColor: getAvatarColor(mention.value) }}
                  >
                    {getInitials(mention.value)}
                  </Avatar>
                  <div>
                    <div style={{ fontSize: "12px" }}>{mention.name}</div>
                    <div style={{ fontSize: "12px" }}>{mention.value}</div>
                  </div>
                </div>
              </Menu.Item>
            );
          })}
        </Menu>
      }
      trigger={["click"]}
    >
      <div
        style={{
          position: "absolute",
          left: cursorPosition.x,
          top: cursorPosition.y,
          zIndex: 9999,
        }}
      />
    </Dropdown>
  );

  const config = useMemo(
    () => ({
      readonly: false,
      toolbar: isEditorFocused,
      buttons: ["bold", "italic", "underline", "strikethrough"],
      toolbarSticky: false,
      toolbarAdaptive: false,
      height: "90px",
      width: "100%",
      spellcheck: true,
      minHeight: 30,
      minWidth: 1140,
      allowResizeX: false,
      allowResizeY: false,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      placeholder: "Write your comment and tag using '@'...",
      plugins: ["mention"],
      mention: {
        separator: " ",
        delimiters: ["@"],
        trigger: "@",
        insert: (item: any) => {
          return `@${item.value}`;
        },
        render: (item: any) => `<div>${item.value}</div>`,
        items: (query: string, callback: (items: any[]) => void) => {
          getUsers()
            .then((response: any) => {
              switch (true) {
                case !!response?.data:
                  const filteredItems = response.data
                    .filter(
                      (user: any) =>
                        user.name
                          ?.toLowerCase()
                          .includes(query.toLowerCase()) ||
                        user.email?.toLowerCase().includes(query.toLowerCase())
                    )
                    .map((user: any) => ({
                      id: user.id,
                      value: user.email,
                      name: user.name,
                    }));
                  callback(filteredItems);
                  break;

                default:
                  console.warn("No data found in response.");
                  callback([]);
                  break;
              }
            })
            .catch((error: any) => {
              console.error("Error fetching mentions:", error);
              callback([]);
            });
        },
      },
      events: {
        focus: () => {
          setIsEditorFocused(true);
        },
        blur: () => {
          if (!editorContent.trim()) {
            setIsEditorFocused(false);
          }
        },
        keydown: handleKeyDown,
      },
    }),
    [companyId, getUsers, isEditorFocused]
  );

  const handleReply = (comment: any) => {
    setReplyTo(comment);
    setIsEditorFocused(true);
    setEditorContent("");
  };

  const handleSendReply = (documentNumber: string) => {
    if (!editorContent.trim() || !replyTo) return;

    const plainTextContent = stripHtmlTags(editorContent);
    const newReply = {
      id: uuidv4(),
      text: plainTextContent,
      author: loginData?.data?.email || "Anonymous",
      dateTime: new Date().toLocaleString(),
      replies: [],
    };

    setCommentsByDocument((prevComments) => {
      return {
        ...prevComments,
        [documentNumber]: prevComments[documentNumber].map((comment) =>
          comment.id === replyTo.id
            ? {
                ...comment,
                replies: [
                  ...comment.replies,
                  { ...newReply, repliedTo: replyTo.author },
                ],
              }
            : comment
        ),
      };
    });

    setEditorContent("");
    setIsEditorFocused(false);
    setReplyTo(null);
  };

  const handleCancel = () => {
    setEditorContent("");
    setIsEditorFocused(false);
    setReplyTo(null);
  };

  const stripHtmlTags = (content: string) => {
    const div = document.createElement("div");
    div.innerHTML = content;
    return div.textContent || div.innerText || "";
  };

  const handleSend = (documentNumber: string) => {
    if (!editorContent.trim()) return;
    const plainTextContent = stripHtmlTags(editorContent);
    const mentionRegex = /@(\w+)/g;
    const mentions = Array.from(plainTextContent.matchAll(mentionRegex)).map(
      (match) => match[1]
    );
    const newComment = {
      id: uuidv4(),
      text: plainTextContent,
      mentions,
      author: loginData?.data?.email || "Anonymous",
      dateTime: new Date().toLocaleString(),
      replies: [],
    };

    setCommentsByDocument((prevComments) => {
      return {
        ...prevComments,
        [documentNumber]: [...(prevComments[documentNumber] || []), newComment],
      };
    });
    setEditorContent("");
    setIsEditorFocused(false);
    setReplyTo(null);
  };

  const documentLabels =
    documentType === "Purchase" ? purchaseDocumentLabels : salesDocumentLabels;

  const disabledTabs = ["OC", "DC", "INV", "SR"];

  const onChange = (key: string) => {
    console.log(key);
  };

  const renderTabContent = (documentNumber: string) => {
    const documentData = (
      documentType === AppConstants.TIMELINE.SALES ? cardData : purchaseData
    ).find((doc) => doc.documentNumber === documentNumber);

    if (!documentData) return <div>No data available for this document.</div>;
    const comments = commentsByDocument[documentNumber] || [];
    return (
      <div className="mt-20">
        <div className="timelineDetails flexBox">
          <div className="flexBox" style={{ width: "100%" }}>
            <div className="title">
              {documentLabels[documentData.documentNumber.split("-")[0]]}:{" "}
              <span className="backLinkSM">{documentData.documentNumber}</span>
            </div>
            <div>
              <div className="title">Created By: {documentData.createdBy}</div>
              <div className="title">Created Date: {documentData.dateTime}</div>
            </div>
          </div>
        </div>

        <div className="documentDetails mt-20">
          {documentData.details.map((detail, index) => (
            <div key={index} className="title">
              {detail.label}: {detail.value}
            </div>
          ))}
        </div>

        <div className="commentList mt-20">
          <List
            itemLayout="horizontal"
            locale={{ emptyText: "Start Conversation" }}
            dataSource={comments}
            renderItem={(item: any) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar>{getInitials(item.author)}</Avatar>}
                  title={item.author || "Anonymous"}
                  description={
                    <>
                      <div style={{ fontSize: "0.85em" }}>
                        {item.createdAt
                          ? formatDate(item.createdAt)
                          : new Date().toLocaleString()}
                      </div>
                      <div style={{ color: "black" }}>{item.text}</div>
                      <Button
                        type="link"
                        onClick={() => handleReply(item)}
                        style={{ marginLeft: "-12px" }}
                      >
                        Reply
                      </Button>
                      {item.replies?.length > 0 && (
                        <div style={{ marginTop: "10px" }}>
                          {item.replies.map((reply: any) => (
                            <div
                              key={reply.id}
                              style={{
                                marginTop: "10px",
                                display: "flex",
                                marginRight: "30px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <Avatar className="mt-10">
                                  {getInitials(reply.author)}
                                </Avatar>
                                <div style={{ marginLeft: "15px" }}>
                                  <div
                                    style={{
                                      margin: "0px",
                                      color: "black",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {reply.author}{" "}
                                    <span
                                      style={{
                                        color: "#888",
                                        fontSize: "12px",
                                      }}
                                    >
                                      (Replied to{" "}
                                      {replyTo?.author || "Anonymous"})
                                    </span>
                                  </div>
                                  <div
                                    style={{ color: "#888", fontSize: "12px" }}
                                  >
                                    {reply.dateTime}
                                  </div>
                                  <div>{reply.text}</div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  }
                />
              </List.Item>
            )}
          />
        </div>

        <div className="editor mt-20">
          <div className="title">Discussion</div>
          <div style={{ display: "flex", columnGap: "20px" }} className="mt-10">
            <Avatar>{getInitials(loginData?.data?.email)}</Avatar>
            <JoditEditor
              ref={editorRef}
              value={editorContent}
              config={config}
              className="editor"
              onBlur={() => setIsEditorFocused(true)}
              onChange={(newContent) => setEditorContent(newContent)}
            />
            {mentionDropdownVisible && renderDropdown()}
          </div>
          {isEditorFocused && (
            <div className="button-group mt-10">
              <Button onClick={handleCancel}>Cancel</Button>
              <Button
                type="primary"
                onClick={() =>
                  replyTo
                    ? handleSendReply(documentData.documentNumber)
                    : handleSend(documentData.documentNumber)
                }
                className="ml-10"
                disabled={!editorContent.trim()}
              >
                Save
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };

  const items: TabsProps["items"] = data.map((doc) => {
    const prefix = doc.documentNumber.split("-")[0];
    const label = documentLabels[prefix] || "Unknown Document";
    console.log(documentLabels, "571");
    return {
      key: doc.documentNumber,
      label,
      children: renderTabContent(doc.documentNumber),
      disabled: disabledTabs.includes(prefix),
    };
  });

  const renderComments = (comments: any[]) =>
    comments.map((comment) => (
      <div key={comment.id} style={{ marginBottom: "10px" }}>
        <div>
          <Avatar>{getInitials(comment.author)}</Avatar>
          <div style={{ flex: 1 }}>
            <p style={{ margin: 0, fontWeight: "bold" }}>{comment.author}</p>
            <p style={{ color: "#888", fontSize: "13px" }}>
              {comment.dateTime}
            </p>
            <p>
              {comment.text.split(" ").map((word: any, wordIndex: any) =>
                word.startsWith("@") ? (
                  <span key={wordIndex} style={{ color: "blue" }}>
                    {word}
                  </span>
                ) : (
                  `${word} `
                )
              )}
            </p>
            <Button type="link" onClick={() => handleReply(comment)}>
              Reply
            </Button>
          </div>
        </div>
        {comment.replies.length > 0 && (
          <div>{renderComments(comment.replies)}</div>
        )}
      </div>
    ));

  const timelineData =
    documentType === AppConstants.TIMELINE.SALES
      ? salesTimeline
      : purchaseTimeline;

  return (
    <Layout className="layout">
      <div>
        <Breadcrumb
          style={{
            margin: "16px 0",
            flex: 1,
          }}
        >
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item>
            {documentType === "Purchase"
              ? "Purchase"
              : AppConstants.TIMELINE.SALES}
          </Breadcrumb.Item>
          <Breadcrumb.Item>Timeline</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Content className="content-section parent-div">
        <div className="textDocument">Timeline</div>
        <p>
          The sales process typically begins with a sales enquiry, where a
          customer expresses interest in a product or service. This is followed
          by the creation of a sales quotation, detailing prices and terms. Upon
          customer approval, the order is confirmed with an order confirmation.
          The product is then dispatched with a delivery challan, signifying
          proof of delivery. After successful delivery, an invoice is issued for
          payment. If the customer finds defects or has issues, a sales return
          may occur, completing the transaction cycle.
        </p>
        <div className="timelineDetailsRow">
          <div className="timelineCard invoiceStatusCard">
            Invoice Status:
            <br />
            Pending
          </div>
          <div className="timelineCard goodStatusCard">
            Goods Status:
            <br />
            Not Delivered
          </div>
          <div className="timelineCard companyCard">
            Company Name: <br />
            <span className="backLinkSM">Datronix Digital Pvt Ltd.</span>
          </div>
          <div className="timelineCard dateCard">
            Created At:
            <br /> 15-12-2024
          </div>
        </div>
        <div className="timelineParent mt-10">
          <div
            className={
              shouldDisableHandler("Sales Enquiry")
                ? "timelineDisableHandler"
                : ""
            }
          >
            <div className="timelineItem">
              <div className="textCenter">
                <div className="strongTitle">Placeholder</div>
                <div>Date: 16-12-2024</div>
                {/* <div>Created Document</div>
                <div>Created By - xyz</div> */}
              </div>
              <div className="mt-20">
                <span className="timelineHeading yellow yellowColor">
                  {documentType === AppConstants.TIMELINE.SALES
                    ? "Sales Enquiry"
                    : "Purchase Order"}
                </span>
              </div>
              <div className="connectingLineUp"></div>
              <div className="timelineArrowYellow mt-10 yellow yellowBackground"></div>
              <div
                className="docText"
                onClick={() => handleDocumentClick("Sales Enquiry")}
              >
                {documentType === AppConstants.TIMELINE.SALES
                  ? "+ Sales Enquiry"
                  : "+ Purchase Order"}
              </div>
            </div>
          </div>

          <div
            className={
              shouldDisableHandler("Sales Quotation")
                ? "timelineDisableHandler"
                : "timelineItem"
            }
          >
            <div
              className="docText"
              onClick={() => handleDocumentClick("Sales Quotation")}
            >
              {documentType === AppConstants.TIMELINE.SALES
                ? "+ Sales Quotation"
                : "+ Advance Receipt"}
            </div>
            <div className="timelineArrowGreen mt-10 GreenBackground"></div>
            <div className="connectingLineDown"></div>
            <div className="">
              <div className="timelineHeading GreenColor">
                {documentType === AppConstants.TIMELINE.SALES
                  ? "Sales Quotation"
                  : "Advance Receipt"}
              </div>
            </div>
            <div className="textCenter mt-10">
              <div className="strongTitle">Placeholder</div>
              <div>Date: 16-12-2024</div>
              {/* <div>Created Document</div>
              <div>Created By - xyz</div> */}
            </div>
          </div>

          <div className="timelineDisableHandler">
            <div className="timelineItem disabled">
              <div className="textCenter">
                <div className="strongTitle">Placeholder</div>
                <div>Date: 16-12-2024</div>
                {/* <div>Created Document</div>
                <div>Created By - xyz</div> */}
              </div>
              <div className="mt-20">
                <span className="timelineHeading mt-10 blue blueColor">
                  {documentType === AppConstants.TIMELINE.SALES
                    ? "Order Confirmation"
                    : "Goods Received Note"}
                </span>
              </div>
              <div className="connectingLineUp"></div>
              <div className="timelineArrowBlue mt-10 blue blueBackground"></div>
              <div
                className="docText"
                onClick={() => handleDocumentClick("Order Confirmation")}
              >
                {documentType === AppConstants.TIMELINE.SALES
                  ? "+ Order Confirmation"
                  : "+ Goods Received Note"}
              </div>
            </div>
          </div>

          <div className="timelineItem timelineDisableHandler">
            <div
              className="docText"
              onClick={() => handleDocumentClick("Advance Receipt")}
            >
              {documentType === AppConstants.TIMELINE.SALES
                ? "+ Advance Receipt"
                : "+ Quality Report"}
            </div>
            <div className="timelineArrowPurple mt-10 purpleBackground"></div>
            <div className="connectingLineDown"></div>
            <div className="">
              <div className="timelineHeading purpleColor">
                {documentType === AppConstants.TIMELINE.SALES
                  ? "Advance Receipt"
                  : "Quality Report"}
              </div>
            </div>
            <div className="textCenter mt-10">
              <div className="strongTitle">Placeholder</div>
              <div>Date: 16-12-2024</div>
              {/* <div>Created Document</div>
              <div>Created By - xyz</div> */}
            </div>
          </div>

          <div className="timelineDisableHandler">
            <div className="timelineItem">
              <div className="textCenter">
                <div className="strongTitle">Placeholder</div>
                <div>Date: 16-12-2024</div>
                {/* <div>Created Document</div>
                <div>Created By - xyz</div> */}
              </div>
              <div className="mt-20">
                <span className="timelineHeading mt-10 pink pinkColor">
                  {documentType === AppConstants.TIMELINE.SALES
                    ? "Delivery Challan"
                    : "Purchase Invoice"}
                </span>
              </div>
              <div className="connectingLineUp"></div>
              <div className="timelineArrowPink mt-10 blue pinkBackground"></div>
              <div
                className="docText"
                onClick={() => handleDocumentClick("Invoice")}
              >
                {documentType === AppConstants.TIMELINE.SALES
                  ? "+ Delivery Challan"
                  : "+ Purchase Invoice"}
              </div>
            </div>
          </div>

          <div className="timelineItem timelineDisableHandler">
            <div
              className="docText"
              onClick={() => handleDocumentClick("Sales Return")}
            >
              {documentType === AppConstants.TIMELINE.SALES
                ? "+ Invoice"
                : "+ Purchase Return"}
            </div>
            <div
              className={` ${
                documentType === AppConstants.TIMELINE.SALES
                  ? "timelineArrowFinal"
                  : "timelineArrowFinalPurchase"
              } mt-10 finalBackground`}
            ></div>
            <div className="connectingLineDown"></div>
            <div className="">
              <span className="timelineHeading finalColor">
                {documentType === AppConstants.TIMELINE.SALES
                  ? "Invoice"
                  : "Purchase Return"}
              </span>
            </div>
            <div className="textCenter mt-10">
              <div className="strongTitle">Placeholder</div>
              <div>Date: 16-12-2024</div>
            </div>
          </div>

          {documentType === AppConstants.TIMELINE.SALES ? (
            <div className="timelineDisableHandler">
              <div className="timelineItem">
                <div className="textCenter">
                  <div className="strongTitle">Placeholder</div>
                  <div>Date: 16-12-2024</div>
                </div>
                <div className="mt-20">
                  <span className="timelineHeading mt-10 magentaColor">
                    Sales Return
                  </span>
                </div>
                <div className="connectingLineUp"></div>
                <div className="timelineArrowMagenta mt-10 blue magentaBackground"></div>
                <div
                  className="docText"
                  onClick={() => handleDocumentClick("Invoice")}
                >
                  + Sales Return
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="timelineTags">
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>

        <EntitySelectionForm
          modelTitle={modalTitle}
          isModalOpen={isModalOpen}
          handleSubmit={onSubmit}
          handleCancel={handleCloseModal}
        />
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: any) => ({
  loginData: state.api.login,
  usersData: state.api.getUsers?.data?.reverse(),
  companyId: state.api.login?.data?.companyId,
});

const mapDispatchToProps = (dispatch: any) => ({
  getUsers: (payload: any) => dispatch(getUsers(payload)),
  setDocumentCompanyData: (payload: any) =>
    dispatch(setDocumentCompanyData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);
