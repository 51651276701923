import * as type from '../../types/API/termsConditions'

export function getTermsCondition(payload: any) {
    return {
        type: type.GET_TERMS_CONDITION,
        payload: payload
    }
}

export function addTermsCondition(payload: any) {
    return {
        type: type.ADD_TERMS_CONDITION,
        payload: payload
    }
}

export function editTermsCondition(payload: any) {
    return {
        type: type.EDIT_TERMS_CONDITION,
        payload: payload
    }
}

export function deleteTermsCondition(payload: any) {
    return {
        type: type.DELETE_TERMS_CONDITION,
        payload: payload
    }
}
