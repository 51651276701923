import { Card, Input, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { AppConstants } from "../../../../Appconstants";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { setAdditionalDetailsData } from "../../../../redux/actions/UI/createDocument";
const { TextArea } = Input;

interface IAdditionalDetailsProps {
  setAdditionalDetailsData: Function;
  additionalDetailsValue: any;
}

const AdditionalDetails: React.FC<IAdditionalDetailsProps> = ({
  setAdditionalDetailsData,
  additionalDetailsValue,
}) => {
  const [additionalDetails, setAdditionalDetails] = useState("");

  // Set initial state based on Redux data
  useEffect(() => {
    setAdditionalDetails(
      additionalDetailsValue?.length > 0 ? additionalDetailsValue[0] : ""
    );
  }, [additionalDetailsValue]);

  useEffect(() => {
    setAdditionalDetailsData([]);
  }, []);

  const handleBlur = () => {
    const newData = additionalDetails
      ?.toString()
      ?.split("\n")
      ?.filter((line: string) => line.trim() !== "");
    setAdditionalDetailsData(newData);
  };

  return (
    <>
      <Card className="custom-card no-header-border flex-grow-1">
        <div className="card-content">
          <div className="title mb-20 fS-20">
            {AppConstants.ADDITIONAL_DETAIL.ADDITIONAL_DETAILS_LABEL} &nbsp;
            <Tooltip
              title={AppConstants.ADDITIONAL_DETAIL.ADDITIONAL_DETAILS_LABEL}
            >
              <InfoCircleOutlined className="info-icon" />
            </Tooltip>
          </div>
          {AppConstants.ADDITIONAL_DETAIL.WRITE_ADDITIONAL_DETAIL}
          <TextArea
            style={{ resize: "none" }}
            rows={3}
            placeholder={AppConstants.ADDITIONAL_DETAIL.WRITE_ADDITIONAL_DETAIL}
            showCount
            maxLength={100}
            className="mb-20"
            value={additionalDetails}
            onChange={(e) => setAdditionalDetails(e.target.value)}
            onBlur={handleBlur}
          />
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  additionalDetailsValue: state.ui.createDocumentUIData?.additionalDetails,
});

const mapDispatchToProps = (dispatch: any) => ({
  setAdditionalDetailsData: (payload: any) => dispatch(setAdditionalDetailsData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalDetails);
