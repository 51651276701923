import { Breadcrumb, Button, Dropdown, Layout, Menu } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { getDocumentById } from "../../../redux/actions/API/documents";
import "./PreviewDocument.css";
import { Link } from "react-router-dom";
import {
  ArrowLeftOutlined,
  DownloadOutlined,
  PrinterOutlined,
  EditOutlined,
  MailOutlined,
  WhatsAppOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import logo from "../../../Utility/images/ease/transparent/ease-primary.png";
import DocumentSlicer from "./DocumentSlicer";
import noRecord from "../../../Utility/images/norecord.png";

interface IPreviewDocumentProps {
  getDocumentById: Function;
  dataToPreview: any;
  companyId: string;
  companyName: string;
}

const PreviewDocument: React.FC<IPreviewDocumentProps> = ({
  getDocumentById,
  dataToPreview,
  companyId,
  companyName,
}) => {
  const quotationRef = useRef<HTMLDivElement>(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 896);

  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 414);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const url = window.location.href;
    const params = new URLSearchParams(url.split("?")[1]);
    const documentNumber = params.get("documentNumber");
    if (documentNumber) {
      getDocumentById({ documentNumber, companyId: Number(companyId) });
    }
  }, [getDocumentById, companyId]);

  const downloadPDF = async () => {
    const element = quotationRef.current;
    if (element) {
      const html2pdf = (await import("html2pdf.js")).default;

      html2pdf()
        .from(element)
        .set({
          margin: 0,
          filename: "quotation.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        })
        .save();
    }
  };

  const editDocument = () => {
    // navigate("/create_document?documentType=salesQuotation");
  };

  const handleWhatsAppShare = () => {
    const message = encodeURIComponent(
      "Check out this amazing content! https://example.com"
    );
    const whatsappUrl = `https://wa.me/?text=${message}`;
    window.open(whatsappUrl, "_blank");
  };

  const breadcrumbItems = [
    "Dashboard",
    "Sales",
    "Documents",
    "Preview Document",
  ];

  const additionalItems = [
    ...(isMobileView
      ? [
          <Menu.Item key="ManageUOM">
            <Button
              type="link"
              onClick={() => {
                window.print();
              }}
            >
              <PrinterOutlined /> Print
            </Button>
          </Menu.Item>,
          <Menu.Item key="ManageUOM">
            <Button type="link">
              <MailOutlined /> Email
            </Button>
          </Menu.Item>,
          <Menu.Item key="ManageUOM">
            <Button type="link" onClick={handleWhatsAppShare}>
              <WhatsAppOutlined /> WhatsApp
            </Button>
          </Menu.Item>,
          <Menu.Item key="ManageUOM">
            <Button type="primary" onClick={editDocument} className="buttonEditDocument">
              <EditOutlined /> Edit
            </Button>
          </Menu.Item>,
        ]
      : []),
  ];

  const menu = <Menu>{additionalItems}</Menu>;

  return (
    <Layout className="layout">
      <Breadcrumb>
        {breadcrumbItems.map((item, index) => (
          <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <div className="flexBox mt-10 documentMobileRow">
        <div className="divDocuments Mt-10">
          <Link to="/sales_documents" className="linkToDocs">
            <p className="textDocument">
              <ArrowLeftOutlined /> {dataToPreview?.documentNumber}
            </p>
          </Link>
        </div>
        <div className="flexBox documentButtonRow">
          {!isMobileView ? (
            <>
              {dataToPreview?.status == 1 && (
                <>
                  <Button type="link" onClick={downloadPDF}>
                    <DownloadOutlined /> Download
                  </Button>
                  <Button
                    type="link"
                    onClick={() => {
                      window.print();
                    }}
                  >
                    <PrinterOutlined /> Print
                  </Button>
                  <Button type="link">
                    <MailOutlined /> Email
                  </Button>
                  <Button type="link" onClick={handleWhatsAppShare}>
                    <WhatsAppOutlined /> WhatsApp
                  </Button>
                </>
              )}
              <Button type="primary" onClick={editDocument}>
                <EditOutlined /> Edit
              </Button>
            </>
          ) : (
            <>
              <Button type="link" onClick={downloadPDF}>
                <DownloadOutlined /> Download
              </Button>
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button type="link" className="buttonAddItem">
                  <EllipsisOutlined />
                </Button>
              </Dropdown>
            </>
          )}
        </div>
      </div>
      {dataToPreview ? (
        <div
          className="documentContainer"
          id="documentContainer"
          ref={quotationRef}
        >
          {dataToPreview.status == 0 && <div className="watermark">Draft</div>}
          <DocumentSlicer
            dataToPreview={dataToPreview}
            companyName={companyName}
            logo={logo}
          />
        </div>
      ) : (
        <img
          src={noRecord}
          alt="No Records Found"
          style={{
            width: "auto",
            height: "300px",
          }}
        />
      )}
    </Layout>
  );
};

const mapStateToProps = (state: any) => ({
  companyId: state.api.login?.data?.companyId,
  dataToPreview: state.api.getDocumentById?.data,
  companyName: state.api.login.data.companyName,
});

const mapDispatchToProps = (dispatch: any) => ({
  getDocumentById: (payload: any) => dispatch(getDocumentById(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewDocument);
