import { Button, Checkbox, Col, Form, Input, notification, Row, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Footer from "../Footer";
import Loader from "../../Loader/Loader";
import Header from "../Header";
import { useLocation } from "react-router-dom";
import "./demoQuery.css";
import DemoContent from "./DemoContent";
import { connect } from "react-redux";
import { addDemoQueryData } from "../../../redux/actions/API/demoQueryAction";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";

const { TextArea } = Input;
const { Option } = Select;

interface IDemoQueryProps {
  addDemoQueryData: Function;
  addDemoQueryDataState: IDataResponse;
}

type NotificationType = "success" | "info" | "warning" | "error";
const DemoQuery: React.FC<IDemoQueryProps> = ({
  addDemoQueryData,
  addDemoQueryDataState,
}) => {
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const prevPropsRef = useRef<any>();
  const [sector, setSector] = useState("");
  const [loading, setLoading] = useState(false);

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

  useEffect(() => {
    const scrollToTop = () => {
      const element = document.getElementById("header");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };

    scrollToTop();
  }, [pathname]);

  useEffect(() => {
    if (
      prevPropsRef?.current?.addDemoQueryDataState?.loading &&
      !addDemoQueryDataState?.loading
    ) {
      if (addDemoQueryDataState?.error) {
        openNotificationWithIcon("error", "Something went wrong please try again later!");
        setLoading(false);
      } else {
        form.resetFields();
        openNotificationWithIcon("success", addDemoQueryDataState?.data?.message);
        setLoading(false);
      }
    }
    prevPropsRef.current = {
      addDemoQueryDataState,
      addDemoQueryData,
    };
  }, [addDemoQueryDataState]);

  const moduleOptions = [
    { label: "Free Invoicing", value: "freeInvoicing" },
    { label: "Inventory Management", value: "inventoryManagement" },
    { label: "Production Management", value: "productionManagement" },
  ];

  const handleSectorChange = (value: string) => {
    setSector(value);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const selectedModules = values.modules?.map(
          (val: string) =>
            moduleOptions.find((option) => option.value === val)?.label
        );

        const payload = {
          fullName: values.fullName,
          email: values.email,
          phone: values.phone,
          companyName: values.companyName,
          companyWebsite: values.companyWebsite,
          industry: values.sector === "other" ? values.otherSector : values.sector,
          companySize: values.companySize,
          currentERP: values.currentERP,
          modules: selectedModules,
          query: values.query,
          source: values.source,
          ip_address: null,
        };
        setLoading(true);
        addDemoQueryData(payload);
      })
      .catch(() => {
        openNotificationWithIcon("error", "Please fill all the required fields");
      });
  };

  return (
    <>
      <Loader loading={loading} />
      <Header />
      <div id="requestDemo">
        <section className="container mx-auto px-4 py-10 flex flex-col lg:flex-row gap-10">
          <div className="flex-1">
            <DemoContent />
          </div>
          <div className="flex-1 bg-white shadow-lg p-6 rounded-lg demoForm">
            <Form form={form} layout="vertical">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                  <Form.Item name="fullName" label="Full Name" rules={[{ required: true, message: "Please enter your full name" }]}>
                    <Input placeholder="Enter your full name" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item name="email" label="Email Address" rules={[{ required: true, type: "email", message: "Enter a valid email" }]}>
                    <Input placeholder="example@company.com" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                  <Form.Item name="phone" label="Phone Number" rules={[{ required: true, message: "Enter your phone number" }, { pattern: /^[6-9]\d{9}$/, message: "Enter a valid 10-digit number" }]}>
                    <Input prefix="+91 " placeholder="666-666-6666" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item name="companyName" label="Company Name" rules={[{ required: true, message: "Please enter company name" }]}>
                    <Input placeholder="Your Company Name" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                  <Form.Item name="companyWebsite" label="Company Website">
                    <Input placeholder="https://yourcompany.com" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item name="sector" label="Sector" rules={[{ required: true, message: "Please select a sector" }]}>
                    <Select placeholder="Select a sector" onChange={handleSectorChange}>
                      <Option value="manufacturing">Manufacturing</Option>
                      <Option value="trading">Trading</Option>
                      <Option value="other">Other</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              {sector === "other" && (
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Form.Item name="otherSector" label="Specify Sector" rules={[{ required: true, message: "Please specify the sector" }]}>
                      <Input placeholder="Enter your sector" />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                  <Form.Item name="companySize" label="Company Size" rules={[{ required: true, message: "Please select company size" }]}>
                    <Select placeholder="Select company size">
                      <Option value="1-10">1-10</Option>
                      <Option value="11-50">11-50</Option>
                      <Option value="51-200">51-200</Option>
                      <Option value="201-500">201-500</Option>
                      <Option value="500+">500+</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item name="currentERP" label="Current ERP System (if any)">
                    <Input placeholder="Enter ERP system name" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item name="modules" label="Interested Modules">
                    <Checkbox.Group>
                      <Checkbox value="freeInvoicing">Free Invoicing</Checkbox>
                      <Checkbox value="inventoryManagement">Inventory Management</Checkbox>
                      <Checkbox value="productionManagement">Production Management</Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item name="source" label="How Did You Hear About Us?" rules={[{ required: true, message: "This field is required" }]}>
                    <Select placeholder="Select an option">
                      <Option value="facebook">Facebook</Option>
                      <Option value="instagram">Instagram</Option>
                      <Option value="linkedin">LinkedIn</Option>
                      <Option value="website">Website</Option>
                      <Option value="referral">Referral</Option>
                      <Option value="other">Other</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item name="query" label="Query" rules={[{ required: true, message: "Please write your query." }]}>
                    <TextArea placeholder="Describe your requirements" rows={3} />
                  </Form.Item>
                </Col>
              </Row>

              <Button type="primary" onClick={handleSubmit} block className="mt-4">
                Request a Demo
              </Button>
            </Form>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  addDemoQueryDataState: state.api.addDemoQueryData,
});

const mapDispatchToProps = (dispatch: any) => ({
  addDemoQueryData: (payload: any) => dispatch(addDemoQueryData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DemoQuery);
