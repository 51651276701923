import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Checkbox, Col, Form, Grid, Input, notification, Row, Select, theme, Typography } from "antd";
import { LockOutlined, UserOutlined, MailOutlined, UsergroupAddOutlined, PhoneOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { setAppLocation } from "../../redux/actions/UI/appLocation";
import { forgotPassword, loginUser, resetPassword, signupUser } from "../../redux/actions/API/auth";
import { connect } from "react-redux";
import "./Signin.css";
import Loader from "../Loader/Loader";
import ease from "../../Utility/images/logo.png";
import Header from "./Header";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
import Footer from "./Footer";
type NotificationType = "success" | "info" | "warning" | "error";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text } = Typography;

const { Option } = Select;

export interface ISignInProps {
  loginUser: Function;
  loginData: IDataResponse;
  signupUser: Function;
  signupData: IDataResponse;
  forgotPassword: Function;
  forgotPasswordState: IDataResponse;
  resetPassword: Function;
  resetPasswordState: IDataResponse;
}

const Signin: React.FC<ISignInProps> = ({
  loginUser,
  loginData,
  signupUser,
  signupData,
  forgotPassword,
  resetPassword,
  resetPasswordState,
  forgotPasswordState,
}) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [name, setName] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [industryType, setIndustryType] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const prevPropsRef = useRef<any>();
  const [isForgetPassword, setIsForgetPassword] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showResetPasswordEmailConfirmation, setShowResetPasswordEmailConfirmation] = useState(false);

  const screens = useBreakpoint();
  const { token } = useToken();

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (prevPropsRef?.current?.loginData?.loading && !loginData?.loading) {
      if (!navigator.onLine) {
        openNotificationWithIcon(
          "error",
          "No internet connection! Please check your network."
        );
        setLoading(false);
        return;
      }
      if (loginData?.error) {
        openNotificationWithIcon("error", "Something went wrong!");
        setLoading(false);
        setHasError(true);
      } else {
        if (loginData?.data?.token) {
          navigate("/dashboard");
          openNotificationWithIcon("success", loginData?.data?.message);
        } else {
          setHasError(false);
        }
        setLoading(false);
      }
    }

    if (prevPropsRef?.current?.signupData?.loading && !signupData?.loading) {
      if (signupData?.error) {
        openNotificationWithIcon("error", signupData?.error?.message);
        setIsSignUp(true);
        setHasError(true);
        setLoading(false);
      } else {
        openNotificationWithIcon("success", signupData?.data?.message);
        form.resetFields();
        navigate("/login");
        setIsSignUp(false);
        setHasError(false);
        setLoading(false);
      }
    }

    if (
      prevPropsRef?.current?.forgotPasswordState?.loading && !forgotPasswordState?.loading
    ) {
      if (forgotPasswordState?.error) {
        openNotificationWithIcon("error", forgotPasswordState?.error?.message);
        setLoading(false);
      } else {
        setLoading(false);
        setShowResetPassword(true);
        setShowResetPasswordEmailConfirmation(true);
      }
    }

    if (prevPropsRef?.current?.resetPasswordState?.loading && !resetPasswordState?.loading) {
      if (resetPasswordState?.error) {
        openNotificationWithIcon("error", resetPasswordState?.error?.message);
        setLoading(false);
      } else {
        openNotificationWithIcon("success", resetPasswordState?.data?.message);
        setLoading(false);
        navigate("/login");
      }
    }
    prevPropsRef.current = {
      loginData,
      signupData,
      forgotPasswordState,
      resetPasswordState,
    };
  }, [loginData, signupData, forgotPasswordState, resetPasswordState]);

  const handleSignUp = () => {
    form
      .validateFields()
      .then((values) => {
        setHasError(false);
        setLoading(true);
        const signupPayload = {
          username: values.username,
          email: values.email,
          companyName: values.companyName,
          contactNo: values.contactNumber,
          businessType: values.businessType,
          password: values.password,
          name: values.name,
          role: '1'
        };
        signupUser(signupPayload);
      })
      .catch(() => {
        notification.error({ message: "Please fill in all required fields!" });
        setLoading(false);
      });
  };

  const handleLogIn = () => {
    form
      .validateFields()
      .then((values) => {
        setHasError(false);
        setLoading(true);

        loginUser({
          email: values.username,
          password: values.password,
        });
        navigate("/sign-in");
      })
      .catch(() => notification.error({ message: "Please enter your username and password!" }));
  };

  const handleForgotPassword = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        forgotPassword({ email: values.email });
      })
      .catch(() => {
        notification.error({
          message: "Please enter your email address!",
        });
        setLoading(false);
      });
  };

  const handleResetPassword = () => {
    form
      .validateFields()
      .then((values) => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        setLoading(true);
        return resetPassword({
          newPassword: values.newPassword,
          token: token,
        });
      })
      .catch(() => {
        notification.error({
          message: "Please enter your password, confirm password",
        });
      });
  };

  const resetPasswordConfirmation = () => {
    return (
      <>
        <Loader loading={loading}></Loader>
        <Link to="/">
          <img className="logo-ease dhide" src={ease} alt="sign" />
        </Link>
        <p style={{ marginTop: '10px' }} className="dhide">Automate your manufacturing workflow and enhance your margin with ease.</p>

        <div
          style={{
            padding: "16px",
            backgroundColor: "#D1FAE5",
            color: "#065F46",
            border: "1px solid #10B981",
            borderRadius: "8px",
          }}
        >
          <p style={{ fontSize: "18px", fontWeight: "bold" }}>
            Reset Password Link Sent
          </p>
          <p>Please check your registered email for the reset password link.</p>
        </div>

        <div className="flexBox mt-50">
          <div></div>
          <div>
            <span
              className="link"
              onClick={() => {
                setIsForgetPassword(false);
                navigate("/login");
              }}
            > <ArrowLeftOutlined /> Back to Sign In
            </span>
          </div>
        </div>
      </>
    );
  };

  const loginForm = () => {
    return (
      <>
        <Loader loading={loading}></Loader>
        <Link to="/">
          <img className="logo-ease dhide" src={ease} alt="sign" />
        </Link>
        <p style={{ marginTop: '10px' }} className="dhide">Automate your manufacturing workflow and enhance your margin with ease.</p>
        {/* {screens.xs && <h4 className="loginHeading">Welcome back</h4>} */}
        <h2 className="loginTitle">Login</h2>
        <small>Sign In to continue</small>
        <div className="formBody">
          {hasError && <div className="loginError">Invalid Credentials !</div>}
          <Form
            form={form}
            name="normal_login"
            initialValues={{ remember: true }}
            layout="vertical"
            requiredMark="optional"
          >
            <Form.Item
              name="username"
              rules={[
                {
                  type: "string",
                  required: true,
                  message: "Please enter your username!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder="Username"
                onChange={(e) => {
                  setUsername(e.target.value);
                  setHasError(false);
                }}
                className="authField"
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please enter your password!" },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setHasError(false);
                }}
                className="authField"
              />
            </Form.Item>
            <Form.Item>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>
                <span
                  className="link"
                  onClick={() => {
                    setIsForgetPassword(true);
                    navigate("/forgot-password");
                  }}
                >
                  Forgot password?
                </span>
              </div>
            </Form.Item>
            <Form.Item style={{ marginBottom: "0px" }}>
              <Button block={true} type="primary" onClick={handleLogIn} className="authField">
                Log in
              </Button>
              <div className="loginText" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text>Don't have an account?</Text>
                <span
                  className="link"
                  onClick={() => {
                    form.resetFields();
                    setIsSignUp(true);
                    setHasError(false);
                    navigate("/sign-up");
                  }}
                >
                  Sign up now
                </span>
              </div>
            </Form.Item>
          </Form>
        </div>
      </>
    );
  };

  const signUpForm = () => {
    return (
      <>
        <Loader loading={loading}></Loader>
        <Link to="/">
          <img className="logo-ease dhide" src={ease} alt="sign" />
        </Link>
        <p style={{ marginTop: '10px' }} className="dhide">Automate your manufacturing workflow and enhance your margin with ease.</p>
        {/* {screens.xs && <h4 className="loginHeading">Welcome back</h4>} */}
        <h2 className="loginTitle">Sign Up</h2>
        <div className="formBody">
          <Form
            form={form}
            name="normal_signup"
            layout="vertical"
            requiredMark="optional"
          >
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  label={<span>Company Name <span style={{ color: "red" }}>*</span></span>}
                  name="companyName"
                  rules={[{ required: true, message: "Please enter your company name!" }]}
                >
                  <Input
                    prefix={<UsergroupAddOutlined />}
                    placeholder="Please enter company name"
                    onChange={(e) => setCompanyName(e.target.value)}
                    value={companyName}
                    className="authField"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  label={<span>Business Type <span style={{ color: "red" }}>*</span></span>}
                  name="businessType"
                  rules={[{ required: true, message: "Please select business type!" }]}
                >
                  <Select
                    placeholder="Business Type"
                    onChange={(value) => setBusinessType(value)}
                    value={businessType}
                    className="authField"
                  >
                    <Option value="Manufacturer">Manufacturer</Option>
                    <Option value="Whole Seller">Whole Seller</Option>
                    <Option value="Trader">Trader</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  label={<span>Contact Person Name <span style={{ color: "red" }}>*</span></span>}
                  name="name"
                  rules={[{ required: true, message: "Please enter contact person name" }]}
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder="Contact person name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    className="authField"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  label={<span>Email <span style={{ color: "red" }}>*</span></span>}
                  name="email"
                  rules={[
                    { required: true, message: "Please enter email address!" },
                    {
                      pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Please enter a valid email address!",
                    },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined />}
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="authField"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  label={<span>Contact Number <span style={{ color: "red" }}>*</span></span>}
                  name="contactNumber"
                  rules={[
                    { required: true, message: "Please enter your contact number!" },
                    {
                      pattern: /^[6-9]\d{9}$/,
                      message: "Please enter a valid contact number!",
                    },
                  ]}
                >
                  <Input
                    prefix="+91 "
                    addonBefore={<PhoneOutlined />}
                    placeholder="Contact number"
                    onChange={(e) => setContactNumber(e.target.value)}
                    value={contactNumber}
                    className="authField"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  label={<span>Username <span style={{ color: "red" }}>*</span></span>}
                  name="username"
                  rules={[{ required: true, message: "Please enter your username!" }]}
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder="Username"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    className="authField"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  label={<span>Password <span style={{ color: "red" }}>*</span></span>}
                  name="password"
                  rules={[{ required: true, message: "Please enter your Password!" }]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    className="authField"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item style={{ marginBottom: "0px" }}>
              <Button block={true} type="primary" onClick={handleSignUp} className="authField">
                Sign up
              </Button>
              <div className="loginText" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text>Already have an account?</Text>
                <span
                  className="link"
                  onClick={() => {
                    setIsSignUp(false);
                    setHasError(false);
                    form.resetFields();
                    navigate("/sign-in");
                  }}
                >
                  Sign In
                </span>
              </div>
            </Form.Item>
          </Form>

        </div>
      </>
    );
  };

  const forgotPasswordForm = () => {
    return (
      <>
        <Loader loading={loading}></Loader>
        <Link to="/">
          <img className="logo-ease dhide" src={ease} alt="sign" />
        </Link>
        <p style={{ marginTop: '10px' }} className="dhide">Automate your manufacturing workflow and enhance your margin with ease.</p>
        <h2 className="loginTitle">Reset your password</h2>
        <div className="formBody">
          <Form
            form={form}
            name="forgot_password"
            layout="vertical"
            requiredMark="optional"
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please enter your email!" },
                {
                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Please enter a valid email address!",
                },
              ]}
            >
              <Input prefix={<MailOutlined />} placeholder="Email" className="authField" />
            </Form.Item>
            <Form.Item>
              <Button block type="primary" onClick={handleForgotPassword} className="authField">
                {loading ? "Sending..." : "Send Reset Link"}
              </Button>
            </Form.Item>
            <Form.Item>
              <div className="flexBox">
                <div></div>
                <div>
                  <span
                    className="link"
                    onClick={() => {
                      setIsForgetPassword(false);
                      navigate("/login");
                    }}
                  > <ArrowLeftOutlined /> Back to Sign In
                  </span>
                </div>
              </div>
            </Form.Item>
          </Form>
        </div>
      </>
    );
  };

  const resetPasswordForm = () => {
    return (
      <>
        <Loader loading={loading}></Loader>
        <h2 className="loginTitle">Reset Password</h2>
        <div className="formBody">
          <Form
            form={form}
            layout="vertical"
            requiredMark="optional"
          >
            <Form.Item
              name="newPassword"
              rules={[
                {
                  type: "string",
                  required: true,
                  message: "Please enter your Password!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              dependencies={["newPassword"]}
              rules={[
                {
                  required: true,
                  message: "Please re-enter your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Passwords do not match!"));
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                type="password"
                placeholder="Confirm Password"
              />
            </Form.Item>
            <Form.Item style={{ marginBottom: "0px" }}>
              <Button
                block={true}
                type="primary"
                onClick={handleResetPassword}
              >
                Change Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="mhide"><Header></Header></div>
      <div className="loginBox">
        {/* Left Side - Description */}
        <div className="left-section mhide">
          <h4>Welcome to EaseMargin</h4>
          <p>
            Streamline your manufacturing process, create documents for your
            sales and purchases, manage inventory, fetch real-time data, and
            generate reports as per requirement.
          </p>
          <h5 className="loginTitle">Why Choose Us?</h5>
          <ul>
            <li>
              <strong>Enhance Manufacturing Performance</strong>: Ease Margin's
              solution helps to improve productivity for small and medium-sized
              manufacturers.
            </li>
            <li>
              <strong>Comprehensive Features</strong>: Real-time inventory and
              production management, sales and purchase tracking, and effective
              user management.
            </li>
            <li>
              <strong>Powerful Reporting</strong>: Provides advanced and
              accurate reporting tools for making decisions based on data.
            </li>
            <li>
              <strong>Easy Integration</strong>: Connects with multiple
              accounting software and API access for integrations based on need.
            </li>
            <li>
              <strong>User-Friendly Experience</strong>: Provides an easy
              interface, customization options, and all-time high-quality user
              support for a seamless experience.
            </li>
          </ul>
        </div>

        {/* Right Side - Form Section */}
        <div className="right-section">
          {window.location.href.includes('sign-up') && <div className="auth-form-container">{signUpForm()}</div>}
          {window.location.href.includes('sign-in') && <div className="auth-form-container">{loginForm()}</div>}
          {window.location.href.includes('login') && <div className="auth-form-container">{loginForm()}</div>}
          {window.location.href.includes('forgot-password') && <div className="auth-form-container">{showResetPasswordEmailConfirmation ? resetPasswordConfirmation() : forgotPasswordForm()}</div>}
          {window.location.href.includes('reset-password') && <div className="auth-form-container">{resetPasswordForm()}</div>}
        </div>
      </div>
      <div className="mhide"><Footer /></div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loginData: state.api.login,
  signupData: state.api.signup,
  forgotPasswordState: state.api.forgotPassword,
  resetPasswordState: state.api.resetPassword,
});

const mapDispatchToProps = (dispatch: any) => ({
  setAppLocation: (payload: any) => dispatch(setAppLocation(payload)),
  loginUser: (payload: any) => dispatch(loginUser(payload)),
  signupUser: (payload: any) => dispatch(signupUser(payload)),
  forgotPassword: (payload: any) => dispatch(forgotPassword(payload)),
  resetPassword: (payload: any) => dispatch(resetPassword(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
