import * as type from "../../types/API/documents";

export function getDocuments(payload: any) {
  return {
    type: type.GET_DOCUMENTS,
    payload: payload,
  };
}

export function createDocument(payload: any) {
  return {
    type: type.CREATE_DOCUMENT,
    payload: payload,
  };
}

export function getDocumentById(payload: any) {
  return {
    type: type.GET_DOCUMENTS_BY_ID,
    payload: payload,
  };
}

export function editDocument(payload: any) {
  return {
    type: type.EDIT_DOCUMENT,
    payload: payload,
  };
}

export function discardDocument(payload: any) {
  return {
    type: type.DISCARD_DOCUMENT,
    payload: payload,
  };
}

export function deleteDocument(payload: any) {
  return {
    type: type.DELETE_DOCUMENT,
    payload: payload,
  };
}
