import { QRCode } from "antd";
import React from "react";
import { connect } from "react-redux";

interface IDocumentSlicerProps {
  dataToPreview: any;
  companyName: string;
  logo: string;
}

const DocumentSlicer: React.FC<IDocumentSlicerProps> = ({ dataToPreview, companyName, logo }) => {
    return <div>
        <div className="documentHeader">
            <div>
                <img
                    src={logo}
                    className="logo"
                    alt="Ease Margin"
                    style={{ margin: "0px 0px 0px 0px", height: "40px" }}
                />
                <div className='mt-20' style={{ fontSize: '17px', fontWeight: '500' }}>{companyName}</div>
                <div className='title'>Address: {dataToPreview?.supplierBillingAddress}</div>
                <div className='title'>Contact: +91-9876543210</div>
                <div className='title'>Email: info@easemargin.com</div>
                <div className='title'>Website: www.easemargin.com</div>
                <div className='title'>
                    Invoice Date: {new Date(dataToPreview?.documentDate).toLocaleDateString("en-GB")}
                </div>
            </div>
            <div>
                <h3>{dataToPreview?.documentType}</h3>
                <div className='title'>Invoice Number: {dataToPreview?.documentNumber}</div>
            </div>
        </div>
        <h6 className="mb-10">Bill To:</h6>
        <hr></hr>
        <div className="buyerSupplierContainer mt-10">
            <div className="supplierDetails" style={{ maxWidth: '40%' }}>
                <div style={{ fontSize: '17px', fontWeight: '500' }}>{dataToPreview?.buyerName}</div>
                <div className='title'>Contact Number: {dataToPreview?.buyerContactNumber}</div>
                <div className='title'>Billing Address: {dataToPreview?.buyerBillingAddress}</div>
                <div className='title'>Delivery Address: {dataToPreview?.buyerDeliveryAddress} </div>
            </div>
            <div className="flexBox supplierDetails">
                <div>
                    {/* <div>Enquiry No.: {dataToPreview?.enquiryNumber}</div>
                    <div>Enquiry Date: {dataToPreview?.enquiryDate ? new Date(dataToPreview.enquiryDate).toLocaleDateString("en-GB") : "-"}</div>
                    <div>Exp. Delivery Date: {dataToPreview?.deliveryDate ? new Date(dataToPreview.deliveryDate).toLocaleDateString("en-GB") : "-"}</div>
                    <div>Payment Term: {dataToPreview?.paymentTerm} </div>
                    <div>Reply Date: {dataToPreview?.replyDate ? new Date(dataToPreview.replyDate).toLocaleDateString("en-GB") : "-"}</div>
                    <div>Return Receive Date: {dataToPreview?.returnRecieveDate ? new Date(dataToPreview.returnRecieveDate).toLocaleDateString("en-GB") : "-"}</div>
                    <div>Invoice Date: {dataToPreview?.invoiceDate ? new Date(dataToPreview.invoiceDate).toLocaleDateString("en-GB") : "-"}</div> */}
                    <div className='title'>Bill Date: {dataToPreview?.billDate ? new Date(dataToPreview.enquiryDate).toLocaleDateString("en-GB") : "-"}</div>
                    <div className='title'>Purchase Order Number: {dataToPreview?.purchaseOrderNumber}</div>
                    <div className='title'>Purchase Order Date: {dataToPreview?.purchaseOrderDate ? new Date(dataToPreview.purchaseOrderDate).toLocaleDateString("en-GB") : "-"}</div>
                    <div className='title'>OC Number: {dataToPreview?.OCNumber}</div>

                </div>
                <div>
                    <div className='title'>Payment Term: {dataToPreview?.paymentTerm}</div>
                    <div className='title'>Payment Date: {dataToPreview?.paymentDate ? new Date(dataToPreview.paymentDate).toLocaleDateString("en-GB") : "-"}</div>
                    <div className='title'>Store: {dataToPreview?.store}</div>
                    <div className='title'>OC Date: {dataToPreview?.OCDate ? new Date(dataToPreview.OCDate).toLocaleDateString("en-GB") : "-"}</div>
                    {/* <div>OC Number: {dataToPreview?.OCNumber}</div>
                    <div>OC Date: {dataToPreview?.OCDate ? new Date(dataToPreview.OCDate).toLocaleDateString("en-GB") : "-"}</div>
                    <div>POC Date: {dataToPreview?.POCDate ? new Date(dataToPreview.POCDate).toLocaleDateString("en-GB") : "-"}</div> */}
            {/* <div>POC Name: {dataToPreview?.POCName} </div>
                    <div>POC Number: {dataToPreview?.POCNumber} </div>
                    <div>Transporter name: {dataToPreview?.transporterName} </div>
                    <div>Invoice No: {dataToPreview?.invoiceNumber} </div> */}
          </div>
          <div>
            {/* <div>TD No.: {dataToPreview?.TDNumber}</div>
                    <div>TD Date: {dataToPreview?.TDDate ? new Date(dataToPreview.TDDate).toLocaleDateString("en-GB") : "-"}</div>
                    <div>TG No: {dataToPreview?.TGNumber}</div>
                    <div>Vehicle No: {dataToPreview?.VehicleNumber} </div>
                    <div>Bill Date: {dataToPreview?.billDate ? new Date(dataToPreview.billDate).toLocaleDateString("en-GB") : "-"}</div>
                    <div>Reply Date: {dataToPreview?.replyDate ? new Date(dataToPreview.replyDate).toLocaleDateString("en-GB") : "-"}</div>
                    <div>Exp. Payment Date: {dataToPreview?.paymentDate ? new Date(dataToPreview.paymentDate).toLocaleDateString("en-GB") : "-"}</div> */}
                </div>
            </div>
        </div>
        <table className="documentTable" style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid silver' }}>
            {(() => {
                const showDiscountColumn = dataToPreview?.items?.some((item: any) => item.discountOne > 0 || item.discountTwo > 0);
                const showTotalDiscountColumn = dataToPreview?.items?.some((item: any) => item.discountTwo > 0 && item.totalDiscount > 0);
                return (
                    <>
                        <thead>
                            <tr>
                                <th style={{ border: '1px solid silver', padding: '8px' }}>S.No</th>
                                <th style={{ border: '1px solid silver', padding: '8px' }}>Item Description</th>
                                <th style={{ border: '1px solid silver', padding: '8px' }}>UOM</th>
                                <th style={{ border: '1px solid silver', padding: '8px' }}>Qty.</th>
                                <th style={{ border: '1px solid silver', padding: '8px' }}>Price</th>
                                {showDiscountColumn && (
                                    <th style={{ border: '1px solid silver', padding: '8px' }}>Discount %</th>
                                )}
                                {showTotalDiscountColumn && (
                                    <th style={{ border: '1px solid silver', padding: '8px' }}>Total Discount %</th>
                                )}
                                <th style={{ border: '1px solid silver', padding: '8px' }}>Tax Type</th>
                                <th style={{ border: '1px solid silver', padding: '8px' }}>Tax %</th>
                                <th style={{ border: '1px solid silver', padding: '8px' }}>Total Tax</th>
                                <th style={{ border: '1px solid silver', padding: '8px' }}>Total Before Tax</th>
                                <th style={{ border: '1px solid silver', padding: '8px' }}>Total After Tax</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataToPreview?.items?.map((item: any, index: number) => {
                                if (!item.quantity || item.quantity === 0) return null;

                                return (
                                    <tr key={item.id}>
                                        <td style={{ border: '1px solid silver', padding: '8px' }}>{index + 1}</td>
                                        <td style={{ border: '1px solid silver', padding: '8px' }}>
                                            <span>{item.itemName}</span>
                                            <br />
                                            <span>{item.HSN !== '' ? `HSN Code - ${item.HSN}` : ''}</span>
                                        </td>
                                        <td style={{ border: '1px solid silver', padding: '8px' }}>{item.UOM}</td>
                                        <td style={{ border: '1px solid silver', padding: '8px' }}>{item.quantity}</td>
                                        <td style={{ border: '1px solid silver', padding: '8px' }}>₹ {Number(item.price).toFixed(2)}</td>
                                        {showDiscountColumn && (
                                            <td style={{ border: '1px solid silver', padding: '8px' }}>
                                                {item.discountOne > 0 || item.discountTwo > 0
                                                    ? `${item.discountOne > 0 ? `${item.discountOne}%` : ''} ${item.discountTwo > 0 ? `- ${item.discountTwo}%` : ''
                                                        }`.trim()
                                                    : ''}
                                            </td>
                                        )}
                                        {showTotalDiscountColumn && (
                                            <td style={{ border: '1px solid silver', padding: '8px' }}>
                                                {item.discountTwo > 0 && item?.totalDiscount > 0
                                                    ? `${Number(item.totalDiscount).toFixed(2)}%`
                                                    : ''}
                                            </td>
                                        )}
                                        <td style={{ border: '1px solid silver', padding: '8px' }}>{item.taxType}</td>
                                        <td style={{ border: '1px solid silver', padding: '8px' }}>{item?.tax}%</td>
                                        <td style={{ border: '1px solid silver', padding: '8px' }}>
                                            ₹ {Number(item.totalTax).toFixed(2)}
                                        </td>
                                        <td style={{ border: '1px solid silver', padding: '8px' }}>
                                            ₹ {Number(item.totalBeforeTax).toFixed(2)}
                                        </td>
                                        <td style={{ border: '1px solid silver', padding: '8px' }}>
                                            ₹ {Number(item.totalAfterTax).toFixed(2)}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </>
                );
            })()}
        </table>

        {dataToPreview?.additionalCharges && dataToPreview.additionalCharges.some((item: any) => item.chargingFor && item.price !== 0 && item.total !== 0) ? (
            <table className="documentTable" style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid silver' }}>
                <thead>
                    <tr>
                        <th style={{ border: '1px solid silver', padding: '8px' }}>S.No</th>
                        <th style={{ border: '1px solid silver', padding: '8px' }}>Additional Charges</th>
                        <th style={{ border: '1px solid silver', padding: '8px' }}>Price</th>
                        <th style={{ border: '1px solid silver', padding: '8px' }}>Tax%</th>
                        <th style={{ border: '1px solid silver', padding: '8px' }}>Total Charge</th>
                    </tr>
                </thead>
                <tbody>
                    {dataToPreview.additionalCharges.map((item: any, index: number) => (
                        <tr key={item.key}>
                            <td style={{ border: '1px solid silver', padding: '8px' }}>{index + 1}</td>
                            <td style={{ border: '1px solid silver', padding: '8px' }}>{item.chargingFor}</td>
                            <td style={{ border: '1px solid silver', padding: '8px' }}>₹ {item.price}</td>
                            <td style={{ border: '1px solid silver', padding: '8px' }}>{item.tax}</td>
                            <td style={{ border: '1px solid silver', padding: '8px' }}>₹ {item.total}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        ) : null}

        <div className="logisticDetails mb-10" style={{ columnGap: '100px' }}>
            <div className="logistic-info-section">
                <div className="bankDetails">
                    <h6>Bank Details</h6>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }} className='mt-10'>
                        <tbody>
                            <tr>
                                <td className="fw-500">Bank Name: {dataToPreview?.bankDetails[0]?.bankName}</td>
                            </tr>
                            <tr>
                                <td className="fw-500">Account Number: {dataToPreview?.bankDetails[0]?.accountNumber}</td>
                            </tr>
                            <tr>
                                <td className="fw-500">IFSC Code: {dataToPreview?.bankDetails[0]?.IFSCCode}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            <div>
                <div>Pay Using UPI</div>
                <QRCode
                    style={{ marginBottom: 16, padding: '5px'}}
                    size={105}
                    errorLevel={'L'}
                    value="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"
                />
            </div>
            <div className="totals">
                <div className="total-item">
                    <span className="fw-500">Total Before Tax</span>
                    <span className="fw-500">
                        ₹ {dataToPreview?.items?.reduce((acc: number, item: any) => acc + Number(item.totalBeforeTax), 0).toFixed(2) ?? 0}
                    </span>
                </div>
                <div className="total-item">
                    <span className="fw-500">Total Tax (CGST and SGST)</span>
                    <span className="fw-500">
                        ₹ {dataToPreview?.items?.reduce((acc: number, item: any) => acc + Number(item.totalTax), 0).toFixed(2) ?? 0}
                    </span>
                </div>
                <div className="total-item">
                    <span className="fw-500">Total After Tax</span>
                    <span className="fw-500">
                        ₹ {dataToPreview?.items?.reduce((acc: number, item: any) => acc + Number(item.totalAfterTax), 0).toFixed(2) ?? 0}
                    </span>
                </div>
                <div className="total-item">
                    <span className="fw-500">Additional Charges</span>
                    <span className="fw-500">
                        ₹ {dataToPreview?.additionalCharges?.reduce((acc: number, charge: any) => acc + charge.total, 0).toFixed(2) ?? 0}
                    </span>
                </div>
                <div className="total-item grand-total">
                    <span className="fw-500">Grand Total</span>
                    <span className="fw-500">
                        ₹ {(
                            dataToPreview?.items?.reduce((acc: number, item: any) => acc + Number(item.totalAfterTax), 0) +
                            dataToPreview?.additionalCharges?.reduce((acc: number, charge: any) => acc + Number(charge.total), 0)
                        ).toFixed(2) ?? 0}
                    </span>
                </div>
            </div>
        </div>
        <hr></hr>
        <div className="flexBox">
            <div>
                <h6 className='mt-10 title'>Logistic Details</h6>
                <p style={{ fontSize: '14px' }}>{dataToPreview?.logisticDetails || "No logistic details"}</p>

                <h6 className="mt-10 title">Additional Details</h6>
                <p style={{ fontSize: '14px' }}>
                    {dataToPreview?.additionalDetails || "No additional details"}
                </p>
                <h6 className="mt-10 title">Attachments</h6>
                <p style={{ fontSize: '14px' }}>No attachment available</p>
                {dataToPreview?.attachments}
                <h6 className="title mb-10">Terms and Conditions</h6>
                <p>{dataToPreview?.termsCondition}</p>
                {/* <div style={{ fontSize: '14px' }}>1. All invoices are payable within 15 days from the date of invoice.<br></br>
                    2. Late payments penalty of 2.5% interest per day on the outstanding balance.<br></br>
                    3. Any additional services requested by the client shall be subject to additional fees.<br></br>
                    4. The client retains all rights to materials provided by them for use in the project.<br></br>
                    5. Both parties agree to keep all information exchanged during the project confidential.<br></br>
                    6. This includes, business strategies, trade secrets, & any proprietary information.<br></br>
                    7. Company reserves the right to update these terms and conditions at any time.<br></br>
                    8. This agreement shall be governed by and construed in accordance with the laws.<br></br>
                    9. By accepting this invoice, the client agrees to abide by these terms and conditions.
                </div> */}
            </div>
            <div className="signature total-item grand-total mt-20">
                <div>
                    <img src={dataToPreview?.signature} alt="Signature" />
                    <p>Authorized Signature</p>
                </div>
            </div>
        </div>

    </div>;
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSlicer);
