import React from "react";
import logo from "../../Utility/images/logo.png";
import "./Footer.css";

import { Layout, Row, Col, Typography, Space } from "antd";
import { FacebookFilled, TwitterSquareFilled, LinkedinFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Footer: AntFooter } = Layout;
const { Title, Text } = Typography;

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  return (
    <AntFooter className="footer">
      <div className="footer-container">
        <Row gutter={[32, 32]} justify="space-between">

          {/* Company Section */}
          <Col xs={24} sm={12} md={8} lg={6}>
            <Title level={4} className="footer-title">Ease Margin</Title>
            <Text className="footer-text">
              Automate your manufacturing workflow and enhance your margin with ease.<br />
              Streamline your manufacturing process, create documents for your sales and purchases,
              manage inventory, fetch real-time data, and generate reports as per requirement.
            </Text>
          </Col>

          {/* Services */}
          <Col xs={12} sm={6} md={4} lg={4}>
            <Title level={5} className="footer-title">Services</Title>
            <Space direction="vertical">
              <Link to="/services/inventory">Inventory Management</Link>
              {/* <Link to="/services/purchase">Purchase Management</Link>
              <Link to="/services/sales">Sales Management</Link> */}
            </Space>
          </Col>

          {/* Quick Links */}
          <Col xs={12} sm={6} md={4} lg={4}>
            <Title level={5} className="footer-title">Quick Links</Title>
            <Space direction="vertical">
              <Link to="/our-blogs">Blogs</Link>
              {/* <Link to="/pricing">Pricing</Link> */}
              <Link to="/requestdemo">Request a Demo</Link>
            </Space>
          </Col>

          {/* Legal */}
          <Col xs={12} sm={6} md={4} lg={4}>
            <Title level={5} className="footer-title">Legal</Title>
            <Space direction="vertical">
              <Link to="/terms-and-condition">Terms of Service</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
              {/* <Link to="/cookie-policy">Cookie Policy</Link> */}
              <Link to="/refund-cancellation">Refund & Cancellation</Link>
            </Space>
          </Col>

          {/* Social Media */}
          <Col xs={24} sm={12} md={4} lg={4}>
            <Title level={5} className="footer-title">Follow Us</Title>
            <Space size="middle">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                <FacebookFilled className="social-icon" />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                <TwitterSquareFilled className="social-icon" />
              </a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                <LinkedinFilled className="social-icon" />
              </a>
            </Space>
          </Col>
        </Row>

        {/* Bottom Section */}
        <Row className="footer-bottom">
          <Col>
            <span>© {new Date().getFullYear()} EaseMargin. All rights reserved.</span>
          </Col>
        </Row>
      </div>
    </AntFooter>

  );
};

export default Footer;
