export const GET_LOGISTIC_DETAILS = 'GET_LOGISTIC_DETAILS';
export const GET_LOGISTIC_DETAILS_SUCCESS = 'GET_LOGISTIC_DETAILS_SUCCESS';
export const GET_LOGISTIC_DETAILS_FAILED = 'GET_LOGISTIC_DETAILS_FAILED';

export const ADD_LOGISTIC_DETAILS = 'ADD_LOGISTIC_DETAILS';
export const ADD_LOGISTIC_DETAILS_SUCCESS = 'ADD_LOGISTIC_DETAILS_SUCCESS';
export const ADD_LOGISTIC_DETAILS_FAILED = 'ADD_LOGISTIC_DETAILS_FAILED';

export const EDIT_LOGISTIC_DETAILS = 'EDIT_LOGISTIC_DETAILS';
export const EDIT_LOGISTIC_DETAILS_SUCCESS = 'EDIT_LOGISTIC_DETAILS_SUCCESS';
export const EDIT_LOGISTIC_DETAILS_FAILED = 'EDIT_LOGISTIC_DETAILS_FAILED';

export const DELETE_LOGISTIC_DETAILS = 'DELETE_LOGISTIC_DETAILS';
export const DELETE_LOGISTIC_DETAILS_SUCCESS = 'DELETE_LOGISTIC_DETAILS_SUCCESS';
export const DELETE_LOGISTIC_DETAILS_FAILED = 'DELETE_LOGISTIC_DETAILS_FAILED'; 