import { Breadcrumb, Button, Col, Drawer, Form, FormInstance, Input, Layout, List, Popconfirm, Row, Space, Tooltip, notification } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { ChangeEvent } from "react";
import { connect } from "react-redux";
import { getTeams, addTeams, editTeam, deleteTeam } from "../../../redux/actions/API/team";
import Loader from "../../../Home/Loader/Loader";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import './Teammanagement.css';
import { EditOutlined, DeleteOutlined} from "@ant-design/icons";

type NotificationType = "success" | "info" | "warning" | "error";

interface ITeamState {
  drawerOpen: boolean;
  editItem: any | null;
  submittedData: any[];
  team: string;
  teamDesc: string;
  isEditTeam: boolean;
  loading: boolean;
  forTitle: boolean;
  searchData: string;
  filteredData: any;
}

interface ITeamProps {
  teamsData: any;
  getTeams: Function;
  addTeams: Function;
  editTeam: Function;
  deleteTeam: Function;
  userId: number;
  getTeamsState: IDataResponse;
  addTeamsState: IDataResponse;
  editTeamstate: IDataResponse;
  deleteTeamState: IDataResponse;
  companyId: any;
}

class Team extends React.Component<ITeamProps, ITeamState> {
  private formRef = React.createRef<FormInstance>();

  constructor(props: ITeamProps) {
    super(props);
    this.state = {
      drawerOpen: false,
      editItem: null,
      submittedData: [],
      team: "",
      teamDesc: "",
      isEditTeam: false,
      loading: true,
      forTitle: true,
      searchData: "",
      filteredData: this.props.teamsData,
    };
  }

  componentDidMount(): void {
    this.props.getTeams({ companyId: Number(this.props.companyId) });
  }

  componentDidUpdate(
    prevProps: Readonly<ITeamProps>,
    prevState: Readonly<ITeamState>,
    snapshot?: any
  ): void {
    if (prevProps.getTeamsState.loading && !this.props.getTeamsState.loading) {
      if (this.props.getTeamsState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to add team");
      } else {
        this.setState({
          loading: false,
          filteredData: this.filterData(this.props.teamsData),
        });
      }
    }

    if (
      prevProps.addTeamsState?.loading &&
      !this.props.addTeamsState?.loading
    ) {
      if (this.props.addTeamsState?.error) {
        this.openNotificationWithIcon("error", this.props.addTeamsState?.error?.message);
        this.setState({
          loading: false
        })
      } else {
        this.formRef.current?.resetFields();
        this.setState({
          drawerOpen: false
        });
        this.props.getTeams({
          companyId: Number(this.props.companyId),
        });
        this.openNotificationWithIcon("success", "Team added successfully");
      }
    }

    if (
      prevProps.editTeamstate?.loading &&
      !this.props.editTeamstate?.loading
    ) {
      if (this.props.editTeamstate?.error) {
        this.openNotificationWithIcon("error", this.props.editTeamstate?.error?.message);
        this.setState({
          loading: false,
          isEditTeam: true,
        })
      } else {
        this.props.getTeams({
          companyId: Number(this.props.companyId),
        });
        this.openNotificationWithIcon("success", "Team updated successfully");
        this.setState({
          drawerOpen: false,
          isEditTeam: false,
          editItem: null,
        });
      }
    }

    if (
      prevProps.deleteTeamState?.loading &&
      !this.props.deleteTeamState?.loading
    ) {
      if (this.props.deleteTeamState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to delete team");
      } else {
        this.props.getTeams({
          companyId: Number(this.props.companyId),
        });
        this.openNotificationWithIcon("success", "Team deleted successfully");
      }
    }
    if (prevProps.teamsData !== this.props.teamsData) {
      this.setState({
        filteredData: this.filterData(this.props.teamsData),
      });
    }
  }

  openNotificationWithIcon = (type: NotificationType, message: string) => {
    notification[type]({
      message: message,
      duration: 3,
    });
  };

  filterData = (data: any[]) => {
    return data.filter(
      (item: { name: string; description: string }) =>
        item.name.toLowerCase().includes(this.state.searchData) ||
        item.name.toLowerCase().includes(this.state.searchData)
    );
  };

  onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    this.setState({
      searchData: query,
      filteredData: this.props.teamsData.filter((item: any) => {
        return (
          item.name.toLowerCase().includes(query) ||
          item.description.toLowerCase().includes(query)
        );
      }),
    });
  };

  handleSubmit = () => {
    this.setState({
      loading: true,
    });

    this.formRef.current
      ?.validateFields()
      .then((values) => {
        if (this.state.isEditTeam && this.state.editItem) {
          // Edit existing team
          this.props.editTeam({
            companyId: Number(this.props.companyId),
            teamId: this.state.editItem.id,
            name: values.teamname,
            description: values.teamdescription,
            ip_address: "127.0.0.1",
            status: 1,
          });
        } else {
          // Add new team
          this.props.addTeams({
            companyId: Number(this.props.companyId),
            name: values.teamname,
            description: values.teamdescription,
            ip_address: "127.0.0.1",
            status: 1,
          });

          // Add the new team to the array
          this.setState((prevState) => ({
            submittedData: [
              ...prevState.submittedData,
              {
                id: Date.now(), // Temporary unique ID
                name: values.teamname,
                description: values.teamdescription,
              },
            ],
          }));
        }
        this.setState({
          isEditTeam: false,
        });
      })
      .catch((errorInfo) => {
        this.setState({
          loading: false,
        });
      });
  };

  handleEdit = (values: any) => {
    // Update state for editing
    this.setState(
      {
        drawerOpen: true,
        editItem: values,
        isEditTeam: true,
        forTitle: false,
      },
      () => {
        // form values are set after state update
        if (this.formRef.current) {
          this.formRef.current.setFieldsValue({
            teamname: values.name,
            teamdescription: values.description,
          });
        }
      }
    );
  };

  handleDelete = (item: any) => {
    this.setState({
      loading: true,
    });
    this.props.deleteTeam({
      teamId: item.id,
    });
  };

  handleCloseDrawer = () => {
    this.setState({
      drawerOpen: false,
      isEditTeam: false,
      editItem: null,
    });
    this.formRef.current?.resetFields();
  };

  getDrawerWidth = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth <= 768) {
      return screenWidth * 0.9;
    }

    return 720;
  }


  render() {
    return (
      <>
        <Loader loading={this.state.loading}></Loader>
        <Layout
          style={{
            padding: "0 24px 24px",
          }}
        >
          <div
            className="div-head-management"
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
                flex: 1,
              }}
            >
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>Settings</Breadcrumb.Item>
              <Breadcrumb.Item className={window.location.pathname === '/team_management' ? 'breadcrumb-active' : ''}>Teams</Breadcrumb.Item>
            </Breadcrumb>

            <div className="search-input-team">
              <Input
                className="searchInputTeam"
                placeholder="Search Teams"
                onChange={this.onSearch}
              />
              <Button
                type="primary"
                onClick={() => {
                  this.setState({
                    drawerOpen: true,
                    forTitle: true,
                  });
                }}
                className="buttonAddTeam"
              >
                Add Team
              </Button>
            </div>
          </div>

          <Content
            className="content-section"
          >
            <List
              itemLayout="horizontal"
              dataSource={this.state.filteredData}
              renderItem={(item: any, index: number) => (
                <List.Item>
                  <List.Item.Meta
                    title={<a href="https://ant.design">{item.name}</a>}
                    description={item.description}
                  />
                  <div className="action-button">
                    <Tooltip title="Edit Details">
                      <div
                        onClick={() => this.handleEdit(item)}
                      >
                        <span className="actionIcons"><EditOutlined /></span>
                      </div>
                    </Tooltip>
                    <Popconfirm
                      title="This team might contain users. Are you sure to delete this?"
                      onConfirm={() => this.handleDelete(item)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <div><span className="actionIcons"><DeleteOutlined /></span></div>
                    </Popconfirm>
                  </div>
                </List.Item>
              )}
            />
          </Content>
        </Layout>
        <Drawer
          open={this.state.drawerOpen}
          title={this.state.forTitle ? "Add a team" : "Edit a team"}
          width={this.getDrawerWidth()}
          onClose={this.handleCloseDrawer}
          extra={
            <Space>
              <Button onClick={this.handleCloseDrawer}>Cancel</Button>
              <Button onClick={this.handleSubmit} type="primary">
                {this.state.forTitle ? "Submit" : "Update"}
              </Button>
            </Space>
          }
        >
          <Form
            layout="vertical"
            onFinish={this.handleSubmit}
            ref={this.formRef}
            className="form-team"
          >
            <Row gutter={0}>
              <Col span={24}>
                <Form.Item
                  name="teamname"
                  label="Team Name"
                  rules={[
                    { required: true, message: "Please enter team name" },
                  ]}
                  className="form-item-name"
                >
                  <Input placeholder="Please enter team name" className="input-teamname" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={0}>
              <Col span={24}>
                <Form.Item
                  name="teamdescription"
                  label="Team Description"
                  rules={[
                    {
                      required: false,
                      message: "please enter team description",
                    },
                  ]}
                  className="form-item-desc"
                >
                  <Input.TextArea
                    rows={4}
                    placeholder="please enter team description"
                    className="input-teamDesc"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  teamsData: state.api.getTeams?.data?.reverse(),
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
  getTeamsState: state.api.getTeams,
  addTeamsState: state.api.addTeam,
  editTeamstate: state.api.editTeam,
  deleteTeamState: state.api.deleteTeam,
});

const mapDispatchToProps = (dispatch: any) => ({
  getTeams: (payload: any) => dispatch(getTeams(payload)),
  addTeams: (payload: any) => dispatch(addTeams(payload)),
  editTeam: (payload: any) => dispatch(editTeam(payload)),
  deleteTeam: (payload: any) => dispatch(deleteTeam(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Team);
