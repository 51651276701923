import React, { useEffect } from "react";
import { Card, Select, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import "./BankDetails.css";
import { connect } from "react-redux";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import { getBankDetail } from "../../../redux/actions/API/bankDetail";
import { setBankDetails } from "../../../redux/actions/UI/createDocument";
import noData from '../../../Utility/images/noData.png'

interface IBankDetailsProps {
  getBankDetail: Function;
  setBankDetails: Function;
  bankDetailData: any[];
  getBankDetailState: IDataResponse;
  companyId: number;
}

const BankDetails: React.FC<IBankDetailsProps> = ({ bankDetailData, companyId, getBankDetail, setBankDetails }) => {

  useEffect(() => {
    getBankDetail({ companyId: Number(companyId) })
  }, [])

  const [selectedBank, setSelectedBank] = React.useState<any>(null);

  const handleAccountChange = (value: string) => {
    const selectedAccount = bankDetailData.find((bank) => {
      return bank.accountHolderName.toLowerCase() === value.toLowerCase();
    });

    setSelectedBank(selectedAccount);
    if (selectedAccount) {
      setBankDetails(selectedAccount);
    }
  };

  return (
    <>
      <Card className="custom-card no-header-border flex-grow-1">
        <div className="card-content">
          <div className="flexBox mobAddCategory">
            <div className="title mb-10 fS-20">
              {"Bank details"} &nbsp;
              <Tooltip title={"Selct bank account details"}>
                <InfoCircleOutlined className="info-icon" />
              </Tooltip>
            </div>
            <Select
              className="itemGridFields-lg"
              placeholder="Select bank account name"
              onChange={handleAccountChange}
            >
              {bankDetailData?.map((bank) => (
                <Select.Option key={bank.id} value={bank.accountHolderName}>
                  {bank.accountHolderName}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div>
            {selectedBank ? (
              <div className="flexBox mt-20"> 
                <div className="flexBox mobAddCategory">
                  <div>
                    <div className="title fS-14">Bank Name:</div>
                    <div className="title fS-14">A/C Holder Name:</div>
                    <div className="title fS-14">Branch:</div>
                    <div className="title fS-14">A/C Number:</div>
                  </div>
                  <div>
                    <div>{selectedBank?.bankName}</div>
                    <div>{selectedBank?.accountHolderName}</div>
                    <div>{selectedBank?.branch}</div>
                    <div>{selectedBank?.accountNumber}</div>
                  </div>
                </div> 
                <div className="flexBox mobAddCategory">
                  <div>
                    <div className="title fS-14">SWIFT Code:</div>
                    <div className="title fS-14">IFSC Code:</div>
                    <div className="title fS-14">MICR Code:</div>
                  </div>
                  <div>
                    <div>{selectedBank?.swiftCode}</div> 
                    <div>{selectedBank?.IFSCCode}</div>
                    <div>{selectedBank?.MICRCode}</div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="center">
                <img src={noData} alt="Bank details" />
              </div>
            )}
          </div>
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  bankDetailData: state.api.getBankDetail?.data?.reverse(),
  companyId: state.api.login.data.companyId,
  getBankDetailState: state.api.getBankDetail,
});

const mapDispatchToProps = (dispatch: any) => ({
  getBankDetail: (payload: any) => dispatch(getBankDetail(payload)),
  setBankDetails: (payload: any) => dispatch(setBankDetails(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(BankDetails);