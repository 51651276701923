import React, { useEffect, useState } from "react";
import { Input, Card, Tooltip } from "antd";
import { connect } from "react-redux";
import { setCommentInputData } from "../../../redux/actions/UI/createDocument";
import { AppConstants } from "../../../Appconstants";
import { InfoCircleOutlined } from "@ant-design/icons";

const { TextArea } = Input;
interface ICommentInputProps {
  setCommentInputData: Function;
  commentData: any;
}

const CommentInput: React.FC<ICommentInputProps> = ({
  setCommentInputData,
  commentData,
}) => {
  const [comment, setComment] = useState("");

  useEffect(() => {
    setComment(commentData?.length > 0 ? commentData[0] : "");
  }, [commentData]);

  useEffect(() => {
    setCommentInputData([]);
  }, []);

  const handleBlur = () => {
    const newData = comment
      ?.toString()
      ?.split("\n")
      ?.filter((line: string) => line.trim() !== "");
      setCommentInputData(newData);
  };

  return (
    <Card className="custom-card no-header-border flex-grow-1">
      <div className="card-content">
        <div className="title mb-20">
          {AppConstants.COMMENT_INPUT.COMMENT_INPUT_LABEL} &nbsp;
          <Tooltip title={AppConstants.COMMENT_INPUT.COMMENT_INPUT_LABEL}>
            <InfoCircleOutlined className="info-icon" />
          </Tooltip>
        </div>
        {AppConstants.COMMENT_INPUT.WRITE_COMMENT_INPUT}
        <TextArea
          style={{ resize: "none" }}
          rows={3}
          placeholder={AppConstants.COMMENT_INPUT.WRITE_COMMENT_INPUT}
          className="mb-20"
          maxLength={100}
          showCount
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          onBlur={handleBlur}
        />
      </div>
    </Card>
  );
};

const mapStateToProps = (state: any) => ({
  commentData: state.ui.createDocumentUIData?.commentInput,
});

const mapDispatchToProps = (dispatch: any) => ({
  setCommentInputData: (payload: any) => dispatch(setCommentInputData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentInput);
