import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Input,
  Form,
  Row,
  Col,
  Popconfirm,
  Space,
  Table,
  Tag,
  notification,
  Alert,
  Drawer,
  TableProps,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";

import { TableRowSelection } from "antd/es/table/interface";
type NotificationType = "success" | "info" | "warning" | "error";

interface IBOMBOMSeriesProps {
  isVisible: boolean;
  onClose: () => void;
  onSelectSeries: (selectedSeries: string) => void;
  setIsVisible: (visible: boolean) => void;
  documentSeriesData: any;
  companyId: any;
  userId: number;
  documentType: string;
}

interface DataType {
  key: string;
  seriesName: string;
  prefix: string;
  numberData: number;
  nextNumberData: number;
  id?: string;
}

const BOMSeries: React.FC<IBOMBOMSeriesProps> = ({
  isVisible,
  onClose,
  documentSeriesData,
  companyId,
  userId,
  documentType,
}) => {
  const prevPropsRef = useRef<any>();
  const [form] = Form.useForm();
  const [seriesName, setSeriesName] = useState<string>("");
  const [series, setSeries] = useState<string>("");
  const [number, setNumber] = useState<number | undefined>(undefined);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editRecord, setEditRecord] = useState<DataType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (documentSeriesData) {
      const filteredSeries = documentSeriesData?.filter(
        (series: { DocType: string }) => series.DocType == documentType
      );
      setDataSource(filteredSeries);
    }
  }, [documentSeriesData]);

  useEffect(() => {
    if (!loading && documentSeriesData) {
      setDataSource(documentSeriesData);
    }
  }, [documentSeriesData, loading]);

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification[type]({
      message: message,
      duration: 2,
    });
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value)) {
      setNumber(value);
    }
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const payload = {
          ...values,
          companyId,
          userId,
          ip_address: "127.0.0.1",
        };
      })
      .catch((error) => console.error("Validation failed:", error));
  };

  const handleAddNewSeries = () => {
    setIsFormVisible(true);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setIsFormVisible(false);
    form.resetFields();
  };

  const handleEditSeries = (record: any) => {
    setIsEditing(true);
    setEditRecord(record);
    setIsFormVisible(true);
    form.setFieldsValue({
      seriesName: record.seriesName,
      series: record.prefix,
      number: record.number,
    });
  };

  const handleDelete = (id: number) => {
    setLoading(true);
  };

  const formatData = (dataSource: any) => {
    const filteredSeries = documentSeriesData?.filter(
      (series: { DocType: string }) => series.DocType == documentType
    );

    let data = filteredSeries?.reverse()?.map((elem: any, index: number) => {
      return {
        key: elem.id || index,
        id: elem.id,
        seriesName: elem.seriesName,
        prefix: elem.prefix,
        number: elem.number,
        numberData: elem.prefix + "-" + elem.number,
        nextNumber: elem.nextNumber,
        nextNumberData: elem.prefix + "-" + elem.nextNumber,
        default: elem.default,
        defaultData:
          elem.default == 1 ? (
            <Tag color="green">Default</Tag>
          ) : (
            <Tag color="blue" style={{ cursor: "pointer" }}>
              Set as default
            </Tag>
          ),
      };
    });

    return data;
  };

  const rowSelection: TableRowSelection<DataType> = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log("Selected Row Keys: ", selectedRowKeys);
      console.log("Selected Rows: ", selectedRows);
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.seriesName === "Disabled Series",
      name: record.seriesName,
    }),
  };

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Name",
      dataIndex: "seriesName",
      key: "seriesName",
    },
    {
      title: "Prefix",
      dataIndex: "prefix",
      key: "series",
    },
    {
      title: "Doc. Starting No.",
      dataIndex: "numberData",
      key: "number",
    },
    {
      title: "Doc. Next No.",
      dataIndex: "nextNumberData",
      key: "nextNumber",
    },
    {
      title: "Action",
      key: "action",
      width: "70px",
      render: (_: any, record: any) => (
        <Space size="middle">
          <a onClick={() => handleEditSeries(record)}>
            <EditOutlined />
          </a>
          <Popconfirm
            title="Are you sure to delete this?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleDelete(record.id)}
          >
            <a key="list-loadmore-more">
              <DeleteOutlined />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Drawer
        title={"Customize BOM Series"}
        placement="right"
        size={"large"}
        onClose={onClose}
        open={isVisible}
        width={800}
        extra={
          <Space>
            <Button onClick={onClose}>Close</Button>
          </Space>
        }
      >
        {isFormVisible && (
          <p
            className="backLinkSM"
            onClick={() => {
              setIsFormVisible(false);
              setIsEditing(false);
              form.resetFields();
            }}
          >
            <ArrowLeftOutlined /> Back to Manage BOM Series
          </p>
        )}

        <Alert
          description="A custom BOM series generates unique identifiers for records using prefixes, sequential numbers, and date information, ensuring traceability and efficient management."
          type="info"
        />

        {!isEditing && !isFormVisible && (
          <div className="flexBox mt-10">
            <Input
              placeholder="Search Series"
              onChange={(e: any) => {}}
              className=""
            />
            <Button type="primary" onClick={handleAddNewSeries}>
              Add New Series
            </Button>
          </div>
        )}
        {isFormVisible && (
          <Form
            form={form}
            layout="vertical"
            requiredMark
            className="series-form"
          >
            <Row gutter={18}>
              <Col span={8}>
                <Form.Item label="Series Name" name="seriesName">
                  <Input
                    id="series-name"
                    name="seriesName"
                    value={seriesName}
                    onChange={(e) => setSeriesName(e.target.value)}
                    placeholder="Enter series name"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Series"
                  name="series"
                  rules={[
                    {
                      required: true,
                      message: "Please fill the prefix",
                    },
                  ]}
                >
                  <Input
                    id="series-prefix"
                    name="series"
                    value={series}
                    onChange={(e) => setSeries(e.target.value)}
                    placeholder="Enter prefix"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Number"
                  name="number"
                  rules={[
                    {
                      required: true,
                      message: "Please fill the number",
                    },
                  ]}
                >
                  <Input
                    id="series-number"  
                    name="number"
                    type="number"
                    value={number}
                    onChange={handleNumberChange}
                    placeholder="Enter number"
                    disabled={isEditing}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={18} justify="end">
              <Col>
                <Button onClick={handleSubmit} type="primary">
                  {isEditing ? "Update" : "Submit"}
                </Button>
              </Col>
              <Col>
                <Button onClick={handleCancel}>Cancel</Button>
              </Col>
            </Row>
          </Form>
        )}

        {!isEditing && !isFormVisible && (
          <Table
            rowKey="id"
            rowSelection={rowSelection}
            bordered
            dataSource={formatData(dataSource)}
            columns={columns}
            pagination={false}
            className="mt-20"
          />
        )}
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  documentSeriesData: state.api.getDocumentSeries?.data,
  userId: state.api.login?.data.id,
  companyId: state.api.login?.data.companyId,
  documentType: state.ui.createDocumentUIData.documentType,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BOMSeries);
