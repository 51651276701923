export const GET_PAYMENT_TERMS = 'GET_PAYMENT_TERMS';
export const GET_PAYMENT_TERMS_SUCCESS = 'GET_PAYMENT_TERMS_SUCCESS';
export const GET_PAYMENT_TERMS_FAILED = 'GET_PAYMENT_TERMS_FAILED';

export const ADD_PAYMENT_TERMS = 'ADD_PAYMENT_TERMS';
export const ADD_PAYMENT_TERMS_SUCCESS = 'ADD_PAYMENT_TERMS_SUCCESS';
export const ADD_PAYMENT_TERMS_FAILED = 'ADD_PAYMENT_TERMS_FAILED';

export const EDIT_PAYMENT_TERMS = 'EDIT_PAYMENT_TERMS';
export const EDIT_PAYMENT_TERMS_SUCCESS = 'EDIT_PAYMENT_TERMS_SUCCESS';
export const EDIT_PAYMENT_TERMS_FAILED = 'EDIT_PAYMENT_TERMS_FAILED';

export const DELETE_PAYMENT_TERMS = 'DELETE_PAYMENT_TERMS';
export const DELETE_PAYMENT_TERMS_SUCCESS = 'DELETE_PAYMENT_TERMS_SUCCESS';
export const DELETE_PAYMENT_TERMS_FAILED = 'DELETE_PAYMENT_TERMS_FAILED'; 