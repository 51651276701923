import { Card, Tooltip } from "antd";
import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { AppConstants } from "../../../../Appconstants";
import { connect } from "react-redux";
import { setDocumentSignatureData } from "../../../../redux/actions/UI/createDocument";

interface ISignatureProps {
  setDocumentSignatureData: Function;
}

const Signature: React.FC<ISignatureProps> = ({ setDocumentSignatureData }) => {
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isInvalidFileType, setIsInvalidFileType] = useState(false);
  const [fileError, setFileError] = useState("");

  useEffect(() => {
    setDocumentSignatureData([]);
  }, [setDocumentSignatureData]);

  useEffect(() => {
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setPreviewUrl(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    } else {
      setPreviewUrl(null);
    }
  }, [file]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFileError("");
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      const fileSize = selectedFile.size / 1024;

      if (!["image/png", "image/jpeg"].includes(fileType)) {
        setIsInvalidFileType(true);
        setFile(null);
        setFileError("Invalid file type");
        setDocumentSignatureData([]); 
        return;
      }

      if (fileSize > 2048) {
        setFile(selectedFile);
        setFileError("Maximum 500kb file available");
        setDocumentSignatureData([]); 
        return;
      }

      const formData = new FormData();
      formData.append("file", selectedFile);
      setDocumentSignatureData(formData); 
      setFile(selectedFile);
    } else {
      handleFileReset();
    }
  };

  const handleFileReset = () => {
    setFile(null);
    setFileError("");
    setPreviewUrl(null);
    setDocumentSignatureData([]); 
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <Card className="custom-card no-header-border flex-grow-1">
      <div className="card-content">
        <div className="title mb-10 fS-20">
          {AppConstants.SIGNATURE_DETAIL.SIGNATURE_LABEL} &nbsp;
          <Tooltip title={AppConstants.SIGNATURE_DETAIL.SIGNATURE_LABEL}>
            <InfoCircleOutlined className="info-icon" />
          </Tooltip>
        </div>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        {!previewUrl && (
          <div>{AppConstants.SIGNATURE_DETAIL.SIGNATURE_UPLOAD_DETAILS}</div>
        )}
        <div>
          {previewUrl ? (
            <div className="image-preview-container">
              <img
                src={previewUrl}
                alt="Signature preview"
                className="previewImage"
              />
              <Tooltip title="Remove">
                <CloseOutlined className="remove-icon" onClick={handleFileReset} />
              </Tooltip>
            </div>
          ) : null}
        </div>
      </div>
      <span className="errorText">{fileError}</span>
    </Card>
  );
};

const mapStateToProps = (state: any) => ({
  dataSource: state.ui.createDocumentData?.documentSignatureData,
});

const mapDispatchToProps = (dispatch: any) => ({
  setDocumentSignatureData: (payload: any) => dispatch(setDocumentSignatureData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signature);
