import React, { useEffect, useState } from "react";
import { Input, Table, Select, Button, Tooltip, Popconfirm } from "antd";
import { ReloadOutlined, DeleteOutlined } from "@ant-design/icons";
import type { TableColumnsType } from "antd";
import { connect } from "react-redux";
import "./AdditionalCharge.css";
import { setDocumentAdditionalChargesData } from "../../../../redux/actions/UI/createDocument";

interface DataType {
  key: React.Key;
  chargingFor: string;
  price: number;
  tax: number;
  total: number;
}

const { Option } = Select;

interface IAdditionalChargeProps {
  setDocumentAdditionalChargesData: Function;
  additionalChargesData: any;
}

const AdditionalCharge: React.FC<IAdditionalChargeProps> = ({
  setDocumentAdditionalChargesData,
  additionalChargesData,
}) => {
  // Key counter starts from 2 since key 1 is already assigned to the first row
  const [keyCounter, setKeyCounter] = useState<number>(2);
  const [extraChargeData, setExtraChargeData] = useState<DataType[]>([
    {
      key: 1,
      chargingFor: "",
      price: 0,
      tax: 0,
      total: 0,
    },
  ]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 896);
  
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 414);
    };
  
    useEffect(() => {
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

  useEffect(() => {
    setExtraChargeData(additionalChargesData);
  }, [additionalChargesData]);

  useEffect(() => {
    setDocumentAdditionalChargesData([
      {
        key: 1,
        chargingFor: "",
        price: 0,
        tax: 0,
        total: 0,
      },
    ]);
  }, []);

  const addRow = () => {
    const newRow: DataType = {
      key: keyCounter,
      chargingFor: "",
      price: 0,
      tax: 0,
      total: 0,
    };

    setExtraChargeData([...extraChargeData, newRow]);
    setDocumentAdditionalChargesData([...extraChargeData, newRow]);
    setKeyCounter(keyCounter + 1);
  };

  const deleteRow = (key: React.Key) => {
    const updatedData = extraChargeData?.filter((item) => item.key !== key);

    setExtraChargeData(updatedData);
    setDocumentAdditionalChargesData(updatedData);
  };

  const handleResetField = (key: React.Key) => {
    const updatedData = extraChargeData?.map((item) => {
      if (item.key === key) {
        return {
          ...item,
          chargingFor: "",
          price: 0,
          tax: 0,
          total: 0,
        };
      }
      return item;
    });

    setExtraChargeData(updatedData);
    setDocumentAdditionalChargesData(updatedData);
  };

  const handleInputChange = (
    key: React.Key,
    field: keyof DataType,
    value: any
  ) => {
    const updatedData = extraChargeData?.map((item) => {
      if (item.key === key) {
        const updatedItem = { ...item, [field]: value };
        const price = parseFloat(updatedItem.price.toString()) || 0;
        const tax = parseFloat(updatedItem.tax.toString()) || 0;

        updatedItem.total = price + (price * tax) / 100;
        return updatedItem;
      }
      return item;
    });

    setExtraChargeData(updatedData);
    setDocumentAdditionalChargesData(updatedData);
  };

  const handleBlur = (record: DataType) => {
    const total = record.price + (record.price * record.tax) / 100;

    const updatedData = extraChargeData?.map((item) =>
      item.key === record.key ? { ...item, total } : item
    );

    setExtraChargeData(updatedData);
    setDocumentAdditionalChargesData(updatedData);
  };

  const handleAdditionalChargeInput = (
    key: React.Key,
    field: keyof DataType,
    value: any
  ) => {
    const updatedData = extraChargeData?.map((item) => {
      if (item.key === key) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setExtraChargeData(updatedData);
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: "Additional Charges",
      dataIndex: "chargingFor",
      key: "chargingFor",
      fixed: isMobileView ? undefined : "left",
      render: (text: string, record: DataType) => (
        <Input
          value={text}
          placeholder="Please write additional charges details"
          onChange={(e) =>
            handleAdditionalChargeInput(
              record.key,
              "chargingFor",
              e.target.value
            )
          }
          onBlur={() => handleBlur(record)}
        />
      ),
    },
    {
      title: "Amount",
      width: 200,
      dataIndex: "price",
      key: "price",
      fixed: isMobileView ? undefined : "left",
      render: (text: string, record: DataType) => (
        <Input
          className="tableWidth"
          type="number"
          value={text}
          onChange={(e) =>
            handleInputChange(
              record.key,
              "price",
              parseFloat(e.target.value) || 0
            )
          }
        />
      ),
    },
    {
      title: "Tax %",
      width: 150,
      dataIndex: "tax",
      key: "tax",
      render: (value: number, record: DataType, index: number) => (
        <Select
          value={value}
          className="tableWidth"
          onChange={(value) => handleInputChange(record.key, "tax", value)}
        >
          <Option value={0}>0%</Option>
          <Option value={0.1}>0.1%</Option>
          <Option value={0.25}>0.25%</Option>
          <Option value={3}>3%</Option>
          <Option value={5}>5%</Option>
          <Option value={6}>6%</Option>
          <Option value={12}>12%</Option>
          <Option value={18}>18%</Option>
          <Option value={28}>28%</Option>
        </Select>
      ),
    },
    {
      title: "Total Charge",
      width: 200,
      dataIndex: "total",
      key: "total",
      render: (text: number) => (
        <Input className="tableWidth" value={text?.toFixed(2)} readOnly />
      ),
    },
    {
      title: "Actions",
      fixed: "right",
      width: isMobileView ? 40 : 200,
      render: (elem, record: DataType) => {
        return (
          <div style={{ display: "flex", gap: 8 }}>
            <Tooltip title="Refresh">
              <span className="actionIcons">
                <ReloadOutlined onClick={() => handleResetField(record.key)} />
              </span>
            </Tooltip>
            {extraChargeData?.length > 1 && (
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure you want to delete this row?"
                  onConfirm={() => deleteRow(record.key)}
                  okText="Yes"
                  cancelText="No"
                >
                  <span className="actionIcons">
                    <DeleteOutlined />
                  </span>
                </Popconfirm>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="additional-charge-table">
      <Table<DataType>
        pagination={false}
        columns={columns}
        dataSource={extraChargeData}
        scroll={{ x: "max-content" }}
      />
      <Button
        className="buttonSpacing"
        color="primary"
        variant="filled"
        onClick={addRow}
      >
        Add Extra charge
      </Button>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  additionalChargesData: state.ui.createDocumentUIData?.additionalCharges,
});

const mapDispatchToProps = (dispatch: any) => ({
  setDocumentAdditionalChargesData: (payload: any) =>
    dispatch(setDocumentAdditionalChargesData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalCharge);
