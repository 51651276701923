import React, { useState } from "react";
import { List, Switch } from "antd";
import "./InventoryList.css";

const InventoryList: React.FC = () => {
  // State for managing switches
  const [purchaseTransaction, setPurchaseTransaction] = useState({
    GRN: false,
    QualityReport: false,
  });

  const [salesTransaction, setSalesTransaction] = useState({
    DeliveryChallan: false,
    Invoice: false,
  });

  const handlePurchaseToggle = (field: "GRN" | "QualityReport") => {
    setPurchaseTransaction({
      GRN: field === "GRN",
      QualityReport: field === "QualityReport",
    });
  };

  const handleSalesToggle = (field: "DeliveryChallan" | "Invoice") => {
    setSalesTransaction({
      DeliveryChallan: field === "DeliveryChallan",
      Invoice: field === "Invoice",
    });
  };

  // List items
  const listItems = [
    { id: 1, name: "Negative Stock Restriction" },
    { id: 2, name: "Custom Fields" },
    {
      id: 3,
      name: <h6>Purchase Transaction</h6>,
      children: [
        {
          key: "GRN",
          label: "GRN",
          isChecked: purchaseTransaction.GRN,
          onToggle: () => handlePurchaseToggle("GRN"),
        },
        {
          key: "QualityReport",
          label: "Quality Report",
          isChecked: purchaseTransaction.QualityReport,
          onToggle: () => handlePurchaseToggle("QualityReport"),
        },
      ],
    },
    {
      id: 4,
      name: <h6>Sales Transaction</h6>,
      children: [
        {
          key: "DeliveryChallan",
          label: "Delivery Challan",
          isChecked: salesTransaction.DeliveryChallan,
          onToggle: () => handleSalesToggle("DeliveryChallan"),
        },
        {
          key: "Invoice",
          label: "Invoice",
          isChecked: salesTransaction.Invoice,
          onToggle: () => handleSalesToggle("Invoice"),
        },
      ],
    },
  ];

  return (
    <div className="inventory-list-container">
      <List
        dataSource={listItems}
        renderItem={(item) => (
          <>
            <List.Item className="inventory-list-item">
              <span className="list-item-text">{item.name}</span>
              {!item.children && <Switch className="list-item-switch" />}
            </List.Item>

            {item.children && (
              <div className="inventory-children">
                {/* <h6 className="inventory-header">{item.name}</h6> */}
                <List
                  dataSource={item.children}
                  renderItem={(child) => (
                    <List.Item className="inventory-list-item-child">
                      <span className="list-item-text">{child.label}</span>
                      <Switch
                        checked={child.isChecked}
                        onChange={child.onToggle}
                        className="list-item-switch"
                      />
                    </List.Item>
                  )}
                />
              </div>
            )}
          </>
        )}
      />
    </div>
  );
};

export default InventoryList;