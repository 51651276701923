 
export const ADD_ITEM = "+ ADD ITEM.";
export const CUSTOM_FIELD = "Custom Field.";
export const DISCOUNT = "Discount.";
export const SUCCESSIVE_DISCOUNT = "Successive Discount.";

export interface IDocumentItemGridProps {
  getItems: Function;
  companyId: number;
  userId: number;
  itemsData: any;
  dataSource: any;
  documentItemValidationData: any;
  setDocumentItemGridData:Function;
  documentType: string;
  UOMData: any;
}

export interface SalesEnquiryProps {
  getItems: Function;
  companyId: Number;
  userId: Number;
  itemsData: any;
}

export type Item = {
  itemId: string;
  itemName: string;
  metricsUnit: string;
  HSN: string;
};

export type DataSource = {
  key: number;
  itemId: string;
  itemName: string;
  UOM: string;
  HSN: string;
  quantity: number;
  taxType: string;
  tax?: number;
  totalTax?: number;
  price: number;
  totalBeforeTax?: number;
  totalAfterTax: number;
  discount1?: number;
  discount2?: number;
  totalDiscount?: number;
  length?: number;
  breadth?: number;
  height?: number;
  formula?: string;
  calculatedCost?: number;
  [key: string]: any;
};

export interface CustomField {
  key: string;
  label: string;
  fieldType: "text" | "integer" | "select" | "datetime";
  options?: string[];
  defaultValue: any;
  level: "primary" | "item"; 
  createdBy: string;
  createdAt: string;
  category: string;
}

export const TaxValues = [
  { value: 0, label: "0%" },
  { value: 0.1, label: "0.1%" },
  { value: 0.25, label: "0.25%" },
  { value: 3, label: "3%" },
  { value: 5, label: "5%" },
  { value: 6, label: "6%" },
  { value: 12, label: "12%" },
  { value: 18, label: "18%" },
  { value: 28, label: "28%" },
];
