export const GET_TRANSPORTER_DETAILS = 'GET_TRANSPORTER_DETAILS';
export const GET_TRANSPORTER_DETAILS_SUCCESS = 'GET_TRANSPORTER_DETAILS_SUCCESS';
export const GET_TRANSPORTER_DETAILS_FAILED = 'GET_TRANSPORTER_DETAILS_FAILED';

export const ADD_TRANSPORTER_DETAILS = 'ADD_TRANSPORTER_DETAILS';
export const ADD_TRANSPORTER_DETAILS_SUCCESS = 'ADD_TRANSPORTER_DETAILS_SUCCESS';
export const ADD_TRANSPORTER_DETAILS_FAILED = 'ADD_TRANSPORTER_DETAILS_FAILED';

export const EDIT_TRANSPORTER_DETAILS = 'EDIT_TRANSPORTER_DETAILS';
export const EDIT_TRANSPORTER_DETAILS_SUCCESS = 'EDIT_TRANSPORTER_DETAILS_SUCCESS';
export const EDIT_TRANSPORTER_DETAILS_FAILED = 'EDIT_TRANSPORTER_DETAILS_FAILED';

export const DELETE_TRANSPORTER_DETAILS = 'DELETE_TRANSPORTER_DETAILS';
export const DELETE_TRANSPORTER_DETAILS_SUCCESS = 'DELETE_TRANSPORTER_DETAILS_SUCCESS';
export const DELETE_TRANSPORTER_DETAILS_FAILED = 'DELETE_TRANSPORTER_DETAILS_FAILED'; 