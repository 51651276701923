import { Col, QRCode, Row } from "antd";
import React from "react";
import { connect } from "react-redux";
import materialData from "../../utility/materialData.json";

interface AdditionalCharge {
  key: number;
  chargesName: string;
  amount: number;
}

interface Item {
  id: number;
  itemName: string;
  UOM: string;
  quantity: number;
  store: number;
  costAllocation: number;
}

interface ScrapMaterial {
  id: number;
  itemName: string;
  UOM: string;
  quantity: number;
  store: number;
  costAllocation: number;
}

interface RawMaterial {
  id: number;
  itemName: string;
  UOM: string;
  quantity: number;
  store: number;
}

interface DataToPreview {
  bomID: string;
  bomName: string;
  bomDescription: string;
  documentType: string;
  documentNumber: string;
  signature: string;
  items: Item[];
  additionalCharges?: AdditionalCharge[];
  scrapMaterials?: ScrapMaterial[];
  rawMaterials?: RawMaterial[];
}

interface IMaterialSlicerProps {
  dataToPreview: DataToPreview;
  companyName: string;
  //   logo: string;
}

const MaterialSlicer: React.FC<IMaterialSlicerProps> = ({
  dataToPreview,
  companyName,
}) => {
  return (
    <div>
      <div className="documentHeader">
        <div>
          <Row gutter={24}>
            <Col span={5}>
              <div>
                <span className="title">BOM ID:</span> {dataToPreview?.bomID}
              </div>
            </Col>
            <Col span={8}>
              <div>
                <span className="title">BOM Name:</span>{" "}
                {dataToPreview?.bomName}
              </div>
            </Col>
            <Col span={8}>
              <div>
                <span className="title">BOM Description:</span>{" "}
                {dataToPreview?.bomDescription}
              </div>
            </Col>
            <Col span={3}>
              <div className="title">Attachments: </div>
            </Col>
          </Row>
        </div>
      </div>
      <hr></hr>
      <h5 className="mb-2">Finished Good</h5>
      <table
        className="documentTable mb-2"
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid silver",
        }}
      >
        {(() => {
          return (
            <>
              <thead>
                <tr>
                  <th
                    style={{
                      border: "1px solid silver",
                      padding: "8px",
                      width: "100px",
                    }}
                  >
                    S.No
                  </th>
                  <th
                    style={{
                      border: "1px solid silver",
                      padding: "8px",
                      width: "400px",
                    }}
                  >
                    Item Description
                  </th>
                  <th style={{ border: "1px solid silver", padding: "8px" }}>
                    UOM
                  </th>
                  <th style={{ border: "1px solid silver", padding: "8px" }}>
                    Qty.
                  </th>
                  <th style={{ border: "1px solid silver", padding: "8px" }}>
                    Store
                  </th>
                  <th style={{ border: "1px solid silver", padding: "8px" }}>
                    Cost Allocation %
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataToPreview?.items?.map((item: any, index: number) => {
                  if (!item.quantity || item.quantity === 0) return null;

                  return (
                    <tr key={item.id}>
                      <td
                        style={{
                          border: "1px solid silver",
                          padding: "8px",
                          width: "100px",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          border: "1px solid silver",
                          padding: "8px",
                          width: "400px",
                        }}
                      >
                        <span>{item.itemName}</span>
                        <br />
                      </td>
                      <td
                        style={{ border: "1px solid silver", padding: "8px" }}
                      >
                        {item.UOM}
                      </td>
                      <td
                        style={{ border: "1px solid silver", padding: "8px" }}
                      >
                        {item.quantity}
                      </td>
                      <td
                        style={{ border: "1px solid silver", padding: "8px" }}
                      >
                        ₹ {Number(item.store)}
                      </td>{" "}
                      <td
                        style={{ border: "1px solid silver", padding: "8px" }}
                      >
                        ₹ {Number(item.costAllocation)}
                      </td>{" "}
                    </tr>
                  );
                })}
              </tbody>
            </>
          );
        })()}
      </table>

      <h5 className="mb-2">Raw Material</h5>
      <table
        className="documentTable mb-2"
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid silver",
        }}
      >
        {(() => {
          return (
            <>
              <thead>
                <tr>
                  <th
                    style={{
                      border: "1px solid silver",
                      padding: "8px",
                      width: "100px",
                    }}
                  >
                    S.No
                  </th>
                  <th
                    style={{
                      border: "1px solid silver",
                      padding: "8px",
                      width: "400px",
                    }}
                  >
                    Item Description
                  </th>
                  <th style={{ border: "1px solid silver", padding: "8px" }}>
                    UOM
                  </th>
                  <th style={{ border: "1px solid silver", padding: "8px" }}>
                    Qty.
                  </th>
                  <th style={{ border: "1px solid silver", padding: "8px" }}>
                    Store
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataToPreview?.rawMaterials?.map(
                  (item: any, index: number) => {
                    if (!item.quantity || item.quantity === 0) return null;

                    return (
                      <tr key={item.id}>
                        <td
                          style={{
                            border: "1px solid silver",
                            padding: "8px",
                            width: "100px",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            border: "1px solid silver",
                            padding: "8px",
                            width: "400px",
                          }}
                        >
                          <span>{item.itemName}</span>
                          <br />
                        </td>
                        <td
                          style={{ border: "1px solid silver", padding: "8px" }}
                        >
                          {item.UOM}
                        </td>
                        <td
                          style={{ border: "1px solid silver", padding: "8px" }}
                        >
                          {item.quantity}
                        </td>
                        <td
                          style={{ border: "1px solid silver", padding: "8px" }}
                        >
                          ₹ {Number(item.store)}
                        </td>{" "}
                      </tr>
                    );
                  }
                )}
              </tbody>
            </>
          );
        })()}
      </table>

      <h5 className="mb-2">Scrap Material</h5>
      <table
        className="documentTable mb-2"
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid silver",
        }}
      >
        {(() => {
          return (
            <>
              <thead>
                <tr>
                  <th
                    style={{
                      border: "1px solid silver",
                      padding: "8px",
                      width: "100px",
                    }}
                  >
                    S.No
                  </th>
                  <th
                    style={{
                      border: "1px solid silver",
                      padding: "8px",
                      width: "400px",
                    }}
                  >
                    Item Description
                  </th>
                  <th style={{ border: "1px solid silver", padding: "8px" }}>
                    UOM
                  </th>
                  <th style={{ border: "1px solid silver", padding: "8px" }}>
                    Qty.
                  </th>
                  <th style={{ border: "1px solid silver", padding: "8px" }}>
                    Store
                  </th>
                  <th style={{ border: "1px solid silver", padding: "8px" }}>
                    Cost Allocation %
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataToPreview?.scrapMaterials?.map(
                  (item: any, index: number) => {
                    if (!item.quantity || item.quantity === 0) return null;

                    return (
                      <tr key={item.id}>
                        <td
                          style={{
                            border: "1px solid silver",
                            padding: "8px",
                            width: "100px",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            border: "1px solid silver",
                            padding: "8px",
                            width: "400px",
                          }}
                        >
                          <span>{item.itemName}</span>
                          <br />
                        </td>
                        <td
                          style={{ border: "1px solid silver", padding: "8px" }}
                        >
                          {item.UOM}
                        </td>
                        <td
                          style={{ border: "1px solid silver", padding: "8px" }}
                        >
                          {item.quantity}
                        </td>
                        <td
                          style={{ border: "1px solid silver", padding: "8px" }}
                        >
                          ₹ {Number(item.store)}
                        </td>{" "}
                        <td
                          style={{ border: "1px solid silver", padding: "8px" }}
                        >
                          ₹ {Number(item.costAllocation)}
                        </td>{" "}
                      </tr>
                    );
                  }
                )}
              </tbody>
            </>
          );
        })()}
      </table>

      <h5 className="mb-2">Additional Charges</h5>
      {dataToPreview?.additionalCharges &&
      dataToPreview.additionalCharges.length > 0 ? (
        <table
          className="documentTable"
          style={{
            width: "100%",
            borderCollapse: "collapse",
            border: "1px solid silver",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid silver",
                  padding: "8px",
                  width: "100px",
                }}
              >
                S.No
              </th>
              <th style={{ border: "1px solid silver", padding: "8px" }}>
                Charges Name
              </th>
              <th style={{ border: "1px solid silver", padding: "8px" }}>
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {dataToPreview.additionalCharges.map((item, index) => (
              <tr key={item.key}>
                <td
                  style={{
                    border: "1px solid silver",
                    padding: "8px",
                    width: "100px",
                  }}
                >
                  {index + 1}
                </td>
                <td style={{ border: "1px solid silver", padding: "8px" }}>
                  {item.chargesName}
                </td>
                <td style={{ border: "1px solid silver", padding: "8px" }}>
                  ₹ {item.amount}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MaterialSlicer);
