export const salesTimeline = [
    { key: "Sales Enquiry", label: "Sales Enquiry", action: "+ Sales Enquiry" },
    { key: "Sales Quotation", label: "Sales Quotation", action: "+ Sales Quotation" },
    { key: "Order Confirmation", label: "Order Confirmation", action: "+ Order Confirmation" },
    { key: "Advance Receipt", label: "Advance Receipt", action: "+ Advance Receipt" },
    { key: "Delivery Challan", label: "Delivery Challan", action: "+ Delivery Challan" },
    { key: "Invoice", label: "Invoice", action: "+ Invoice" },
    { key: "Sales Return", label: "Sales Return", action: "+ Sales Return" },
  ];
  
  export const purchaseTimeline = [
    { key: "Purchase Order", label: "Purchase Order", action: "+ Purchase Order" },
    { key: "Advance Receipt", label: "Advance Receipt", action: "+ Advance Receipt" },
    { key: "Goods Received Note", label: "Goods Received Note", action: "+ Goods Received Note" },
    { key: "Quality Report", label: "Quality Report", action: "+ Quality Report" },
    { key: "Purchase Invoice", label: "Purchase Invoice", action: "+ Purchase Invoice" },
    { key: "Purchase Return", label: "Purchase Return", action: "+ Purchase Return" },
  ];

  export const salesDocumentLabels: { [key: string]: string } = {
    SE: "Sales Enquiry",
    SQ: "Sales Quotation",
    OC: "Order Confirmation",
    DC: "Delivery Challan",
    INV: "Sales Invoice",
    SR: "Sales Return",
  };

 export const purchaseDocumentLabels: { [key: string]: string } = {
    PO: "Purchase Order",
    GRN: "Goods Receive Note",
    QR: "Quality Report",
    PINV: "Purchase Invoice",
    DN: "Debit Note",
    CN: "Credit Note",
    PR: "Purchase Return",
  };