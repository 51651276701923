import { Breadcrumb, Button, Layout } from "antd";
import React, { useRef } from "react";
import MaterialSlicer from "./MaterialSlicer";
import { Link } from "react-router-dom";
import {
  ArrowLeftOutlined,
  DownloadOutlined,
  PrinterOutlined,
  EditOutlined,
  MailOutlined,
  WhatsAppOutlined,
  EllipsisOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import materialData from "../../utility/materialData.json";

const PreviewBillMaterial: React.FC = () => {
  const quotationRef = useRef<HTMLDivElement>(null);

  const downloadPDF = async () => {
    const element = quotationRef.current;
    if (element) {
      const html2pdf = (await import("html2pdf.js")).default;

      html2pdf()
        .from(element)
        .set({
          margin: 0,
          filename: "quotation.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        })
        .save();
    }
  };

  const breadcrumbItems = [
    "Dashboard",
    "Production Section",
    "Bill of Material",
    "Preview Bill of Material",
  ];

  return (
    <>
      <Layout className="layout">
        <Breadcrumb>
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div className="flexBox mt-10 documentMobileRow">
          <div className="divDocuments Mt-10">
            <Link to="/create_bill_of_material" className="linkToDocs">
              <p className="textDocument">
                <ArrowLeftOutlined /> BOM-2001
              </p>
            </Link>
          </div>
          <div className="flexBox documentButtonRow">
            <Button type="link" onClick={downloadPDF}>
              <DownloadOutlined /> Download
            </Button>
            <Button type="primary">
              <EditOutlined /> Edit
            </Button>
            <Button type="link">
              <DeleteOutlined /> Delete
            </Button>
          </div>
        </div>
        <div className="documentContainer">
          <MaterialSlicer
            dataToPreview={materialData.dataToPreview}
            companyName={materialData.companyName}
          />
        </div>
      </Layout>
    </>
  );
};

export default PreviewBillMaterial;
