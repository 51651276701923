export const GET_CUSTOM_FIELDS = 'GET_CUSTOM_FIELDS';
export const GET_CUSTOM_FIELDS_SUCCESS = 'GET_CUSTOM_FIELDS_SUCCESS';
export const GET_CUSTOM_FIELDS_FAILED = 'GET_CUSTOM_FIELDS_FAILED';

export const ADD_CUSTOM_FIELDS = 'ADD_CUSTOM_FIELDS';
export const ADD_CUSTOM_FIELDS_SUCCESS = 'ADD_CUSTOM_FIELDS_SUCCESS';
export const ADD_CUSTOM_FIELDS_FAILED = 'ADD_CUSTOM_FIELDS_FAILED';

export const EDIT_CUSTOM_FIELDS = 'EDIT_CUSTOM_FIELDS';
export const EDIT_CUSTOM_FIELDS_SUCCESS = 'EDIT_CUSTOM_FIELDS_SUCCESS';
export const EDIT_CUSTOM_FIELDS_FAILED = 'EDIT_CUSTOM_FIELDS_FAILED';

export const DELETE_CUSTOM_FIELDS = 'DELETE_CUSTOM_FIELDS';
export const DELETE_CUSTOM_FIELDS_SUCCESS = 'DELETE_CUSTOM_FIELDS_SUCCESS';
export const DELETE_CUSTOM_FIELDS_FAILED = 'DELETE_CUSTOM_FIELDS_FAILED'; 