import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import "../../Utility/css/pink-theme.css";
import "../../Utility/css/purple-theme.css";
import "../../Utility/css/violet-theme.css";
import "../../Utility/css/skyblue-theme.css";
import "../../Utility/css/magenta-theme.css";
import "../../Utility/css/crocus-theme.css";
import "../../Utility/css/red-theme.css";
import "../../Utility/css/green-theme.css";
import "../../Utility/css/blue-theme.css";
import "../../Utility/css/responsive.css";
import { Link } from "react-router-dom";
import "../../Utility/css/bootstrap.min.css";
import "../../Utility/css/flaticon.css";
import "../../Utility/css/menu.css";
import "../../Utility/css/magnific-popup.css";
import "../../Utility/css/owl.carousel.min.css";
import "../../Utility/css/owl.theme.default.min.css";
import "../../Utility/css/lunar.css";
import "../../Utility/css/animate.css";
import "../../Utility/css/pink-theme.css";
import "../../Utility/css/blue-theme.css";
import "../../Utility/css/responsive.css";
import "../../Utility/css/fonts/rubik.css";
import "../../Utility/css/fonts/jakarta.css";
import "../../Utility/css/fonts/inter.css";
import { blogPosts } from "../../Utility/data";
import Footer from "./Footer";
import { getBlogCategories, getBlogs } from "../../redux/actions/API/blogs";
import { connect } from "react-redux";
import "./Blogs.css";
import Loader from "../Loader/Loader";
import { Card, Col, notification, Row, Select, Skeleton } from "antd";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
interface IBlogs {
  getBlogs: Function;
  blogsData: any;
  getBlogsState: IDataResponse;
  getBlogCategories: Function;
  getBlogCategoriesState: IDataResponse;
  blogCategoriesData: any;
}
type NotificationType = "success" | "info" | "warning" | "error";
const openNotificationWithIcon = (
  type: NotificationType,
  message: string
) => {
  notification.open({
    message: message,
    type: type,
    duration: 3,
  });
};

const Blogs: React.FC<IBlogs> = ({ getBlogs, blogsData, getBlogsState, getBlogCategories, getBlogCategoriesState, blogCategoriesData }) => {
  const prevPropsRef = useRef<any>();
  const postsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const totalPages = Math.ceil(blogPosts.length / postsPerPage);
  const startIndex = (currentPage - 1) * postsPerPage + 1;
  const endIndex = startIndex + postsPerPage;
  const [selectedCategory, setSelectedCategory] = useState<number | "all">("all");

  useEffect(() => {
    window.scrollTo(0, 0);
    getBlogs();
    getBlogCategories();
  }, []);

  useEffect(() => {
    if (
      prevPropsRef?.current?.getBlogsState?.loading && !getBlogsState?.loading
    ) {
      if (getBlogsState?.error?.length > 0) {
        openNotificationWithIcon("error", "Something went wrong!");
      } else {
        setLoading(false);
      }
    }
    if (
      prevPropsRef?.current?.getBlogCategoriesState?.loading && !getBlogCategoriesState?.loading
    ) {
      if (getBlogCategoriesState?.error) {
        setLoading(false);
        openNotificationWithIcon("error", "Something went wrong!");
      } else {
        setLoading(false);
      }
    }
    prevPropsRef.current = {
      getBlogs,
      getBlogsState,
      getBlogCategoriesState,
    };
  }, [getBlogsState]);

  const truncateText = (text: any, maxLength = 150) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  const filteredBlogs = selectedCategory === "all" || selectedCategory == null
    ? blogsData
    : blogsData?.filter((blog: any) => blog.blogCategory === selectedCategory);

  const renderBlogs = (blogs: any) => {
    return blogs.map((blogData: any, index: number) => {
      const updatedAtDate = new Date(blogData.updatedAt).toLocaleDateString();
      return (
        <div id="our-blogs" key={index} className="col-md-6 col-lg-4">
          <div className="blog-post mb-40 wow fadeInUp clearfix">
            <div className="blog-post-img mb-35">
              <img
                className="img-fluid r-16 mainBlogImage"
                src={blogData.imageURL}
                alt="blog-post-image"
              />
            </div>
            <div className="blog-post-txt">
              <span className={`post-tag`}>{blogData.header}</span>
              <h6 className="s-20 w-700">
                <Link to={`/blog_details/${blogData?.id}/${encodeURIComponent(blogData.title).replace(/%20/g, '-').replace(/%7D/g, '')}`}>
                  {blogData.title}
                </Link>
              </h6>
              <div className="blog-post-meta mt-20">
                <ul className="post-meta-list ico-10">
                  <li>
                    <p className="p-sm w-500">Author : {blogData.author}</p>
                  </li>
                  <li className="meta-list-divider">
                    <p>
                      <span className="flaticon-minus"></span>
                    </p>
                  </li>
                  <li>
                    <p className="p-sm">{updatedAtDate}</p>
                  </li>
                </ul>
              </div>
              <p>{truncateText(blogData.shortDesc)}</p>
              <Link to={`/blog_details/${blogData.id}/${encodeURIComponent(blogData.title).replace(/%20/g, '-').replace(/%7D/g, '')}`}>
                <div className="readMore">Read more...</div>
              </Link>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <Header />
      <div>
        {/* <Loader loading={loading} /> */}
        <section id="blog" className="container">
          <div className="flexBox mb-20">
            <div></div>
            <Select
              placeholder="Select Category"
              className="categorySelection"
              onChange={(value) => setSelectedCategory(value)}
            >
              <Select.Option value="all">All Categories</Select.Option>
              {blogCategoriesData?.map((item: any) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.category}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="posts-wrapper">
            <div className="row">{loading ? <>
              <Row gutter={[32, 32]} >
                <Col xs={24} sm={12} md={8}>
                <Skeleton active={loading} />
                </Col>
                <Col xs={24} sm={12} md={8}>
                <Skeleton active={loading} />
                </Col>
                <Col xs={24} sm={12} md={8}>
                <Skeleton active={loading} />
                </Col>
              </Row>
            </> : renderBlogs(filteredBlogs ?? [])}</div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  blogsData: state.api.getBlogs?.data,
  getBlogsState: state.api.getBlogs,
  blogCategoriesData: state.api.getBlogCategories?.data,
  getBlogCategoriesState: state.api.getBlogCategories,
});

const mapDispatchToProps = (dispatch: any) => ({
  getBlogs: (payload: any) => dispatch(getBlogs(payload)),
  getBlogCategories: (payload: any) => dispatch(getBlogCategories(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Blogs);
