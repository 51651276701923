import React, { useEffect, useState } from "react";
import { Input, Select, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import "./documnetTermsAndCondition.css";
import { AppConstants } from "../../../../Appconstants";
import { connect } from "react-redux";
import { setDocumentTermsAndConditionsData } from "../../../../redux/actions/UI/createDocument";
import { getTermsCondition } from "../../../../redux/actions/API/termsCondition";
const { TextArea } = Input;
const { Option } = Select;

interface ITermsAndConditionProps {
  companyId: number;
  dataSource: string;
  termsConditionData: any;
  getTermsCondition: Function;
  setDocumentTermsAndConditionsData: Function;
}

const TermsAndCondition: React.FC<ITermsAndConditionProps> = ({
  companyId,
  dataSource,
  termsConditionData,
  getTermsCondition,
  setDocumentTermsAndConditionsData,
}) => {
  const [termsCondition, setTermsCondition] = useState<string>("");
  const [selectedTerms, setSelectedTerms] = useState<number[]>([]);

  useEffect(() => {
    getTermsCondition({ companyId: Number(companyId) });    
  }, []); 

  useEffect(() => {
    if (termsCondition) {
      handleBlur();
    }
  }, [termsCondition, selectedTerms]);

  useEffect(() => {
    if (dataSource?.length > 0) {
      setTermsCondition(dataSource[0]);
    }
  }, [dataSource]);

  const handleBlur = () => {
    const newData = termsCondition
      ?.toString()
      ?.split("\n")
      ?.filter((line: string) => line.trim() !== "");
    setDocumentTermsAndConditionsData(newData);
  };

  return (
    <div className="flex-grow-1">
      <div className="title mb-20 fS-20">
        {AppConstants.TERMS_AND_CONDITIONS.TERMS_AND_CONDITIONS_LABEL} &nbsp;
        <Tooltip
          title={AppConstants.TERMS_AND_CONDITIONS.TERMS_AND_CONDITIONS_LABEL}
        >
          <InfoCircleOutlined className="info-icon" />
        </Tooltip>
      </div>
      <Select
        mode="multiple"
        placeholder="Select Terms"
        style={{ width: "100%", marginBottom: 10 }}
        onChange={(values) => {
          setSelectedTerms(values);
          const selectedDescriptions = values
            .map((id, index) => {
              const term = termsConditionData[0]?.terms[0]?.termsData.find(
                (t: { id: number; }) => t.id === id
              );
              return `Term ${index + 1}: ${term?.desc || ""}`;
            })
            .join("\n");
          setTermsCondition(selectedDescriptions);
        }}
        value={selectedTerms}
      >
        {termsConditionData?.[0]?.terms?.[0]?.termsData?.map((term: any) => (
          <Option key={term.id} value={term.id}>
            {term.term}
          </Option>
        ))}
      </Select>
      <TextArea
        style={{ resize: "none" }}
        className="termCondition-textarea mb-20"
        rows={10}
        maxLength={1000}
        showCount
        placeholder="Select Terms and condition"
        value={termsCondition}
        onChange={(e) => setTermsCondition(e.target.value)}
        onBlur={handleBlur}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userId: state.api.login.data?.id,
  companyId: state.api.login.data.companyId,
  termsConditionData: state.api.getTermsCondition?.data?.data,
  dataSource: state.ui.documentItemsGridData?.documentTermsAndConditionData,
});

const mapDispatchToProps = (dispatch: any) => ({
  setDocumentTermsAndConditionsData: (payload: any) => dispatch(setDocumentTermsAndConditionsData(payload)),
  getTermsCondition: (payload: any) => dispatch(getTermsCondition(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndCondition);
