import { Button, Col, Drawer, Form, Input, InputNumber, message, notification, Row, Select, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { getItems } from "../../redux/actions/API/items";
import { getStoreByItems, getStores } from "../../redux/actions/API/storesActions";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import "./StockTransfer.css";
import { stockTransfer } from "../../redux/actions/API/stockTransferAction";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
import Loader from "../../Home/Loader/Loader";
const { Option } = Select;

interface IStore {
  id: number;
  name: string;
  itemCount: number;
  availableQuantity?: number;
}

interface IStockTransferProps {
  title: string;
  onClose: () => void;
  open: boolean;
  // showCommentSection: boolean;
  // showActionButton: boolean;
  // showCurrentStock: boolean;
  itemsData: any[];
  getItems: Function;
  stockTransfer: Function;
  getStores: Function;
  getStoreByItems: Function;
  stockTransferState: IDataResponse;
  getItemState: IDataResponse,
  storeData: IStore[];
  storeByItemsData:any;
  companyId: Number;
  dataToSet: any;
  userId: any;
}

interface IRowData {
  key: string;
  item: string;
  fromStore: number | undefined;
  toStore: number | undefined;
  quantity: number | undefined;
  [key: string]: any;
}

const StockTransferMetaData: React.FC<IStockTransferProps> = ({
  title,
  onClose,
  open,
  itemsData,
  getItems,
  storeData,
  getStores,
  companyId,
  stockTransfer,
  stockTransferState,
  // showCommentSection,
  // showActionButton,
  // showCurrentStock,
  dataToSet,
  getStoreByItems,
  storeByItemsData,
  getItemState,
  userId
}) => {
  type NotificationType = "success" | "info" | "warning" | "error";
  
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

  const prevPropsRef = useRef<IStockTransferProps | null>(null);
  const [rows, setRows] = useState<IRowData[]>([
    {
      key: "1",
      item: '',
      fromStore: undefined,
      toStore: undefined,
      quantity: undefined,
      fromStoreDisabled: true,
    },
  ]);
  const [form] = Form.useForm();
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: boolean }>({});
  const [comment, setComment] = useState("");
  const [showStockProperty, setShowStockProperty] = useState<boolean>(false);
  const [showCommentSection, setShowCommentSection] = useState<boolean>(true);
  const [showCurrentStock, setShowCurrentStock] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]); 
  const [availableQuantities, setAvailableQuantities] = useState<Record<string, number>>({});

  useEffect(() => {
    getStores({ companyId: Number(companyId) });
    getItems({ companyId: Number(companyId) });
  }, []);

  useEffect(() => {
    if (dataToSet && dataToSet.itemId) {
      form.setFieldsValue({
        item: dataToSet.item,
      });
    }
  }, [dataToSet]);

  useEffect(() => {
    if (prevPropsRef.current?.stockTransferState?.loading && !stockTransferState.loading) {
      if (stockTransferState?.error > 0 ) {
        openNotificationWithIcon("error", "Something went wrong!");
        setLoading(false);
      } else {
        getItems({companyId: Number(companyId)})
        onCloseHandler()
        openNotificationWithIcon("success", stockTransferState?.data.message);
        setLoading(false);
      }
    }
    prevPropsRef.current = {
      title,
      onClose,
      open,
      itemsData,
      getItems,
      stockTransfer,
      getStores,
      stockTransferState,
      storeData,
      companyId,
      // showCommentSection,
      // showActionButton,
      // showCurrentStock,
      dataToSet,
      getStoreByItems, 
      storeByItemsData,
      getItemState,
      userId
    };
  }, [stockTransferState, companyId]);
  
  const onCloseHandler = () => {
    resetRows();
    onClose();
  };

  const addRow = () => { 
    const newRow = {
      key: Date.now().toString(),
      item: '',
      fromStore: undefined,
      toStore: undefined,
      quantity: undefined,
      fromStoreDisabled: true,
    };
    setRows([...rows, newRow]);
  };

  const resetRows = () => {
    setRows([{
      key: "1",
      item: '',
      fromStore: undefined,
      toStore: undefined,
      quantity: undefined,
      fromStoreDisabled: true,
    }]);

    setComment("");
    setValidationErrors({});
    form.resetFields();
  };

  const deleteRow = (key: string) => { 
    setRows(rows.filter((row) => row.key !== key));
  };

  const handleItemChange = (key: string, field: string, value: string) => {
    const updatedRows = rows.map((row) =>
      row.key === key
        ? { ...row, [field]: value, fromStoreDisabled: false }
        : row
    );
    setRows(updatedRows);
    setSelectedItems([...selectedItems, value]);
    getStoreByItems({"itemId": value});
  };

  const handleToStoreChange = (key: string, value: any) => {
    const updatedRows = rows.map((row) =>
      row.key === key ? { ...row, toStore: value } : row
    );
    setRows(updatedRows);
  };
  
  const handleFromStoreChange = (key: string, storeId: number) => {
    const updatedRows = rows.map((row) =>
      row.key === key ? { ...row, fromStore: storeId } : row
    );
    setRows(updatedRows);
  
    const selectedStore = storeByItemsData?.find(
      (store: { storeId: number }) => store.storeId === storeId
    );
    if (selectedStore) {
      console.log(selectedStore)
      setAvailableQuantities((prevQuantities) => ({
        ...prevQuantities,
        [key]: selectedStore?.quantity 
      }));
    }
  };  
  
  const handleQuantityChange = (key: string, value: number) => {
    const updatedRows = rows.map((row) =>
      row.key === key ? { ...row, quantity: value } : row
    );
    setRows(updatedRows);
    validateQuantity(key, value, updatedRows);
  };
  
  const validateQuantity = (
    key: string,
    quantity: number | undefined,
    updatedRows: IRowData[]
  ) => {
    const row = updatedRows.find((row) => row.key === key);
    if (row?.item && row?.fromStore && quantity !== undefined) {
      const currentStock = availableQuantities[key] || 0;
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [key]: quantity > currentStock,
      }));
    }
  };

  const getAvailableItems = (currentKey: string) => {
    const selectedItems = rows?.map((row) => row.item);
    return itemsData?.filter(
      (item: any) =>
        !selectedItems.includes(item.id) ||
        rows.find((row) => row.key === currentKey)?.item === item.id
    );
  };

  const getAvailableStores = (currentKey: string, field: string) => {
    const selectedStores = rows?.reduce(
      (acc, row) => {
        if (row.key !== currentKey) {
          if (row.toStore) acc?.toStores.push(row.toStore);
        }
        return acc;
      },
      { toStores: [] as number[] }
    );

    const currentRow = rows.find((row) => row.key === currentKey);

    return storeData?.filter((store) => {
      const storeId = store.id;
      const isStoreInStoreByItemsData = storeByItemsData?.some(
        (item: { storeId: number }) => item.storeId === storeId
      );

      if (field === "toStore") {
        return (
          !selectedStores.toStores.includes(storeId) &&
          storeId !== currentRow?.fromStore && 
          (!isStoreInStoreByItemsData || isStoreInStoreByItemsData) 
        );
      }
      return true;
    });
  };

  const getCurrentStock = (itemId: string) => {
    const item = itemsData.find((item) => item.itemId === itemId);
    return item ? item.currentStock : 'Not available';
  };
  
  const handleCommentChange = (e: any) => {
    setComment(e.target.value);
  };

  const generateTransferNumber = (() => {
    let lastTimestamp = Date.now() % 1000;
    let counter = 100;
  
    return () => {
      const currentTimestamp = Date.now() % 1000;
  
      if (currentTimestamp !== lastTimestamp) {
        lastTimestamp = currentTimestamp;
        counter = 100;
      } else if (counter >= 999) {
        counter = 100 + Math.floor(Math.random() * 900);
      }
  
      const transferNumber = (currentTimestamp * 1000 + counter).toString().padStart(6, '0');
      counter += 1;
  
      return Number(transferNumber);
    };
  })();

  const handleTransferClick = () => {
    form.validateFields()
      .then(() => {
        setLoading(true);
        const formData = form.getFieldsValue();
        const stockData = rows.map((row) => ({
          fromStoreId: formData[`fromStore-${row.key}`] || row.fromStore,
          itemId: formData[`item-${row.key}`] || row.item,
          toStoreId: formData[`toStore-${row.key}`] || row.toStore,
          quantity: Number(formData[`quantity-${row.key}`]) || row.quantity,
          transferDate: new Date().toISOString(),
        }));
        const payload = {
          transferNumber: generateTransferNumber(),
          transferredBy: userId,
          companyId: Number(companyId),
          comment: comment || "", 
          stockData,
        };
        stockTransfer(payload);
      })
      .catch((error) => {
        openNotificationWithIcon("error", "Please fill all required fields.");
      });
  };

  return (
    <>
      <Loader loading={loading} />
      <Drawer
        width={740}
        title={title}
        onClose={onCloseHandler}
        open={open}
        extra={
          <Space>
            <Button className="buttomCancelMobView" onClick={onCloseHandler}>Cancel</Button>
            <Button type="primary" onClick={handleTransferClick}>
              Transfer
            </Button>
          </Space>
        }
      >
        {/* {JSON.stringify(dataToSet)} */}
        <Form layout="vertical" form={form}>
          {rows.map((row, index) => (
            <Row gutter={18} key={row.key} align="middle" style={{ marginTop: index > 0 ? '30px' : '0px' }}>
              <Col span={5} xs={12} sm={12} lg={5}>
                <Form.Item
                  label="Item"
                  name={showCurrentStock ? "itemName" : `item-${row.key}`}
                  rules={!showCurrentStock ? [{ required: true, message: "Please select an item" }] : []}
                >
                  {/* {showCurrentStock && dataToSet ? (
                    <Input
                      placeholder="Please enter item"
                      disabled={!!dataToSet}
                      value={dataToSet.itemName}
                    />
                  ) : ( */}
                    <Select
                      placeholder="Select item"
                      value={row.item }
                      onChange={(value) =>
                        handleItemChange(row.key, "item", value)
                      }
                      optionLabelProp="label"
                    >
                      {getAvailableItems(row.key)?.map((item: any) => (
                        <Option
                          key={item.id}
                          value={item.id}
                          label={item.itemName}
                        >
                          <div className="stockDropdown">
                            <span className="stockDropdownTitle">
                              {item.itemName}
                            </span>
                            <div
                              style={{
                                display: !showStockProperty ? "block" : "none",
                              }}
                            >
                              <div style={{ fontSize: "12px", color: "#888" }}>
                                Item-Id: {item.itemId}
                              </div>
                            </div>
                          </div>
                        </Option>
                      ))}
                    </Select>
                  {/* )} */}
                </Form.Item>
              </Col>
              <Col span={5} xs={12} sm={12} lg={5}>
                <Form.Item
                  label="From Store"
                  name={`fromStore-${row.key}`}
                  rules={[
                    { required: true, message: "Please select a from store" },
                  ]}
                >
                  <Select
                    placeholder="Select store"
                    value={row.fromStore}
                    onChange={(value) => handleFromStoreChange(row.key, value)}
                    onDropdownVisibleChange={(data) =>
                      setShowStockProperty(data)
                    }
                    optionLabelProp="label"
                    disabled={row.fromStoreDisabled}
                  >
                    {storeByItemsData?.map((store: any) => (
                      <Option
                        key={store.storeId}
                        value={store.storeId}
                        label={store.storeName}
                      >
                        <div className="stockDropdown">
                          <span className="stockDropdownTitle">
                            {store.storeName}
                          </span>
                          <div
                            style={{
                              display: showStockProperty ? "block" : "none",
                            }}
                          >
                            <div style={{ fontSize: "12px", color: "#888" }}>
                              Avl. Qty: {store.quantity}
                            </div>
                          </div>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {row.fromStore && (
                  <div className="stock-quantity">
                    Avl Qty: {availableQuantities[row.key] || 0}
                  </div>
                )}
              </Col>

              <Col span={5} xs={12} sm={12} lg={5}>
                <Form.Item
                  label="To Store"
                  name={`toStore-${row.key}`}
                  rules={[
                    { required: true, message: "Please select a to store" },
                  ]}
                >
                  <Select
                    placeholder="Select store"
                    value={row.toStore}
                    onDropdownVisibleChange={(data) => setShowStockProperty(data)}
                    onChange={(value) => handleToStoreChange(row.key, value)}
                    optionLabelProp="label"
                  >
                    {getAvailableStores(row.key, "toStore")?.map(
                      (store: IStore) => (
                        <Option
                          key={store.id}
                          value={store.id}
                          label={store.name}
                        >
                          <div className="stockDropdown">
                            <span className="stockDropdownTitle">
                              {store.name}
                            </span>
                            <div
                              style={{
                                display: showStockProperty ? "block" : "none",
                              }}
                            >
                              <div style={{ fontSize: "12px", color: "#888" }}>
                                Avl. Qty: {store.availableQuantity}
                              </div>
                            </div>
                          </div>
                        </Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={5} xs={12} sm={12} lg={5}>
                <Form.Item
                  label="Quantity"
                  name={`quantity-${row.key}`}
                  rules={[
                    { required: true, message: "Please enter quantity!" },
                    {
                      pattern: /^[1-9][0-9]*$/,  
                      message: "Enter valid quantity!",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    min={1}
                    max={
                      storeByItemsData?.find(
                        (store: { storeId: number | undefined }) =>
                          store.storeId === row.fromStore
                      )?.quantity
                    }
                    placeholder="Enter quantity"
                    value={row.quantity}
                    onChange={(e: any) => {
                      handleQuantityChange(row.key, Number(e?.target?.value));
                    }}
                />
                  {/* <InputNumber
                    type="number"
                    min={1}
                    max={
                      storeByItemsData?.find(
                        (store: { storeId: number | undefined }) =>
                          store.storeId === row.fromStore
                      )?.quantity
                    }
                    placeholder="Enter quantity"
                    value={row.quantity}
                    onChange={(e: any) => {
                      handleQuantityChange(row.key, Number(e?.target?.value));
                    }}
                  /> */}
                </Form.Item>
              </Col>
                <Col span={4}>
                  {index === 0 ? (
                    <Button
                      className="button-action"
                      icon={<PlusOutlined />}
                      onClick={addRow}
                    />
                  ) : (
                    <Button
                      className="button-action"
                      icon={<DeleteOutlined />}
                      danger
                      onClick={() => deleteRow(row.key)}
                    />
                  )}
                </Col>
            </Row>
          ))}
     
          {showCommentSection && (
            <Row gutter={18} align="middle" className="stockTransferCommentMobView">
              <Col span={24}>
                <Form.Item label="Comment">
                  <Input.TextArea
                    value={comment}
                    onChange={handleCommentChange}
                    placeholder="Add your comments here"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  itemsData: state.api.getItems?.data?.reverse(),
  storeData: state.api.getStores?.data?.reverse(),
  userId: state.api.login.data?.id,
  storeByItemsData: state.api.getStoreByItems?.data,
  companyId: state.api.login.data.companyId,
  stockTransferState: state.api.stockTransfer,
  getItemState: state.api.getItems,
});

const mapDispatchToProps = (dispatch: any) => ({
  getItems: (payload: any) => dispatch(getItems(payload)),
  getStores: (payload: any) => dispatch(getStores(payload)),
  getStoreByItems: (payload: any) => dispatch(getStoreByItems(payload)),
  stockTransfer: (payload: any) => dispatch(stockTransfer(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StockTransferMetaData);
