import * as type from "../../types/API/transferHistory";

export function itemStockTransferHistory(payload: any) {
  return {
    type: type.ITEM_STOCK_TRANSFER_HISTORY,
    payload: payload,
  };
}

export function stockTransferHistory(payload: any) {
  return {
    type: type.STOCK_TRANSFER_HISTORY,
    payload: payload,
  };
}
