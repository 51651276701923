import React, { useState } from "react";
import { Breadcrumb, Layout, Menu } from "antd";
import { Content } from "antd/es/layout/layout";
import {
  CheckCircleOutlined,
  FileOutlined,
  FileSearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import SetupPermissionModal from "./SetupPermission";
import Logs from "./approval-logs/Logs";
import DocumentsList from "./ApproveDocumentList";

const DocsApproval: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState("SetUpPermission");

  const menuItems = [
    {
      key: "SetUpPermission",
      icon: <SettingOutlined />,
      label: "Set-up Permission",
    },
    {
      key: "logs",
      icon: <FileSearchOutlined />,
      label: "Logs",
    },
    {
      key: "approveDocuments",
      icon: <CheckCircleOutlined />,
      label: "Approve Documents",
    },
  ];

  const handleMenuClick = ({ key }: { key: string }) => {
    setSelectedTab(key);
  };

  return (
    <Layout className="layout">
      <div>
        <Breadcrumb
          items={[{ title: "Dashboard" }, { title: "Docs Approval" }]}
        />
      </div>
      <Content className="content-section">
        <div className="textDocument">
          <FileOutlined style={{ marginRight: "8px" }} />
          Docs Approval
        </div>

        <div className="flexBox">
          <Menu
            style={{ flex: 1 }}
            className="mt-20 menu-account"
            defaultSelectedKeys={["SetUpPermission"]}
            mode="inline"
            theme="light"
            items={menuItems}
            onClick={handleMenuClick}
          />

          <div className="mt-20" style={{ flex: 11 }}>
            {selectedTab === "SetUpPermission" ? (
              <SetupPermissionModal />
            ) : selectedTab === "logs" ? (
              <Logs />
            ) : selectedTab === "approveDocuments" ? (
              <DocumentsList />
            ) : (
              <div>Add Another Tab</div>
            )}
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default DocsApproval;
